import styled from "styled-components";

const SelectComponent = styled.select`
  width: 100px;
  height: 35px;
  background: white;
  color: #605d5d;
  padding-left: 5px;
  font-size: 14px;
  border: none;
  margin-left: 10px;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

interface SelectProps{
    option1:string;
    option2:string;
    value1:string;
    value2:string;
    selectName:string;
    value:any;
    handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export function Select({option1,option2,value1,value2,selectName,value,handleChange}: SelectProps){
return (
    <SelectComponent  value={value} onChange={(e)=>handleChange(e)}
    >
        <option value="" hidden>
        {selectName}
        </option>
        <option value={value1}>{option1}</option>
        <option value={value2}>{option2}</option>
    </SelectComponent>
)
}