import PageWithHeader from 'components/ReusableDivs/PageWithHeader';
import PageWrapper from 'components/ReusableDivs/PageWrapper';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  TextField,
  IconButton, Typography, Box, Tooltip
} from '@mui/material';
import { usersService } from '../../services/usersService';
import CottageIcon from '@mui/icons-material/Cottage';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ActionButton from 'components/InputControls.tsx/ActionButton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import NoData from 'components/ReusableDivs/NoData';
import Empty from 'components/ReusableDivs/Empty';
import { StyledHead } from 'screens/Menu/Patients';
import { StyledTable, StyledTableCell } from 'styles/tables/TableStyles';
import { ActionsWrapper } from './PatientManagers';
import { CancelBtn, ConfirmBtn, StyledDialogContent, StyledDialogContentText, StyledDialogMessage, StyledDialogTitle } from 'styles/dialogs/DialogStyles';
import SpinnerComponent from 'components/spinners/SpinnerComponent';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { formatPhoneNumber } from 'utils/formatters/phoneNumberFormatter';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from 'utils/Constants';

function ManagerPatients() {
  const [patients, setPatients] = useState([]);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [patientId, setpatientId] = useState('');
  const [isEmpty, setIsEmpty] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [list, setlist] = useState([])
  const [value, setValue] = useState<string | null>(list[0]);

  const [selectedPatient, setselectedPatient] = useState('')
  const [patientsList, setpatientsList] = useState([])
  const [refresh, setrefresh] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [isError, setisError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const navigate = useNavigate();
  const redirect = (page: string) => {
    navigate(`/${page}`);
  };
  const userRole = useSelector((state: any) => state.userDomisante.userRole);
  const orgaId = useSelector((state: any) => state.userDomisante.managedOrganizationId);
  const acc_token = useSelector((state: any) => state.userDomisante.userToken);

  const { firstname, lastname, id } = useParams()
  let title = 'Liste des bénéficiaires';

  if (firstname && lastname) {
    title = 'Liste des bénéficiaires de ' + firstname + ' ' + lastname;
  }

  useEffect(() => {
    usersService.fetchAssociatedPatientManager(setPatients, id!, true, acc_token);
    if (patients.length === 0) {
      setIsEmpty(true);
    }
  }, [refresh]);

  async function removeRelation(id_patient: string, id: string) {

    setisLoading(true)
    let response = await usersService.removeAssociation(id_patient, id!, acc_token);
    if (response == 200) {
      setisLoading(false)
      setIsSuccess(true)
      setrefresh(!refresh)

    }
    else {
      setisError(true)
      setisLoading(false)
    }
  }

  function closeDeleteModal() {
    setDeleteModalIsOpen(false);
    setIsSuccess(false)
    setisError(false)
    setisLoading(false)
  }
  const closeAddManager = async () => {
    setOpenAdd(false)
    setisError(false)
    setIsSuccess(false)
    setisLoading(false)
  }

  const AssignManagerToPatient = async (managerId: string) => {
    setisLoading(true)
    let data = {
      managerId: id!,
      patientId: selectedPatient
    }
    let response = await usersService.associateManagerPatient(data, acc_token)
    if (response == 200) {
      setIsSuccess(true)
      setisLoading(false)
      setrefresh(!refresh)
      setselectedPatient('')
    }
    else {
      setisError(true)
      setisLoading(false)
    }


  };
  const handleChange = (e: any, v: any) => {
    setValue(v);
    setselectedPatient(v!.id)
  }

  function formatpatientsList(array: any[]) {
    let formatted: any = []
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      formatted.push({ label: `${element['firstname']} ${element['lastname']}`, id: `${element['id']}` })
    }
    setlist(formatted)
  }


  useEffect(() => {

    /**
     * Il faut que l'organization id soit spécifié dés le chargement
     */
    if (orgaId != null) {
      usersService.fetchPatients(setpatientsList, acc_token, orgaId!.toString())
      if (patientsList.length > 0) {
        formatpatientsList(patientsList)
      }
    }


  }, [patientsList.length])

  return (
    <PageWithHeader>
      <PageWrapper title={title} backBtnContainer={true}   >

        <StyledHead >
          <div style={{ width: '120px' }}>
            <ActionButton title="Ajouter" onClick={() => setOpenAdd(true)} />
          </div>
        </StyledHead>
        {patients.length > 0 &&
          <TableContainer>
            <StyledTable size="medium" aria-label="a dense table">
              <TableHead>
                <TableRow
                  sx={{
                    borderBottom: '2px solid #C2D4D8'
                  }}
                >
                  <StyledTableCell sx={{ color: '#545454', fontWeight: 'bold' }} align="center">
                    Nom
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: '#545454', fontWeight: 'bold' }} align="center">
                    Prénom
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: '#545454', fontWeight: 'bold' }} align="center">
                    Email
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: '#545454', fontWeight: 'bold' }} align="center">
                    Date de naissance{' '}
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: '#545454', fontWeight: 'bold' }} align="center">
                    Numéro de téléphone{' '}
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: '#545454', fontWeight: 'bold' }} align="center">
                    Numéro de sécurité sociale{' '}
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: '#545454', fontWeight: 'bold' }} align="center">
                    Foyer{' '}
                  </StyledTableCell>
                  {(userRole == 'aidant' || userRole == 'professionnel de santé') && (
                    <StyledTableCell sx={{ color: '#545454', fontWeight: 'bold' }} align="center">
                      Agenda{' '}
                    </StyledTableCell>
                  )}
                  <StyledTableCell sx={{ color: '#545454', fontWeight: 'bold' }} align="center">

                    Aidants{' '}

                  </StyledTableCell>
                  <StyledTableCell sx={{ color: '#545454', fontWeight: 'bold' }} align="center">
                    Retirer{' '}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {patients.length > 0 ? (
                  patients.map((row) => {
                    return (
                      <TableRow key={row['id']} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <StyledTableCell component="th" scope="row" align="center">
                          {row['lastname']}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="center">
                          {row['firstname']}
                        </StyledTableCell>
                        <StyledTableCell align="center" style={{
                          maxWidth: "150px",
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                        }}>{row['email']}</StyledTableCell>
                        <StyledTableCell align="center">{dayjs(row['birthDate']).format('DD/MM/YYYY')}</StyledTableCell>
                        <StyledTableCell align="center">{formatPhoneNumber(row['phoneNumber'])}</StyledTableCell>
                        <StyledTableCell align="center">{row['ssn']}</StyledTableCell>
                        <StyledTableCell align="center">
                          {row['home'] != null &&
                            <Tooltip title="Consulter les détails du foyer" placement="top">
                              <IconButton
                                to={`/home-details/${row['home']['id']}`}
                                component={Link}>
                                <CottageIcon style={{ color: '#18C5BF' }} />
                              </IconButton>
                            </Tooltip>}

                        </StyledTableCell>

                        {(userRole == 'aidant' || userRole == 'professionnel de santé') && (
                          <StyledTableCell align="center">
                            <Tooltip title="Consulter l'agenda" placement="top">
                              <IconButton onClick={() => redirect(`calendar?firstname=${row['firstname']}&_id=${row['id']}`)}>
                                <CalendarMonthIcon style={{ color: '#18C5BF' }} />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        )}

                        <StyledTableCell align="center">
                          <Tooltip title="Consulter la liste des managers" placement="top">
                            <IconButton
                              to={`/patient-managers/${row['firstname']}/${row['lastname']}/${row['id']}`}
                              component={Link}>
                              <PeopleAltIcon style={{ color: '#F4C274', margin: 'auto' }} />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Tooltip title="Désaffecter le patient" placement="top">
                            <IconButton
                              onClick={() => {
                                setDeleteModalIsOpen(true);
                                setpatientId(row['id']);
                              }}
                            >
                              <DeleteIcon style={{ color: '#035282' }} />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <></>
                )}
              </TableBody>
            </StyledTable>
          </TableContainer>}
        {patients.length === 0 && !isEmpty && <NoData />}
        {patients.length === 0 && isEmpty && <Empty />}
        <Dialog
          open={deleteModalIsOpen}
          onClose={closeDeleteModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: 99 }}
        >

          <StyledDialogTitle id="alert-dialog-title">{'Êtes-vous sûr de vouloir supprimer cet aidant ?'}</StyledDialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => closeDeleteModal()}
            sx={{
              position: 'absolute',
              right: 20,
              top: 20,
              color: colors.dark_blue
            }}
          >
            <CloseIcon />
          </IconButton>

          <StyledDialogContent>
            {!isLoading && !isSuccess && !isError && <DialogContentText id="alert-dialog-description">La suppression est définitive</DialogContentText>
            }

            {
              isSuccess && <Box sx={{ padding: "50px 0px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Manager supprimé avec succès.</StyledDialogMessage>
                </Box>
              </Box>
            }
            {
              isError && <Box sx={{ padding: "50px 0px" }}>

                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer.</StyledDialogMessage>
                </Box>
              </Box>
            }
            {isLoading &&
              <Box sx={{ padding: "50px 0px" }}>
                <SpinnerComponent />
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
                </Box>
              </Box>
            }
          </StyledDialogContent>

          {!isLoading &&
            <DialogActions>
              <ActionsWrapper>
                <CancelBtn onClick={() => closeDeleteModal()}>{
                  !isLoading && !isError && !isSuccess ?
                    "Annuler" : "Fermer"
                }</CancelBtn>
                {!isLoading && !isSuccess && !isError && <ConfirmBtn onClick={() => removeRelation(id!, patientId)} autoFocus>
                  Supprimer
                </ConfirmBtn>}
              </ActionsWrapper>
            </DialogActions>}
        </Dialog>

        <Dialog

          open={openAdd} onClose={() => closeAddManager()} style={{ height: 900, paddingBottom: "50px", zIndex: 99 }} >
          <StyledDialogTitle>Ajouter un patient</StyledDialogTitle>

          <IconButton
            aria-label="close"
            onClick={() => closeAddManager()}
            sx={{
              position: 'absolute',
              right: 20,
              top: 20,
              color: colors.dark_blue
            }}
          >
            <CloseIcon />
          </IconButton>

          <StyledDialogContent >
            {!isLoading && !isError && !isSuccess
              &&
              <>


                <StyledDialogContentText>
                  Veuillez sélectionner le patient à assigner. Vous pouvez le chercher par son nom ou son prénom.
                </StyledDialogContentText>
                <div style={{ margin: "20px 20px 20px 0px", marginBottom: "50px" }}>

                  <Autocomplete
                    ListboxProps={{ style: { maxHeight: 100, overflow: 'auto', } }}
                    onChange={handleChange}
                    value={value || null}
                    disablePortal
                    id="combo-box-demo"
                    options={list}
                    sx={{ width: "100%", }}
                    renderInput={(params) => <TextField {...params} label="Patient" />}
                  />

                </div>
              </>
            }
            {isLoading && <Box sx={{ padding: "50px 0px" }}>

              <SpinnerComponent />
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
              </Box>
            </Box>
            }
            {
              isSuccess && <Box sx={{ padding: "50px 0px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Manager assigné avec succès.</StyledDialogMessage>
                </Box>
              </Box>
            }
            {
              isError && <Box sx={{ padding: "50px 0px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer.</StyledDialogMessage>
                </Box>
              </Box>
            }
          </StyledDialogContent>
          {
            <DialogActions>
              <ActionsWrapper>
                <CancelBtn onClick={() => closeAddManager()}>{
                  !isLoading && !isError && !isSuccess ?
                    "Annuler" : "Fermer"
                }</CancelBtn>
                {!isLoading && !isSuccess && !isError && <ConfirmBtn
                  onClick={() => AssignManagerToPatient(selectedPatient)}
                >Ajouter</ConfirmBtn>}
              </ActionsWrapper>
            </DialogActions>
          }

        </Dialog>
      </PageWrapper>
    </PageWithHeader>
  );
}

export default ManagerPatients;
