import styled from "styled-components";

export const LargeCardWrapper = styled.div`
  overflow: hidden;
  padding: 0 0 32px;
  margin: 48px auto 0;
  width: 85vw;
  font-family: Quicksand, arial, sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05), 0 0px 40px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background-color: white;
`; export
  const LargeCardHeading = styled.h1`
  font-size: 32px;
  font-weight: "600";
  text-align: center;
  color:#035282 ;
  font-family: open sans;
`; export
  const LargeCardHeader = styled.header`
  margin:0px 0px ;
  background-color: #fff;
  padding-top: 32px;
  padding-bottom: 32px;
  display:flex ;
  flex-direction:row ;
  align-items:center ;
  justify-content:center ;
  padding-left:50px ;
  padding-right:50px ;

`;