import Button from 'components/InputControls.tsx/Button';
import React, { useEffect, useState } from 'react';
import { colors } from 'utils/Constants';

interface OrgaCardProps {
  title: string;
  address?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  isSelected: boolean;
}


function OrgaCard({ title, address, onClick, isSelected }: OrgaCardProps) {
  const [isHovered, setIsHovered] = useState(false);
  function capitalizeFirstLetter(str: string) {
    let title = str.trimStart().trimEnd()
    return title.charAt(0).toUpperCase() + title.slice(1);
  }
  const cardStyles = {
    boxShadow: '0 0 20px (255, 255, 255, 0.08), 0 0px 40px (255, 255, 255, 0.08)',
    width: '20vw',
    minWidth: '250px',
    height: '30vh',
    backgroundColor: 'white',
    margin: '3vh auto',
    borderRadius: 5,
    border: isHovered ? "4px solid #F4C274" : "none", // Change the border color to the desired color on hover
    // Add other styles here
  };

  
  return (
    <div
      style={cardStyles}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={{
        textAlign: 'center', padding: '2vh', fontSize: '20px', fontWeight: 600, height: '6vh', color: "#035282", textTransform: "-moz-initial",

        paddingTop: !address ? "15%" : "13%"
      }


      }>
        {capitalizeFirstLetter(title)}
      </div>
      {
        address &&
        <div style={{ padding: '2vh 0', fontSize: '15px', textAlign: 'center', height: '6vh', color: colors.dark_blue }}>{address}</div>
      }

      <div style={{ width: '10vh', margin: 'auto', marginBottom: '5vh' }}>
        <Button title={'Choisir'} onClick={onClick} />
      </div>
    </div >
  );
}

export default OrgaCard;
