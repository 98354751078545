export const validateAssignedTablet = (values: any, setErrors: any) => {
  const errors = {
    imei: '',
    error: ''
  };
  const imeiRegex = /^\d{15}$/;
  if (values.imei !== '' && values.deviceID === '') {
    errors.imei = imeiRegex.test(values.imei) ? '' : 'imei non valide.';
  }
  if (values.deviceID === '' && values.imei === '') {
    errors.error = 'Veuillez entrer au moins une donnée';
  }
  if (values.imei === '' && values.deviceID !== '') {
    errors.imei = '';
  }

  setErrors(errors);
  return Object.values(errors).every((x) => x === '');
};
