type initialFieldsType = {
  patientId: string;
  measureType: string;
  minValue: number | string;
  maxValue: number | string;
  visibleForPatient: boolean;
};
export const validateNewMeasureLimit = (values: initialFieldsType, setNewLimitErrors: any) => {
  const errors = {
    patientId: '',
    measureType: '',
    minValue: '',
    maxValue: ''
  };

  if (!values.patientId) {
    errors.patientId = 'Veuillez spécifier le bénéficiaire';
  }
  if (!values.measureType) {
    errors.measureType = 'Veuillez spécifier le type';
  }
  if (values.minValue && values.maxValue) {
    if (values.minValue > values.maxValue) {
      errors.minValue = 'La valeur min doit être inférieure à la valeur max';
    }
  }
  if (values.minValue && Number(values.minValue) < 0) {
    errors.minValue = 'La valeur min doit être supèrieure à 0';
  }
  if (values.maxValue && Number(values.maxValue) < 0) {
    errors.maxValue = 'La valeur max doit être supèrieure à 0';
  }
  setNewLimitErrors(errors);
  return Object.values(errors).every((x) => x === '');
};
