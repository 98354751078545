import React, { useState, useEffect } from 'react'
import { TableContainer, TableHead, TableRow, TableBody, Typography, Box } from '@mui/material'
import { useSelector } from 'react-redux';
import { OrganizationService } from 'services/organizationsService';
import { StyledTable, StyledTableCell } from 'styles/tables/TableStyles';
import { Constants } from 'utils/Constants';

function MyOrganizations() {
  const { userInfo } = useSelector((state: any) => state.userDomisante);

  const [myOrganizations, setmyOrganizations] = useState([])
  const currentUserRole = useSelector((state: any) => state.userDomisante.userRole);

  const acc_token=useSelector((state:any) => state.userDomisante.userToken);
  useEffect(() => {
    if (currentUserRole.includes(Constants.organization_admin)) {
      OrganizationService.getUserOrganizations(acc_token,setmyOrganizations)
    }
    else {
      OrganizationService.getMyOrganization(setmyOrganizations,acc_token)
    }
  }, [])

  return (
    <Box sx={{ p: 3 }}>
      {
        currentUserRole.includes(Constants.organization_admin) ? <Typography style={{ textAlign: "start", fontSize: "17px", fontWeight: "500", color: "#035282", margin: "10px 0px" }}>Les organizations que vous gérez</Typography> :
          <Typography style={{ textAlign: "start", fontSize: "17px", fontWeight: "500", color: "#035282", margin: "10px 0px" }}>L'organisation à laquelle vous appartenez</Typography>
      }

      <TableContainer>
        <StyledTable size="medium" aria-label="a dense table">

          <TableHead>
            <TableRow
              sx={{
                borderBottom: '2px solid #C2D4D8'
              }}
            >
              <StyledTableCell sx={{ color: '#545454', fontWeight: 'bold' }} align="center">
                Nom de l'organisation
              </StyledTableCell>
              <StyledTableCell sx={{ color: '#545454', fontWeight: 'bold' }} align="center">
                Adresse
              </StyledTableCell>
              <StyledTableCell sx={{ color: '#545454', fontWeight: 'bold' }} align="center">
                Numéro de téléphone
              </StyledTableCell>
              <StyledTableCell sx={{ color: '#545454', fontWeight: 'bold' }} align="center">
                Email
              </StyledTableCell>



            </TableRow>
          </TableHead>
          <TableBody>
            {myOrganizations.length > 0 ? (
              myOrganizations.map((row) => (
                <TableRow key={row['id']} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <StyledTableCell component="th" scope="row" align="center">
                    {row['name']}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {row['address']}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row['phoneNumber']}</StyledTableCell>
                  <StyledTableCell align="center">{row['email']}</StyledTableCell>



                </TableRow>
              ))
            ) : (
              <></>
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
    </Box>
  )
}

export default MyOrganizations