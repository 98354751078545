import React from 'react'
import styled from "styled-components"
interface BlueSkyProps {
  title: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}


const BlueSkyButton = styled.button`
  display: block;
  width: 40%;
  padding: 15px 0;
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background-color: ${(props) => (props.disabled ? '#CCC' : '#8CC8DB')};
  border: 0;
  border-radius: 4px;
  font-family: open sans;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  padding-left: 7px;
  padding-right: 7px;

  &:active {
    background-color: #fff;
    color: #8cc8db;
    border: 1px solid #8cc8db;
  }

  &:hover {
    background-color: #fff;
    color: #8cc8db;
    border: 1px solid #8cc8db;
  }
`;

function histBlueSkyButton({ title, onClick, disabled }: BlueSkyProps) {
  return (
    <BlueSkyButton type="button" onClick={onClick} disabled={disabled}>
      {title}
    </BlueSkyButton>
  );
}
export default histBlueSkyButton