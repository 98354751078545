import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { OrganizationService } from "services/organizationsService";
import { logout, updateManageOrganizationIdState, updateManageOrgnaizationNameState, updateOrgaUniqueState, updateUserRole } from "store/userSlice";
import { Role } from "utils/Constants";

function LoginCheck() {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const { userInfo } = useSelector((state: any) => state.userDomisante);
    const tempToken = useSelector((state: any) => (state.tempToken ? state.tempToken : null));
    const loginChannel = useSelector((state: any) => (state.tempToken ? state.tempToken : null));

    const enable2FA = useSelector((state: any) => (state.enable2FA ? state.enable2FA : null));
    const acc_token = useSelector((state: any) => state.userDomisante.userToken);
    const shouldChangePwd = useSelector((state: any) =>
        state.userDomisante.userInfo ? state.userDomisante.userInfo.mustChangePwd : false
    );
    const initialUserRoles = useSelector((state: any) => state.userDomisante.userInfo ? state.userDomisante.userInfo.roles : null
    );

    async function checkNumberOrga(multiRoles: boolean) {
        const managedOrga = await OrganizationService.getUserOrganizations(acc_token);

        if (managedOrga.length > 1) {
            if (!multiRoles) {
                dispatch(updateUserRole(initialUserRoles));
            }
            console.log('Connected user has ', managedOrga);
            /**
             * *Redirecting to orga choice screen
             */
            navigate('/orga-choice');
        } else {
            dispatch(updateManageOrganizationIdState(managedOrga[0].id));
            dispatch(updateManageOrgnaizationNameState(managedOrga[0].name));
            dispatch(updateOrgaUniqueState(true));

            if (multiRoles) {
                navigate('/role-choice');
            } else {
                dispatch(updateUserRole(initialUserRoles));
                navigate('/users');
            }
        }
    }
    function redirectToRoleHomePage(role: string) {
        switch (role) {
            case 'organization admin':
                checkNumberOrga(false);
                break;
            case 'aidant':
            case 'professionnel de santé':
                navigate('/dashboard');
                break;
            case 'professional':
                navigate('/get-homes');
                break;
            case 'admin':
                navigate('/get-homes');
                break;
            case 'super admin':
                navigate('/get-homes');
                break;

            default:
                break;
        }
    }

    // useEffect(() => {
    //     if (userInfo != null && !isExpired(acc_token) && userInfo) {
    //         let shouldChangePwd = userInfo.mustChangePwd;
    //         console.log(' shouldChangePwd ***', shouldChangePwd);
    //         if (shouldChangePwd) {
    //             navigate('/update-pwd');
    //         } else {
    //             /**
    //              * *First step is checking if the user has multiple roles
    //              * *Next step is to check if he has the organization admin role,
    //              */
    //             let numberOfRoles = checkRoles(initialUserRoles);
    //             if (numberOfRoles > 1) {
    //                 /**
    //                  * * Checking if he has the organization admin role ,
    //                  */
    //                 let hasOrgaAdminRole = hasRole(initialUserRoles, Role.ORGANIZATION_ADMIN);

    //                 if (hasOrgaAdminRole == true) {
    //                     /**
    //                      * * Checking if the organization admin is managing multiple organizations
    //                      */
    //                     checkNumberOrga(true);
    //                 } else {
    //                     /**
    //                      * * If the connected user has multiple roles but is not an organization admi
    //                      */
    //                     navigate('/role-choice');
    //                 }
    //             } else {
    //                 dispatch(updateUserRole(initialUserRoles));
    //                 redirectToRoleHomePage(initialUserRoles);
    //             }
    //         }
    //     }
    // }, [userInfo, initialUserRoles,])


    useEffect(() => {
        if (userInfo != null && !isExpired(acc_token) && userInfo) {
            let shouldChangePwd = userInfo.mustChangePwd;
            console.log(' shouldChangePwd ***', shouldChangePwd);
            if (shouldChangePwd) {
                navigate('/update-pwd');
            } else {
                /**
                 * *First step is checking if the user has multiple roles
                 * *Next step is to check if he has the organization admin role,
                 */
                let numberOfRoles = checkRoles(initialUserRoles);
                if (numberOfRoles > 1) {
                    /**
                     * * Checking if he has the organization admin role ,
                     */

                    let hasOrgaAdminRole = hasRole(initialUserRoles, Role.ORGANIZATION_ADMIN);

                    if (hasOrgaAdminRole == true) {
                        /**
                         * * Checking if the organization admin is managing multiple organizations
                         */

                        checkNumberOrga(true);
                    } else {
                        /**
                         * * If the connected user has multiple roles but is not an organization admi
                         */
                        navigate('/role-choice');
                    }
                } else {
                    dispatch(updateUserRole(initialUserRoles));
                    redirectToRoleHomePage(initialUserRoles);
                }
            }
        } else if (tempToken && enable2FA == true) {
            navigate('/verify-otp');
        } else {
            dispatch(logout());
            navigate('/');
        }
    }, [acc_token, shouldChangePwd, tempToken, enable2FA]);



    function checkRoles(roles: string) {
        let numRoles = roles.split('|').length;
        return numRoles;
    }

    function hasRole(rolesString: string, role: string) {
        let roles = rolesString.split('|');
        roles = roles.map((role) => role.trim());
        return roles.includes(role);
    }
    const isExpired = (userToken: string) => {
        const decodedJwt = parseJwt(userToken);
        if (decodedJwt && decodedJwt.exp * 1000 < Date.now()) {
            return true;
        } else return false;
    };
    const parseJwt = (token: string) => {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    };
    return (
        <></>
    )
}

export default LoginCheck