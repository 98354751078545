import axios from 'axios';
import { is, curryN, gte } from 'ramda';
import { requestChecker } from 'utils/RequestCheck';

const isWithin = curryN(3, (min, max, value) => {
  const isNumber = is(Number);
  return isNumber(min) && isNumber(max) && isNumber(value) && gte(value, min) && gte(max, value);
});
const { REACT_APP_BASE_URL } = process.env

const in200s = isWithin(200, 299);

async function getLastMeasures(acc_token: string, patientId: string) {


  let url = REACT_APP_BASE_URL + `/measures/latest?patientId=` + patientId
  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`,
      },
      timeout: requestChecker.requestTimeout,
    });

    if (requestChecker.in200s(response.status)) {
      return response.data;
    } else {
      return null
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;

  }

}







async function getMeasures(acc_token: string, patientId: string, type: string, start_date?: string, end_date?: string, length?: number) {
  let url = REACT_APP_BASE_URL + `/measures?patientId=` + patientId + `&type=` + type
  if (start_date) {
    url += `&start_date=${start_date}`
  }
  if (end_date) {
    url += `&end_date=${end_date}`
  }
  if (length) {
    url += `&length=${length}`
  }
  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`,
      },
      timeout: requestChecker.requestTimeout,
    });

    if (requestChecker.in200s(response.status)) {
      return response.data;
    } else {
      return null
    }
  } catch (error: any) {
    console.log("Error: ", error, "url", url)
    requestChecker.HandleErrors(error, url);
    return null;

  }
}

async function exportAllMeasures(acc_token: string, patientId?: string, orgaId?: string) {
  let url = REACT_APP_BASE_URL + `/measures/all/export`

  if (patientId) {
    url += `?patientId=` + patientId
  }
  else if (orgaId) {
    url += '?organizationId=' + orgaId
  }
  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token} `,
      },
      timeout: requestChecker.requestTimeout,
      responseType: 'blob'

    });

    if (requestChecker.in200s(response.status)) {

      return response
    } else {
      return null
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;

  }
}
async function getMeasureLimits(acc_token: string, patientId: string) {
  let url = REACT_APP_BASE_URL + "/measures/measure-limits?patientId=" + patientId

  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token} `,
      },
      timeout: requestChecker.requestTimeout,
    });

    if (requestChecker.in200s(response.status)) {
      return response.data.data;
    } else {
      return null
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;

  }
}

async function getMeasureLimitsHistory(acc_token: string, patientId: string, measureType: string) {
  let url = REACT_APP_BASE_URL + "/measures/measure-limits/" + patientId + "/history?type=" + measureType

  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token} `,
      },
      timeout: requestChecker.requestTimeout,
    });

    if (requestChecker.in200s(response.status)) {
      return response.data;
    } else {
      return null
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;

  }
}

async function addMeasureLimits(acc_token: string, data: any) {
  let url = REACT_APP_BASE_URL + "/measures/measure-limits"


  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token} `
      },
      timeout: requestChecker.requestTimeout, // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });

    if (requestChecker.in200s(response.status)) {
      return true
    } else {
      return false//is it null or this one 
    }

  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }


}

async function deleteMeasureLimit(acc_token: string, measureLimit: string) {
  let url = REACT_APP_BASE_URL + "/measures/measure-limits/" + measureLimit

  try {
    let response = await axios.request({
      url: url,
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token} `,
      },
      timeout: requestChecker.requestTimeout, // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return true
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;

  }


}


async function connectToDoc2u(token: string) {
  let url = REACT_APP_BASE_URL + "/measures/connect-doc2u";
  console.log('Calling ConnectToDoc2u api =====================>', url);

  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: token,
      },
      timeout: requestChecker.requestTimeout, // Timeout after 5 seconds 5000,
    });

    if (requestChecker.in200s(response.status)) {
      return response.data.data.tokenKey;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

export const measuresService = {
  getMeasures,
  getLastMeasures,
  getMeasureLimitsHistory,
  getMeasureLimits,
  addMeasureLimits,
  deleteMeasureLimit,
  exportAllMeasures,
  connectToDoc2u
};