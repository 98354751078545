import styled, { css } from 'styled-components';
import { Box } from "@mui/material";
const CardWrapper = styled(Box)`
  overflow: hidden;
  padding: 0 0 32px;
  // margin: 48px auto 0;
  // margin: 48px auto 0;

  width: 35vw;
  font-family: Quicksand, arial, sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05), 0 0px 40px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background-color: white;
  @media (max-width: 900px) {
    width: 100%;
    flex-direction: column;
    margin-top:20px;
  }

 
 
`;

const CardHeader = styled.header`
  padding-top: 32px;
  padding-bottom: 32px;
  @media (max-width: 900px) {
    width:100% !important;
    flex-direction: column;
  }
`;

const CardHeading = styled.h1`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  padding:0px 20px 0px ;
  color:#18C5BF;
  @media (max-width: 768px) {
    width: 90%;
    flex-direction: column;
    margin-top:20px;
    font-size:18px;
  }
  
`;
const CardHeadingDescription = styled.h1`
  font-size: 18px;
  text-align: center;
  color: #939397;
  font-weight: 500;
  margin: 40px 50px 0 50px;
  @media (max-width: 768px) {
    width: 92%;
    display:flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
  }
`;

const CardBody = styled.div`
  padding-right: 50px;
  padding-left: 50px;
`;

const CardFieldset = styled.fieldset`
  position: relative;
  padding: 0;
  margin: 0;
  border: 0;

  & + & {
    margin-top: 24px;
  }

  &:nth-last-of-type(2) {
    margin-top: 32px;
  }

  &:last-of-type {
    text-align: center;
  }
`;

const CardInput = styled.input`
  padding: 7px 0;
  width: 100%;
  font-family: inherit;
  font-size: 14px;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #035282;
  border-left: 0;
  transition: border-bottom-color 0.25s ease-in;
  margin: 20px 0px;

  &:focus {
    border-bottom-color: #035282;
    outline: 0;
  }
`;

const CardOptionsNote = styled.small`
  padding-top: 8px;
  display: block;
  width: 100%;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
`;

const CardOptions = styled.ul`
  padding: 0;
  margin: 16px 0 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  list-style-type: none;
`;

const CardOptionsItem = styled.li`
  &:nth-of-type(n + 2) {
    margin-left: 16px;
  }
`;

const CardButton = styled.button`
display: block;
width: 100%;
padding: 15px 0;
font-family: inherit;
font-size: 16px;
font-weight: 700;
color: #fff;
background-color: #18C5BF;
border: 0;
border-radius: 4px;
font-family:open sans;
min-width:215px;
cursor: pointer;
transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
padding-left:7px;
padding-right:7px;
margin-top:15px;

&:active {
  transform: translate(0, -5px);
  background-color: #FFF;
  color:#18C5BF;
  border : 1px solid #18C5BF;
}
&:hover {
  transform: translate(0, -5px);
  background-color: #FFF;
  color:#18C5BF;
  border : 1px solid #18C5BF;
}
@media (max-width: 350px) {
  min-width:100px;
}
`;

const CancelButton = styled.button`
background-color: #fff;
color: #035282;
border: 1px solid #035282;
display: block;
width: 100%;
padding: 15px 0;
font-family: inherit;
font-size: 16px;
font-weight: 700;
border-radius: 4px;
font-family:open sans;
min-width:215px;
cursor: pointer;
transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
padding-left:7px;
padding-right:7px;
margin-top:15px;

&:active {
  transform: translate(0, -5px);
  background-color: #FFF;
  color:#18C5BF;
  border : 1px solid #18C5BF;
}
&:hover {
  transform: translate(0, -5px);
  background-color: #FFF;
  color:#18C5BF;
  border : 1px solid #18C5BF;
}
/* Update border color when button is disabled */
  ${(props) =>
    props.disabled &&
    css`
      background-color: #f8f8f8;
      color: #999999;
      border: 1px solid #999999;
      cursor: not-allowed;

      &:active,
      &:hover {
        transform: none;
        background-color: #f8f8f8;
        color: #999999;
        border: 1px solid #999999;
      }
    `}

@media (max-width: 350px) {
  min-width:100px;
}
`;

const CardLink = styled.a`
  display: inline-block;
  font-size: 12px;
  text-decoration: none;
  color: #aaa;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: color 0.25s ease-in;

  &:hover {
    color: #777;
  }
`;

export {
  CardWrapper,
  CardHeader,
  CardHeading,
  CardHeadingDescription,
  CardBody,
  CardFieldset,
  CardInput,
  CardOptionsNote,
  CardOptions,
  CardOptionsItem,
  CardButton,
  CardLink,
  CancelButton
};
