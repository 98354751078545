import { useEffect, useState } from 'react';
import { homeService } from 'services/homeService';
import styled from 'styled-components';
import 'material-icons/iconfont/material-icons.css';
import { InputBlueBottom } from 'components/InputControls.tsx/Inputs';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import NoData from 'components/ReusableDivs/NoData';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import HomeIcon from '@mui/icons-material/Home';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Empty from 'components/ReusableDivs/Empty';
import CheckBox from '@mui/material/Checkbox';

import {
  Dialog,
  DialogActions,
  DialogContent, Box, Typography, Tooltip
} from '@mui/material';
import { ActionsWrapper } from 'screens/Menu/UsersList';
import { StyledTable, StyledTableCell } from 'styles/tables/TableStyles';
import PageWithHeader from 'components/ReusableDivs/PageWithHeader';
import "../../styles/tables/tableStyles.css"
import { CancelBtn, ConfirmBtn, StyledDialogContent, StyledDialogContentText, StyledDialogMessage, StyledDialogTitle, TertiaryBtn } from 'styles/dialogs/DialogStyles';
import PageWrapper from 'components/ReusableDivs/PageWrapper';
import Foyer from 'Inscription/Foyer';
import User from 'Inscription/User';
import moment from 'moment';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SpinnerComponent from 'components/spinners/SpinnerComponent';
import { useSelector } from 'react-redux';
import { validatePatient } from 'validators/UserValidators/newPatientValidator';
import { validateHome } from 'validators/HomeValidators/newHomeValidator';
import { validateAssignedTablet } from 'validators/TabletValidators/NewTabletValidator';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from 'utils/Constants';


export const Label = styled.p`
  font-size: 14px;
  color: red;
`;

export const StyledButton = styled.button`
  font-size: 1em;
  padding: 0.25em 1em;
  border-radius: 3px;
  margin: 0px 0px 0px 20px;
  background-color: #035282;
  color: white;
  border: none;
`;
export const CloseModal = styled.button`
  background-color: #939397;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* margin: 4px 2px; */
  padding: 5px;
  height: fit-content;
  width: fit-content;
  border-radius: 8px;
`;
export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ModalTitle = styled.h2`
  font-size: 18px;
  color: #31241c;
  flex-grow:1
`;
export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: baseline;
  padding-top: 30px;
`;
export const ContentWrapper = styled.div`
display:"flex";
flex-direction:"row";
align-items:"center"
`


export function Homes() {
  const [openAddHome, setopenAddHome] = useState(false);
  const [homesList, setHomesList] = useState<any[]>([])
  const [homesListToShow, setHomesListToshow] = useState<any[]>([])
  const [elementId, setElementId] = useState<string>('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false)
  const [isError, setisError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [newTablet, setNewTablet] = useState({
    imei: "",
    deviceID: "",
    home_id: ""
  })
  const [errors, setErrors] = useState({
    imei: "",
    error: ""
  })
  const [userErrors, setUserErrors] = useState({
    email: '',
    firstname: '',
    lastname: '',
    phoneNumber: '',
    ssn: '',
    gender: '',
    birthDate: "",
    externalRef: ""
  });
  const [homeIsVisible, sethomeIsVisible] = useState(true)
  const [userIndex, setuserIndex] = useState(0)

  const [showErrorLabel, setshowErrorLabel] = useState(false)
  const [tabletCheck, setTabletCheck] = useState(true)
  const [noTabletCheck, setNoTabletCheck] = useState(true)
  const initialHomeFields = {
    adress1: '',
    adress2: '',
    country: 'France',
    city: '',
    zipCode: '',
    subscriberName: '',
    subscriberLastname: '',
    subscriberEmail: ''
  };
  const [homeDetails, sethomeDetails] = useState(initialHomeFields)

  const [homeErrors, setHomeErrors] = useState({
    adress1: '',
    country: '',
    city: '',
    zipCode: ''
  });

  const [usersList, setusersList] = useState([{
    email: '',
    firstname: '',
    lastname: '',
    phoneNumber: '',
    ssn: '',
    gender: '',
    birthDate: '',
    externalRef: ""
  }])
  const [refresh, setrefresh] = useState(false)
  const [isLoading, setisLoading] = useState(false)

  const orgaIdfromRedux = useSelector((state: any) => state.userDomisante.managedOrganizationId);
  const acc_token = useSelector((state: any) => state.userDomisante.userToken);


  const handleTabletCheck = (event: any) => {
    const isChecked = event.target.checked;
    setTabletCheck(isChecked)

  };


  const handleNoTabletCheck = (event: any) => {
    const isChecked = event.target.checked;
    setNoTabletCheck(isChecked)

  };
  useEffect(() => {
    /**
     * Checking if current user is an organization admin with an orgaIdfromRedux
     */

    if (orgaIdfromRedux != null) {

      homeService.getHomes(setHomesList, acc_token, orgaIdfromRedux)
    }
    else {
      homeService.getHomes(setHomesList, acc_token)
    }
    if (homesList.length === 0) {
      setIsEmpty(true)
    }
  }, [refresh])

  useEffect(() => {
    if (tabletCheck == false && noTabletCheck == false) {
      setHomesListToshow([])
    }

    if (tabletCheck == false && noTabletCheck == true) {
      const filteredHomes = homesList.filter(home => home.Tablet == null);
      setHomesListToshow(filteredHomes)
    }
    if (tabletCheck == true && noTabletCheck == false) {
      const filteredHomes = homesList.filter(home => home.Tablet != null);
      setHomesListToshow(filteredHomes)

    }
    if (tabletCheck == true && noTabletCheck == true) {
      setHomesListToshow(homesList)
    }

  }, [tabletCheck, noTabletCheck, homesList]);


  // Tablet management functions
  const handleChangeTablet = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setNewTablet({
      ...newTablet, [name]: value
    })
  }




  async function assignTablet(homeid: string) {
    setisLoading(true)
    let response
    if (validateAssignedTablet(newTablet, setErrors)) {
      if (newTablet.deviceID === "") {
        let data = {
          imei: newTablet.imei,
          home_id: newTablet.home_id
        }
        response = homeService.assignTablet(data, acc_token);

      }
      if (newTablet.imei === "") {
        let data = {
          deviceID: newTablet.deviceID,
          home_id: newTablet.home_id
        }
        response = homeService.assignTablet(data, acc_token);

      }
      else {
        let data = {
          imei: newTablet.imei,
          deviceID: newTablet.deviceID,
          home_id: newTablet.home_id
        }
        response = homeService.assignTablet(data, acc_token);
      }
      if (await response) {
        setIsSuccess(true)
        setrefresh(!refresh)
        setisLoading(false)


      } else {
        setisError(true)
        setisLoading(false)

      }
    }
    else notify()
  }


  // Modals management functions
  function openModal() {
    setIsOpen(true);
  }
  function handleModal(id: string) {
    openModal();
    setNewTablet({
      ...newTablet,
      home_id: id
    })
  }

  function closeModal() {
    setIsOpen(false);
  }

  const notify = () => toast.error("Veuillez vérifier les données !");


  // Item to display filled patient data
  type itemProps = {
    firstname: string,
    lastname: string,
    birthDate: string,
    index: number,
  }

  function Item({ firstname, lastname, birthDate, index }: itemProps) {

    function retrievePatient(array: any[], index: number): void {

      if (index > -1) {
        array.splice(index, 1);
        setusersList(array)
        setuserIndex(userIndex - 1)
      }
    }
    return (
      <Box style={{ borderRadius: 10, margin: "10px" }}>
        <Box style={{ display: "flex", border: "1px solid #F4C274", flexDirection: "row", padding: "8px 20px", borderRadius: 10, backgroundColor: "#f7f9f9", alignItems: "stretch", }}>
          <Box style={{ display: "flex", flexDirection: "row", padding: 5 }}>
            <Box style={{ fontSize: 17, fontWeight: "500", padding: "0px 2px", color: "#035282" }}>{firstname}</Box>
            <Box style={{ fontSize: 17, fontWeight: "500", padding: "0px 2px", color: "#035282" }}>{lastname}</Box>
            <Box style={{ fontSize: 17, fontWeight: "500", padding: "0px 2px", color: "#035282" }}> {dayjs(birthDate).format('DD/MM/YYYY')}</Box>
          </Box>
          <Box>
            <IconButton
              size="large"
              onClick={() => retrievePatient(usersList, index)}
              color="inherit"
              style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 5 }}
            >
              <RemoveCircleOutlineIcon style={{ color: "#0ED2EB" }} />
            </IconButton>
          </Box>

        </Box>
      </Box>
    )
  }


  // Home managemement functions 
  async function addHome() {

    if (usersList.length > 0 && validateHome(homeDetails, setHomeErrors) && validatePatient(usersList[userIndex], setUserErrors)) {
      setisLoading(true)

      let home = {}
      if (orgaIdfromRedux && orgaIdfromRedux.length > 0) {
        home = { ...homeDetails, organizationId: orgaIdfromRedux }
      }
      else {
        home = homeDetails
      }
      let data = {
        "users": usersList,
        "home": home
      }

      let response = await homeService.postHome(data)
      if (response == true) {
        setIsSuccess(true)
        // setopenAddHome(false)
        // cancelAddHome()
        setisLoading(false)
        setrefresh(!refresh)
      } else {
        setisError(true)
        setisLoading(false)
      }

    }
    else {
      notify()
      setshowErrorLabel(true)
    }
  }
  function cancelAddHome() {
    setopenAddHome(false)
    sethomeDetails({
      adress1: '',
      adress2: '',
      country: 'France',
      city: '',
      zipCode: '',
      subscriberName: '',
      subscriberLastname: '',
      subscriberEmail: ''
    })
    setusersList([{
      email: '',
      firstname: '',
      lastname: '',
      phoneNumber: '',
      ssn: '',
      gender: '',
      birthDate: "",
      externalRef: ''
    }])
    setuserIndex(0)
    sethomeIsVisible(true)
    setshowErrorLabel(false)
    setisError(false)
    setIsSuccess(false)
  }

  function passToNextUser() {
    if (validateHome(homeDetails, setHomeErrors)) {
      if (!homeIsVisible && validatePatient(usersList[userIndex], setUserErrors)) {

        setusersList([...usersList, {
          email: '',
          firstname: '',
          lastname: '',
          phoneNumber: '',
          ssn: '',
          gender: '',
          birthDate: '',
          externalRef: ""
        }])
        setuserIndex(userIndex + 1)
      }
      if (homeIsVisible == true) {
        sethomeIsVisible(false)
      }
      else {
        console.log("user errors ---------------", userErrors)
      }
    }
  }

  return (
    <PageWithHeader>
      <PageWrapper title="Liste des foyers enregistrés" btnTitle="Ajouter un foyer" btnClick={() => setopenAddHome(true)}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: 6, }}>
            <CheckBox
              checked={tabletCheck}
              onChange={handleTabletCheck}
              inputProps={{ 'aria-label': 'Afficher au patient' }}
              style={{ color: tabletCheck ? '#18C5BF' : '#035282', borderColor: "inherit" }}
            />

            <Typography style={{ color: '#035282' }}>Avec tablette</Typography>
          </div>

          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: 6, }}>
            <CheckBox
              checked={noTabletCheck}
              onChange={handleNoTabletCheck}
              inputProps={{ 'aria-label': 'Afficher au patient' }}
              style={{ color: noTabletCheck ? '#18C5BF' : '#035282', borderColor: "inherit" }}
            />

            <Typography style={{ color: '#035282' }}>Sans tablette </Typography>
          </div>
        </div>
        <ContentWrapper>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%" }}  >

            {homesListToShow.length > 0 &&
              <TableContainer className="table-container">
                <StyledTable size="medium" aria-label="sticky table">
                  <TableHead>
                    <TableRow>

                      <StyledTableCell align="left">Adresse </StyledTableCell>
                      <StyledTableCell align="left">Ville</StyledTableCell>
                      <StyledTableCell align="center">Code postal</StyledTableCell>
                      <StyledTableCell align="center">Pays</StyledTableCell>
                      <StyledTableCell align="center">Numéro de série</StyledTableCell>
                      <StyledTableCell align="center">Détails</StyledTableCell>
                      <StyledTableCell align="center">Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ cursor: 'pointer' }}>

                    {homesListToShow.map((row, index) => (
                      <TableRow
                        key={row["id"]}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <StyledTableCell component="th" scope="row" align="left">
                          <Link style={{ color: "#035282", textDecoration: 'none' }} to={`/home-details/${row["id"]}`}>
                            {row["adress1"]} {row["adress2"]}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="left">
                          <Link style={{ color: "#035282", textDecoration: 'none' }} to={`/home-details/${row["id"]}`}>
                            {row["city"]}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="center">
                          <Link style={{ color: "#035282", textDecoration: 'none' }} to={`/home-details/${row["id"]}`}>
                            {row["zipCode"]}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="center">
                          <Link style={{ color: "#035282", textDecoration: 'none' }} to={`/home-details/${row["id"]}`}>
                            {row["country"]}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="center">


                          <Link style={{ color: "#035282", textDecoration: 'none' }} to={`/home-details/${row["id"]}`}>
                            {row["Tablet"] ? row["Tablet"]["deviceID"] : "Non défini"}
                          </Link>

                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="center">
                          <Tooltip title="Consulter les détails du foyer" placement="top">
                            <IconButton
                              to={`/home-details/${row["id"]}`}
                              component={Link}>
                              <HomeIcon style={{ color: "#035282", fontSize: 30 }} />

                            </IconButton>
                          </Tooltip>

                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="center">
                          {
                            !row["Tablet"] ? (
                              <Tooltip title="Assigner une tablette" placement="top">
                                <IconButton
                                  onClick={() => handleModal(row["id"])}>
                                  <AppSettingsAltIcon style={{ color: "#18C5BF", fontSize: 30, paddingLeft: 5 }} />
                                </IconButton>
                              </Tooltip>
                            ) : <></>
                          }

                        </StyledTableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </TableContainer>}
            {homesList.length === 0 && !isEmpty && <NoData />}
            {homesListToShow.length === 0 && isEmpty && <Empty />}
          </div>
        </ContentWrapper>

        <Dialog open={modalIsOpen}
          style={{ zIndex: 99 }}
          onClose={() => closeModal()} fullWidth={true}
          maxWidth='md'>

          <StyledDialogTitle> Ajouter une tablette</StyledDialogTitle>

          <IconButton
            aria-label="close"
            onClick={() => {
              setNewTablet({
                imei: "",
                deviceID: "",
                home_id: ""
              });
              closeModal();
              setisError(false);
              setIsSuccess(false)
              setisLoading(false)
            }}
            sx={{
              position: 'absolute',
              right: 20,
              top: 20,
              color: colors.dark_blue
            }}
          >
            <CloseIcon />
          </IconButton>
          <StyledDialogContent>
            {!isLoading && !isSuccess && !isError &&
              <>
                <StyledDialogContentText>
                  Veuillez ajouter les spécifications de votre tablette.
                </StyledDialogContentText>

                <InputBlueBottom
                  value={newTablet.imei}
                  onChange={handleChangeTablet}
                  placeholder="Sim"
                  type="text"
                  name="imei"
                  required={false}
                />

                <Label>{errors.imei}</Label>

                <InputBlueBottom
                  value={newTablet.deviceID}
                  onChange={handleChangeTablet}
                  placeholder="Numéro de série"
                  type="text"
                  name="deviceID"
                  required={false}
                />
                <Label>{errors.error}</Label>

              </>}
            <>
              {
                isSuccess && <Box sx={{ padding: "50px 0px" }}>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                    <StyledDialogMessage>Tablette assignée avec succès.</StyledDialogMessage>
                  </Box>
                </Box>
              }
              {
                isError && <Box sx={{ padding: "50px 0px" }}>

                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                    <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer.</StyledDialogMessage>
                  </Box>
                </Box>
              }
              {
                isLoading &&
                <Box sx={{ padding: "50px 0px" }}>
                  <SpinnerComponent />
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                    <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
                  </Box>
                </Box>}
            </>

          </StyledDialogContent>
          <DialogActions>
            <ActionsWrapper>
              <CancelBtn onClick={() => {
                setNewTablet({
                  imei: "",
                  deviceID: "",
                  home_id: ""
                });
                closeModal();
                setisError(false);
                setIsSuccess(false)
                setisLoading(false)
              }}>{
                  !isLoading && !isError && !isSuccess ?
                    "Annuler" : "Fermer"
                }</CancelBtn>
              {
                !isError && !isSuccess && !isLoading &&
                <ConfirmBtn onClick={() => assignTablet(elementId)}>Ajouter</ConfirmBtn>
              }
            </ActionsWrapper>

          </DialogActions>
        </Dialog>

        <Dialog open={openAddHome} fullWidth={true} style={{ zIndex: 99 }} onClose={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setopenAddHome(false);
          }

        }}
          maxWidth='md'>
          <StyledDialogTitle> Ajouter un Foyer </StyledDialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => cancelAddHome()}
            sx={{
              position: 'absolute',
              right: 20,
              top: 20,
              color: colors.dark_blue
            }}
          >
            <CloseIcon />
          </IconButton>
          <StyledDialogContent>
            {!isLoading && !isSuccess && !isError &&
              <>
                <StyledDialogContentText>
                  Veuillez ajouter l'adresse du foyer à ajouter et renseigner les informations des bénéficiaires associés.
                </StyledDialogContentText>

                {homeIsVisible && <Foyer
                  withSubscriber={false}
                  AddHome={homeDetails}
                  setAddHome={sethomeDetails}
                  errors={homeErrors}
                />
                }
                {
                  showErrorLabel == true && homeIsVisible && <Box ><Typography style={{ color: "red", }}>Vous devez saisir les données d'un bénéficiaire au moins </Typography></Box>
                }
                {
                  usersList.length > userIndex && !homeIsVisible &&
                  usersList.slice(0, usersList.length - 1).map((user, index) => {
                    return (
                      <Item firstname={user.firstname} lastname={user.lastname} birthDate={user.birthDate} key={user.ssn} index={index} />
                    )
                  })
                }
                {
                  usersList.length > 0 && !homeIsVisible &&
                  <User
                    userData={usersList[Number(userIndex)]} errors={userErrors} userIndex={userIndex} setter={setusersList} users={usersList}
                  />
                }
              </>
            }

            {
              isSuccess && <Box sx={{ padding: "50px 0px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Éléments ajoutés avec succès.</StyledDialogMessage>
                </Box>
              </Box>
            }
            {
              isError && <Box sx={{ padding: "50px 0px" }}>

                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer.</StyledDialogMessage>
                </Box>
              </Box>
            }
            {isLoading &&
              <Box sx={{ padding: "50px 0px" }}>
                <SpinnerComponent />
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
                </Box>
              </Box>
            }
          </StyledDialogContent>
          <DialogActions>
            <ActionsWrapper>
              <CancelBtn onClick={cancelAddHome}>{
                !isLoading && !isError && !isSuccess ?
                  "Annuler" : "Fermer"
              }</CancelBtn>
              {
                (!isLoading && !isSuccess && !isError) &&
                <Box style={{
                  display: "flex", width: "70%",
                }}>
                  <Box style={{
                    display: "flex", flex: 2, justifyContent: "flex-end"
                  }}>

                    <TertiaryBtn style={{ backgroundColor: '#C2D4D8', color: '#035282' }}
                      onClick={() => passToNextUser()}>{homeIsVisible ? 'Suivant' : 'Bénéficiaire suivant'}</TertiaryBtn>
                  </Box>
                  {!homeIsVisible ?
                    <Box style={{
                      display: "flex", flex: 1, justifyContent: "flex-end"
                    }}>
                      <ConfirmBtn onClick={() => addHome()}>Terminer</ConfirmBtn>
                    </Box>
                    : null}
                </Box>}
            </ActionsWrapper>

          </DialogActions>
        </Dialog>

      </PageWrapper>
      <ToastContainer />
    </PageWithHeader >
  );
}




