import Card from 'components/Cards/Card';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import SpinnerComponent from 'components/spinners/SpinnerComponent';
import { Box } from '@mui/material';
import AuthTemplatePage from 'components/ReusableDivs/AuthTemplatePage';
import { StyledDialogMessage } from 'styles/dialogs/DialogStyles';
import { colors } from "../../utils/Constants";
import { authService } from 'services/authService';
import { useDispatch } from 'react-redux';
import { updateUserLogin } from 'store/userSlice';
import { CancelButton } from 'components/Cards/ColumnCard';
import { Typography } from "@mui/material";
import LoginCheck from './LoginCheck';
import Button from 'components/InputControls.tsx/Button';


interface CountdownTimerProps {
  onClick: any
}
const CustomErrorTypography = styled(Typography)`
  font-weight: bold;
  color:red; 
  font-family: Open Sans !important;
  font-size:"20px" ;
  margin:"10px";
`;
const CustomTypography = styled(Typography)`
  font-weight: bold;
  color:${colors.dark_blue}; 
  font-family: Open Sans !important;
  font-size:"20px" ;
  margin:"10px";
`;
const timerInMinutes = 5
const timerInSeconds = 0

const CountdownTimer: React.FC<CountdownTimerProps> = ({ onClick }) => {
  const [minutes, setMinutes] = useState(timerInMinutes);
  const [seconds, setSeconds] = useState(timerInSeconds);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isRunning, setIsRunning] = useState(true);
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    if (isRunning) {
      timer = setInterval(() => {
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(timer!);
            setIsDisabled(false);
            setIsRunning(false)
          } else {
            setMinutes((prevMinutes) => prevMinutes - 1);
            setSeconds(59);
          }
        } else {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }
      }, 1000);
    }

    return () => clearInterval(timer!);
  }, [isRunning, minutes, seconds]);

  const handleRestart = () => {
    setMinutes(timerInMinutes);
    setSeconds(timerInSeconds);
    setIsDisabled(true);
    setIsRunning(true);
    onClick()

  };

  return (
    <CancelButton
      disabled={isDisabled}
      type="button"
      onClick={handleRestart}>
      Renvoyer
      {isRunning &&
        <>
          {` dans `}  {minutes < 10 ? '0' + minutes : minutes}:{seconds < 10 ? '0' + seconds : seconds}
        </>
      }
    </CancelButton>
  );
};
interface ResetPwdProps { }

const VerifyOTP: React.FC<ResetPwdProps> = () => {
  const { temp_token, channel } = useParams()
  const [isLoading, setisLoading] = useState(false)
  const [newCodeIsSent, setnewCodeIsSent] = useState<boolean | null>(false)
  const [noEmailorPhone, setnoEmailorPhone] = useState<boolean | null>(false)
  const [verifError, setverifError] = useState<boolean | null>(false)
  const [isError, setisError] = useState<boolean | null>(false)
  const [verifOtherError, setVerifOtherError] = useState<boolean | null>(false)
  const [checkSession, setcheckSession] = useState<boolean | null>(false)
  const navigate = useNavigate()
  const [otp, setOTP] = useState<string[]>(new Array(6).fill(''));
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const isComplete = otp.every(digit => digit !== '');
    // Handle submission when OTP is complete
    if (isComplete) {
      checkOTP(otp.join(''));
    }
  }, [otp])

  const handleInputChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Ensure only numbers are entered
    if (/^\d*$/.test(value) && index >= 0 && index < otp.length) {
      const newOTP = [...otp];
      newOTP[index] = value;
      setOTP(newOTP);
      // Move to the next input field if available
      if (value !== '' && index < otp.length - 1 && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1].focus();
      }
    }
    // Check if all OTP digits are entered

  };

  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    // Handle backspace to move to the previous input field
    if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
      inputRefs.current[index - 1].focus();
    }
    retry()
  };

  function retry() {
    setverifError(null)
    setisError(null)
    setVerifOtherError(null)
    setnewCodeIsSent(null)
  }
  async function checkOTP(otp: string) {
    let data = {
      OTP: otp
    }
    retry()
    if (temp_token != null) {
      let response = await authService.verifyOTP(data, temp_token)

      console.log("response checkOTP", response)
      console.log("response != 403", response != 403)

      if (response != null && response != 403 && response != 401) {
        let test = await dispatch(updateUserLogin(response))
        setcheckSession(true)
        // setcheckLogin(true)
        setverifError(false)
        setVerifOtherError(false)
      }
      else if (response == 401) {
        handleInvalidToken()
      }
      else if (response == 403) {
        setverifError(true)

      }
      else {
        setVerifOtherError(true)
      }
    }
  }

  async function resendOTP() {
    setnewCodeIsSent(false)
    if (temp_token) {
      let response = await authService.resendOTP(temp_token)
      console.log("response in resend OTP **************", response)
      if (response == 200) {
        setnewCodeIsSent(true)
      } else if (response == 401) {
        handleInvalidToken()
      } else if (response == 403) {
        setnoEmailorPhone(false)
        setisError(true)
      }
    }
  }

  function handleInvalidToken() {
    navigate("/")
  }

  return (

    <AuthTemplatePage
    >
      <Card
        title="Vérification d'identité"
        description={"Entrez le code de connexion que vous avez reçu " + `${channel?.includes("@") ? `dans votre boite email ` : 'par sms sur le '} ${channel} `}
      // buttonTitle="Retour"
      // onClick={() => navigate("/")}
      >
        {!isLoading ? <>
          <div style={{ margin: "10px" }}>
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                value={digit}
                onChange={(e) => handleInputChange(index, e)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                ref={(el) => (inputRefs.current[index] = el as HTMLInputElement)}
                style={{
                  width: '30px',
                  marginRight: '5px',
                  border: '2px solid #18C5BF', // Set bottom border style
                  textAlign: 'center',
                  color: colors.dark_blue,
                  fontSize: "18px"
                }}
              />
            ))}
          </div>
          <div style={{ minHeight: "50px" }}>
            {
              verifError == true &&
              <CustomErrorTypography style={{}}>
                Code invalide !
              </CustomErrorTypography>
            }

            {
              newCodeIsSent &&
              <CustomTypography>
                Un nouveau code secret est envoyé !
              </CustomTypography>
            }


            {
              noEmailorPhone && <CustomErrorTypography>
                Vous n'avez pas d'adresse email ou un numéro de téléphone
              </CustomErrorTypography>
            }
            {
              isError && <CustomErrorTypography>
                Une erreur s'est produite. Veuillez réessayer plus tard !
              </CustomErrorTypography>
            }
          </div>
          <CountdownTimer onClick={resendOTP} />

          <div style={{ marginTop: "15px", }}><Button title={'Retour'} onClick={() => navigate("/")} /></div>
        </> :
          <Box sx={{ padding: "50px 0px" }}>
            <SpinnerComponent />
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
              <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
            </Box>
          </Box>
        }
      </Card>
      {
        checkSession == true && <LoginCheck />
      }

    </AuthTemplatePage>
  );
}

export default VerifyOTP;
