import React, { useState } from 'react'
import { Box, Typography, } from '@mui/material'
import { InputBlueBottom } from 'components/InputControls.tsx/Inputs'
import { StyledBox } from './ProfileInfos'
import ActionButton from 'components/InputControls.tsx/ActionButton'
import { authService } from 'services/authService'
import { in200s } from 'utils/RequestCheckFns'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { useSelector } from 'react-redux'



function ManageProfile() {
  const [oldPassword, setoldPassword] = useState("")
  const [newPassword, setnewPassword] = useState("")
  const [newPasswordConfirm, setnewPasswordConfirm] = useState("")
  const [changeSuccess, setchangeSuccess] = useState(false)
  const [changeError, setchangeError] = useState(false)
  const acc_token = useSelector((state: any) => state.userDomisante.userToken);
  async function changePwd() {

    if (newPassword == newPasswordConfirm) {
      let data = {
        oldPassword: oldPassword,
        newPassword: newPassword
      }
      let response = await authService.changePassword(data, acc_token)
      if (in200s(response)) {
        setchangeSuccess(true)
      }
      else {
        setchangeError(true)
      }
    }
  }

  return (
    <Box>
      <StyledBox style={{ display: "flex", flexDirection: "column" }}>
        <Box style={{ display: "flex", flexDirection: "column", height: "100%", flex: 1 }}>

          {
            changeSuccess &&
            <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", flex: 1, flexDirection: "column", height: "100%" }}>
              <CheckCircleIcon style={{ fontSize: "50px", color: "#18C5BF" }} />

              <Typography style={{ padding: "15px" }}>Votre mot de passe a été modifié avec succès</Typography>
            </Box>

          }
          {
            changeError &&
            <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", flex: 1, flexDirection: "column", height: "100%" }}>
              <ErrorIcon style={{ fontSize: "50px", color: "#F4C274" }} />

              <Typography style={{ padding: "15px" }}>Une erreur s'est produite ! Veuillez vérifier votre mot de passe </Typography>
            </Box>

          }
          {
            !changeSuccess && !changeError && <>
              <InputBlueBottom
                type="password"
                name="oldPassword"
                value={oldPassword}
                onChange={(e) => setoldPassword(e.target.value)}
                placeholder="Mot de passe actuel"
              />
              <InputBlueBottom
                type="password"
                name="oldPassword"
                value={newPassword}
                onChange={(e) => setnewPassword(e.target.value)}
                placeholder="Nouveau mot de passe"
              />
              <InputBlueBottom
                type="password"
                name="oldPassword"
                value={newPasswordConfirm}
                onChange={(e) => setnewPasswordConfirm(e.target.value)}
                placeholder="Confirmer nouveau mot de passe"
              />
              <Box style={{ width: "100px", margin: "10px 0px" }}>
                <ActionButton title="Changer" onClick={() => changePwd()} />
              </Box>

            </>
          }



        </Box>


      </StyledBox>
    </Box>
  )
}

export default ManageProfile