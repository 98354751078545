import { CancelButton, CardBody, CardButton, CardFieldset, CardHeader, CardHeading, CardHeadingDescription, CardWrapper } from "./ColumnCard";

interface CardProps {
  title: string;
  description: string;
  children: React.ReactNode;
  buttonTitle?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  withCancelBtn?: boolean;
  cancelFn?: any;
  secondaryBtnFn?: any;
  secondaryBtnTitle?: string;
  isSecondaryBtnDisabled?: boolean;
}

const Card: React.FC<CardProps> = ({
  title,
  description,
  children,
  buttonTitle,
  onClick,
  withCancelBtn
  , cancelFn,
  secondaryBtnFn,
  secondaryBtnTitle,
  isSecondaryBtnDisabled
}) => {
  return (

    <CardWrapper>
      <CardHeader>
        <CardHeading>{title}</CardHeading>
        <CardHeadingDescription>
          {description}
        </CardHeadingDescription>
      </CardHeader>
      <CardBody>
        <CardFieldset>
          {children}
        </CardFieldset>

        {buttonTitle ?
          <CardButton
            type="button"
            onClick={onClick}>
            {buttonTitle}
          </CardButton> : ""
        }
        {
          secondaryBtnFn && secondaryBtnTitle &&
          <CancelButton
            disabled={isSecondaryBtnDisabled}
            type="button"
            onClick={secondaryBtnFn}>
            {secondaryBtnTitle}
          </CancelButton>
        }
        {
          withCancelBtn && cancelFn &&
          <CancelButton
            type="button"
            onClick={cancelFn}>
            Annuler
          </CancelButton>
        }
      </CardBody>
    </CardWrapper>

  );
}
export default Card


