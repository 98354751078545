import React from 'react'
import styled from "styled-components"
interface ButtonProps {
  title: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const BlueButton = styled.button`
  display: block;
  width: 100%;
  padding: 15px 0;
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background-color: #18C5BF;
  border: 0;
  border-radius: 4px;
  font-family:open sans;

  min-width:215px;

  cursor: pointer;
  // transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  padding-left:7px;
  padding-right:7px;

  &:active {
    // transform: translate(0, -5px);
    background-color: #FFF;
    color:#18C5BF;
    border : 1px solid #18C5BF;
  }
  &:hover {
    // transform: translate(0, -5px);
    background-color: #FFF;
    color:#18C5BF;
    border : 1px solid #18C5BF;
  }

`;
function ActionButton({ title, onClick }: ButtonProps) {
  return (
    <BlueButton type="button" onClick={onClick}>
      {title}
    </BlueButton>
  );
}

export default ActionButton