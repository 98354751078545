import axios from 'axios';
import { requestChecker } from 'utils/RequestCheck';
const { REACT_APP_BASE_URL } = process.env;

async function getOrganizations(setOrganization: React.Dispatch<React.SetStateAction<any>>, acc_token: string) {
  let url = `${REACT_APP_BASE_URL}/organizations`
  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      setOrganization(response.data.data);
      return response.data.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function getRelatedManagerOrganization(setOrganization: React.Dispatch<React.SetStateAction<any>>, acc_token: string, organisation_id?: string, user_id?: string) {
  let url = `${REACT_APP_BASE_URL}/organizations/related-organizations?`
  if (organisation_id) {
    url += 'organization_id=' + organisation_id
  }
  if (user_id) {
    url += 'orga_admin_id' + user_id
  }

  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      setOrganization(response.data.data);
      return response.data.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function addNewOrganization(data: any, acc_token: string) {

  let url = REACT_APP_BASE_URL + `/organizations`

  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}


async function getUserOrganizations(acc_token: string, setData?: React.Dispatch<React.SetStateAction<any>>) {
  let url = REACT_APP_BASE_URL + `/organizations/managed-organizations`

  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      if (setData) {
        setData(response.data.data)
      }
      return response.data.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function assignManagerToOrganization(organizationId: string, orga_admin_id: string, acc_token: string) {

  let url = REACT_APP_BASE_URL + `/organizations/assign-organization`

  let data = {
    "organization_id": organizationId,
    "orga_admin_id": orga_admin_id
  }
  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function deleteOrganization(id: any, acc_token: string) {

  let url = REACT_APP_BASE_URL + `/organizations?id=${id}`

  try {
    let response = await axios.request({
      url: url,
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function removeManagerFromOrganization(organisation_id: string, manager_id: string, acc_token: string) {
  let url = REACT_APP_BASE_URL + `/organizations/organization-admin?organization_id=${organisation_id}&orga_admin_id=${manager_id}`

  try {
    let response = await axios.request({
      url: url,
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function editOrganization(id: any, data: any, acc_token: string) {

  let url = REACT_APP_BASE_URL + `/organizations/${id}`

  try {
    let response = await axios.request({
      url: url,
      method: 'put',
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function getMyOrganization(setData: React.Dispatch<React.SetStateAction<any>>, acc_token: string) {

  let url = REACT_APP_BASE_URL + `/organizations/my-organization`

  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      setData(response.data.data)
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}
export const OrganizationService = {
  getOrganizations,
  addNewOrganization,
  deleteOrganization,
  editOrganization,
  getRelatedManagerOrganization,
  removeManagerFromOrganization,
  assignManagerToOrganization,
  getUserOrganizations,
  getMyOrganization
};
