import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import DeleteIcon from '@mui/icons-material/Delete';
import PageWithHeader from 'components/ReusableDivs/PageWithHeader';
import { OrganizationService } from 'services/organizationsService';
import { Autocomplete, Box, Dialog, DialogActions, DialogContent, Stack, IconButton, Tooltip, TableBody, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import ActionButton from 'components/InputControls.tsx/ActionButton';
import { usersService } from 'services/usersService';
import PageWrapper from 'components/ReusableDivs/PageWrapper';
import { InfoField, InfoValue } from 'screens/home/HomeDetails';
import { StyledTable, StyledTableCell } from 'styles/tables/TableStyles';
import { CancelBtn, ConfirmBtn, StyledDialogContent, StyledDialogContentText, StyledDialogMessage, StyledDialogTitle } from 'styles/dialogs/DialogStyles';
import { useSelector } from 'react-redux';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { formatPhoneNumber } from 'utils/formatters/phoneNumberFormatter';
import SpinnerComponent from 'components/spinners/SpinnerComponent';
import { in200s } from 'utils/RequestCheckFns';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from 'utils/Constants';

export const CardWrapperSubscription = styled.div`
  overflow: hidden;
  padding: 20px 50px;
  margin: 30px 20px;
  /* width: 35vw; */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05), 0 0px 40px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background-color: white;
`;
export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 30px;
`;
export const StyledButton = styled.button`
  font-size: 17px;
  padding:  0.5em 2em;
  border-radius: 3px;

  background-color: #035282;
  color: white;
  border: none;
`;

const TableWrapper = styled.div`
 display:flex;
flex-direction:row;
align-items:center;
margin-top:50px;
`;
const ActionsWrapper = styled.div`
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  width:100%;
  padding:0px 10px;
`;


const TopWrapper = styled.div`
display:flex;
width:100%;
`;



function OrganisationDetails() {
    const [organisationData, setorganisationData] = useState({
        address: "",
        id: "",
        name: "",
        phoneNumber: "",
        email: "",
        users: []
    })
    const [open, setOpen] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [managersList, setmanagersList] = useState([])
    const { id } = useParams() ?? 'undefined';
    const [selectedId, setselectedId] = useState("")
    const [list, setlist] = useState([])
    const [value, setValue] = useState<string | null>(list[0]);
    const [selectedOrganizationAdmin, setselectedOrganizationAdmin] = useState('')
    const acc_token = useSelector((state: any) => state.userDomisante.userToken);
    const [refresh, setrefresh] = useState(false)
    const [isLoading, setisLoading] = useState(false)
    const [isError, setisError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const deleteManager = async () => {
        setisLoading(true)
        let response = await OrganizationService.removeManagerFromOrganization(organisationData.id, selectedId, acc_token)
        if (response && in200s(response)) {
            setisLoading(false)
            setrefresh(!refresh)
            setValue("")
            setIsSuccess(true)
        }
        else {
            setisError(true)
            setisLoading(false)
            setrefresh(!refresh)
        }

    }
    const closeAddManager = async () => {
        setOpenAdd(false)
        setValue("")
        setisError(false)
        setIsSuccess(false)
    }

    function formatManagersList(array: any[]) {
        let formatted: any = []
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            formatted.push({ label: `${element['firstname']} ${element['lastname']}`, id: `${element['id']}` })
        }
        setlist(formatted)
    }


    useEffect(() => {
        OrganizationService.getRelatedManagerOrganization(setorganisationData, acc_token, id)
    }, [refresh]);



    useEffect(() => {
        usersService.search(setmanagersList, acc_token, undefined, 'organization admin')
        if (managersList.length > 0) {
            formatManagersList(managersList)
        }

    }, [managersList.length])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setisError(false)
        setIsSuccess(false)
        setisLoading(false)
    };

    const assignManagerToOrganization = async (managerId: string) => {
        let response = await OrganizationService.assignManagerToOrganization(organisationData.id, managerId, acc_token)
        if (response && response == 200) {
            setIsSuccess(true)
            setisLoading(false)
            setrefresh(!refresh)

        } else {
            setisError(true)
            setrefresh(!refresh)
        }
    };

    const handleChange = (e: any, v: any) => {
        setValue(v);
        setselectedOrganizationAdmin(v!.id)
    }

    return (
        <PageWithHeader>
            <>


                <PageWrapper title="Détails de l'organisation" backBtnContainer={true}
                // btnTitle='Ajouter un administrateur' btnClick={() => setOpenAdd(true)}
                // extraBlock={
                //     <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", }}>
                //         <div className='second-type-info'>
                //             <InfoField>
                //                 NOM DE L'ORGANISATION :<InfoValue>{organisationData.name}</InfoValue>
                //             </InfoField>
                //             <InfoField>
                //                 ADRESSE :<InfoValue>{organisationData.address}</InfoValue>
                //             </InfoField>
                //             <InfoField>
                //                 NUMÉRO DE TÉLÉPHONE :<InfoValue>{organisationData.phoneNumber}</InfoValue>
                //             </InfoField>
                //             <InfoField>
                //                 ADRESSE EMAIL :<InfoValue>{organisationData.email}</InfoValue>
                //             </InfoField>
                //         </div>
                //     </div>
                // }
                >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{
                            display: "flex", flexDirection: "row", justifyContent: "space-between",
                            width: "100%", padding: "0px 10px ", borderRadius: 10,
                        }}>
                            <div className='second-type-info' style={{
                                flex: 1,
                                display: "flex", flexDirection: "row",
                                width: "100%",
                                justifyContent: "space-between",
                                alignItems: "flex-end"

                            }}>
                                <Box>
                                    <Stack direction="row" alignItems="center" gap={1} style={{ marginBottom: 10, }}>
                                        <CorporateFareIcon style={{ color: "#18C5BF", fontSize: 45 }} />
                                        <span className='info-title'>Organisation </span>
                                    </Stack>
                                    <InfoField>
                                        Nom de l'organisation :<InfoValue>{organisationData.name}</InfoValue>
                                    </InfoField>
                                    <InfoField>
                                        Adresse :<InfoValue>{organisationData.address}</InfoValue>
                                    </InfoField>
                                    <InfoField>
                                        Numéro de téléphone :<InfoValue>{formatPhoneNumber(organisationData.phoneNumber)}</InfoValue>
                                    </InfoField>
                                    <InfoField>
                                        Adresse mail :<InfoValue>{organisationData.email}</InfoValue>
                                    </InfoField>
                                </Box>
                                <Box style={{ display: "flex", alignItems: "flex-end", height: "100%", justifyContent: "flex-end", }}                >
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: "flex-end",

                                        width: "150px",
                                        alignItems: "flex-end"
                                    }}>
                                        <ActionButton title='Ajouter un administrateur' onClick={() => setOpenAdd(true)} />
                                    </Box>
                                </Box>
                            </div>

                        </div>
                    </div>
                    <TableWrapper >
                        <TableContainer>
                            <StyledTable size="medium" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">
                                            Nom
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            Prénom
                                        </StyledTableCell>

                                        <StyledTableCell align="center">
                                            Actions
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {organisationData.users.length > 0 &&
                                        organisationData.users.map((row) => (
                                            <TableRow key={row['id']} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <StyledTableCell component="th" scope="row" align="left">
                                                    {row['lastname']}

                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row" align="left">
                                                    {row['firstname']}
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    component="th"
                                                    scope="row"
                                                    align="center"

                                                >
                                                    <Tooltip title="Supprimer" placement="top">
                                                        <IconButton
                                                            onClick={() => {
                                                                handleClickOpen()
                                                                setselectedId(row['id'])
                                                            }}
                                                        >
                                                            <DeleteIcon style={{ color: '#035282' }} />
                                                        </IconButton>
                                                    </Tooltip>

                                                </StyledTableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </StyledTable>
                        </TableContainer>
                    </TableWrapper>

                </PageWrapper>

                <Dialog
                    style={{ zIndex: 99 }}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <StyledDialogTitle id="alert-dialog-title">
                        {"Retirer un administrateur"}
                    </StyledDialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => handleClose()}
                        sx={{
                            position: 'absolute',
                            right: 20,
                            top: 20,
                            color: colors.dark_blue
                        }}
                    >
                        <CloseIcon />
                    </IconButton>


                    <StyledDialogContent>
                        {
                            !isLoading && !isSuccess && !isError &&
                            <StyledDialogContentText id="alert-dialog-description">
                                Êtes-vous sûr de bien vouloir retirer cet un administrateur de cette organisation ?
                            </StyledDialogContentText>
                        }
                        {isLoading &&
                            <Box sx={{ padding: "50px 0px" }}>
                                <SpinnerComponent />
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                                    <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
                                </Box>
                            </Box>
                        }
                        {
                            isSuccess && <Box sx={{ padding: "50px 0px" }}>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                                    <StyledDialogMessage>Élément supprimé avec succès.</StyledDialogMessage>
                                </Box>
                            </Box>
                        }
                        {
                            isError && <Box sx={{ padding: "50px 0px" }}>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                                    <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer.</StyledDialogMessage>
                                </Box>
                            </Box>
                        }
                    </StyledDialogContent>
                    <DialogActions>
                        <ActionsWrapper>

                            <CancelBtn onClick={handleClose}>{
                                !isLoading && !isError && !isSuccess ?
                                    "Annuler" : "Fermer"
                            }</CancelBtn>
                            {!isLoading && !isSuccess && !isError && <ConfirmBtn onClick={() => deleteManager()} autoFocus>
                                Confirmer
                            </ConfirmBtn>}
                        </ActionsWrapper>
                    </DialogActions>
                </Dialog>

                <Dialog

                    open={openAdd} onClose={() => closeAddManager()} style={{ height: 900, zIndex: 99 }}>

                    <StyledDialogTitle>Ajouter un administrateur</StyledDialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => closeAddManager()}
                        sx={{
                            position: 'absolute',
                            right: 20,
                            top: 20,
                            color: colors.dark_blue
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <StyledDialogContent >
                        {
                            !isLoading && !isSuccess && !isError &&
                            <>
                                <StyledDialogContentText>
                                    Veuillez sélectionner l'administrateur à assigner. Vous pouvez le chercher par son nom ou son prénom.
                                </StyledDialogContentText>

                                <div style={{ margin: "20px 20px 70px 0px" }}>

                                    <Autocomplete
                                        ListboxProps={{ style: { maxHeight: 100, overflow: 'auto' } }}
                                        // value={value}
                                        onChange={handleChange}

                                        // inputValue={inputValue}
                                        // onInputChange={(event, newInputValue) => {
                                        //     setInputValue(newInputValue);
                                        // }}
                                        value={value || null}
                                        disablePortal
                                        id="combo-box-demo"
                                        options={list}
                                        sx={{ width: "100%", }}
                                        renderInput={(params) => <TextField {...params} label="un administrateur" />}
                                    />

                                </div>
                            </>
                        }
                        {isLoading && <Box sx={{ padding: "50px 0px" }}>

                            <SpinnerComponent />
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                                <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
                            </Box>
                        </Box>
                        }
                        {
                            isSuccess && <Box sx={{ padding: "50px 0px" }}>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                                    <StyledDialogMessage>Élément ajouté avec succès.</StyledDialogMessage>
                                </Box>
                            </Box>
                        }
                        {
                            isError && <Box sx={{ padding: "50px 0px" }}>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                                    <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer.</StyledDialogMessage>
                                </Box>
                            </Box>
                        }
                    </StyledDialogContent>

                    <DialogActions>
                        <ActionsWrapper>
                            <CancelBtn onClick={() => closeAddManager()}>{
                                !isLoading && !isError && !isSuccess ?
                                    "Annuler" : "Fermer"
                            }</CancelBtn>
                            {
                                !isLoading && !isSuccess && !isError &&
                                <ConfirmBtn onClick={() => assignManagerToOrganization(selectedOrganizationAdmin)}>Ajouter</ConfirmBtn>
                            }
                        </ActionsWrapper>
                    </DialogActions>
                </Dialog>

            </>
        </PageWithHeader >
    )
}

export default OrganisationDetails

