import PageWithHeader from 'components/ReusableDivs/PageWithHeader';
import PageWrapper from 'components/ReusableDivs/PageWrapper';
import { useEffect, useState } from 'react';
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, TableBody, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { StyledTable, StyledTableCell } from 'styles/tables/TableStyles';
import { notifService } from 'services/notifService';
import { useSelector } from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import Dialog from '@mui/material/Dialog';
import BlueSkyButton from 'components/InputControls.tsx/BlueSkyButton';
import { CancelBtn, ConfirmBtn, StyledDialogContent, StyledDialogContentText, StyledDialogMessage, StyledDialogTitle } from 'styles/dialogs/DialogStyles';
import SpinnerComponent from 'components/spinners/SpinnerComponent';
import { ActionsWrapper } from 'screens/patients/PatientManagers';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from 'utils/Constants';


const Alerts = () => {
  const [data, setData] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [rowId, setRowId] = useState('');
  const [isLoading, setisLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [selectedAlert, setselectedAlert] = useState("")
  const [selectedPatientId, setselectedPatientId] = useState("")
  const acc_token = useSelector((state: any) => state.userDomisante.userToken);
  async function Init() {
    let notifications
    notifications = await notifService.getNotifications(acc_token)
    setData(await (notifications))
  }
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/page2#constants');
  };
  useEffect(() => {
    Init()
  }, []);



  async function AlertArchiveClick(notif_id: string) {
    await notifService.updateNotification(acc_token, notif_id, undefined, true)
    Init()
    setShowConfirmation(false)
  }

  const handleOpenConfirmation = (rowId: string) => {
    setRowId(rowId)
    setShowConfirmation(true);
  };

  const redirect = (page: string) => {
    navigate(`/${page}`);
  };
  function closeModal() {

    setOpenModal(false)
    setselectedPatientId("")
    setselectedAlert("")
  }
  function redirectToPatientDetails() {
    redirect(`patient-details/${selectedPatientId}/#mySection`)
  }

  async function setAlertAsRead() {
    await notifService.updateNotification(acc_token, selectedAlert, true, undefined)
    redirectToPatientDetails()
  }

  return (
    <PageWithHeader>
      <PageWrapper title={"Alertes"} >

        <TableContainer style={{ maxWidth: "100%" }}>
          <StyledTable size="medium" aria-label="a dense table">
            <TableHead>
              <TableRow>

                <StyledTableCell align="center">
                  Date
                </StyledTableCell>
                <StyledTableCell align="center">
                  Nom
                </StyledTableCell>
                {/* <StyledTableCell align="center">
                  Prénom patient
                </StyledTableCell> */}
                <StyledTableCell align="center">
                  Message
                </StyledTableCell>
                <StyledTableCell align="center">
                  Details
                </StyledTableCell>
                <StyledTableCell align="center">
                  Archiver
                </StyledTableCell>

              </TableRow>
            </TableHead>
            <TableBody>

              {data.map((row: any) => (
                <TableRow key={row["id"]} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                  <StyledTableCell component="th" scope="row" align="center" style={row['isRead'] === false ? { fontWeight: "800" } : {}}>
                    {new Date(row['pushDate']).toLocaleDateString('fr-GB', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                    })}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center" style={row['isRead'] === false ? { fontWeight: "800" } : {}}>
                    {row['Patient'].firstname}  {row['Patient'].lastname}
                  </StyledTableCell>
                  {/* <StyledTableCell component="th" scope="row" align="center" style={row['isRead'] === false ? { fontWeight: "800" } : {}}>
                    {row['Patient'].lastname}
                  </StyledTableCell> */}
                  <StyledTableCell component="th" scope="row" align="center" style={row['isRead'] === false ? { fontWeight: "800" } : {}}>
                    {row['message']}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    <Tooltip title="Consulter les mesures" placement="top" sx={{
                      zIndex: 10,
                      position: "absolute",
                      transform: "translate(-50%, -50%)",

                    }}>
                      <IconButton
                        style={{}}
                        onClick={() => {
                          setOpenModal(true);
                          setselectedPatientId(row['Patient'].id);
                          setselectedAlert(row['id']);
                        }}
                      >
                        <InfoOutlinedIcon style={{ color: '#18C5BF', cursor: "pointer" }} />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">

                    <Tooltip title="Archiver l'alerte" placement="top" sx={{
                      zIndex: 10,
                      position: "absolute",
                      transform: "translate(-50%, -50%)",

                    }}>
                      <IconButton
                        style={{}}
                        onClick={() => { handleOpenConfirmation(row['id']) }}
                      >
                        <ArchiveOutlinedIcon style={{ color: '#18C5BF', cursor: "pointer" }} />
                      </IconButton>

                    </Tooltip>
                    {showConfirmation && (
                      <Dialog open={showConfirmation}
                        onClose={() => { setShowConfirmation(false) }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={{ zIndex: 99 }}
                      >
                        <DialogTitle id="alert-dialog-title" style={{ marginTop: "10px", marginLeft: "20px", color: "#035282" }}>{"Confirmer l'archivage"}</DialogTitle>
                        <StyledDialogContent>
                          <div id="alert-dialog-description">
                            <Box style={{ padding: "20px" }}>
                              Êtes-vous sûr de vouloir archiver cet élément ?
                            </Box>

                          </div>
                        </StyledDialogContent>
                        <DialogActions>
                          <div style={{ width: "70%" }}>
                            <BlueSkyButton title={'Confirmer'} onClick={() => { AlertArchiveClick(rowId) }} />
                          </div>
                          <CancelBtn onClick={() => { setShowConfirmation(false) }} style={{ cursor: 'pointer' }}  > Retour</CancelBtn>

                        </DialogActions>
                      </Dialog>)}
                  </StyledTableCell>



                </TableRow>
              ))}

            </TableBody>
          </StyledTable>
        </TableContainer>
        <Dialog open={openModal} onClose={() => setOpenModal(false)} fullWidth={true}
          maxWidth='sm'
          style={{ zIndex: 9999 }}>
          <StyledDialogTitle> Marquer la notification comme lue </StyledDialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => redirectToPatientDetails()}
            sx={{
              position: 'absolute',
              right: 20,
              top: 20,
              color: colors.dark_blue
            }}
          >
            <CloseIcon />
          </IconButton>

          <StyledDialogContent>
            {!isLoading ?
              <StyledDialogContentText>
                Je confirme avoir pris connaissance de cette alerte.
              </StyledDialogContentText>
              :
              <Box sx={{ padding: "50px 0px" }}>
                <SpinnerComponent />
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
                </Box>
              </Box>
            }

          </StyledDialogContent>
          {!isLoading &&
            <DialogActions>
              <ActionsWrapper>
                <CancelBtn onClick={() => redirectToPatientDetails()}>Plus tard</CancelBtn>
                <ConfirmBtn onClick={() => setAlertAsRead()}>Confirmer</ConfirmBtn>
              </ActionsWrapper>

            </DialogActions>
          }
        </Dialog>
      </PageWrapper>
    </PageWithHeader >


  );
}

export default Alerts;