import { Box, Typography, Avatar, Tabs, Tab } from '@mui/material'
import PageWithHeader from 'components/ReusableDivs/PageWithHeader'
import PageWrapper from 'components/ReusableDivs/PageWrapper'
import React, { useState } from 'react'
import TabPanel, { a11yProps } from './ProfileInfos/TabPanel';
import ProfileInfos from './ProfileInfos/ProfileInfos';
import MyOrganizations from './ProfileInfos/MyOrganizations';
import ManageProfile from './ProfileInfos/ManageProfile';
import { styled } from '@mui/styles';
import { useSelector } from 'react-redux';


const AntTabs = styled(Tabs)({
  // borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#00bd9a'
  }
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  fontSize: '16px',
  color: 'rgba(0, 0, 0, 0.85)',
  border: "none",

  '&:hover': {
    color: '#18c5bf',
    opacity: 1
  },
  '&.Mui-selected': {
    color: '#18c5bf'
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#18c5bf'
  }
}));


function Profile() {
  const [value, setValue] = useState(0);
  const { userInfo } = useSelector((state: any) => state.userDomisante);
  let orgaAdminRole = "organization admin"

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <PageWithHeader>
      <PageWrapper title="Profil">

        <Box
          sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: "100%" }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", height: "100%", borderRight: 1, borderColor: 'divider', }}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", margin: "0px 0px 40px", }}>
              <Avatar
                src="/static/images/avatar/1.jpg"
                sx={{ width: 100, height: 100 }}
              />
              <Typography style={{ margin: "20px 0px", textTransform: "capitalize", fontWeight: "600", fontSize: "16px", color: "#035282" }}>{userInfo.firstname} {userInfo.lastname}</Typography>
            </Box>
            <Box sx={{ height: "100%", flex: 1 }} display="flex" flexGrow={1}>


              <AntTabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                sx={{ borderRight: 1, borderColor: 'divider', flex: 1, padding: "10% 0%" }}
              >

                <AntTab label="Informations du compte" {...a11yProps(0)} />
                <AntTab label="Mes organisations" {...a11yProps(1)} />
                <AntTab label="Sécurité" {...a11yProps(2)} />
              </AntTabs>
              {/* :
              <AntTabs
                orientation="vertical"
                // variant="scrollable"
                value={value}
                onChange={handleChange}
                sx={{ flex: 1, height: "100%", padding: "10% 0%", border: 'none' }}
              >

                <AntTab label="Informations du compte" {...a11yProps(0)} sx={{ border: 'none' }} />
                <AntTab label="Sécurité" {...a11yProps(1)} sx={{ border: 'none' }} />
              </AntTabs> */}

            </Box>
          </Box>

          {/* <> */}
          <TabPanel value={value} index={0}>
            <Typography style={{ textAlign: "start", fontWeight: "600", fontSize: "20px", color: "#035282", padding: "5px" }}>Informations du compte</Typography>
            <Typography style={{ textAlign: "start", fontWeight: "500", fontSize: "16px", padding: "5px" }}>Gérer vos informations personelles : votre nom, votre prénom et votre numéro de téléphone </Typography>

            <Box sx={{ width: "100%", flex: 1 }}>
              <ProfileInfos />
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Typography style={{ textAlign: "start", fontWeight: "600", fontSize: "20px", color: "#035282", padding: "5px" }}>Mes organisations</Typography>
            <Typography style={{ textAlign: "start", fontWeight: "500", fontSize: "16px", padding: "5px" }}>Gérer vos informations personelles : votre nom, votre prénom et votre numéro de téléphone </Typography>
            <MyOrganizations />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Typography style={{ textAlign: "start", fontWeight: "600", fontSize: "20px", color: "#035282", padding: "5px" }}>Sécurité</Typography>
            <Typography style={{ textAlign: "start", fontWeight: "500", fontSize: "16px", padding: "5px" }}>Changer votre mot de passe </Typography>
            <ManageProfile />
          </TabPanel>
          {/* </> :
              <>
                <TabPanel value={value} index={0}>
                  <Typography style={{ textAlign: "start", fontWeight: "600", fontSize: "20px", color: "#035282", padding: "5px" }}>Informations du compte</Typography>
                  <Typography style={{ textAlign: "start", fontWeight: "500", fontSize: "16px", padding: "5px" }}>Gérer vos informations personelles : votre nom, votre prénom et votre numéro de téléphone </Typography>

                  <Box sx={{ width: "100%", flex: 1 }}>
                    <ProfileInfos />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Typography style={{ textAlign: "start", fontWeight: "600", fontSize: "20px", color: "#035282", padding: "5px" }}>Sécurité</Typography>
                  <Typography style={{ textAlign: "start", fontWeight: "500", fontSize: "16px", padding: "5px" }}>Changer votre mot de passe </Typography>
                  <ManageProfile />
                </TabPanel>
              </> */}




        </Box>
      </PageWrapper>
    </PageWithHeader >
  )
}

export default Profile