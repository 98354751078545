import { Img, ImgContainer, LogoWrapper } from 'components/StyledWrappers/StyledWrappers'
import React from 'react'

function Logo() {
  return (
    <LogoWrapper>
    <ImgContainer>
      <Img src={process.env.PUBLIC_URL + '/DomiSante_Logo.png'} alt="DomiSanté" />
    </ImgContainer>
  </LogoWrapper>
  )
}

export default Logo