import { Box } from '@mui/material';
import { Img, ImgContainer } from 'components/StyledWrappers/StyledWrappers';
import { useEffect, useState } from 'react';
import { Content, Wrapper } from 'screens/Menu/Dashboard';
import Carousel from 'react-multi-carousel';
import { responsive } from 'screens/organisations/OrgaChoice';
import { useDispatch, useSelector } from 'react-redux';
import OrgaCard from 'screens/organisations/OrgaCard';
import { useNavigate } from 'react-router-dom';
import { updateUserRole } from 'store/userSlice';
import DropDownMenu from './DropDownMenu';

function RoleChoice() {
    const navigate = useNavigate();
    const acc_token = useSelector((state: any) => state.userDomisante.userToken);
    const roles: string = useSelector((state: any) => state.userDomisante.userInfo.roles);
    const [initialRoles, setInitialRoles] = useState<string[]>([])
    const dispatch = useDispatch();
    const [isHover, setIsHover] = useState(false);


    useEffect(() => {
        if (roles.length > 0) {
            let rolesTemp: string[] = roles.split("|")
            console.log("roles", roles)
            setInitialRoles(rolesTemp)
        }
    }, [roles])

    const handleMouseEnter = () => {
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setIsHover(false);
    };



    function chooseRoleAndRedirect(role: string) {
        console.log("role", role)
        let chosenRole = role.trimStart().trimEnd()
        dispatch(updateUserRole(chosenRole));
        redirectToRoleHomePage(chosenRole)

    }
    function redirectToRoleHomePage(userRole: string) {
        switch (userRole) {
            case 'organization admin':
                navigate('/users');
                break;
            case 'aidant':
            case 'professionnel de santé':
                navigate('/dashboard');
                break;
            case 'professional':
                navigate('/get-homes');
                break;
            case 'admin':
                navigate('/get-homes');
                break;
            case 'super admin':
                navigate('/get-homes');
                break;

            default:
                break;
        }
    }
    function matchRoleName(type: string) {
        console.log("type", type)
        switch (type.trimEnd().trimStart()) {
            case "professionnel de santé":

                return "Professionnel de santé"

            case "organization admin":

                return "Administrateur d'organisation"
            default:
                return ""
        }
    }
    return (
        <Wrapper>
            <Content style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <ImgContainer  >
                        <Img src={process.env.PUBLIC_URL + '/Logo_final_fond_bleu.svg'} alt="DomiSanté"
                        />
                    </ImgContainer>

                </Box>
                <div
                    style={{
                        backgroundColor: 'transparent',
                        height: '60vh',
                        width: '50%',
                        marginTop: '5vh',
                        display: "flex",
                        flexDirection: "column"
                    }}
                >
                    <div style={{
                        textAlign: 'center',
                        padding: '2vw',
                        fontSize: '24px', fontWeight: 500, color: 'white',
                    }}>
                        Se connecter en tant que :
                    </div>
                    <div
                        style={{
                            height: '35vh',
                            width: '70vw',
                            margin: 'auto',

                        }}
                    >
                        <Carousel responsive={responsive}>
                            {initialRoles ?
                                initialRoles.map((obj, index) => {
                                    console.log("obj", obj)
                                    return <OrgaCard title={matchRoleName(obj)} key={index} onClick={() => { chooseRoleAndRedirect(obj) }}
                                        isSelected={false} />;
                                }) :

                                <div>Aucune organisation trouvée</div>}
                        </Carousel>
                    </div>


                </div>
            </Content>
            <DropDownMenu />
        </Wrapper>
    )
}

export default RoleChoice

