import { LargeCardHeading } from 'components/Cards/CardItems';
import React from 'react';

interface TransmissionsProps {
  transmissionData: { title: string; message: string; createdAt: string ;createdByProfessional:{firstname:string,lastname:string}};
}

const Transmissions: React.FC<TransmissionsProps> = ({ transmissionData }) => {
  return (
    <div style={{backgroundColor: '#fff',padding: '20px',width: '90%',height: '70px',borderRadius: '10px',display: 'flex', alignItems: 'flex-start',  justifyContent: 'space-between',marginBottom:"10px"}}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <LargeCardHeading style={{ fontSize: '22px', marginBottom: '0px', marginTop: '0px' }}>
          {transmissionData.title}
        </LargeCardHeading>
        <div style={{ color: '#0ED2EB',marginBottom:"10px", fontSize: '15px'}}>{new Date(transmissionData.createdAt).toLocaleDateString('fr-GB',{year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,})} par {transmissionData.createdByProfessional.firstname} {transmissionData.createdByProfessional.lastname}</div>
        <div>{transmissionData.message}</div>
      </div>
      
      
    </div>
  );
};

export default Transmissions;