import PageWithHeader from 'components/ReusableDivs/PageWithHeader'
import PageWrapper from 'components/ReusableDivs/PageWrapper'
import React, { useEffect, useState } from 'react'
import { Box, Dialog, DialogContent, TableBody, TableContainer, TableHead, TableRow, Typography, DialogActions, Autocomplete, TextField, FormControl, InputLabel, MenuItem, Tooltip, IconButton } from '@mui/material';
import { devicesService } from "../../services/devicesService";
import { measuresService } from "../../services/measuresService";
import NoData from 'components/ReusableDivs/NoData';
import Empty from 'components/ReusableDivs/Empty';
import { StyledTable, StyledTableCell } from 'styles/tables/TableStyles';
import { CancelBtn, ConfirmBtn, StyledDialogContent, StyledDialogContentText, StyledDialogMessage, StyledDialogTitle } from 'styles/dialogs/DialogStyles';
import { InputBlueBottom } from 'components/InputControls.tsx/Inputs';
import SpinnerComponent from 'components/spinners/SpinnerComponent';
import { ActionsWrapper } from './UsersList';
import { in200s } from 'utils/RequestCheckFns';
import { usersService } from 'services/usersService';
import Select from '@mui/material/Select';
import CheckBox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';
import { Label } from 'Inscription/Foyer';
import { useSelector } from 'react-redux';
import { Role, colors } from 'utils/Constants';
import { Chip } from '@mui/material';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { validateDevice } from 'validators/DeviceValidators/NewDeviceValidator';
import CloseIcon from '@mui/icons-material/Close';
import OrangeButton from 'components/InputControls.tsx/OrangeButton';
import { StyledHead } from './Patients';
import ActionButton from 'components/InputControls.tsx/ActionButton';

const useStyles = makeStyles({
  label: {
    fontFamily: "open sans",
    "&.Mui-focused": {
      color: "#035282",
      fontFamily: "open sans"
    },
  },
});
interface User {
  firstname: string;
  id: string;
  lastname: string;
  roles: string;
}
function Devices() {
  const classes = useStyles();

  const [devices, setDevices] = useState([{
    id: '',
    serialNumber: '',
    type: '',
    User: {
      id: '',
      firstname: '',
      lastname: '',
      roles: ''
    }
  }])
  const [devicesToShow, setDevicesToShow] = useState([{
    id: '',
    serialNumber: '',
    type: '',
    User: {
      id: '',
      firstname: '',
      lastname: '',
      roles: ''
    }
  }])
  const [PatientsData, setPatientsData] = useState([])//pas encore utilisé
  const [isEmpty, setIsEmpty] = useState(false)
  const [open, setopen] = useState(false)
  const [openDiassociate, setopenDiassociate] = useState(false)

  const [isLoading, setisLoading] = useState(false)
  //const [organizationId, setorganizationId] = useState()
  const [patientsCheck, setPatientsCheck] = useState(true)
  const [proCheck, setProCheck] = useState(true)

  const [Patientslist, setPatientslist] = useState([])
  const [Professionalslist, setProfessionalslist] = useState([])
  const [patients, setPatients] = useState([])
  const [Professionals, setProfessionals] = useState([])
  const [value, setValue] = useState<string | null>("");
  const [patientValue, setPatientValue] = useState<string | null>("");
  const [ProfessionalValue, setProfessionalValue] = useState<string | null>("");
  const [typeValue, setTypeValue] = useState<string | null>("");
  const [refresh, setrefresh] = useState(false)
  const [errors, setErrors] = useState({
    user_id: "",
    professional_id: "",
    patient_id: "",
    type: "",
    serialNumber: ""
  })
  const [userToDiassociate, setuserToDiassociate] = useState("")
  const [deviceToDiassociate, setdeviceToDiassociate] = useState("")

  const [isError, setisError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const role = useSelector((state: any) => state.userDomisante.userRole);
  const orgaId = useSelector((state: any) => state.userDomisante.managedOrganizationId);
  const acc_token = useSelector((state: any) => state.userDomisante.userToken);
  const handleOpen = () => setopenDiassociate(true);

  const handlePatients = (id: string) => {
    devicesService.getPatientsByDevice(id, setPatientsData, acc_token)
  }

  function handleChange(e: any) {
    let name = e.target.name
    let value = e.target.value

    setnewDevice({
      ...newDevice,
      [name]: value
    })
    setTypeValue(value)
  }
  const [newDevice, setnewDevice] = useState({
    user_id: "",
    professional_id: "",
    patient_id: "",
    type: "",
    serialNumber: ""
  })
  const handleCloseAddDevice = () => {
    setopen(false);
    setnewDevice({
      user_id: "",
      professional_id: "",
      patient_id: "",
      type: "",
      serialNumber: ""
    });
    //setValue('')
    setPatientValue("")
    setProfessionalValue("")
    setTypeValue("")
    setIsSuccess(false)
    setisError(false)
    setopen(false)
  };

  async function handleAddDevice() {
    if (newDevice.patient_id) {
      newDevice.user_id = newDevice.patient_id
    }
    if (newDevice.professional_id) {
      newDevice.user_id = newDevice.professional_id;
    }

    if (validateDevice(newDevice, setErrors)) {
      setisLoading(true);
      let response = await devicesService.addNewDevice(newDevice, acc_token);
      console.log("response", response)
      if (response && in200s(response)) {
        setIsSuccess(true)
        setisLoading(false);
        setrefresh(!refresh);
      } else {
        setisError(true)
        setisLoading(false);
      }
    }
    setnewDevice({
      user_id: "",
      professional_id: "",
      patient_id: "",
      type: "",
      serialNumber: ""
    });

    //setValue("")
    setPatientValue("");
    setProfessionalValue("");
    setTypeValue("");
    setrefresh(!refresh);

  }


  async function getPatientsPros() {
    if (open) {
      usersService.getPatients(setPatients, acc_token, orgaId);
      usersService.getProfessionals(setProfessionals, acc_token, orgaId);


      if (patients.length > 0) {
        await setPatientslist(formatList(patients)
        )
      }
      if (Professionals.length > 0) {
        await setProfessionalslist(formatList(Professionals))
      }
    }
  }

  useEffect(() => {

    if (role == Role.ORGANIZATION_ADMIN && orgaId) {
      devicesService.getAllDevices(setDevices, acc_token, orgaId)
    }
    else {
      devicesService.getAllDevices(setDevices, acc_token)
      if (devices.length === 0) {
        setIsEmpty(true)
      }
    }
  }, [refresh])

  useEffect(() => {
    getPatientsPros()

  }, [open, patients.length, Professionals.length])







  const handleChangePatient = (e: any, v: any) => {
    if (!v) {
      setnewDevice({
        ...newDevice,
        patient_id: "",
      })
      setPatientValue("")
    } else {
      setPatientValue(v)
      setnewDevice({
        ...newDevice,
        patient_id: v!.id,
      })
    }
  }

  const handleChangeProfessional = (e: any, v: any) => {
    if (!v) {
      setnewDevice({
        ...newDevice,
        professional_id: ""
      })
      setProfessionalValue("")
    } else {
      setProfessionalValue(v)
      setnewDevice({
        ...newDevice,
        professional_id: v!.id,
      })
    }
  }


  function formatList(array: any[]) { //faut renommer la fonction pour l'utiliser aussi pour les pro
    let formatted: any = []
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      formatted.push({ label: `${element['firstname']} ${element['lastname']}`, id: `${element['id']}`, key: `${element['id']}` })
    }
    return formatted

  }
  const handleClose = () => {
    setopenDiassociate(false)
    setisError(false)
    setIsSuccess(false)
    setisLoading(false)
  }




  const handlePatientsCheckboxChange = (event: any) => {
    const isChecked = event.target.checked;
    setPatientsCheck(isChecked)
  };


  const handleProCheckboxChange = (event: any) => {
    const isChecked = event.target.checked;
    setProCheck(isChecked)

  };

  useEffect(() => {
    if (patientsCheck == true && proCheck == true) {
      setDevicesToShow(devices)
    }
    if (patientsCheck == false && proCheck == true) {
      const filteredDevices = devices.filter(device => device.User.roles === 'professionnel de santé');
      setDevicesToShow(filteredDevices)
    }
    if (patientsCheck == true && proCheck == false) {
      const filteredDevices = devices.filter(device => device.User.roles === 'adhérent');
      setDevicesToShow(filteredDevices)

    }
    if (patientsCheck == false && proCheck == false) {
      setDevicesToShow([])
      setIsEmpty(false)
    }


  }, [patientsCheck, proCheck, devices]);
  async function diassociateDevice() {
    setisLoading(true)
    let response = await devicesService.diassociateDevice(userToDiassociate, deviceToDiassociate, acc_token)
    if (role.includes(Role.ORGANIZATION_ADMIN)) {
      response = await devicesService.diassociateDevice(userToDiassociate, deviceToDiassociate, acc_token, orgaId)
    }
    if (response != null) {
      setIsSuccess(true)
      setrefresh(!refresh)
      setisLoading(false)
    }
    else {
      setisError(true)
      setisLoading(false)


    }


  }
  const selectRoleColor = (role: string) => {
    if (role == 'super admin' || role == 'admin') {
      return '#035282';
    }
    if (role == 'aidant') {
      return '#18C5BF';
    }
    if (role == 'professionnel de santé') {
      return '#8CC8DB';
    }
    if (role == 'professional') {
      return '#F4C274';
    }
    if (role == 'organization admin') {
      return '#0ED2EB';
    }
    if (role == 'adhérent') {
      return '#A0FFE9';
    }
  };

  async function handleDoc2u() {
    let roomToken = await measuresService.connectToDoc2u(acc_token)
    console.log("ROOM : ", roomToken)
    const url = `https://app.doc2u.fr/room?t=${roomToken}`
    console.log("URL : ", url)
    window.open(url, '_blank')
  }
  return (
    <PageWithHeader>
      <PageWrapper title="Liste des objets santé">

        <StyledHead >

          <div style={{ width: '200px', display: "flex", flexDirection: "row" }}>
            <ActionButton title="Ajouter" onClick={() => setopen(true)} />
          </div>
          <div style={{ width: '200px', display: "flex", flexDirection: "row", marginLeft: "30px" }}>
            <OrangeButton title="Tester Doc2u" onClick={handleDoc2u} />
          </div>
        </StyledHead>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: 6, }}>
            <CheckBox
              checked={patientsCheck}
              onChange={handlePatientsCheckboxChange}
              inputProps={{ 'aria-label': 'Afficher au patient' }}
              style={{ color: patientsCheck ? '#18C5BF' : '#035282', borderColor: "inherit" }}
            />

            <Typography style={{ color: '#035282' }}>Bénéficiaire</Typography>
          </div>

          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: 6, }}>
            <CheckBox
              checked={proCheck}
              onChange={handleProCheckboxChange}
              inputProps={{ 'aria-label': 'Afficher au patient' }}
              style={{ color: proCheck ? '#18C5BF' : '#035282', borderColor: "inherit" }}
            />

            <Typography style={{ color: '#035282' }}>Professionel de santé</Typography>
          </div>
        </div>

        {devicesToShow.length > 0 &&
          <TableContainer >
            <StyledTable size="medium" aria-label="a dense table">
              <TableHead >
                <TableRow >
                  <StyledTableCell align="left">Numéro de série</StyledTableCell>
                  <StyledTableCell align="left">Type</StyledTableCell>
                  <StyledTableCell align="center">Affecté à</StyledTableCell>
                  <StyledTableCell align="center">Rôle</StyledTableCell>
                  <StyledTableCell align="center">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {devicesToShow.map((row) => (
                  <TableRow
                    key={row["id"]}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <StyledTableCell component="th" scope="row" align="left" >
                      {row["serialNumber"]}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="left" >
                      {row["type"]}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">

                      {
                        row["User"] != null ? row["User"]['firstname'] + row["User"]['lastname'] : "non affecté"
                      }

                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row["User"] != null && row["User"]['roles'].split('|').length > 0 ? (
                        row["User"]['roles']
                          .split('|')
                          .map((role, index) => (
                            <Chip
                              label={role.trimEnd().trimStart() == "adhérent" ? "Bénéficiare" : `${role.trimEnd().trimStart()}`}
                              variant="outlined"
                              key={index}
                              style={{ margin: 5, color: role.trimEnd().trimStart() == "adhérent" ? "#035282" : "white", fontSize: "16px", backgroundColor: selectRoleColor(`${role.trimEnd().trimStart()}`) }}
                            />
                          ))
                      ) : (
                        <div style={{ color: selectRoleColor(`${role.trimEnd().trimStart()}`) }}>
                          {' '}
                          données absentes
                        </div>
                      )}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      {row["User"] != null && row["User"] &&
                        <Tooltip title="Dissocier l'objet santé" placement="top">
                          <IconButton onClick={() => {
                            handleOpen();
                            setuserToDiassociate(row["User"]["id"]);
                            setdeviceToDiassociate(row["id"]);
                          }} >
                            <LinkOffIcon style={{ color: "#18C5BF" }} />
                          </IconButton>
                        </Tooltip>

                      }
                    </StyledTableCell>

                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableContainer>}
        {isEmpty && <NoData />}
        {devicesToShow.length === 0 && isEmpty && <Empty />}

      </PageWrapper>
      <div>
        <Dialog open={open}
          style={{ zIndex: 99 }}
          onClose={() => setopen(false)} fullWidth={true} maxWidth="md">
          <StyledDialogTitle>Ajouter un objet santé</StyledDialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => handleCloseAddDevice()}
            sx={{
              position: 'absolute',
              right: 20,
              top: 20,
              color: colors.dark_blue
            }}
          >
            <CloseIcon />
          </IconButton>
          <StyledDialogContent>
            {!isLoading && !isSuccess && !isError &&
              <>
                <StyledDialogContentText style={{ padding: 5 }}>
                  Veuillez spécifier les données du nouvel objet santé.
                </StyledDialogContentText>
                <div
                  style={{
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 5
                  }}
                >
                  <InputBlueBottom
                    value={newDevice.serialNumber}
                    onChange={handleChange}
                    placeholder="Numéro de série"
                    type="text"
                    name="serialNumber"
                    required
                  />
                  <Label>{errors.serialNumber}</Label>
                  <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-standard-label" className={classes.label}>Type d'objet </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      onChange={handleChange}
                      value={typeValue}
                      label="Type d'objet"
                      name="type"
                    >
                      <MenuItem value={"doc2u"}>One</MenuItem>
                      <MenuItem value={"balance connectée"}>Balance connectée</MenuItem>
                    </Select>
                  </FormControl>

                  <Label>{errors.type}</Label>

                  <div style={{ margin: "0px 0px 80px 0px" }}>
                    <Autocomplete
                      ListboxProps={{ style: { maxHeight: 100, overflow: 'auto' } }}
                      onChange={handleChangePatient}
                      value={patientValue || null}
                      disablePortal
                      id="combo-box-demo"
                      options={Patientslist}
                      sx={{ width: "100%", }}
                      renderInput={(params) => <TextField {...params} label="Bénéficiaire" />}

                    />
                    <div style={{ marginTop: '40px' }}>
                      <Autocomplete
                        ListboxProps={{ style: { maxHeight: 100, overflow: 'auto' } }}
                        onChange={handleChangeProfessional}
                        value={ProfessionalValue || null}
                        disablePortal
                        id="combo-box-demo"
                        options={Professionalslist}
                        sx={{ width: "100%", }}
                        renderInput={(params) => <TextField {...params} label="Professionnel de santé" />}

                      />
                    </div>

                    <Label>{errors.professional_id}</Label>

                  </div>


                </div>
              </>
            }
            {isLoading && <Box sx={{ padding: "50px 0px" }}>
              <SpinnerComponent />
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
              </Box>
            </Box>
            }
            {
              isSuccess && <Box sx={{ padding: "50px 0px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Objet ajouté avec succès.</StyledDialogMessage>
                </Box>
              </Box>
            }
            {
              isError && <Box sx={{ padding: "50px 0px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer.</StyledDialogMessage>
                </Box>
              </Box>
            }
          </StyledDialogContent>
          <DialogActions>
            <ActionsWrapper>
              <CancelBtn onClick={() => {
                handleCloseAddDevice();

              }}>{
                  !isLoading && !isError && !isSuccess ?
                    "Annuler" : "Fermer"
                }</CancelBtn>
              {
                !isLoading && !isError && !isSuccess &&
                <ConfirmBtn onClick={() => handleAddDevice()}> Ajouter</ConfirmBtn>
              }
            </ActionsWrapper>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDiassociate}
          onClose={() => setopenDiassociate(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: 99 }}
        >
          <StyledDialogTitle id="alert-dialog-title">
            {"Êtes-vous sûr de bien vouloir dissocier cet objet santé ?"}
          </StyledDialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            sx={{
              position: 'absolute',
              right: 20,
              top: 20,
              color: colors.dark_blue
            }}
          >
            <CloseIcon />
          </IconButton>

          <StyledDialogContent>
            {
              !isLoading && !isError && !isSuccess &&
              <StyledDialogContentText id="alert-dialog-description">
                En désaffectant cet objet santé, vous pouvez le réaffecter à un autre professionnel ou à un bénéficiaire.
                Voulez-vous confirmer cette action ?

              </StyledDialogContentText>}
            {
              isLoading && <Box sx={{ padding: "50px 0px" }}>
                <SpinnerComponent />
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
                </Box>
              </Box>
            }
            {
              isError && <Box sx={{ padding: "50px 0px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer.</StyledDialogMessage>
                </Box>
              </Box>
            }
            {
              isSuccess && <Box sx={{ padding: "50px 0px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Objet dissocié avec succès.</StyledDialogMessage>
                </Box>
              </Box>
            }
          </StyledDialogContent>
          <DialogActions>
            <ActionsWrapper>
              <CancelBtn onClick={() => handleClose()}>{
                !isLoading && !isError && !isSuccess ?
                  "Annuler" : "Fermer"
              }</CancelBtn>
              {!isLoading && !isError && !isSuccess &&
                <ConfirmBtn onClick={() =>
                  diassociateDevice()
                } autoFocus>
                  Confirmer
                </ConfirmBtn>}
            </ActionsWrapper>

          </DialogActions>
        </Dialog>
      </div>
    </PageWithHeader>
  )
}

export default Devices
