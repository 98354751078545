import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { DialogContentText, DialogTitle, Typography, Box, Dialog, DialogContent } from '@mui/material';

export const CancelBtn = styled(Button)`
  background-color: #fff;
  color: #035282;
  border: 1px solid #035282;
  margin: 5px 15px;
`;

export const ToggleBtn = styled(Button)`
  background-color: #fff;
  color: #035282;
  border: 1px solid #035282;
  margin: 10px;
  padding: 2px 8px;
  font-size: 12px;
`;
export const ConfirmBtn = styled(Button)`
  background-color: #18c5bf;
  color: white;
  margin: 5px 0px;

  &:active {
    background-color: #fff;
    color: #18c5bf;
    border: 1px solid #18c5bf;
  }
  &:hover {
    background-color: #fff;
    color: #18c5bf;
    border: 1px solid #18c5bf;
  }
`;

export const TertiaryBtn = styled(Button)`
  background-color: #c2d4d8;
  color: #035282;
  margin: 5px 15px;
`;

export const StyledDialogActions = styled(DialogActions)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 10px 10px 10px 20px;
`;
export const StyledDialogMessage = styled(Typography)`
  color: #035282;
  font-size: 20px;
`;
export const StyledDialogTitle = styled(DialogTitle)`
  color: #035282;
  font-family: Open Sans !important;
  padding: 30px 70px;
`;
export const StyledDialogContent = styled(DialogContent)`
  padding: 20px 70px;
`;
export const StyledDialogContentText = styled(DialogContentText)`
  font-family: open sans;
  font-size: 16px;
  padding: 5px 0px;
`;

export const StyledDialogContentField = styled(Typography)`
  font-family: open sans;
  font-size: 16px;
  padding: 5px 0px;
  text-transform: capitalize;
`;

export const StyledFieldName = styled(Typography)`
  font-family: open sans;
  font-size: 16px;
  padding: 5px 0px;
  width: 150px;
  // text-transform: uppercase;
  color: #035282;
`;

export const StyledFieldContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  margin: 5px 0px;
`;
