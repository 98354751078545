import { Typography, Box } from '@mui/material'
import { Img } from 'components/StyledWrappers/StyledWrappers'
import React from 'react'


type AuthTemplatePageProps = {
    children: any
}
function AuthTemplatePage({ children }: AuthTemplatePageProps) {
    return (
        <Box
            className="container"
            sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                minHeight: '100vh',
                '@media (max-width: 900px)': {
                    background: 'linear-gradient(to bottom  , #035282, #109BA9)',
                    flexDirection: 'column',
                },
                '@media (min-width: 901px) ': {
                },

            }}
        >
            <Box
                className="box"
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',

                    '@media (max-width: 900px)': {
                    },
                    '@media (min-width: 901px) ': {
                        background: 'linear-gradient(to bottom  , #035282, #109BA9)',
                    },
                }}
            >
                <Box
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <Img src={process.env.PUBLIC_URL + '/Logo_final_fond_bleu.svg'} alt="DomiSanté" />
                    <Typography sx={{
                        color: 'white', fontSize: '30px', paddingTop: '20px',
                        '@media (max-width: 900px)': {
                            fontSize: "18px",
                            backgroundColor:"none",
                            textAlign: 'center'

                        },
                        '@media (min-width: 901px)': {
                            textAlign: 'center'
                        },

                    }}>
                        Votre passerelle de santé à domicile
                    </Typography>
                </Box>
            </Box>
            <Box
                className="box"
                sx={{
                    flex: 1,
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',

                    '@media (max-width: 900px)': {
                        width: "85vw"
                    },
                    '@media (min-width: 901px) ': {
                    },
                }}
            >
                {children}
            </Box>
        </Box>
    )
}

export default AuthTemplatePage