import React, { useMemo } from 'react'
import styled from 'styled-components'
import MainHeader from './MainHeader'


type PageWithHeaderProps = {
  children: React.ReactNode
}

/**
 * to bottom right or to bottom left to test other options 
 */
const Content = styled.div`
padding-top:10px;
background: linear-gradient(to bottom  , #035282, #109BA9);
flex: 1
`

function PageWithHeader({ children }: PageWithHeaderProps) {
  const memoizedMainHeader = useMemo(() => <MainHeader />, []);
  
  return (
    <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      {/* <MainHeader /> */}
      {memoizedMainHeader}
      <Content style={{ paddingBottom: "50px" }}>
        {children}
      </Content>
    </div >
  )
}

export default PageWithHeader