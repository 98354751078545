import { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { homeService } from 'services/homeService';
import styled from 'styled-components';
import Modal from 'react-modal';
import { Autocomplete, Card, CardActionArea, CardContent, Checkbox, IconButton, Paper, Popper, TextField, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SelectChangeEvent } from '@mui/material/Select';
import { InputBlueBottom } from 'components/InputControls.tsx/Inputs';
import { Box, Dialog, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import PageWithHeader from 'components/ReusableDivs/PageWithHeader';
import { PageCard } from 'components/Cards/PageCard';
import { ContentWrapper } from 'styles/pages/pageStyles';
import "../../styles/pages/pageStyles.css"
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import { useSelector } from 'react-redux';
import TabletIcon from '@mui/icons-material/Tablet';
import { StyledTable, StyledTableCell } from 'styles/tables/TableStyles';
import { TableBody, TableContainer, TableHead, TableRow, } from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { GiThreeFriends } from "react-icons/gi";
import ActionButton from 'components/InputControls.tsx/ActionButton';
import { CancelBtn, ConfirmBtn, StyledDialogContent, StyledDialogContentText, StyledDialogMessage, StyledDialogTitle } from 'styles/dialogs/DialogStyles';
import { Label } from 'screens/homes/Homes';
import SpinnerComponent from 'components/spinners/SpinnerComponent';
import { ActionsWrapper } from 'screens/Menu/UsersList';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { usersService } from 'services/usersService';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { Role, colors } from 'utils/Constants';
import NewPatient from 'screens/patients/NewPatient';
import { validatePatient } from 'validators/UserValidators/newPatientValidator';
import { validateAssignedTablet } from '../../validators/TabletValidators/NewTabletValidator';
import { formatPhoneNumber } from 'utils/formatters/phoneNumberFormatter';
import { in200s } from 'utils/RequestCheckFns';

export const CardWrapperSubscription = styled.div`
  overflow: hidden;
  padding: 20px 50px;
  margin: 30px 20px;
  /* width: 35vw; */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05), 0 0px 40px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background-color: white;
`;
export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 30px;
`;
export const StyledButton = styled.button`
  font-size: 17px;
  padding:  0.5em 2em;
  border-radius: 3px;

  background-color: #035282;
  color: white;
  border: none;
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

export const InfoValue = styled.h1`
  font-size: 17px;
  color: #035282;
  display: inline;
  padding: 0px 5px;
  font-weight: 500;
`;

export const InfoField = styled.div`
  font-size: 17px;
  color: #8CC8DB;
  font-weight: 600;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
`;


function HomeDetails() {
  const { id } = useParams() ?? 'undefined';
  const [numSerie, setNumSerie] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalAddPatient, setmodalAddPatient] = useState(false);
  const [elementId, setElementId] = useState<string>('');
  const [deviceType, setDeviceType] = useState('');
  const [usersData, setUsersData] = useState([]);
  const [homeData, setHomeData] = useState({
    "adress1": "",
    "adress2": "",
    "city": "",
    "country": "",
    "zipCode": "",
    "iban": "",
    "subscriberName": "",
    "subscriberLastname": "",
    "subscriberEmail": "",
    "Tablet": {
      "id": "",
      "imei": "",
      "deviceID": "158c0ee94ac35dca"
    }
  })
  const [showErrorLabel, setshowErrorLabel] = useState(false)

  const [isLoading, setisLoading] = useState(false)
  const [newTablet, setNewTablet] = useState({
    imei: "",
    deviceID: "",
    home_id: id
  })
  const [errors, setErrors] = useState({
    imei: "",
    error: ""
  })
  const [selectedPatient, setSelectedPatient] = useState<any>('');
  const [value, setValue] = useState('');
  const [dateValue, setDateValue] = useState<Dayjs | null>(
    dayjs(new Date()),
  );
  let initialFields = {
    email: '',
    firstname: '',
    lastname: '',
    phoneNumber: '',
    ssn: '',
    gender: '',
    birthDate: "",
    externalRef: ""
  }
  const [userDetails, setuserDetails] = useState(initialFields)
  const [userErrors, setUserErrors] = useState({
    email: '',
    firstname: '',
    lastname: '',
    phoneNumber: '',
    ssn: '',
    gender: '',
    birthDate: "",
    externalRef: ""
  });
  const [openDiassociatePatientHome, setopenDiassociatePatientHome] = useState(false)
  const [isError, setisError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const handleDateChange = (newValue: Dayjs | null) => {
    const formattedDate = newValue ? dayjs(newValue).format('YYYY-MM-DD') : '';
    setuserDetails((prevState: any) => ({
      ...prevState,
      birthDate: formattedDate
    }));
    setDateValue(newValue);
  };
  const orgaIdfromRedux = useSelector((state: any) => state.userDomisante.managedOrganizationId);

  const userRole = useSelector((state: any) => state.userDomisante.userRole);

  const [refresh, setrefresh] = useState(false)
  const [selectedManager, setselectedManager] = useState('')
  const [patientsList, setpatientsList] = useState([])
  const [formattedPatientsList, setFormattedPatientsList] = useState([])
  const [patientToDiassociate, setpatientToDiassociate] = useState("")
  const acc_token: any = useSelector((state: any) => state.userDomisante.userToken);
  const [helloCareAccount, sethelloCareAccount] = useState(false);
  const [anamneseAccount, setanamneseAccount] = useState(false);
  const [refreshPatientsList, setrefreshPatientsList] = useState(false)
  const notify = () => toast.error("Veuillez vérifier les données !");

  console.log("String(id)", String(id))
  useEffect(() => {


    homeService.getUsersByHome(String(id), setHomeData, setUsersData, acc_token);
  }, [refresh]);



  function handleNumSerie(e: React.ChangeEvent<HTMLInputElement>) {
    setNumSerie(e.target.value);
  }

  const handleAddDevice = () => {
    let data = { user_id: elementId, type: deviceType, serialNumber: numSerie };
    homeService.assignObject(data, acc_token);
    setrefresh(!refresh)
    setIsOpen(false);
    setElementId("");
    setDeviceType('')
    setNumSerie('')
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  async function assignTablet() {
    let response
    if (validateAssignedTablet(newTablet, setErrors)) {
      if (newTablet.deviceID === "") {
        let data = {
          imei: newTablet.imei,
          home_id: id
        }
        setisLoading(true)

        response = homeService.assignTablet(data, acc_token);

      }
      if (newTablet.imei === "") {
        let data = {
          deviceID: newTablet.deviceID,
          home_id: id
        }
        setisLoading(true)

        response = homeService.assignTablet(data, acc_token);

      }
      else {
        let data = {
          imei: newTablet.imei,
          deviceID: newTablet.deviceID,
          home_id: id
        }
        setisLoading(true)

        response = homeService.assignTablet(data, acc_token);
      }

      if (await response && in200s(await response)) {
        setisLoading(false)
        setrefresh(!refresh)
        setIsSuccess(true)
        setNewTablet({
          imei: "",
          deviceID: "",
          home_id: ""
        })

      }
      else {
        setisError(true)
        setisLoading(false)
        setrefresh(!refresh)
        setNewTablet({
          imei: "",
          deviceID: "",
          home_id: ""
        })
      }
    }


  }
  function closeModal() {
    setIsOpen(false);
    setisLoading(false)
    setisError(false);
    setIsSuccess(false);
  }
  function closeModalDiassociatePatient() {
    setopenDiassociatePatientHome(false);
    setisError(false)
    setIsSuccess(false)
    setisLoading(false)
  }
  function closeModalAddPatient() {
    setmodalAddPatient(false);
    setisError(false);
    setIsSuccess(false)
    setisLoading(false);
  }


  function emptyFieldsAddPatient() {
    setSelectedCard(null)
    setSelectedPatient("")
    setpatientToDiassociate("")
    setuserDetails(initialFields)
  }
  function openModalAddPatient() {
    setmodalAddPatient(true);
  }



  // Tablet management functions
  const handleChangeTablet = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setNewTablet({
      ...newTablet, [name]: value
    })
  }
  const handleChange = (event: SelectChangeEvent) => {
    setDeviceType(event.target.value);
  };


  const navigate = useNavigate();

  const redirect = (page: string) => {
    navigate(`/${page}`);
  };
  const shouldApplyCursorStyle = userRole === 'professionnel de santé' || userRole === 'aidant';
  const [selectedCard, setSelectedCard] = useState<number | null>(null);
  const handleChangePatient = (e: any, v: any) => {
    setSelectedPatient(v);
    setselectedManager(v!.id)
  }
  const handleCardClick = (cardId: number) => {
    setSelectedCard(cardId);
  };
  function formatPatientsList(array: any[]) {
    let formatted: any = []
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      formatted.push({ label: `${element['firstname']} ${element['lastname']}`, id: `${element['id']}` })
    }
    setFormattedPatientsList(formatted)
  }

  useEffect(() => {
    /**
     * Il faut que l'organization id soit spécifié dés le chargement
     */
    if (orgaIdfromRedux !== null) {
      usersService.fetchPatients(setpatientsList, acc_token, orgaIdfromRedux!.toString(), "0")
    }
    else {
      usersService.fetchPatients(setpatientsList, acc_token, undefined, "0")
    }
    if (patientsList.length > 0) {
      formatPatientsList(patientsList)
    }

  }, [acc_token, orgaIdfromRedux, selectedCard, refreshPatientsList])

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  async function AssociateNewPatientToHome() {
    let data = {
      ...userDetails,
      homeId: String(id),
    }
    if (validatePatient(data, setUserErrors)) {
      let response = await usersService.addNewPatient(data, helloCareAccount, anamneseAccount, acc_token)

      if (response && in200s(response)) {
        setIsSuccess(true)
        setrefresh(!refresh)
        setisLoading(false)

      } else {
        setisError(true)
        setisLoading(false)

      }
      emptyFieldsAddPatient()
    }

  }

  console.log("user Details", userDetails)

  async function AssociateExistingPatientToHome() {


    /**
     * This api needs to pass 3 req.query parameters : userId , homeId and organizationId
     */
    let userId = selectedManager
    let homeId = String(id)


    /**
     * This function is calling the create member api and adding homeId to the post body 
     */
    let response
    setisLoading(true)

    if (userRole === Role.ORGANIZATION_ADMIN && orgaIdfromRedux !== null) {
      response = await usersService.associatePatientHome(homeId, userId, acc_token, orgaIdfromRedux)
    }
    else {
      response = await usersService.associatePatientHome(homeId, userId, acc_token)
    }

    if (response != null) {
      setisLoading(false)
      setrefresh(!refresh)
      setIsSuccess(true)
      // closeModalAddPatient()
      emptyFieldsAddPatient()
    } else {
      setisError(true)
      setisLoading(false)

    }

  }
  async function removePatientFromHome() {
    let homeId = String(id)
    let response
    setisLoading(true)

    response = await usersService.DiassociatePatientHome(homeId, patientToDiassociate, acc_token, orgaIdfromRedux)
    if (response != null) {
      setrefresh(!refresh)
      setisLoading(false)
      setIsSuccess(true)
      emptyDiassociationFields()
      setrefreshPatientsList(!refreshPatientsList)
    } else {
      setisError(true)
      setisLoading(false)
    }

  }


  function emptyDiassociationFields() {
    setSelectedPatient("")
  }

  console.log("selectedPatient", selectedPatient)

  return (
    <PageWithHeader>
      <>

        <PageCard title="Détails du foyer" backBtnContainer={true}>

          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div style={{
              display: "flex", flexDirection: "row", justifyContent: "space-between",
              width: "100%", padding: "0px ", borderRadius: 10,
            }}>
              <div className='second-type-info' style={{ flex: 1 }}>
                <Stack direction="row" alignItems="center" gap={1} style={{ marginBottom: 10, }}>
                  <HomeIcon style={{ color: "#18C5BF", fontSize: 45 }} />
                  <span className='info-title'>Adresse </span>
                </Stack>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <InfoField>
                    Adresse :<InfoValue>{homeData["adress1"]}, {homeData["adress2"]}</InfoValue>
                  </InfoField>

                </div>
                <InfoField>
                  Ville:<InfoValue>{homeData["city"]}</InfoValue>
                </InfoField>
                <InfoField>
                  Code postal:<InfoValue>{homeData["zipCode"]}</InfoValue>
                </InfoField>
                <InfoField>
                  Pays:<InfoValue>{homeData["country"]}</InfoValue>
                </InfoField>

              </div>

              <div className='second-type-info' style={{ flex: 1 }}>
                <Stack direction="row" alignItems="center" gap={1} style={{ marginBottom: 10, }}>
                  <TabletIcon style={{ color: "#18C5BF", fontSize: 45 }} />
                  <span className='info-title'>Tablette </span>
                </Stack>
                <InfoField>
                  Numéro de série :
                  <InfoValue>

                    {
                      homeData["Tablet"] && homeData["Tablet"]['deviceID'] ? homeData["Tablet"]['deviceID'] : '--'
                    }

                  </InfoValue>
                </InfoField>
                <InfoField>
                  Imei :
                  <InfoValue>

                    {
                      homeData["Tablet"] && homeData["Tablet"]['imei'] ? homeData["Tablet"]['imei'] : '--'
                    }

                  </InfoValue>
                </InfoField>


                {
                  homeData["Tablet"] == null &&
                  <Box style={{ display: "flex", alignItems: "flex-end", height: "100%", justifyContent: "flex-end", }}                >
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: "flex-end",
                      height: '100px', // Set the desired height for the box
                      padding: '20px', // Optional ,
                      width: "150px",
                      alignItems: "flex-end"
                    }}>
                      <ActionButton title='Affecter une tablette' onClick={() => setIsOpen(true)} />
                    </Box>
                  </Box>
                }


              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-end", width: "95%", paddingTop: "30px" }}>
            <div style={{ width: "15%" }}>
              <ActionButton title="Ajouter un bénéficiaire" onClick={openModalAddPatient} />
            </div>
          </div>

          <ContentWrapper style={{ marginTop: "50px" }} >
            <TableContainer style={{ width: "100%", display: "flex", justifyContent: "center", }} >
              <StyledTable size="medium" aria-label="a dense table"  >
                <TableHead>
                  <TableRow >
                    <StyledTableCell align="center">
                      Nom
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Prénom
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Email
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Date de naissance{' '}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Numéro de téléphone{' '}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Numéro de sécurité sociale{' '}
                    </StyledTableCell>

                    {(userRole == 'aidant' || userRole == 'professionnel de santé') && (
                      <StyledTableCell align="center">
                        Agenda{' '}
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="center">
                      Aidants{' '}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Proches{' '}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Actions{' '}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody style={shouldApplyCursorStyle ? { cursor: 'pointer' } : {}}>
                  {usersData.map((row) => (
                    <TableRow key={row["id"]}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                        onClick={() => {
                          if (userRole === 'aidant' || userRole === 'professionnel de santé') {
                            redirect(`patient-details/${row['id']}`);

                          }
                        }}
                      >
                        {row['lastname']}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                        onClick={() => {
                          if (userRole === 'aidant' || userRole === 'professionnel de santé') {
                            redirect(`patient-details/${row['id']}`);
                          }
                        }}
                      >
                        {row['firstname']}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          maxWidth: "150px",
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                        }}
                        onClick={() => {
                          if (userRole === 'aidant' || userRole === 'professionnel de santé') {
                            redirect(`patient-details/${row['id']}`);
                          }
                        }}
                      >
                        {row['email']}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        onClick={() => {
                          if (userRole === 'aidant' || userRole === 'professionnel de santé') {
                            redirect(`patient-details/${row['id']}`);
                          }
                        }}
                      >
                        {dayjs(row['birthDate']).format('DD/MM/YYYY')}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        onClick={() => {
                          if (userRole === 'aidant' || userRole === 'professionnel de santé') {
                            redirect(`patient-details/${row['id']}`);
                          }
                        }}
                      >
                        {formatPhoneNumber(row['phoneNumber'])}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        onClick={() => {
                          if (userRole === 'aidant' || userRole === 'professionnel de santé') {
                            redirect(`patient-details/${row['id']}`);
                          }
                        }}
                      >
                        {row['ssn']}
                      </StyledTableCell>
                      {(userRole == 'aidant' || userRole == 'professionnel de santé') && (
                        <StyledTableCell align="center">
                          <Tooltip title="Consulter l'agenda" placement="top">
                            <IconButton
                              to={
                                `/calendar/${row['firstname']}/${row['lastname']}/${row['id']}`
                              }
                              component={Link}
                            >
                              <CalendarMonthIcon style={{ color: '#18C5BF' }} />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                      )}
                      <StyledTableCell align="center">
                        <Tooltip title="Afficher la liste des managers" placement="top">
                          <IconButton
                            to={`/patient-managers/${row['firstname']}/${row['lastname']}/${row['id']}`}
                            component={Link}
                          >
                            <PeopleAltIcon style={{ color: '#F4C274', margin: 'auto' }} />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Tooltip title="Afficher la liste des proches" placement="top">
                          <IconButton
                            to={`/patient-relatives/${row['firstname']}/${row['lastname']}/${row["id"]}`}
                            component={Link}>
                            <GiThreeFriends style={{ color: '#F4C274', margin: 'auto', }} size={25} />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Tooltip title="Retirer le bénéficiaire" placement="top">
                          <IconButton onClick={() => {
                            setopenDiassociatePatientHome(true);
                            setpatientToDiassociate(row['id'])
                          }}>
                            <RemoveCircleOutlineIcon style={{ color: '#035282', margin: 'auto', }} />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </ContentWrapper>
        </PageCard>
        <div>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Ajouter un object santé"
            ariaHideApp={false}
          >

            <div style={{ padding: "10px 20px 10px 20px" }}>
              <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", minWidth: "400px" }}>
                <Typography id="modal-modal-title" variant="h6" component="h3" style={{ fontSize: 19, flexGrow: 1 }}>
                  Attribuer un objet santé à un bénéficiaire
                </Typography>
                <IconButton onClick={closeModal} >
                  <CloseIcon />
                </IconButton>
              </Box>

              <div style={{ width: "inherit", paddingTop: "20px", display: "flex", flexDirection: "column" }}>
                <InputBlueBottom
                  value={numSerie}
                  onChange={handleNumSerie}
                  placeholder="Entrez le numéro de série de l'objet santé"
                  type="text"
                  name="objet"
                  required
                />
                <FormControl variant="standard"
                >
                  <InputLabel id="demo-simple-select-standard-label">Type </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={deviceType}
                    onChange={handleChange}
                    label="Entrer le type de l'objet"
                    style={{ minWidth: "150px", color: "#035282" }}
                  >
                    <MenuItem value="tensiomètre"  >Tensiomètre</MenuItem>
                    <MenuItem value="oxymètre">Oxymètre</MenuItem>
                    <MenuItem value="balance connectée">Balance connectée</MenuItem>
                    <MenuItem value="thermomètre">Thermomètre</MenuItem>
                    <MenuItem value="montre connectée">Montre connectée</MenuItem>
                  </Select>

                </FormControl>
                {
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>
                    <StyledButton onClick={handleAddDevice}>Ajouter</StyledButton>
                  </div>
                }

              </div>

            </div>
          </Modal>
        </div>
        <Dialog open={modalIsOpen} onClose={() => closeModal()} fullWidth={true}
          maxWidth='md'
          style={{ zIndex: 99 }}
        >
          <StyledDialogTitle id="customized-dialog-title">
            Ajouter une tablette
          </StyledDialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => closeModal()}
            sx={{
              position: 'absolute',
              right: 20,
              top: 20,
              color: colors.dark_blue
            }}
          >
            <CloseIcon />
          </IconButton>
          <StyledDialogContent>
            {!isLoading && !isError && !isSuccess &&
              <>
                <StyledDialogContentText>
                  Veuillez ajouter les spécifications de votre tablette.
                </StyledDialogContentText>

                <InputBlueBottom
                  value={newTablet.imei}
                  onChange={handleChangeTablet}
                  placeholder="imei"
                  type="text"
                  name="imei"
                  required={false}
                />

                <Label>{errors.imei}</Label>

                <InputBlueBottom
                  value={newTablet.deviceID}
                  onChange={handleChangeTablet}
                  placeholder="deviceID"
                  type="text"
                  name="deviceID"
                  required={false}
                />
                <Label>{errors.error}</Label>

              </>
            }
            {isLoading &&
              <Box sx={{ padding: "50px 0px" }}>
                <SpinnerComponent />
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
                </Box>
              </Box>
            }
            {
              isSuccess && <Box sx={{ padding: "50px 0px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Élément ajouté avec succès.</StyledDialogMessage>
                </Box>
              </Box>
            }
            {
              isError && <Box sx={{ padding: "50px 0px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer.</StyledDialogMessage>
                </Box>
              </Box>
            }

          </StyledDialogContent>
          <DialogActions>
            <ActionsWrapper>
              <CancelBtn onClick={closeModal}>{
                !isLoading && !isError && !isSuccess ?
                  "Annuler" : "Fermer"
              }</CancelBtn>
              {
                !isLoading && !isError && !isSuccess &&
                <ConfirmBtn onClick={() => assignTablet()}>Ajouter</ConfirmBtn>
              }
            </ActionsWrapper>
          </DialogActions>
        </Dialog>
        <Dialog
          open={modalAddPatient}
          onClose={() => closeModalAddPatient()}
          fullWidth={true}
          maxWidth='md'
          style={{ zIndex: 99 }}
        >

          <StyledDialogTitle >
            Ajouter un bénéficiaire
          </StyledDialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => { closeModalAddPatient(); emptyFieldsAddPatient() }}
            sx={{
              position: 'absolute',
              right: 20,
              top: 20,
              color: colors.dark_blue
            }}
          >
            <CloseIcon />
          </IconButton>
          {!isLoading && !isSuccess && !isError &&
            <Box>
              <StyledDialogContent>

                {selectedCard === null && <StyledDialogContentText>
                  Veuillez sélectionner une option.
                </StyledDialogContentText>}
                {selectedCard === 1 && <StyledDialogContentText>
                  Veuillez sélectionner un bénéficiaire parmi la liste.
                </StyledDialogContentText>}
                {selectedCard === 2 && <StyledDialogContentText>
                  Veuillez entrer les données du nouveau bénéficiaire.
                </StyledDialogContentText>}
                {
                  selectedCard === null &&
                  <>
                    <Box style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                      <Box style={{ width: "90%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: "20px", marginBottom: "20px" }}>
                        <Card
                          onClick={() => handleCardClick(1)}
                          sx={{
                            border: selectedCard === 1 ? '2px solid #F4C274' : '2px solid #C2D4D8',
                            flex: 1,
                            marginRight: "20px"
                          }}
                        >
                          <CardActionArea>
                            <CardContent style={{
                              display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center",
                              backgroundColor: "#EEF1F2"
                            }}>
                              <h2 style={{ color: "#035282" }}>Bénéficiaire existant</h2>
                              <p style={{ color: "#035282" }}>Cliquer pour choisir</p>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                        <Card
                          onClick={() => handleCardClick(2)}
                          sx={{
                            border: selectedCard === 2 ? '2px solid #F4C274' : '2px solid #C2D4D8',
                            flex: 1,
                            marginRight: "20px"
                          }}
                        >
                          <CardActionArea>
                            <CardContent style={{
                              display: "flex", backgroundColor: "#EEF1F2", justifyContent: "center",
                              flexDirection: "column", alignItems: "center"
                            }}>
                              <h2 style={{ color: "#035282" }}>Nouveau bénéficiaire</h2>
                              <p style={{ color: "#035282" }}>Cliquer pour choisir</p>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Box>
                    </Box>
                  </>
                }
                {
                  selectedCard === 1 &&
                  <div style={{ marginTop: "20px" }}>
                    <Autocomplete
                      onChange={handleChangePatient}
                      value={selectedPatient || null}
                      id="combo-box-demo"
                      options={formattedPatientsList}
                      sx={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Bénéficiaire"
                          inputProps={{
                            ...params.inputProps,
                            style: { zIndex: 9999 }, // Set a higher z-index value to ensure visibility
                          }}
                        />
                      )}
                    />  <Popper
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      placement="auto-end"
                      style={{ zIndex: 9999 }} // Set a higher z-index value
                    />
                  </div>
                }
                {
                  selectedCard === 2 &&
                  <>
                    {
                      showErrorLabel == true && <Box ><Typography style={{ color: "red", }}>Vous devez saisir les données d'un bénéficiaire au moins </Typography></Box>
                    }
                    <NewPatient userDetails={userDetails} setuserDetails={setuserDetails}
                      sethelloCareAccount={sethelloCareAccount} setanamneseAccount={setanamneseAccount}
                      anamneseAccount={anamneseAccount} helloCareAccount={helloCareAccount}
                      userErrors={userErrors}
                    />
                  </>
                }

              </StyledDialogContent>

            </Box>
          }

          {
            isLoading &&
            <Box sx={{ padding: "50px 0px" }}>
              <SpinnerComponent />
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
              </Box>
            </Box>
          }

          {
            isSuccess &&
            <Box sx={{ padding: "50px 0px" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                <StyledDialogMessage>Bénéficiaire ajouté avec succès.</StyledDialogMessage>
              </Box>
            </Box>
          }
          {
            isError && <Box sx={{ padding: "50px 0px" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer.</StyledDialogMessage>
              </Box>
            </Box>
          }
          {
            selectedCard === null &&
            <DialogActions>
              <ActionsWrapper>
                <CancelBtn onClick={() => { closeModalAddPatient(); emptyFieldsAddPatient() }}>{
                  !isLoading && !isError && !isSuccess ?
                    "Annuler" : "Fermer"
                }</CancelBtn>
              </ActionsWrapper>
            </DialogActions>
          }
          {
            selectedCard === 1 &&
            <DialogActions>
              <ActionsWrapper>
                <CancelBtn onClick={() => { closeModalAddPatient(); emptyFieldsAddPatient() }}>{
                  !isLoading && !isError && !isSuccess ?
                    "Annuler" : "Fermer"
                }</CancelBtn>
                {!isLoading && !isSuccess && !isError && <ConfirmBtn onClick={() => AssociateExistingPatientToHome()}>Ajouter</ConfirmBtn>}
              </ActionsWrapper>
            </DialogActions>
          }
          {
            selectedCard === 2 &&
            <DialogActions>
              <ActionsWrapper>
                <CancelBtn onClick={() => { closeModalAddPatient(); emptyFieldsAddPatient() }}>{
                  !isLoading && !isError && !isSuccess ?
                    "Annuler" : "Fermer"
                }</CancelBtn>
                {!isLoading && !isSuccess && !isError &&
                  <ConfirmBtn onClick={() => AssociateNewPatientToHome()}>Ajouter</ConfirmBtn>}
              </ActionsWrapper>
            </DialogActions>
          }
        </Dialog>

        <Dialog open={openDiassociatePatientHome} onClose={() => closeModalDiassociatePatient()} fullWidth={true}
          maxWidth='md'
          style={{ zIndex: 99 }}
        >

          <StyledDialogTitle> Retirer un bénéficiaire du foyer</StyledDialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => { closeModalDiassociatePatient() }}
            sx={{
              position: 'absolute',
              right: 20,
              top: 20,
              color: colors.dark_blue
            }}
          >
            <CloseIcon />
          </IconButton>
          <StyledDialogContent>
            {!isLoading && !isSuccess && !isError &&
              <>
                <StyledDialogContentText>
                  Êtes-vous sûr de bien vouloir retirer ce bénéficiaire de ce foyer ?
                </StyledDialogContentText>

              </>
            }
            {isLoading && <Box sx={{ padding: "50px 0px" }}>

              <SpinnerComponent />
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
              </Box>
            </Box>
            }
            {
              isSuccess &&
              <Box sx={{ padding: "50px 0px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Bénéficiaire supprimé avec succès.</StyledDialogMessage>
                </Box>
              </Box>
            }
            {
              isError && <Box sx={{ padding: "50px 0px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer.</StyledDialogMessage>
                </Box>
              </Box>
            }

          </StyledDialogContent>
          {!isLoading &&
            <DialogActions>
              <ActionsWrapper>
                <CancelBtn onClick={closeModalDiassociatePatient}>{
                  !isLoading && !isError && !isSuccess ?
                    "Annuler" : "Fermer"
                }</CancelBtn>
                {!isLoading && !isSuccess && !isError &&
                  <ConfirmBtn onClick={() => removePatientFromHome()}>Confirmer</ConfirmBtn>}
              </ActionsWrapper>

            </DialogActions>
          }
        </Dialog>
      </>
    </PageWithHeader >
  );
}

export default HomeDetails;
const CustomPaper: React.FC = (props) => (
  <Paper {...props} style={{ zIndex: 9999 }} /> // Set a higher z-index value
);