import Card from 'components/Cards/Card'
import React from 'react'

function RegisterSuccess() {
    const RedirectPage = () => {
        window.open("https://www.medicaldevicesventure.com/", '_blank')
    }

  return (
    <Card
          title="Merci. "
          description="Votre inscription au dispositif Domisanté est confirmée. Un opérateur prendra contact avec vous prochainement pour planifier l'installation du matériel à votre domicile !"
          buttonTitle="Retour"
          onClick={RedirectPage}
        >

        </Card>
  )
}

export default RegisterSuccess