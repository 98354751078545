import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet, Navigate, useNavigate } from 'react-router-dom'
import { logout } from 'store/userSlice';

const PrivateRoutes = () => {
    const { userToken } = useSelector((state: any) => state.userDomisante);
    const [tokenIsExpired, setTokenisExpired] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const parseJwt = (token: string) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };

    const isExpired = (userToken: string) => {
        const decodedJwt = parseJwt(userToken);
        if (decodedJwt.exp * 1000 < Date.now()) {
            setTokenisExpired(true)
        }
    }

    useEffect(() => {

        if (tokenIsExpired === true || userToken == null) {
            dispatch(logout());
            navigate('/');
        }
        if (userToken != null) {
            isExpired(userToken);
        }

    }, [tokenIsExpired, navigate, userToken])



    return (
        !userToken && userToken === null ? <Navigate to="/" /> : <Outlet />
    )
}

export default PrivateRoutes