import { Fragment, useEffect, useState } from 'react';
import User from './User';
import Foyer from './Foyer';
import { Wrapper } from 'components/StyledWrappers/StyledWrappers';
import {
  CardWrapperSubscription,
  UsersInfo,
  SectionTitle,
  Users
} from 'components/StyledWrappers/SubscriptionWrappers';
import styled from 'styled-components';
import Button from 'components/InputControls.tsx/Button';
import { memberService } from 'services/memberService';
import { Navigate } from 'react-router-dom';

import Logo from 'components/ReusableDivs/Logo';
import CheckBox from 'components/InputControls.tsx/CheckBox';
import RegisterSuccess from './RegisterSuccess';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

const PageWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
`;
const DetailsSection = styled.div`
  display: 'flex';
  margin: 15px;
`;
const ButtonWrapper = styled.div`
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
  margin: 40px 10px 10px;
`;

function Register() {
  const [usersNumber, seUsersNumber] = useState('1');
  const [secondStep, setSecondStep] = useState(false);
  const [firstStep, setFirstStep] = useState(true);

  const [AddHome, setAddHome] = useState({
    adress1: '',
    adress2: '',
    country: 'France',
    city: '',
    zipCode: '',
    iban: '',
    subscriberName: '',
    subscriberLastname: '',
    subscriberEmail: ''
  });

  const [errors, setErrors] = useState({
    adress1: '',
    country: '',
    city: '',
    zipCode: ''
  });
  const [redirect, setRedirect] = useState(false);
  const [userErrors, setUserErrors] = useState({
    email: '',
    firstname: '',
    lastname: '',
    phoneNumber: '',
    ssn: '',
    gender: ''
  });
  const initialFields = {
    email: '',
    firstname: '',
    lastname: '',
    phoneNumber: '',
    ssn: '',
    gender: ''
  };
  const [SubscriberIsPatient, setSubscriberIsPatient] = useState(false);
  const [patient1ToComplete, setpatient1ToComplete] = useState(true);
  const [patient2ToComplete, setpatient2ToComplete] = useState(false);
  const [CheckConditions, setCheckConditions] = useState(false);

  const [users, setUsers] = useState(initialFields);
  const [patient1, setPatient1] = useState(initialFields);
  const [patient2, setPatient2] = useState(initialFields);
  const [showSuccess, setShowSuccess] = useState(false);

  async function registerUser(data) {
    let response = await memberService.register(data);
    return response;
  }

  const handleChange = (e) => {
    seUsersNumber(e.target.value);
  };

  const validateHome = (values) => {
    const errors = {};
    const emailRegex = /$^|.+@.+..+/;
    const nameAndLastNameRegex = /(^[a-zA-Z][a-zA-Z\s]{0,20}[a-zA-Z]$)/;
    let zipCodeRegex = /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/;

    if ('subscriberEmail' in values) {
      errors.subscriberEmail =
        emailRegex.test(values.subscriberEmail) && values.subscriberEmail !== '' ? '' : 'Email non valide.';
    }
    if ('subscriberName' in values) {
      errors.subscriberName =
        nameAndLastNameRegex.test(values.subscriberName) && values.subscriberName !== ''
          ? ''
          : 'Veuillez entrer un prénom valide';
    }
    if ('subscriberLastname' in values) {
      errors.subscriberLastname =
        nameAndLastNameRegex.test(values.subscriberLastname) && values.subscriberLastname !== ''
          ? ''
          : 'Veuillez entrer un nom valide';
    }
    if (!values.adress1) {
      errors.adress1 = 'Veuillez entrer une adresse 1';
    }

    if (!values.country) {
      errors.country = 'Veuillez entrer un pays ';
    }
    if (!values.city) {
      errors.city = 'Veuillez entrer une ville';
    }
    if (!values.zipCode) {
      errors.zipCode = 'Veuillez entrer un code postal';
    } else if (!zipCodeRegex.test(AddHome.zipCode)) {
      errors.zipCode = 'Veuillez entrer un code postal valide';
    }

    setErrors(errors);
    return Object.values(errors).every((x) => x === '');
  };
  const validateSingleUser = (values) => {
    const errors = {};
    const phoneRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
    const emailRegex = /$^|.+@.+..+/;
    const ssnRegex = /^(\d{13}|\s*)?$/;
    if ('email' in values) {
      errors.email = emailRegex.test(values.email) && values.email !== '' ? '' : 'Email non valide.';
    }
    if ('firstname' in values) {
      errors.firstname = values.firstname ? '' : 'Ce champs est obligatoire';
    }
    if ('lastname' in values) {
      errors.lastname = values.lastname ? '' : 'Ce champs est obligatoire';
    }
    if ('phoneNumber' in values) {
      errors.phoneNumber = phoneRegex.test(values.phoneNumber) ? '' : 'Entrer un numéro de téléphone valide';
    }
    if ('ssn' in values) {
      errors.ssn = ssnRegex.test(values.ssn) ? '' : 'Ce champs est obligatoire';
    }
    if ('gender' in values) {
      errors.gender = values.gender ? '' : 'Ce champs est obligatoire';
    }
    setUserErrors(errors);
    return Object.values(errors).every((x) => x === '');
  };

  const DefineUsers = (value) => {
    if (value === '1') {
      setUsers([
        {
          email: '',
          firstname: '',
          lastname: '',
          phoneNumber: '',
          ssn: '',
          gender: ''
        }
      ]);
    } else if (value === '2' && users.length < 2) {
      setUsers([
        ...users,
        {
          email: '',
          firstname: '',
          lastname: '',
          phoneNumber: '',
          ssn: '',
          gender: ''
        }
      ]);
    }
  };

  const handleNext = () => {
    if (validateHome(AddHome)) {
      setFirstStep(false);
      setSecondStep(true);
      console.log('Valid step one ! ');
    }
  };
  const handleSecondStep = () => {
    if (validateSingleUser(patient1) && usersNumber == '2') {
      setpatient2ToComplete(true);
      setpatient1ToComplete(false);
      console.log('Valid step two ! ');
    }
  };
  const handleCheckConditions = (event) => {
    setCheckConditions(event.target.checked);
  };

  const handleCheckboxChange = (event) => {
    setSubscriberIsPatient(event.target.checked);
    setPatient1({
      email: AddHome.subscriberEmail,
      firstname: AddHome.subscriberName,
      lastname: AddHome.subscriberLastname,
      phoneNumber: '',
      ssn: '',
      gender: ''
    });
  };

  // send single user subscription to backend
  const handleSingleSubscription = async () => {
    if (validateSingleUser(patient1) && usersNumber == '1' && CheckConditions) {
      let users = [];
      users.push(patient1);
      console.log('array of users', users);
      let data = {
        users: users,
        home: AddHome
      };
      let response = await registerUser(data);
      if ((await response) === 200) {
        setShowSuccess(true);
      }
    }
  };

  const handleTwoUsers = async () => {
    if (validateSingleUser(patient2) && CheckConditions) {
      let users = [];
      users.push(patient1);
      users.push(patient2);

      let data = {
        users: users,
        home: AddHome
      };
      let response = await registerUser(data);
      if ((await response) === 200) {
        setShowSuccess(true);
      }
    }
  };
  function backFunction(index) {
    if (index === '1') {
      setFirstStep(true);
      setSecondStep(false);
    }
    if (index === '2') {
      setpatient2ToComplete(false);
      setpatient1ToComplete(true);
    }
  }
  useEffect(() => {
    DefineUsers(usersNumber);
  }, [usersNumber, showSuccess]);

  return !redirect ? (
    <Fragment>
      <PageWrapper>
        <Logo />
        {!showSuccess ? (
          <Wrapper>
            {firstStep && (
              <CardWrapperSubscription>
                <DetailsSection>
                  <Foyer
                    usersNumber={usersNumber}
                    handleChange={handleChange}
                    AddHome={AddHome}
                    setAddHome={setAddHome}
                    errors={errors}
                  />
                  <CheckBox
                    checked={SubscriberIsPatient}
                    onChange={handleCheckboxChange}
                    label="Le souscripteur est un patient"
                  />
                  <ButtonWrapper>
                    <Button title="Suivant" onClick={(e) => handleNext(e)} />
                  </ButtonWrapper>
                </DetailsSection>
              </CardWrapperSubscription>
            )}

            {secondStep && (
              <UsersInfo>
                <CardWrapperSubscription>
                  {patient1ToComplete && (
                    <>
                      <IconButton onClick={() => backFunction('1')}>
                        <ArrowBackIcon style={{ color: '#035282' }} />
                      </IconButton>
                      <SectionTitle>Bénéficiaire 1</SectionTitle>
                    </>
                  )}
                  {patient2ToComplete && (
                    <>
                      <IconButton onClick={() => backFunction('2')}>
                        <ArrowBackIcon style={{ color: '#035282' }} />
                      </IconButton>
                      <SectionTitle>Bénéficiaire 2</SectionTitle>
                    </>
                  )}
                  <Users>
                    {patient1ToComplete && (
                      <>
                        <User setter={setPatient1} userData={patient1} errors={userErrors} />
                      </>
                    )}

                    {patient2ToComplete && <User setter={setPatient2} userData={patient2} errors={userErrors} />}
                  </Users>
                  <ButtonWrapper>
                    {usersNumber === '1' && patient1ToComplete && (
                      <>
                        <CheckBox
                          checked={CheckConditions}
                          onChange={handleCheckConditions}
                          label={
                            <>
                              <div style={{ fontFamily: 'Open Sans' }}>
                                En cochant cette case j'accepte les{' '}
                                <a href="https://www.medicaldevicesventure.com/" target="_blank">
                                  conditions générales d'utilisation Domisanté{' '}
                                </a>
                                et je donne mon consentement pour le recueil des informations nécessaires
                              </div>
                            </>
                          }
                        />
                        <div style={{ marginTop: '50px' }}>
                          <Button title="S'inscrire" onClick={handleSingleSubscription} />{' '}
                        </div>
                      </>
                    )}

                    {usersNumber === '2' && !patient2ToComplete && (
                      <Button title="Suivant" onClick={(e) => handleSecondStep(e)} />
                    )}

                    {usersNumber === '2' && patient2ToComplete && (
                      <>
                        <CheckBox
                          checked={CheckConditions}
                          onChange={handleCheckConditions}
                          label={
                            <>
                              En cochant cette case j'accepte les{' '}
                              <a href="https://www.medicaldevicesventure.com/" target="_blank">
                                conditions générales d'utilisation Domisanté{' '}
                              </a>
                              et je donne mon consentement pour le recueil des informations nécessaires
                            </>
                          }
                        />
                        <br />
                        <div style={{ marginTop: '50px' }}>
                          <Button title="S'inscrire" onClick={(e) => handleTwoUsers()} />
                        </div>
                      </>
                    )}
                  </ButtonWrapper>
                </CardWrapperSubscription>
              </UsersInfo>
            )}
          </Wrapper>
        ) : (
          <RegisterSuccess />
        )}
      </PageWrapper>
    </Fragment>
  ) : (
    <Navigate to="/" />
  );
}

export default Register;
