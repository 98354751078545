import PageWithHeader from 'components/ReusableDivs/PageWithHeader';
import PageWrapper from 'components/ReusableDivs/PageWrapper';
import { useEffect, useState } from 'react';
import {
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { usersService } from '../../services/usersService';
import { Box, Typography } from '@mui/material';
import { InputBlueBottom } from 'components/InputControls.tsx/Inputs';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import NoData from 'components/ReusableDivs/NoData';
import Empty from 'components/ReusableDivs/Empty';
import styled from 'styled-components';
import { OrganizationService } from 'services/organizationsService';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Link } from 'react-router-dom';
import { StyledTable, StyledTableCell } from 'styles/tables/TableStyles';
import { ContentWrapper } from 'styles/pages/pageStyles';
import "../../styles/tables/tableStyles.css"
import { CancelBtn, ConfirmBtn, StyledDialogContent, StyledDialogContentText, StyledDialogMessage, StyledDialogTitle } from 'styles/dialogs/DialogStyles';
import SpinnerComponent from 'components/spinners/SpinnerComponent';
import { useSelector } from 'react-redux';
import { customStyles } from 'screens/calendar/CalendarPage';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Role, colors } from 'utils/Constants';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px;
`;
const useStyles = makeStyles({
  label: {
    fontFamily: "open sans",
    color: "#035282",
  },
});
type userProps = {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  login: string;
  birthDate?: null;
  roles?: string;
  gender?: string;
  organizationId: string;
  phoneNumber: string;
  isManagingAllPatients?: boolean;
  role: string
};


function UsersList() {
  const [users, setUsers] = useState<userProps[] | []>([]);
  const [token, setToken] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  // const [organizationId, setorganizationId] = useState<string | undefined>('');
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string | undefined>('');
  const [organizationsList, setorganizationsList] = useState([]);
  const [newUser, setNewUser] = useState<userProps>({
    firstname: '',
    lastname: '',
    login: "",
    role: '',
    email: '',
    phoneNumber: '',
    organizationId: '',
    id: "",
    isManagingAllPatients: false
  });
  const [isEmpty, setIsEmpty] = useState(false);
  const [open, setopen] = useState(false);
  const [role, setRole] = useState('');
  const [edit, setEdit] = useState(false)
  const userRole = useSelector((state: any) => state.userDomisante.userRole);
  const organizationId = useSelector((state: any) => state.userDomisante.managedOrganizationId);
  const [refresh, setrefresh] = useState(false)
  const [isError, setisError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [userToDeleteDetails, setUserToDeleteDetails] = useState({
    firstname: "",
    lastname: ""
  })
  const [userToDelete, setUserToDelete] = useState("")
  const [isManagingAllPatients, setisManagingAllPatients] = useState(true)
  const [errorData, seterrorData] = useState({ firstname: '', lastname: '', email: '', phoneNumber: '', role: '', organization: '', login: "" });


  const classes = useStyles();

  const acc_token = useSelector((state: any) => state.userDomisante.userToken);
  const frenchPhoneNumberRegex: RegExp = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
  const emailRegex: RegExp = /$^|.+@.+..+/;

  function closeDeleteModal() {
    setDeleteModalIsOpen(false);
    emptyPatientFields()
    setisError(false);
    setIsSuccess(false)
  }
  function emptyPatientFields() {
    setUserToDelete("")
    setUserToDeleteDetails({
      firstname: "",
      lastname: "",
    })
  }


  async function deleteUser() {
    let deleteResponse = await usersService.deleteUser(userToDelete, acc_token)
    if (deleteResponse) {
      setrefresh(!refresh)
      setIsSuccess(true)
      emptyPatientFields()
    } else {
      setisError(false)
    }
  }


  useEffect(() => {
    if (userRole != 'organization admin')
      OrganizationService.getOrganizations(setorganizationsList, acc_token);
  }, [userRole]);

  useEffect(() => {
    if (userRole != 'organization admin' && userRole != 'aidant' && userRole != 'professionnel de santé') {
      usersService.getUsers(setUsers, acc_token);
    }
    else if (userRole == 'organization admin' || userRole == 'aidant' || userRole == 'professionnel de santé') {
      if (organizationId != null) {
        // setorganizationId(organizationId!.toString())
        usersService.getUsers(setUsers, acc_token, organizationId);
      }
    }
    if (users.length === 0) {
      setIsEmpty(true);
    }
  }, [token, refresh]);


  function matchRoleName(role: string) {
    switch (role) {
      case Role.ADMIN:
        return "Admin"
      case Role.HEALTH_PROFESSIONAL:
        return "Professionnel de santé"
      case Role.HELPER:
        return "Aidant"
      case Role.MEMBER:
        return "Adhérent"
      case Role.ORGANIZATION_ADMIN:
        return "Admin d'organisation"
      case Role.FAMILY:
        return "Membere de famille"
      case Role.PROFESSIONAL:
        return "Installateur"
      case Role.SUPER_ADMIN:
        return "Super admin"
      default:
        return ""
    }
  }


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewUser({
      ...newUser,
      [name]: value
    });
    seterrorData({ ...errorData, [name]: '' })
  };


  function checkUserAttributes() {
    let isValid = true;
    const errors = { firstname: '', lastname: '', email: '', phoneNumber: '', role: '', organization: '', login: "" }
    const loginRegex = /^[a-zA-Z0-9.-]+$/
    console.log("newUser", newUser)
    if (newUser.firstname == '') {
      errors.firstname = 'Veuillez saisir le prénom';
      isValid = false;
    }

    if (newUser.lastname == '') {
      errors.lastname = 'Veuillez saisir le nom';
      isValid = false;
    }

    if (newUser.email == '' && newUser.login == '') {
      errors.email = 'Veuillez saisir au moins une adresse email ou un login';
      errors.login = 'Veuillez saisir au moins une adresse email ou un login';
      isValid = false;
    }

    else if (!emailRegex.test(newUser.email)) {
      errors.email = 'Veuillez saisir une adresse email valide';
      isValid = false;
    }
    if (newUser.phoneNumber != '' && !frenchPhoneNumberRegex.test(newUser.phoneNumber)) {
      errors.phoneNumber = 'Veuillez saisir un numéro de téléphone portable valide';
      isValid = false;
    }

    if (newUser.role == '') {
      errors.role = 'Veuillez sélectionner un role';
      isValid = false;
    }


    if (userRole != 'organization admin' && (newUser.role == 'professionnel de santé' || newUser.role == 'aidant' || newUser.role == 'organization admin') && newUser.organizationId == '') {
      errors.organization = 'Veuillez sélectionner l\'organisation du nouvel utilisateur';
      isValid = false;
    }
    if (newUser.login != "") {
      errors.login = loginRegex.test(newUser.login) ? '' : 'Le login ne doit pas contenir des caractères spéciaux';
    }

    console.log("errors", errors)
    seterrorData(errors)
    return isValid;
  };


  const handleAddUSer = async () => {
    console.log("new User", newUser)
    if (checkUserAttributes()) {
      if (userRole == 'organization admin') {
        newUser.organizationId = organizationId
      }
      if (role == Role.HEALTH_PROFESSIONAL || role == Role.HELPER) {
        newUser.isManagingAllPatients = isManagingAllPatients
      }
      setisLoading(true)
      const response = await usersService.addNewUSer(newUser, acc_token);
      //TODO : update addNewUser response
      if (await response != null) {
        // handleCloseAddUser()
        setIsSuccess(true)
        setrefresh(!refresh)
        setisLoading(false);

      }
      else {
        setisError(true)
        setisLoading(false)
      }
    }

  };


  async function updateUserData() {
    if (checkUserAttributes()) {
      if (userRole == 'organization admin') {
        newUser.organizationId = organizationId
      }
      console.log("isManagingAllPatients", isManagingAllPatients)
      console.log("userRole ----", userRole)
      console.log("userRole == Role.HEALTH_PROFESSIONAL || userRole == Role.HELPER", userRole == Role.HEALTH_PROFESSIONAL || userRole == Role.HELPER)
      if (role == Role.HEALTH_PROFESSIONAL || role == Role.HELPER) {
        newUser.isManagingAllPatients = isManagingAllPatients
      }
      setisLoading(true)
      console.log("newUser **********", newUser)
      const response = await usersService.updateUserData(newUser, acc_token, newUser.id);
      //TODO : update addNewUser response 
      if (await response != null) {
        // handleCloseAddUser()
        setIsSuccess(true)
        setrefresh(!refresh)
        setisLoading(false);
      }
      else {
        setisError(true)
        setisLoading(false)
      }
    }
  }

  const handleCloseAddUser = () => {
    setNewUser({
      firstname: '',
      lastname: '',
      login: "",
      role: '',
      email: '',
      phoneNumber: '',
      organizationId: '',
      id: ""
    });
    seterrorData({ firstname: '', lastname: '', email: '', phoneNumber: '', role: '', organization: '', login: "" })
    setRole('');
    setSelectedOrganizationId('');
    setisLoading(false);
    setisError(false);
    setIsSuccess(false);
  };

  function closeModal() {
    setopen(false);

  }

  const selectRoleColor = (role: string) => {
    if (role == 'super admin' || role == 'admin') {
      return '#035282';
    }
    if (role == 'aidant') {
      return '#18C5BF';
    }
    if (role == 'professionnel de santé') {
      return '#8CC8DB';
    }
    if (role == 'professional') {
      return '#F4C274';
    }
    if (role == 'organization admin') {
      return '#0ED2EB';
    } else return undefined;
  };
  const handleChangeRole = (event: SelectChangeEvent) => {
    setRole(event.target.value)
    setNewUser({
      ...newUser,
      role: event.target.value
    });
  };
  const handleSelectOrganization = (event: SelectChangeEvent) => {
    setSelectedOrganizationId(event.target.value)
    setNewUser({
      ...newUser,
      organizationId: event.target.value
    });

  };

  return (
    <>
      <PageWithHeader>
        <PageWrapper title="Liste des utilisateurs" btnTitle="Ajouter un utilisateur" btnClick={() => setopen(true)}>
          <ContentWrapper>
            {users.length > 0 && (
              <TableContainer>
                <StyledTable size="medium">
                  <TableHead>
                    <TableRow >
                      <StyledTableCell align="left">
                        Nom
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Prénom
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Email / Login
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Roles{' '}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Organisations{' '}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Bénéficiaires{' '}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Actions
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((row) => (
                      <TableRow key={row["id"]}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >

                        <StyledTableCell component="th" scope="row" align="left">
                          {row['lastname']}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="left">
                          {row['firstname']}
                        </StyledTableCell>
                        <StyledTableCell style={{
                          maxWidth: "150px",
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                        }} align="left">
                          {row['email'] == null ? row['login'] : row['email']}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row['roles'] && row['roles'].split('|').length > 0 ? (
                            row['roles']
                              .split('|')
                              .map((role, index) => (
                                <Chip
                                  label={`${matchRoleName(role.trimEnd().trimStart())}`}
                                  variant="outlined"
                                  key={index}
                                  style={{ margin: 5, color: "white", fontSize: "16px", backgroundColor: selectRoleColor(`${(role.trimEnd().trimStart())}`) }}
                                />
                              ))
                          ) : (
                            <div style={{ color: selectRoleColor(`${role.trimEnd().trimStart()}`) }}>
                              {' '}
                              Pas d'organization assignée
                            </div>
                          )}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {/* @ts-ignore */}
                          {row['organization'] && row['organization']['name'] ? row['organization']['name']
                            :
                            " Données absentes"
                          }
                        </StyledTableCell>

                        <StyledTableCell align='center'>
                          {(row['roles']?.includes('aidant') || row['roles']?.includes('professionnel de santé')) &&
                            <>
                              {row["isManagingAllPatients"] == true ?

                                <Tooltip title="A accès à tous les bénéficiaires" placement="top">

                                  <PeopleAltIcon style={{ color: colors.turquoise, margin: 'auto' }} />
                                </Tooltip> :
                                <Tooltip title="Afficher les bénéficiaires" placement="top">
                                  <IconButton
                                    to={`/manager-patients/${row['firstname']}/${row['lastname']}/${row["id"]}`}
                                    component={Link}>
                                    <PeopleAltIcon style={{ color: '#F4C274', margin: 'auto' }} />
                                  </IconButton>
                                </Tooltip>
                              }

                            </>

                            // <Tooltip title="Afficher les bénéficiaires" placement="top">
                            //   <IconButton
                            //     to={`/manager-patients/${row['firstname']}/${row['lastname']}/${row["id"]}`}
                            //     component={Link}>
                            //     <PeopleAltIcon style={{ color: '#F4C274', margin: 'auto' }} />
                            //   </IconButton>
                            // </Tooltip>
                          }
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="left" >
                          <p style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Tooltip title="Modifier la tablette" placement="top">
                              <IconButton onClick={() => {
                                setopen(true);
                                setEdit(true)
                                setRole(row["roles"] ? row["roles"] : "")
                                //@ts-ignore

                                setSelectedOrganizationId(row["organization"] ? row["organization"]["uuid"] : "")
                                setNewUser({
                                  firstname: row["firstname"] ? row["firstname"] : "",
                                  lastname: row["lastname"] ? row["lastname"] : "",
                                  login: row["login"] ? row["login"] : "",
                                  email: row["email"] ? row["email"] : "",
                                  role: row["roles"] ? row["roles"] : "",
                                  //@ts-ignore
                                  organizationId: row["organization"] ? row["organization"]["uuid"] : "",
                                  phoneNumber: row["phoneNumber"] ? row["phoneNumber"] : "",
                                  id: row["id"]
                                })
                                setisManagingAllPatients(row["isManagingAllPatients"] == true ? true : false)
                                console.log("row ", row)
                              }} >
                                <ModeEditIcon style={{ color: "#18C5BF" }} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Supprimer la tablette" placement="top">
                              <IconButton
                                onClick={() => {
                                  setDeleteModalIsOpen(true);
                                  setUserToDeleteDetails({
                                    firstname: row["firstname"],
                                    lastname: row["lastname"],
                                  });
                                  setUserToDelete(row["id"]);
                                }}
                                style={{ display: "inline" }}>
                                <DeleteIcon style={{ color: colors.dark_blue }} />
                              </IconButton>
                            </Tooltip>
                          </p>

                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </TableContainer>
            )}
            {users.length === 0 && !isEmpty && <NoData />}
            {users.length === 0 && isEmpty && <Empty />}
          </ContentWrapper>
        </PageWrapper>
      </PageWithHeader>
      <div>
        <Dialog
          style={{ zIndex: 99 }}
          open={open}
          //  onClose={handleCloseAddUser} 
          onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
              handleCloseAddUser()
            }

          }}
          fullWidth={true} maxWidth="md">
          <StyledDialogTitle>{edit ? "Modifier les données d'un utilisateur" : "Ajouter un utilisateur"}</StyledDialogTitle>

          <IconButton
            aria-label="close"
            onClick={() => { handleCloseAddUser(); closeModal() }}
            sx={{
              position: 'absolute',
              right: 20,
              top: 20,
              color: colors.dark_blue
            }}
          >
            <CloseIcon />
          </IconButton>

          <StyledDialogContent>

            {!isLoading && !isError && !isSuccess &&
              <>
                <StyledDialogContentText style={{ padding: 5 }}>
                  Veuillez renseigner au moins un email ou un identifiant.
                </StyledDialogContentText>
                <div
                  style={{
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 5
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      gap: 10
                    }}
                  >
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                      <InputLabel id="demo-simple-select-standard-label" className={classes.label}>Role</InputLabel>

                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={role}
                        onChange={handleChangeRole}
                        classes={{ select: classes.label }}
                        label="Role"
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              '& .MuiMenu-paper': {
                                backgroundColor: 'dark.primary',
                              },
                            }
                          },
                        }}

                        sx={{
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#035282',
                          },
                          '.MuiSvgIcon-root': {
                            color: '#fff'
                          },
                          '&:before': {
                            borderBottom: `1px solid #035282`
                          },
                          '&:hover': {
                            ':before': {
                              borderBottom: `1px solid #035282`
                            }
                          },

                        }}
                      >
                        {(userRole !== 'aidant' && userRole !== 'organization admin') && <MenuItem value="admin">Admin</MenuItem>}
                        {(userRole !== 'aidant' && userRole !== 'organization admin') && <MenuItem value="professional">Installateur</MenuItem>}

                        <MenuItem value={Role.HELPER}>Aidant</MenuItem>
                        <MenuItem value={Role.HEALTH_PROFESSIONAL}>Professionnel de santé</MenuItem>
                        <MenuItem value={Role.ORGANIZATION_ADMIN}>Administrateur d'organisation</MenuItem>
                      </Select>
                    </FormControl>
                    <div style={{ ...customStyles.alert, marginLeft: "0px", marginBottom: "10px" }}>{errorData.role}</div>

                    {(role == 'aidant' || role == 'organization admin' || role == 'professionnel de santé') && (userRole !== 'aidant' && userRole !== 'organization admin') && (
                      <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-standard-label" className={classes.label}>Organisation </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={selectedOrganizationId}
                          onChange={handleSelectOrganization}
                          label="Organisation"
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                '& .MuiMenu-paper': {
                                  backgroundColor: 'dark.primary',
                                  // color: 'red'
                                },

                              }
                            },
                          }}

                          sx={{
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#035282',
                            },
                            '.MuiSvgIcon-root': {
                              color: '#fff'
                            },
                            '&:before': {
                              borderBottom: `1px solid #035282`
                            },
                            '&:hover': {
                              ':before': {
                                borderBottom: `1px solid #035282`
                              }
                            },

                          }}
                        >
                          {organizationsList.length > 0 &&
                            organizationsList.map((organisation) => (
                              <MenuItem value={organisation['id']}>{organisation['name']}</MenuItem>
                            ))}
                        </Select>
                        <div style={{ ...customStyles.alert, marginLeft: "0px", marginBottom: "10px" }}>{errorData.organization}</div>
                      </FormControl>
                    )}

                  </div>
                  <InputBlueBottom
                    value={newUser.firstname}
                    onChange={handleChange}
                    placeholder="Prénom"
                    type="text"
                    name="firstname"
                    required
                  />
                  <div style={{ ...customStyles.alert, marginLeft: "0px", marginBottom: "10px" }}>{errorData.firstname}</div>
                  <InputBlueBottom
                    value={newUser.lastname}
                    onChange={handleChange}
                    placeholder="Nom"
                    type="text"
                    name="lastname"
                    required
                  />
                  <div style={{ ...customStyles.alert, marginLeft: "0px", marginBottom: "10px" }}>{errorData.lastname}</div>
                  <InputBlueBottom
                    value={newUser.login ? newUser.login.toLowerCase() : ""}
                    onChange={handleChange}
                    placeholder="Identifiant*"
                    type="text"
                    name="login"
                    required
                  />
                  <div style={{ ...customStyles.alert, marginLeft: "0px", marginBottom: "10px" }}>{errorData.login}</div>
                  <InputBlueBottom
                    value={newUser.email}
                    onChange={handleChange}
                    placeholder="Email*"
                    type="email"
                    name="email"
                    required
                  />
                  <div style={{ ...customStyles.alert, marginLeft: "0px", marginBottom: "10px" }}>{errorData.email}</div>

                  <InputBlueBottom
                    value={newUser.phoneNumber}
                    onChange={handleChange}
                    placeholder="Numéro de téléphone"
                    type="text"
                    name="phoneNumber"

                  />
                  <div style={{ ...customStyles.alert, marginLeft: "0px", marginBottom: "10px" }}>{errorData.phoneNumber}</div>

                  {(role == Role.HEALTH_PROFESSIONAL || role == Role.HELPER) && <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: 6, }}>
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: '#18C5BF'
                        }
                      }}
                      checked={isManagingAllPatients}
                      onChange={(event: any) => {
                        setisManagingAllPatients(
                          event.target.checked
                        )
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography>
                      Gère tous les bénéficiaires
                    </Typography>
                  </div>}
                </div>
                <StyledDialogContentText style={{ padding: 5 }}>
                  ** : au moins un champ est requis.
                </StyledDialogContentText>
              </>
            }

            {
              isLoading && <Box sx={{ padding: "50px 0px" }}>
                <SpinnerComponent />
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
                </Box>
              </Box>
            }
            {
              isError && <Box sx={{ padding: "50px 0px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Une erreur s'est produite, cette adresse email est déja utilisée.</StyledDialogMessage>
                </Box>
              </Box>
            }
            {
              isSuccess && <Box sx={{ padding: "50px 0px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>{
                    edit ? " Élément mis à jour avec succès." :
                      "Élément ajouté avec succès."}</StyledDialogMessage>
                </Box>
              </Box>
            }
          </StyledDialogContent>
          {!isLoading && !isError && !isSuccess &&
            <DialogActions>
              <ActionsWrapper>
                <CancelBtn
                  onClick={() => { handleCloseAddUser(); closeModal() }}>
                  Annuler</CancelBtn>

                {/* {(phoneNumberCorrect)?<ConfirmBtn onClick={() => handleAddUSer()} > Ajouter</ConfirmBtn>:<ConfirmBtn onClick={() => handleAddUSer()} disabled={false}> Ajouter</ConfirmBtn>} */}

                {
                  edit ?
                    <ConfirmBtn onClick={() => updateUserData()}> Modifier</ConfirmBtn> :
                    <ConfirmBtn onClick={() => handleAddUSer()}> Ajouter</ConfirmBtn>
                }
              </ActionsWrapper>
            </DialogActions>
          }
          {(isLoading || isError || isSuccess) &&
            <DialogActions>
              <ActionsWrapper>
                <CancelBtn onClick={() => { handleCloseAddUser(); closeModal() }}>Fermer</CancelBtn>

              </ActionsWrapper>
            </DialogActions>
          }
        </Dialog>

        <Dialog
          style={{ zIndex: 99 }}
          open={deleteModalIsOpen}
          onClose={closeDeleteModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <StyledDialogTitle id="alert-dialog-title">
            {"Êtes-vous sûr de bien vouloir supprimer cet utilisateur ?"}
          </StyledDialogTitle>

          <IconButton
            aria-label="close"
            onClick={() => closeDeleteModal()}
            sx={{
              position: 'absolute',
              right: 20,
              top: 20,
              color: colors.dark_blue
            }}
          >
            <CloseIcon />
          </IconButton>


          <StyledDialogContent>
            {!isError && !isSuccess && <StyledDialogContentText id="alert-dialog-description">

              <Typography style={{ fontWeight: "600", display: "inline" }}>{`${userToDeleteDetails.firstname} ${userToDeleteDetails.lastname} `}</Typography>

            </StyledDialogContentText>
            }

            {
              isError && <Box sx={{ padding: "50px 0px" }}>

                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>{`Une erreur est survenue, veuillez réessayer plus tard ! `}</StyledDialogMessage>
                </Box>
              </Box>
            }
            {!isError && !isSuccess &&
              <StyledDialogContentText >
                Voulez-vous supprimer quand même ?
              </StyledDialogContentText>
            }

            {
              isSuccess &&
              <Box sx={{ padding: "50px 0px" }}>

                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Élément supprimé avec succès .</StyledDialogMessage>
                </Box>
              </Box>
            }
          </ StyledDialogContent>
          <DialogActions>
            <ActionsWrapper>
              <CancelBtn onClick={() => closeDeleteModal()}>
                {
                  !isLoading && !isError && !isSuccess ?
                    "Annuler" : "Fermer"
                }
              </CancelBtn>
              {
                !isError && !isSuccess && <ConfirmBtn onClick={() => deleteUser()} autoFocus>
                  Supprimer
                </ConfirmBtn>
              }

            </ActionsWrapper>

          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default UsersList;
