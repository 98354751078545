export const validateDevice = (values: any, setErrors: any) => {
  const errors = {
    user_id: '',
    professional_id: '',
    patient_id: '',
    type: '',
    serialNumber: ''
  };

  if ('type' in values) {
    errors.type = values.type !== '' ? '' : "Veuillez sélectionner un type d'objet";
  }
  if ('serialNumber' in values) {
    errors.serialNumber = values.serialNumber !== '' ? '' : 'Veuillez sélectionner un numéro de série';
  }
  if ('professional_id' in values && 'patient_id' in values) {
    if (values.professional_id !== '' && values.patient_id !== '') {
      errors.professional_id = 'Erreur: Veuillez sélectionner soit un professionnel, soit un patient';
      errors.patient_id = 'Erreur: Veuillez sélectionner soit un professionnel, soit un patient';
    }
    if (values.professional_id == '' && values.patient_id == '') {
      errors.professional_id = 'Erreur: Veuillez sélectionner soit un professionnel, soit un patient';
      errors.patient_id = 'Erreur: Veuillez sélectionner soit un professionnel, soit un patient';
    }
  }

  setErrors(errors);
  return Object.values(errors).every((x) => x === '');
};
