import { InputBlueBottom } from 'components/InputControls.tsx/Inputs';
import RadioButton from 'components/InputControls.tsx/RadioButton';
import { SectionTitle } from 'components/StyledWrappers/SubscriptionWrappers';
import styled from 'styled-components';




const FoyerForm = styled.form`
display: 'flex';
flex-direction: "column";
`
const FoyerWrapper = styled.div`
 display: 'flex',;
flex-direction: 'column';
font-family: open sans;
`;

export const Label = styled.p`
font-size:14px ;
color:red ;
`
interface FoyerProps {
    handleChange?: any;
    setAddHome: (params: any) => any;
    AddHome: any;
    errors: any;
    withSubscriber?: boolean
}


function Foyer({ handleChange, setAddHome, AddHome, errors, withSubscriber }: FoyerProps) {

    const handleChangeFoyer = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setAddHome({
            ...AddHome, [name]: value
        })
    }
    return (
        <FoyerWrapper>
            <FoyerForm noValidate autoComplete="off" >
                {
                    withSubscriber && <>
                        <SectionTitle>Souscripteur</SectionTitle>

                        <InputBlueBottom
                            type="text"
                            name="subscriberName"
                            value={AddHome.subscriberName}
                            onChange={(e) => handleChangeFoyer(e)}
                            placeholder="Nom"
                        />
                        <Label>{errors.subscriberName}</Label>

                        <InputBlueBottom
                            type="text"
                            name="subscriberLastname"
                            value={AddHome.subscriberLastname}
                            onChange={(e) => handleChangeFoyer(e)}
                            placeholder="Prénom"
                        />
                        <Label>{errors.subscriberLastname}</Label>

                        <InputBlueBottom
                            type="email"
                            name="subscriberEmail"
                            value={AddHome.subscriberEmail}
                            onChange={(e) => handleChangeFoyer(e)}
                            placeholder="Email"
                        />
                        <Label>{errors.subscriberEmail}</Label>
                    </>}
                <SectionTitle>Foyer</SectionTitle>

                <InputBlueBottom
                    type='text'
                    placeholder='Adresse 1'
                    value={AddHome.adress1}
                    onChange={handleChangeFoyer}
                    required={true}
                    name="adress1"
                />
                <Label>{errors.adress1}</Label>
                <InputBlueBottom
                    type='text'
                    placeholder='Adresse 2'
                    value={AddHome.adress2}
                    onChange={handleChangeFoyer}
                    name="adress2"
                />
                <Label>{errors.adress2}</Label>

                <InputBlueBottom
                    type='text'
                    placeholder='Ville'
                    name="city"
                    value={AddHome.city}
                    onChange={handleChangeFoyer}
                />
                <Label>{errors.city}</Label>

                <InputBlueBottom
                    type='text'
                    placeholder='Code postal'
                    name="zipCode"
                    value={AddHome.zipCode}
                    onChange={handleChangeFoyer}
                />
                <Label>{errors.zipCode}</Label>

          

                <InputBlueBottom
                    type='text'
                    placeholder='Pays'
                    name="country"
                    value={AddHome.country}
                    onChange={handleChangeFoyer}
                />
                <Label>{errors.country}</Label>

                {/* <RadioButton
                    firstOption="Seul"
                    secondOption="Par couple"
                    RadioId="Foyerbutton"
                    radioLabel="Type d'inscription"
                    value1="1"
                    value2="2"
                    handleChange={handleChange}
                /> */}


            </FoyerForm >
        </FoyerWrapper >
    );
}

export default Foyer
