import axios from 'axios';
import { is, curryN, gte } from 'ramda';
import { requestChecker } from 'utils/RequestCheck';

const isWithin = curryN(3, (min, max, value) => {
  const isNumber = is(Number);
  return isNumber(min) && isNumber(max) && isNumber(value) && gte(value, min) && gte(max, value);
});
const { REACT_APP_BASE_URL } = process.env;

const in200s = isWithin(200, 299);

async function changePassword(data: any, acc_token: string) {
  let url = REACT_APP_BASE_URL + '/auth/change-password';
  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });

    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function verifyOTP(data: any, acc_token: string) {
  let url = REACT_APP_BASE_URL + '/auth/verify-otp';
  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });

    if (requestChecker.in200s(response.status)) {
      return response.data;
    } else {
      return response.status;
    }
  } catch (error: any) {
    if (error.response && error.response.status === 403) {
      return error.response.status; // Return a string for 403 Forbidden
    } else if (error.response && error.response.status === 401) {
      return error.response.status; // Return a string for 403 Forbidden
    } else {
      requestChecker.HandleErrors(error, url);
      return null;
    }
  }
}
async function resendOTP(acc_token: string) {
  let url = REACT_APP_BASE_URL + '/auth/resend-otp';
  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });

    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return response.status;
    }
  } catch (error: any) {
    if (error.response && error.response.status === 403) {
      return error.response.status; // Return a string for 403 Forbidden
    } else if (error.response && error.response.status === 401) {
      return error.response.status; // Return a string for 403 Forbidden
    } else {
      requestChecker.HandleErrors(error, url);
      return null;
    }
  }
}
async function getOmniDocUrl(token: string) {
  let url = REACT_APP_BASE_URL + '/omnidoc-url';
  console.log('Calling resetPin api =====================>', url);

  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: token
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds requestChecker.requestTimeout, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.data['message'];
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

export const authService = {
  changePassword,
  getOmniDocUrl,
  verifyOTP,
  resendOTP
};
