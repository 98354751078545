import PageWithHeader from 'components/ReusableDivs/PageWithHeader'
import PageWrapper from 'components/ReusableDivs/PageWrapper'
import React, { useEffect, useState } from 'react'
import { StyledHead } from './Patients'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Autocomplete, Popper, TableContainer, TableHead, TableRow, TextField, TableBody, Tooltip, IconButton, Dialog, DialogContent, Box, DialogActions, Typography, Checkbox } from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField'
import { StyledTable, StyledTableCell } from 'styles/tables/TableStyles'
import { usersService } from 'services/usersService'
import { useSelector } from 'react-redux'
import { measuresService } from 'services/measuresService'
import HistoryIcon from '@mui/icons-material/History';
import { CancelBtn, ConfirmBtn, StyledDialogContent, StyledDialogContentText, StyledDialogMessage, StyledDialogTitle } from 'styles/dialogs/DialogStyles';
import { InputBlueBottom } from 'components/InputControls.tsx/Inputs';
import { ActionsWrapper } from './UsersList';
import SpinnerComponent from 'components/spinners/SpinnerComponent';
import { validateNewMeasureLimit } from 'validators/MeasureLimitsValidators/NewMeasureLimitValidator';
import { Label } from 'Inscription/Foyer';
import dayjs, { Dayjs } from 'dayjs';
import { useParams } from 'react-router-dom';
import { colors } from 'utils/Constants';
import CloseIcon from '@mui/icons-material/Close';

interface Patient {
    id: string;
    label: string;
    orgaId: string
    // Add any other properties of the Patient object
}
function MeasureLimits() {
    const initialFields = {
        id: "",
        measureType: "",
        minValue: "",
        maxValue: "",
        createdAt: "",
        visibleForPatient: true,
        professionalId: {
            id: "",
            firstname: "",
            lastname: ""
        }
    }

    let emptyPatientFields = { id: "", label: "", orgaId: "" }

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [list, setlist] = useState<Patient[] | []>([])

    const [selectedPatient, setSelectedPatient] = useState<Patient>(emptyPatientFields);

    const [patientsList, setpatientsList] = useState([])
    const [measureLimitsData, setmeasureLimitsData] = useState<typeof initialFields[] | []>([
        { ...initialFields, measureType: "TEMP_BODY" },
        { ...initialFields, measureType: "WEIGHT" },
        { ...initialFields, measureType: "HEART_RATE_MESSAGE" },
        { ...initialFields, measureType: "OXYGEN_SATURATION" },
        { ...initialFields, measureType: "BLOOD_PRESSURE" },
    ])
    const acc_token = useSelector((state: any) => state.userDomisante.userToken);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [deleteModalOpen, setdeleteModalOpen] = useState(false)
    const [addModalOpen, setaddModalOpen] = useState(false)
    const UNDEFINED_VALUE = "--"
    const [isLoading, setisLoading] = useState(false)
    const [selectedMeasureType, setselectedMeasureType] = useState("")
    const [selectedMeasureLimit, setselectedMeasureLimit] = useState(initialFields)
    const [measureLimitsHistory, setmeasureLimitsHistory] = useState([])
    const [refresh, setrefresh] = useState(false)
    const [visibleForPatient, setvisibleForPatient] = useState(false)
    const [newMeasureLimit, setnewMeasureLimit] = useState(initialFields)
    const [minDiastolic, setminDiastolic] = useState<number | string>("")
    const [maxDiastolic, setmaxDiastolic] = useState<number | string>("")
    const { patientId } = useParams<{ patientId: string }>();
    const userRole = useSelector((state: any) => state.userDomisante.userRole);
    const orgaId = useSelector((state: any) => state.userDomisante.managedOrganizationId);
    const organizationsWithAllConstants = process.env.REACT_APP_ORGAS_ALL_CONSTANTS
    const [organizationId, setorganizationId] = useState<any>(null)
    let initialConstants = ["WEIGHT", "HEART_RATE_MESSAGE", "TEMP_BODY", "OXYGEN_SATURATION", "BLOOD_PRESSURE",]
    let allConstants = ["WEIGHT", "HEART_RATE_MESSAGE", "TEMP_BODY", "OXYGEN_SATURATION", "BLOOD_PRESSURE", "BLOOD_SUGAR"]

    const [measureTypes, setmeasureTypes] = useState<any[]>([])
    const [errors, seterrors] = useState({
        measureType: "",
        minValue: "",
        maxValue: "",
        visibleForPatient: true,

    })
    const [diastolicErrors, setdiastolicErrors] = useState({
        measureType: "",
        minValue: "",
        maxValue: "",
        visibleForPatient: true,

    })
    const [isError, setisError] = useState(false)
    const [isSuccess, setisSuccess] = useState(false)

    useEffect(() => {
        if (patientId && list.length > 0) {
            let filteredPatient = list.find(patient => patient.id == patientId)
            setSelectedPatient(filteredPatient ? filteredPatient : { id: "", label: "", orgaId: "" })
        }
    }, [patientId, list.length])

    // useEffect(() => {
    //     if (selectedPatient["id"].length > 0) {
    //         let organizationId = selectedPatient["orgaId"]
    //         setorganizationId(organizationId)
    //     }
    // }, [selectedPatient,])

    useEffect(() => {
        if (organizationsWithAllConstants && selectedPatient["id"].length > 0) {
            let organizationId = selectedPatient["orgaId"]
            let gotAll = organizationsWithAllConstants.includes(organizationId)
            if (gotAll == true) {
                setmeasureTypes(allConstants)
            }
            else {
                setmeasureTypes(initialConstants)
            }
        }

    }, [selectedPatient])



    useEffect(() => {
        fetchProPatients()
    }, [patientsList.length])

    useEffect(() => {
        fetchPatientMeasureLimits(measureTypes)
    }, [selectedPatient, refresh, measureTypes.length])

    useEffect(() => {
    }, [measureLimitsData, measureLimitsHistory.length,])

    const handleChangePatient = (e: any, v: any) => {
        setSelectedPatient(v);
    }
    function formatpatientsList(array: any[]) {
        let formatted: any = []
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            formatted.push({ label: `${element['firstname']} ${element['lastname']}`, id: `${element['id']}`, orgaId: `${element['organization']["id"]}` })
        }
        setlist(formatted)
    }
    async function fetchProPatients() {
        // usersService.fetchPatients(setpatientsList, acc_token)

        let response = await getPatientsMemoized(setpatientsList, acc_token, orgaId)
        if (patientsList.length > 0) {
            formatpatientsList(patientsList)

        }
    }
    async function getPatientsMemoized(setPatients: any, acc_token: string, orgaId: string) {
        if ((userRole === 'organization admin' || userRole === 'admin' || userRole === 'aidant' || userRole === 'professionnel de santé') && orgaId != null) {
            usersService.getPatients(setPatients, acc_token, orgaId);
        } else {
            usersService.getPatients(setPatients, acc_token);
        }
    }


    async function fetchPatientMeasureLimits(measureTypes: any[]) {
        if (selectedPatient.id != "") {
            let response = await measuresService.getMeasureLimits(acc_token, selectedPatient.id)
            if (response) {
                while (response.length < measureTypes.length) {
                    // Find the missing measure types
                    const missingMeasureTypes = measureTypes.filter(
                        (type) => !response.some((obj: { measureType: string }) => obj.measureType === type)
                    );

                    // Add objects with missing measure types to the response array
                    missingMeasureTypes.forEach((type, index) => {
                        response.splice(index, 0, { ...initialFields, measureType: type });
                    });
                    // Sort the response array by measureType
                    response.sort((a: { measureType: string; }, b: { measureType: any; }) => a.measureType.localeCompare(b.measureType));

                }
                setmeasureLimitsData(response)

            }
        }
    }

    function matchMeasureType(measureType: string) {
        switch (measureType) {
            case "WEIGHT":
                return "Poids"
            case "HEART_RATE_MESSAGE":
                return "Fréquence cardiaque"
            case "TEMP_BODY":
                return "Température"
            case "OXYGEN_SATURATION":
                return "Saturation en oxygène"
            case "BLOOD_PRESSURE":
                return "Tension"
            case "SYSTOLIC_BLOOD_PRESSURE":
                return " systolique"
            case "DIASTOLIC_BLOOD_PRESSURE":
                return " diastolique"
            case "BLOOD_SUGAR":
                return "Taux de glycémie"
            default:
                break;
        }
    }

    async function fetchMeasureLimitHistory() {
        if (selectedPatient.id != "") {
            let response = await measuresService.getMeasureLimitsHistory(acc_token, selectedPatient.id, selectedMeasureType)

            if (response) {
                setmeasureLimitsHistory(response.data)
            }
        }
    }
    useEffect(() => {
        if (selectedPatient != undefined) {
            fetchMeasureLimitHistory()
        }
    }, [selectedMeasureType, selectedPatient,])

    async function deleteMeasureLimit() {
        if (selectedMeasureLimit.id != "") {
            let response = await measuresService.deleteMeasureLimit(acc_token, selectedMeasureLimit.id)
            if (response) {
                // closeDeleteModal()
                setrefresh(!refresh)
                setisSuccess(true)
            }
            else {
                setisError(false)
            }
        }
    }
    async function addMeasureLimit() {

        if (selectedPatient != undefined) {
            let data = {
                patientId: selectedPatient.id,
                measureType: selectedMeasureType,
                minValue: parseFloat(newMeasureLimit.minValue.toString().replace(",", ".")),
                maxValue: parseFloat(newMeasureLimit.maxValue.toString().replace(",", ".")),
                visibleForPatient: visibleForPatient
            }
            let DiastolicResponse

            if (selectedMeasureType == "BLOOD_PRESSURE") {
                data = {
                    ...data,
                    measureType: "SYSTOLIC_BLOOD_PRESSURE",
                }
                let diastolicData = {
                    patientId: selectedPatient.id,
                    measureType: "DIASTOLIC_BLOOD_PRESSURE",
                    minValue: parseFloat(minDiastolic.toString().replace(",", ".")),
                    maxValue: parseFloat(maxDiastolic.toString().replace(",", ".")),
                    visibleForPatient: visibleForPatient,
                }

                if (validateNewMeasureLimit(diastolicData, setdiastolicErrors)) {
                    DiastolicResponse = await measuresService.addMeasureLimits(acc_token, diastolicData)

                }
            }
            if (validateNewMeasureLimit(data, seterrors)) {

                let response = await measuresService.addMeasureLimits(acc_token, data)
                if (response) {
                    if (selectedMeasureType == "BLOOD_PRESSURE" && !DiastolicResponse) {
                        setisError(true)
                    }
                    setisSuccess(true)
                    setrefresh(!refresh)
                }
                else {
                    setisError(true)

                }
            }

        }

        console.log("is Error", isError)
        console.log("is Success", isSuccess)

    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setnewMeasureLimit({
            ...newMeasureLimit, [name]: value
        })
    }


    function closeAddModal() {
        setaddModalOpen(false)
        setselectedMeasureType("")
        setnewMeasureLimit(initialFields)
        setisError(false)
        setisSuccess(false)
    }
    function closeDeleteModal() {
        setdeleteModalOpen(false)
        setisError(false)
        setisSuccess(false)
        setselectedMeasureLimit(initialFields)
    }

    return (
        <PageWithHeader>
            <PageWrapper title={"Gérer les seuils de mesures"} >
                <StyledHead >
                    <div style={{ marginTop: "20px", minWidth: "300px" }}>
                        <Autocomplete
                            disableClearable={true}
                            onChange={handleChangePatient}
                            value={selectedPatient || undefined}
                            options={list}
                            sx={{ width: '100%', minWidth: "300px" }}
                            renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                                <TextField
                                    {...params}
                                    label="Bénéficiaire"
                                    inputProps={{
                                        ...params.inputProps,
                                        style: { zIndex: 9999 }, // Set a higher z-index value to ensure visibility
                                    }}
                                />
                            )}
                        />  <Popper
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            placement="auto-end"
                            style={{ zIndex: 500 }} // Set a higher z-index value
                        />
                    </div>


                </StyledHead>

                {
                    selectedPatient.id != '' && selectedPatient != undefined &&
                    <TableContainer style={{ maxWidth: "100%", display: "flex", justifyContent: "center", }} >
                        <StyledTable size="medium" aria-label="a dense table" >
                            <TableHead>
                                <TableRow  >
                                    <StyledTableCell align="left">
                                        Type de mesure
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        Min
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        Max
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        Ajoutée par
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        Ajoutée le
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        Actions
                                    </StyledTableCell>

                                </TableRow>
                            </TableHead>

                            <TableBody >
                                {measureLimitsData.map((row, index) => (
                                    <TableRow key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <StyledTableCell
                                            component="th"
                                            scope="row"
                                            align="left"

                                        >
                                            {matchMeasureType(row['measureType'])}

                                        </StyledTableCell>
                                        <StyledTableCell
                                            component="th"
                                            scope="row"
                                            align="center"

                                        >
                                            {row['minValue'] != "" && row['minValue'] != null ? row['minValue'] : UNDEFINED_VALUE}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="center"

                                        >
                                            {row['maxValue'] != "" && row['maxValue'] != null ? row['maxValue'] : UNDEFINED_VALUE}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {row['professionalId']["firstname"] != "" && row['professionalId']["lastname"] != ""
                                                ? `${row['professionalId']["firstname"]} ${row['professionalId']["lastname"]}`
                                                : UNDEFINED_VALUE
                                            }
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="center">
                                            {row['createdAt'] != "" ? dayjs(row['createdAt']).format("DD-MM-YYYY HH:mm")
                                                : UNDEFINED_VALUE
                                            }

                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="right"
                                        >
                                            <>
                                                {row['minValue'] == "" || row['maxValue'] == "" ?
                                                    <Tooltip title="Ajouter un seuil" placement="top">
                                                        <IconButton onClick={() => {
                                                            setaddModalOpen(true);
                                                            setselectedMeasureType(row['measureType']);
                                                        }}>
                                                            <AddCircleOutlineIcon style={{ color: '#035282', margin: 'auto', }} />
                                                        </IconButton>
                                                    </Tooltip> : <></>
                                                }

                                                {row['minValue'] != "" || row['maxValue'] != "" ?
                                                    <> <Tooltip title="Modifier le seuil" placement="top">
                                                        <IconButton onClick={() => {
                                                            setaddModalOpen(true);

                                                            setselectedMeasureType(row['measureType']);
                                                            if (row['measureType'] == "BLOOD_PRESSURE") {
                                                                let tempData = { ...row }
                                                                if (row['minValue'] != "") {
                                                                    let minValues = row["minValue"].split("/");
                                                                    // Sys/Dia
                                                                    if (minValues.length > 0) {
                                                                        tempData = { ...tempData, minValue: minValues[0].trim(), }
                                                                        setminDiastolic(Number(minValues[1]))
                                                                    }
                                                                }
                                                                if (row['maxValue'] != "") {
                                                                    let maxValues = row["maxValue"].split("/");
                                                                    // Sys/Dia
                                                                    if (maxValues.length > 0) {
                                                                        tempData = { ...tempData, maxValue: maxValues[0].trim() }
                                                                        setmaxDiastolic(Number(maxValues[1]))
                                                                    }
                                                                }
                                                                setnewMeasureLimit(tempData)
                                                                console.log("Final temp data", tempData)

                                                            } else {
                                                                setnewMeasureLimit(row);
                                                            }

                                                        }}>
                                                            <ModeEditIcon style={{ color: '#035282', margin: 'auto', }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                        <Tooltip title="Supprimer le seuil" placement="top">
                                                            <IconButton onClick={() => {
                                                                setdeleteModalOpen(true);
                                                                setselectedMeasureType(row['measureType']);
                                                                setselectedMeasureLimit(row)
                                                            }}>
                                                                <DeleteIcon style={{ color: '#F4C274', margin: 'auto', }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </>
                                                    : <></>

                                                }
                                                <Tooltip title="Afficher l'historique des seuils" placement="top">
                                                    <IconButton onClick={() => {
                                                        setselectedMeasureType(row['measureType']);
                                                        setIsOpen(true)
                                                    }}>
                                                        <HistoryIcon style={{ color: '#18C5BF', margin: 'auto', }} />
                                                    </IconButton>
                                                </Tooltip>

                                            </>
                                        </StyledTableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </StyledTable>
                    </TableContainer>
                }
            </PageWrapper>
            <Dialog open={modalIsOpen} onClose={() => setIsOpen(false)} fullWidth={true}
                maxWidth='md'
                style={{ zIndex: 9999 }}
            >
                <StyledDialogTitle> Historique des seuils de mesure de : {matchMeasureType(selectedMeasureType)} </StyledDialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setIsOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 20,
                        top: 20,
                        color: colors.dark_blue
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <StyledDialogContent>
                    {!isLoading && !isError && !isSuccess ?
                        <>

                            {
                                measureLimitsHistory.length > 0 ?
                                    <TableContainer style={{ maxWidth: "100%", display: "flex", justifyContent: "center", }} >
                                        <StyledTable size="medium" aria-label="a dense table" >
                                            <TableHead>
                                                <TableRow  >
                                                    {
                                                        selectedMeasureType == "BLOOD_PRESSURE" && <StyledTableCell align="center">
                                                            Type de tension
                                                        </StyledTableCell>
                                                    }

                                                    <StyledTableCell align="center">
                                                        Min
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        Max
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        Ajoutée par
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        Ajoutée le
                                                    </StyledTableCell>

                                                </TableRow>
                                            </TableHead>

                                            <TableBody >
                                                {measureLimitsHistory.map((row, index) => (
                                                    <TableRow key={row['id']}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        {
                                                            selectedMeasureType == "BLOOD_PRESSURE" &&
                                                            <StyledTableCell
                                                                component="th"
                                                                scope="row"
                                                                align="center"

                                                            >
                                                                {matchMeasureType(row['measureType'])}
                                                            </StyledTableCell>
                                                        }

                                                        <StyledTableCell
                                                            component="th"
                                                            scope="row"
                                                            align="center"

                                                        >
                                                            {row['minValue'] != 0 && row['minValue'] != null ? row['minValue'] : UNDEFINED_VALUE}
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            align="center"

                                                        >
                                                            {row['maxValue'] != 0 && row['maxValue'] != null ? row['maxValue'] : UNDEFINED_VALUE}
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            align="center"

                                                        >
                                                            {row['professionalId']["firstname"] != "" && row['professionalId']["lastname"] != ""
                                                                ? `${row['professionalId']["firstname"]} ${row['professionalId']["lastname"]}`
                                                                : UNDEFINED_VALUE
                                                            }

                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            align="center">
                                                            {row['createdAt'] != "" ? dayjs(row['createdAt']).format("DD-MM-YYYY HH:mm")
                                                                : UNDEFINED_VALUE
                                                            }

                                                        </StyledTableCell>

                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </StyledTable>
                                    </TableContainer> :

                                    <Box style={{
                                        width: "100%", display: "flex", flexDirection: "column",
                                        justifyContent: "center", alignItems: "center"
                                    }}>
                                        <StyledDialogMessage>Pas de données pour l'instant.</StyledDialogMessage>
                                    </Box>


                            }
                        </> :
                        <Box sx={{ padding: "50px 0px" }}>
                            <SpinnerComponent />
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                                <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
                            </Box>
                        </Box>
                    }


                </StyledDialogContent>
                {!isLoading &&
                    <DialogActions>
                        <ActionsWrapper>

                            <CancelBtn onClick={() => setIsOpen(false)}>Fermer</CancelBtn>

                        </ActionsWrapper>

                    </DialogActions>
                }
            </Dialog>

            <Dialog open={deleteModalOpen} onClose={() => setdeleteModalOpen(false)}
                fullWidth={true}
                maxWidth='md'
                style={{ zIndex: 9999 }}
            >
                <StyledDialogTitle> Êtes-vous sûr de bien vouloir supprimer ce seuil de {matchMeasureType(selectedMeasureType)} ?  </StyledDialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={() => closeDeleteModal()}
                    sx={{
                        position: 'absolute',
                        right: 20,
                        top: 20,
                        color: colors.dark_blue
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <StyledDialogContent>
                    {!isLoading && !isSuccess && !isError &&
                        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                            <Box style={{ display: "flex", flexDirection: "row" }}>
                                <Typography> Min :</Typography>
                                <Typography> {selectedMeasureLimit.minValue}</Typography>
                            </Box>
                            <Box style={{ display: "flex", flexDirection: "row" }}>
                                <Typography>Max :</Typography>
                                <Typography> {selectedMeasureLimit.maxValue}</Typography>
                            </Box>
                            <Box style={{ display: "flex", flexDirection: "row" }}>
                                <Typography>de: </Typography>
                                <Typography> {selectedPatient && selectedPatient.label}</Typography>
                            </Box>
                        </Box>
                    }

                    {
                        isSuccess && <Box sx={{ padding: "50px 0px" }}>

                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                                <StyledDialogMessage>Élément supprimé avec succès.</StyledDialogMessage>
                            </Box>
                        </Box>
                    }
                    {
                        isError && <Box sx={{ padding: "50px 0px" }}>

                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                                <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer.</StyledDialogMessage>
                            </Box>
                        </Box>
                    }
                    {isLoading &&
                        <Box sx={{ padding: "50px 0px" }}>
                            <SpinnerComponent />
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                                <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
                            </Box>
                        </Box>
                    }

                </StyledDialogContent>

                <DialogActions>
                    <ActionsWrapper>
                        <CancelBtn onClick={() => closeDeleteModal()}>{
                            !isLoading && !isError && !isSuccess ?
                                "Annuler" : "Fermer"
                        }</CancelBtn>
                        {
                            !isLoading && !isError && !isSuccess &&
                            <ConfirmBtn onClick={() => deleteMeasureLimit()}>Supprimer</ConfirmBtn>
                        }

                    </ActionsWrapper>

                </DialogActions>

            </Dialog>
            <Dialog open={addModalOpen} onClose={() => setaddModalOpen(false)} fullWidth={true}
                maxWidth='md'
                style={{ zIndex: 9999 }}
            >
                <StyledDialogTitle> Ajouter une nouveau seuil de mesure
                    de {matchMeasureType(selectedMeasureType)} </StyledDialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => closeAddModal()}
                    sx={{
                        position: 'absolute',
                        right: 20,
                        top: 20,
                        color: colors.dark_blue
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <StyledDialogContent>
                    {!isLoading && !isSuccess && !isError &&
                        <>

                            <StyledDialogContentText>
                                Veuillez définir les seuils de cette mesure.
                            </StyledDialogContentText>


                            {selectedMeasureType == "BLOOD_PRESSURE" &&
                                <>
                                    <InputBlueBottom
                                        value={minDiastolic}
                                        onChange={(e) => setminDiastolic(Number(e.target.value))}
                                        placeholder={"Seuil diastolique min"}
                                        type="number"
                                        name="minValue"
                                        required={false}
                                    />

                                    <Label>{diastolicErrors.minValue}</Label>
                                    <InputBlueBottom
                                        value={maxDiastolic}
                                        onChange={(e) => setmaxDiastolic(Number(e.target.value))}
                                        placeholder={"Seuil diastolique max"}
                                        type="number"
                                        name="minValue"
                                        required={false}
                                    />

                                    <Label>{diastolicErrors.maxValue}</Label>
                                </>
                            }
                            <InputBlueBottom
                                value={newMeasureLimit.minValue}
                                onChange={handleChange}
                                placeholder={selectedMeasureType == "BLOOD_PRESSURE" ? "Seuil systolique min" : "Seuil min"}
                                type="number"
                                name="minValue"
                                required={false}
                            />

                            <Label>{errors.minValue}</Label>

                            <InputBlueBottom
                                value={newMeasureLimit.maxValue}
                                onChange={handleChange}
                                placeholder={selectedMeasureType == "BLOOD_PRESSURE" ? "Seuil systolique max" : "Seuil max"}
                                type="number"
                                name="maxValue"
                                required={false}
                            />
                            <Label>{errors.maxValue}</Label>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: 6, }}>
                                <Checkbox
                                    sx={{
                                        "&.Mui-checked": {
                                            color: '#18C5BF'
                                        }
                                    }}
                                    checked={visibleForPatient}
                                    onChange={(e) => { setvisibleForPatient(e.target.checked); }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <Typography>
                                    Afficher le seuil au bénéficiaire
                                </Typography>
                            </div>

                        </>

                    }
                    {
                        isSuccess && <Box sx={{ padding: "50px 0px" }}>

                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                                <StyledDialogMessage>Élément ajouté avec succès.</StyledDialogMessage>
                            </Box>
                        </Box>
                    }
                    {
                        isError && <Box sx={{ padding: "50px 0px" }}>

                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                                <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer.</StyledDialogMessage>
                            </Box>
                        </Box>
                    }
                    {
                        isLoading &&
                        <Box sx={{ padding: "50px 0px" }}>
                            <SpinnerComponent />
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                                <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
                            </Box>
                        </Box>
                    }

                </StyledDialogContent>
                {
                    <DialogActions>
                        <ActionsWrapper>
                            <CancelBtn onClick={() => closeAddModal()}>{
                                !isLoading && !isError && !isSuccess ?
                                    "Annuler" : "Fermer"
                            }</CancelBtn>
                            {
                                !isLoading && !isError && !isSuccess ?
                                    <ConfirmBtn onClick={() => addMeasureLimit()}>Ajouter</ConfirmBtn>
                                    :
                                    <></>
                            }

                        </ActionsWrapper>

                    </DialogActions>
                }
            </Dialog>

        </PageWithHeader>
    )
}

export default MeasureLimits