import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import PageWithHeader from 'components/ReusableDivs/PageWithHeader';
import PageWrapper from 'components/ReusableDivs/PageWrapper';
import React, { useEffect, useState } from 'react';
import { FormWrapper, ModalWrapper, StyledButton } from 'screens/homes/Homes';
import CloseIcon from '@mui/icons-material/Close';
import { InputBlueBottom } from 'components/InputControls.tsx/Inputs';
import Modal from 'react-modal';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Link } from 'react-router-dom';
import { OrganizationService } from 'services/organizationsService';
import styled from 'styled-components';
import { StyledTableCell } from 'styles/tables/TableStyles';
import { CancelBtn, ConfirmBtn, StyledDialogContent, StyledDialogContentText, StyledDialogMessage, StyledDialogTitle } from 'styles/dialogs/DialogStyles';
import SpinnerComponent from 'components/spinners/SpinnerComponent';
import { in200s } from 'utils/RequestCheckFns';
import { useSelector } from 'react-redux';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { formatPhoneNumber } from 'utils/formatters/phoneNumberFormatter';
import { colors } from 'utils/Constants';
const ActionsWrapper = styled.div`
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  width:100%;
  padding:0px 10px;
`;
function Organizations() {
  const [Orga, setOrga] = useState([]);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [editing, setediting] = useState(false);
  const [currentId, setcurrentId] = useState('');
  const [modalDeleteIsOpen, setmodalDeleteIsOpen] = useState(false)
  const [newOrga, setNewOrga] = useState({
    name: '',
    address: '',
    phoneNumber: '',
    email: ''
  });
  const [currentOrga, setcurrentOrga] = useState({
    name: '',
    address: '',
    phoneNumber: '',
    email: ''
  });
  const [isLoading, setisLoading] = useState(false)
  const [isError, setisError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [refresh, setrefresh] = useState(false)
  const acc_token = useSelector((state: any) => state.userDomisante.userToken);

  useEffect(() => {
    OrganizationService.getOrganizations(setOrga, acc_token);
  }, [refresh,]);

  function openModal() {
    setmodalIsOpen(true);
  }

  function closeModal() {
    setcurrentOrga({
      name: '',
      address: '',
      phoneNumber: '',
      email: ''
    });
    setmodalIsOpen(false);
    setediting(false);
    setisLoading(false)
    setcurrentId('')
    setisError(false)
    setIsSuccess(false)
  }

  function closeModalDelete() {
    setmodalDeleteIsOpen(false)
    setcurrentId('')
    setisError(false)
    setIsSuccess(false)
    setisLoading(false)
  }


  async function addOrganization() {

    setisLoading(true)
    let response = await OrganizationService.addNewOrganization(newOrga, acc_token);
    if (response && in200s(response)) {
      setIsSuccess(true)
      setrefresh(!refresh)
    }
    else {
      setisError(true)
    }
    setisLoading(false)
    setNewOrga({
      name: '',
      address: '',
      phoneNumber: '',
      email: ''
    });
    // closeModal()
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewOrga({
      ...newOrga,
      [name]: value
    });
  };

  const handleChangeEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setcurrentOrga({
      ...currentOrga,
      [name]: value
    });
  };

  function deleteOrga(id: string) {
    setmodalDeleteIsOpen(true)
    setcurrentId(id)
  }

  async function validateDeleteOrga() {
    setisLoading(true)

    let response = await OrganizationService.deleteOrganization(currentId, acc_token);
    if (in200s(response)) {
      setrefresh(!refresh)
      setisLoading(false)
      setIsSuccess(true)
    }
    else {
      setisError(true)
      setisLoading(false)
    }
  }

  function modifOrga(id: string) {
    setmodalIsOpen(true);
    setediting(true);
    setcurrentId(id);
  }

  async function validateEdit() {
    setisLoading(true)
    let response = await OrganizationService.editOrganization(currentId, currentOrga, acc_token);
    if (response && in200s(response)) {
      setrefresh(!refresh)
      setisLoading(false)
      setIsSuccess(true)
      // closeModal()
    } else {
      setisError(true)
      setisLoading(false)

    }

  }

  return (
    <>
      <PageWithHeader>
        <PageWrapper title={'Liste des organisations'} btnTitle="Ajouter une organisation" btnClick={() => openModal()}>
          <TableContainer>
            <Table sx={{ minWidth: 650, border: "1px solid #8CC8DB" }} size="medium" aria-label="a dense table">
              <TableHead
                sx={{
                  color: 'red',
                  borderBottom: '2px solid black'
                }}
              >
                <TableRow
                  sx={{
                    borderBottom: '2px solid #C2D4D8'
                  }}
                >
                  <StyledTableCell align="left">
                    Nom
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Adresse
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Email
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Numéro de téléphone{' '}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Détails
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Actions
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Orga.length > 0 &&
                  Orga.map((row) => (
                    <TableRow key={row['id']} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <StyledTableCell component="th" scope="row" align="left">
                        {row['name']}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row" align="left">
                        {row['address']}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{
                        maxWidth: "150px",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                      }}>{row['email']}</StyledTableCell>
                      <StyledTableCell align="left">{formatPhoneNumber(row['phoneNumber'])}</StyledTableCell>
                      <StyledTableCell align="left">
                        <div style={{ width: "60%", justifyContent: "center", display: "flex" }}>
                          <Tooltip title="Consulter les détails" placement="top">
                            <IconButton
                              style={{}}
                              to={`/organization-details/${row["id"]}`}
                              component={Link}>
                              <CorporateFareIcon style={{ color: '#F4C274' }} />
                            </IconButton>
                          </Tooltip>
                        </div>

                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="left"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: "left",
                          justifyContent: 'start'
                        }}
                      >
                        <>
                          <Tooltip title="Modifier les données" placement="top">
                            <IconButton
                              onClick={() => {
                                const newOrga = {
                                  name: row['name'],
                                  address: row['address'],
                                  phoneNumber: row['phoneNumber'],
                                  email: row['email']
                                };
                                setcurrentOrga(newOrga);
                                modifOrga(row['id']);
                              }}
                            >
                              <ModeEditIcon style={{ color: '#18C5BF' }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Supprimer l'organisation" placement="top">
                            <IconButton
                              onClick={() => {
                                deleteOrga(row['id']);
                              }}
                            >
                              <DeleteIcon style={{ color: '#035282' }} />
                            </IconButton>
                          </Tooltip>
                        </>

                      </StyledTableCell>

                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PageWrapper>
      </PageWithHeader>


      <Dialog open={modalIsOpen} onClose={() => setmodalIsOpen(false)} fullWidth={true}
        maxWidth='md'
        style={{ zIndex: 99 }}
      >
        {
          editing ? <StyledDialogTitle> Modifier une organisation</StyledDialogTitle> : <StyledDialogTitle> Ajouter une organisation</StyledDialogTitle>
        }
        <IconButton
          aria-label="close"
          onClick={() => closeModal()}
          sx={{
            position: 'absolute',
            right: 20,
            top: 20,
            color: colors.dark_blue
          }}
        >
          <CloseIcon />
        </IconButton>

        <StyledDialogContent>
          {
            !isLoading && !isError && !isSuccess && <>

              {
                editing ? <StyledDialogContentText>
                  Veuillez mettre à jour les spécifications de votre organisation.
                </StyledDialogContentText> :
                  <StyledDialogContentText>
                    Veuillez ajouter les spécifications de votre organisation.
                  </StyledDialogContentText>
              }

              {editing && (
                <InputBlueBottom
                  value={currentOrga.name}
                  onChange={handleChangeEdit}
                  placeholder="Nom"
                  type="text"
                  name="name"
                  required
                />
              )}

              {!editing && (
                <InputBlueBottom
                  value={newOrga.name}
                  onChange={handleChange}
                  placeholder="Nom"
                  type="text"
                  name="name"
                  required
                />
              )}

              {editing && (
                <InputBlueBottom
                  value={currentOrga.address}
                  onChange={handleChangeEdit}
                  placeholder="Adresse"
                  type="text"
                  name="address"
                  required={false}
                />
              )}

              {!editing && (
                <InputBlueBottom
                  value={newOrga.address}
                  onChange={handleChange}
                  placeholder="Adresse"
                  type="text"
                  name="address"
                  required={false}
                />
              )}

              {editing && (
                <InputBlueBottom
                  value={currentOrga.email}
                  onChange={handleChangeEdit}
                  placeholder="Email"
                  type="text"
                  name="email"
                  required={false}
                />
              )}

              {!editing && (
                <InputBlueBottom
                  value={newOrga.email}
                  onChange={handleChange}
                  placeholder="Email"
                  type="text"
                  name="email"
                  required={false}
                />
              )}

              {editing && (
                <InputBlueBottom
                  value={currentOrga.phoneNumber}
                  onChange={handleChangeEdit}
                  placeholder="Numéro de téléphone"
                  type="text"
                  name="phoneNumber"
                  required={false}
                />
              )}

              {!editing && (
                <InputBlueBottom
                  value={newOrga.phoneNumber}
                  onChange={handleChange}
                  placeholder="Numéro de téléphone"
                  type="text"
                  name="phoneNumber"
                  required={false}
                />
              )}
            </>
          }
          {isLoading && <Box sx={{ padding: "50px 0px" }}>

            <SpinnerComponent />
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
              <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
            </Box>
          </Box>
          }
          {
            isSuccess && <Box sx={{ padding: "50px 0px" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                {
                  !editing ?
                    <StyledDialogMessage>Élément ajouté avec succès.</StyledDialogMessage> :
                    <StyledDialogMessage>Élément mis à jour avec succès.</StyledDialogMessage>
                }

              </Box>
            </Box>
          }
          {
            isError && <Box sx={{ padding: "50px 0px" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer.</StyledDialogMessage>
              </Box>
            </Box>
          }


        </StyledDialogContent>
        {
          <DialogActions>
            <ActionsWrapper>
              <CancelBtn onClick={() => closeModal()}>{
                !isLoading && !isError && !isSuccess ?
                  "Annuler" : "Fermer"
              }</CancelBtn>
              {
                !isLoading && !isError && !isSuccess &&
                <>{!editing && <ConfirmBtn onClick={() => addOrganization()}>Ajouter</ConfirmBtn>}
                  {editing && <ConfirmBtn onClick={() => validateEdit()}>Modifier</ConfirmBtn>}
                </>}
            </ActionsWrapper>

          </DialogActions>
        }

      </Dialog>

      <Dialog
        open={modalDeleteIsOpen}
        onClose={closeModalDelete}

      >
        <StyledDialogTitle >
          {"Êtes-vous sûr de vouloir supprimer cette organisation ?"}
        </StyledDialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => closeModalDelete()}
          sx={{
            position: 'absolute',
            right: 20,
            top: 20,
            color: colors.dark_blue
          }}
        >
          <CloseIcon />
        </IconButton>

        {
          !isLoading && !isSuccess && !isError &&
          <StyledDialogContent>
            <StyledDialogContentText id="alert-dialog-description">
              La suppression de cette organisation est définitive. Voulez-vous supprimer quand même ?
            </StyledDialogContentText>
          </StyledDialogContent>
        }
        {isLoading && <Box sx={{ padding: "50px 0px" }}>

          <SpinnerComponent />
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
            <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
          </Box>
        </Box>
        }
        {
          isSuccess && <Box sx={{ padding: "50px 0px" }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
              {
                <StyledDialogMessage>Élément supprimé avec succès.</StyledDialogMessage>
              }

            </Box>
          </Box>
        }
        {
          isError && <Box sx={{ padding: "50px 0px" }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
              <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer.</StyledDialogMessage>
            </Box>
          </Box>
        }

        {
          <DialogActions>
            <ActionsWrapper>
              <CancelBtn onClick={() => closeModalDelete()}>{
                !isLoading && !isError && !isSuccess ?
                  "Annuler" : "Fermer"
              }
              </CancelBtn>
              {
                !isLoading && !isSuccess && !isError &&
                <ConfirmBtn onClick={() => validateDeleteOrga()} autoFocus>
                  Supprimer
                </ConfirmBtn>
              }

            </ActionsWrapper>

          </DialogActions>
        }

      </Dialog>

    </>
  );
}

export default Organizations;
