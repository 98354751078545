import axios from 'axios';
import { is, curryN, gte } from 'ramda';
import { requestChecker } from 'utils/RequestCheck';


const isWithin = curryN(3, (min, max, value) => {
  const isNumber = is(Number);
  return isNumber(min) && isNumber(max) && isNumber(value) && gte(value, min) && gte(max, value);
});

const { REACT_APP_BASE_URL } = process.env

const in200s = isWithin(200, 299);

async function getAllDevices(setDevices: React.Dispatch<React.SetStateAction<any>>, acc_token: string, orgaId?: string) {
  let url
  if (orgaId) {
    url = REACT_APP_BASE_URL + "/devices/get-devices?organizationId=" + orgaId
  }
  else {
    url = REACT_APP_BASE_URL + "/devices/get-devices"
  }

  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`,
      },
      timeout: requestChecker.requestTimeout,
    });

    if (requestChecker.in200s(response.status)) {
      setDevices(response.data.data);
      return response.data;
    } else {
      return null
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;

  }


}


async function addNewDevice(data: any, acc_token: string) {

  let url = REACT_APP_BASE_URL + `/devices/assign-device`

  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout, // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });

    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;//is it null or this one 
    }

  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }


}


async function getPatientsByDevice(id: string, setPatients: React.Dispatch<React.SetStateAction<any>>, acc_token: string) {

  let url = REACT_APP_BASE_URL + "/devices/get-patients-by-device/" + `${id}`

  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`,
      },
      timeout: requestChecker.requestTimeout,
    });

    if (requestChecker.in200s(response.status)) {
      setPatients(response.data.data);
      return response.data;
    } else {
      return null
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;

  }
}

async function diassociateDevice(userId: string, deviceId: string, acc_token: string, organizationId?: string) {
  let url = REACT_APP_BASE_URL + `/devices/diassociate-device/?userId=` + userId +
    '&deviceId=' + deviceId

  if (organizationId) {
    url += '&organizationId=' + organizationId
  }
  try {
    let response = await axios.request({
      url: url,
      method: 'put',
      data: {},
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}
async function associateTabletDoc2u(data: any, acc_token: string,) {
  let url = REACT_APP_BASE_URL + `/devices/associate-tablet`

  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}
export const devicesService = {
  getAllDevices,
  getPatientsByDevice,
  addNewDevice,
  diassociateDevice,
  associateTabletDoc2u
};
