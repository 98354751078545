import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { Role } from 'utils/Constants';

const { REACT_APP_BASE_URL } = process.env;

export const userLogin = createAsyncThunk(
  'user/login',
  async ({ login, password, longSession }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      let url = REACT_APP_BASE_URL + '/auth/login';
      if (longSession) {
        url += '?longSession=true';
      }
      const { data } = await axios.post(url, { login, password }, config);

      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.status);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialState = {
  loading: false,
  userInfo: null,
  userToken: null,
  managedOrganizationId: null,
  managedOrganizationName: null,
  orgaUnique: false,
  error: null,
  success: false,
  userRole: null
};

export const userSlice = createSlice({
  name: 'userDomisante',
  initialState,
  reducers: {
    logout: (state) => {
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state.managedOrganizationId = null;
      state.managedOrganizationName = null;
      state.orgaUnique = null;
      state.error = null;
      state.success = null;
      state.userRole = null;
      state.organizationId = null;
    },
    updateUserInfo(state, action) {
      state.userInfo = action.payload;
    },
    updateFirstname(state, action) {
      state.loading = false;
      state.error = null;
      state.userInfo.firstname = action.payload;
    },
    updateLastname(state, action) {
      state.loading = false;
      state.error = null;
      state.userInfo.lastname = action.payload;
    },
    updateBirthDateState(state, action) {
      state.loading = false;
      state.error = null;
      state.userInfo.birthDate = action.payload;
    },
    updatePhoneNumberState(state, action) {
      state.loading = false;
      state.error = null;
      state.userInfo.phoneNumber = action.payload;
    },
    updateMustChangePwdState(state, action) {
      state.loading = false;
      state.error = null;
      state.userInfo.mustChangePwd = action.payload;
    },

    updateOrgaUniqueState(state, action) {
      state.orgaUnique = action.payload;
    },
    updateManageOrganizationIdState(state, action) {
      state.managedOrganizationId = action.payload;
    },
    updateManageOrgnaizationNameState(state, action) {
      state.managedOrganizationName = action.payload;
    },
    updateUserRole(state, action) {
      state.userRole = action.payload;
    },
    updateUserLogin(state, action) {
      console.log('updateUserLogin ****', action.payload);
      let organizationId = null;
      state.loading = false;

      if (action.payload.user && action.payload.accessToken) {
        state.userInfo = action.payload.user;
        state.userToken = action.payload.accessToken;
        state.organizationId = organizationId;
        state.enable2FA = false;
        state.tempToken = null;
        if (action.payload.user.roles.includes(Role.ORGANIZATION_ADMIN)) {
          if (action.payload.user.organization != null) {
            organizationId = action.payload.user.organization.id;
          }
        }
      } else if (action.payload.enable2FA == true && action.payload.tempToken) {
        state.enable2FA = true;
        state.tempToken = action.payload.tempToken;
      }

      state.success = true;
    }
  },
  extraReducers: {
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      let organizationId = null;
      state.loading = false;

      if (payload.user && payload.accessToken) {
        state.userInfo = payload.user;
        state.userToken = payload.accessToken;
        state.organizationId = organizationId;
        state.enable2FA = false;
        state.tempToken = null;
        if (payload.user.roles.includes(Role.ORGANIZATION_ADMIN)) {
          if (payload.user.organization != null) {
            organizationId = payload.user.organization.id;
          }
        }
      } else if (payload.enable2FA == true && payload.tempToken) {
        state.enable2FA = true;
        state.tempToken = payload.tempToken;
      }

      state.success = true;
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    }
  }
});

export const {
  logout,
  updateFirstname,
  setUsername,
  updateLastname,
  updateBirthDateState,
  updatePhoneNumberState,
  updateOrgaUniqueState,
  updateManageOrgnaizationNameState,
  updateManageOrganizationIdState,
  updateToken,
  updateUserInfo,
  updateUserRole,
  updateMustChangePwdState,
  updateUserLogin
} = userSlice.actions;

export const selectUser = (state) => state.userDomisante;

export default userSlice.reducer;
