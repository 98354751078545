export function formatPhoneNumber(phoneNumber: string): string {
  if (phoneNumber.startsWith('0033')) {
    phoneNumber = '+' + phoneNumber.substring(2);
  }
  // Check if the number has the country code (+33)
  const hasCountryCode = phoneNumber.startsWith('+33');
  // Remove any non-digit characters from the phone number
  const cleanedNumber = phoneNumber.replace(/\D/g, '');

  // Format the phone number based on the presence of the country code
  if (hasCountryCode) {
    return cleanedNumber.replace(/(\d{2})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/, '+$1 $2 $3 $4 $5 $6');
  } else if (cleanedNumber.startsWith('0') && cleanedNumber.length == 10) {
    return cleanedNumber.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
  }

  /**This phoneNumber does not match french phone numbers  */
  return phoneNumber;
}
