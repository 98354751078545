import { IconButton, Tooltip } from '@mui/material';
import { LargeCardHeader, LargeCardHeading, LargeCardWrapper } from 'components/Cards/CardItems'
import { CardBody, CardFieldset } from 'components/Cards/ColumnCard'
import ActionButton from 'components/InputControls.tsx/ActionButton';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

type PageWrapperProps = {
  children: any;
  title: string;
  btnTitle?: string;
  btnClick?: React.MouseEventHandler<HTMLButtonElement>;
  extraBlock?: JSX.Element;
  backBtnContainer?: boolean;
}

function PageWrapper({ title, children, btnTitle, btnClick, extraBlock, backBtnContainer, }: PageWrapperProps) {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <LargeCardWrapper>

      <LargeCardHeader>
        {
          btnTitle && btnClick ?
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <div style={{
                width: "100%",
                display: "flex", flexDirection: "column",
              }}>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                }}>

                  <div style={{ width: "100%" }}>
                    {
                      backBtnContainer &&
                      <Tooltip title="Retour" placement="top" sx={{
                        zIndex: 10,
                        position: "absolute",
                        transform: "translate(-50%, -50%)",

                      }}>
                        <IconButton
                          style={{ marginTop: "10px", marginLeft: "30px" }}
                          onClick={handleGoBack}
                        >
                          <ArrowBackIosNewIcon style={{ color: '#18C5BF', fontSize: "35px" }} />
                        </IconButton>
                      </Tooltip>
                    }
                    <LargeCardHeading style={{
                      alignSelf: "center", textAlign: "center", marginRight: "auto",
                      marginLeft: "auto", zIndex: 5
                    }}>{title}</LargeCardHeading>
                  </div>

                  <div style={{ width: "80%", marginTop: 10, marginBottom: 10 }}>
                    {extraBlock}
                  </div>

                </div>


              </div>
              <div style={{
                width: "95%", display: "flex", justifyContent: "flex-end", flexDirection: "row",
                marginRight: "50px"
              }}>
                <div style={{ width: "15%", }}>
                  <ActionButton
                    title={btnTitle}
                    onClick={btnClick}
                  />
                </div>

              </div>
            </div>
            :
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <div style={{
                width: "100%",
                display: "flex", flexDirection: "column",
              }}>
                <div style={{ width: "100%" }}>
                  {
                    backBtnContainer &&
                    <Tooltip title="Retour" placement="top" sx={{
                      zIndex: 10,
                      position: "absolute",
                      transform: "translate(-50%, -50%)",

                    }}>
                      <IconButton
                        style={{ marginTop: "10px", marginLeft: "30px" }}
                        onClick={handleGoBack}
                      >
                        <ArrowBackIosNewIcon style={{ color: '#18C5BF', fontSize: "35px" }} />
                      </IconButton>
                    </Tooltip>
                  }
                  <LargeCardHeading style={{
                    alignSelf: "center", textAlign: "center", marginRight: "auto",
                    marginLeft: "auto", zIndex: 5
                  }}>{title}</LargeCardHeading>
                </div>


              </div>

            </div>

        }


      </LargeCardHeader>
      <CardBody>
        <CardFieldset>{children}</CardFieldset>
      </CardBody>
    </LargeCardWrapper>
  )
}

export default PageWrapper