import PageWithHeader from 'components/ReusableDivs/PageWithHeader';
import PageWrapper from 'components/ReusableDivs/PageWrapper';
import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Autocomplete,
  TextField,
  Box,
  Chip,
  Tooltip
} from '@mui/material';
import { usersService } from '../../services/usersService';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Link, useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import NoData from 'components/ReusableDivs/NoData';
import Empty from 'components/ReusableDivs/Empty';
import "../../styles/tables/tableStyles.css"
import { StyledTable, StyledTableCell } from 'styles/tables/TableStyles';
import { CancelBtn, ConfirmBtn, StyledDialogContent, StyledDialogContentText, StyledDialogMessage, StyledDialogTitle } from 'styles/dialogs/DialogStyles';
import SpinnerComponent from 'components/spinners/SpinnerComponent';
import { useSelector } from 'react-redux';
import { in200s } from 'utils/RequestCheckFns';
import { Role, colors } from 'utils/Constants';
import CloseIcon from '@mui/icons-material/Close';


export const ContentWrapper = styled.div`
  display: 'flex';
  flex-direction: 'row';
  align-items: 'center';
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0px 10px;
`;

type userProps = {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  login: string;
  birthDate: null;
  roles: string;
  gender: string;
  organization: {
    name: string;
  };
};
function PatientManagers() {
  const [users, setUsers] = useState<userProps[] | []>([]);
  const { firstname, lastname, id } = useParams()
  let title = 'Liste des professionnels';

  if (firstname && lastname) {
    title = 'Liste des professionnels de ' + firstname + ' ' + lastname;
  }
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [managerId, setmanagerId] = useState('');
  const [isEmpty, setIsEmpty] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [selectedManager, setselectedManager] = useState('')
  const [managersList, setmanagersList] = useState([])
  const [list, setlist] = useState([])
  const [healthProList, setHealthProList] = useState([])
  const [formattedhealthProList, setFormattedhealthProList] = useState([])
  const [value, setValue] = useState<string | null>(list[0]);

  const [refresh, setrefresh] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [isError, setisError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [connectedRole, setconnectedRole] = useState<string | null>(null)
  const userTypeOptions = ['Aidant', 'Professionnel de santé']
  const orgaIdfromRedux = useSelector((state: any) => state.userDomisante.managedOrganizationId);
  const [managerType, setManagerType] = useState('');
  const role = useSelector((state: any) => state.userDomisante.userRole);
  const acc_token = useSelector((state: any) => state.userDomisante.userToken);

  function formatManagersList(array: any[]) {
    let formatted: any = []
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      formatted.push({ label: `${element['firstname']} ${element['lastname']}`, id: `${element['id']}` })
    }
    setlist(formatted)
  }

  function formatHealthProList(array: any[]) {
    let formatted: any = []
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      formatted.push({ label: `${element['firstname']} ${element['lastname']}`, id: `${element['id']}` })
    }
    setFormattedhealthProList(formatted)
  }

  useEffect(() => {
    usersService.fetchAssociatedPatientManager(setUsers, id!, false, acc_token);
    if (users.length === 0) {
      setIsEmpty(true);
    }
  }, [refresh, acc_token, id, users.length]);

  async function removeRelation(id: string, id_manager: string) {
    setisLoading(true)
    let response = await usersService.removeAssociation(id_manager, id!, acc_token);
    if (response === 200) {
      setIsSuccess(true)
      setisLoading(false)

      setrefresh(!refresh)
    } else {
      setisError(true)
      setisLoading(false)

    }
  }

  function closeDeleteModal() {
    setDeleteModalIsOpen(false);
  }


  async function fetchManagersAndProsList() {
    console.log("orgaIdfromRedux", orgaIdfromRedux)

    let managersResponse = await usersService.fetchManagers(setmanagersList, orgaIdfromRedux!.toString(), 'aidant', acc_token)
    let prosResponse = await usersService.fetchManagers(setHealthProList, orgaIdfromRedux!.toString(), 'professionnel de santé', acc_token)

    console.log("prosResponse", prosResponse)
    console.log("managersResponse", managersResponse)


    if (managersResponse && managersList.length > 0) {
      formatManagersList(managersList)
    }
    if (prosResponse && healthProList.length > 0) {
      formatHealthProList(healthProList)
    }
  }
  useEffect(() => {

    /**
     * Il faut que l'organization id soit spécifié dés le chargement
     */

    if (orgaIdfromRedux !== null) {
      setconnectedRole(role)
    }


  }, [acc_token, orgaIdfromRedux, role])

  const handleChange = (e: any, v: any) => {
    setValue(v);
    setselectedManager(v!.id)
  }
  const closeAddManager = async () => {
    setOpenAdd(false)
    setrefresh(!refresh)
    setManagerType('')
    setisError(false)
    setIsSuccess(false)
  }
  const AssignManagerToPatient = async (managerId: string) => {
    setisLoading(true)
    let data = {
      managerId: selectedManager,
      patientId: id!
    }
    let response = await usersService.associateManagerPatient(data, acc_token)
    if (response && in200s(response)) {
      setisLoading(false)
      setselectedManager('')
      setIsSuccess(true)
    } else {
      setisError(true)
      setisLoading(false)
    }
  };

  console.log("formattedhealthProList   ", formattedhealthProList)
  console.log("healthProList oustide useeffect", healthProList)
  useEffect(() => {
    if (orgaIdfromRedux != null) { fetchManagersAndProsList() }
  }, [managerType, healthProList.length, orgaIdfromRedux])


  const selectRoleColor = (role: string) => {
    if (role === 'super admin' || role === 'admin') {
      return '#035282';
    }
    if (role === 'aidant') {
      return '#18C5BF';
    }
    if (role === 'professionnel de santé') {
      return '#8CC8DB';
    }
    if (role === 'professional') {
      return '#F4C274';
    }
    if (role === 'organization admin') {
      return '#0ED2EB';
    } else return undefined;
  };
  ///////////////////////////////////////////////
  const handleUserTypeChange = (event: any, value: any) => {
    setValue('');
    setselectedManager('')
    setManagerType(decapitalizeFirstLetter(value));
  };
  const decapitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
  };

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  // console.log("!connectedRole?.includes(Role.ADMIN)",)
  console.log("connectedRole", connectedRole != null && connectedRole == Role.ORGANIZATION_ADMIN)
  console.log("connectedRole **", connectedRole)



  return (
    <>
      <PageWithHeader>
        <PageWrapper title={title} btnTitle={connectedRole != null && connectedRole == Role.ORGANIZATION_ADMIN ? 'Ajouter' : undefined}
          btnClick={() => setOpenAdd(true)}
          backBtnContainer={true}
        >
          <ContentWrapper>
            {users.length > 0 && (
              <TableContainer>
                <StyledTable size="medium">
                  <TableHead>
                    <TableRow
                      sx={{
                        borderBottom: '2px solid #C2D4D8'
                      }}
                    >
                      <StyledTableCell sx={{ color: '#545454', fontWeight: 'bold' }} align="left">
                        Prénom
                      </StyledTableCell>
                      <StyledTableCell sx={{ color: '#545454', fontWeight: 'bold' }} align="left">
                        Nom
                      </StyledTableCell>
                      <StyledTableCell sx={{ color: '#545454', fontWeight: 'bold' }} align="left">
                        Email
                      </StyledTableCell>
                      {
                        (connectedRole !== 'aidant' && connectedRole !== 'professionnel de santé') &&
                        <StyledTableCell sx={{ color: '#545454', fontWeight: 'bold' }} align="center">

                          Bénéficiaires{' '}

                        </StyledTableCell>
                      }
                      {
                        (connectedRole !== 'aidant' && connectedRole !== 'professionnel de santé') &&
                        <StyledTableCell sx={{ color: '#545454', fontWeight: 'bold' }} align="center">
                          Retirer{' '}
                        </StyledTableCell>}
                      <StyledTableCell align="left">
                        Roles{' '}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((row) => (
                      <TableRow key={row['id']} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <StyledTableCell component="th" scope="row" align="left">
                          {row['firstname']}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="left">
                          {row['lastname']}
                        </StyledTableCell>
                        <StyledTableCell align="left"
                          style={{
                            maxWidth: "150px",
                            wordWrap: "break-word",
                            overflowWrap: "break-word",
                          }}
                        >{row['email']}</StyledTableCell>

                        {
                          (connectedRole !== 'aidant' && connectedRole !== 'professionnel de santé') &&
                          <StyledTableCell align="center">
                            <Tooltip title="Afficher la liste des bénéficiaires" placement="top">
                              <IconButton
                                to={`/manager-patients/${row['firstname']}/${row['lastname']}/${row["id"]}`}
                                component={Link}>
                                <PeopleAltIcon style={{ color: '#F4C274', margin: 'auto' }} />

                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        }
                        {
                          (connectedRole !== 'aidant' && connectedRole !== 'professionnel de santé') &&
                          <StyledTableCell align="center">
                            <Tooltip title="Désaffecter l'aidant" placement="top">
                              <IconButton
                                onClick={() => {
                                  setDeleteModalIsOpen(true);
                                  setmanagerId(row['id']);
                                }}
                              >
                                <DeleteIcon style={{ color: '#035282' }} />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>}
                        <StyledTableCell align="left">
                          {row['roles'].split('|').length > 0 ? (
                            row['roles']
                              .split('|')
                              .map((role, index) => (
                                <Chip
                                  label={`${role.trimEnd().trimStart()}`}
                                  variant="outlined"
                                  key={index}
                                  style={{ margin: 5, color: "white", backgroundColor: selectRoleColor(`${role.trimEnd().trimStart()}`) }}
                                />
                              ))
                          ) : (
                            <div style={{ color: "gray" }}>
                              {' '}
                              Pas d'organization assignée
                            </div>
                          )}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </TableContainer>
            )}
            {users.length === 0 && !isEmpty && <NoData />}
            {users.length === 0 && isEmpty && <Empty />}
          </ContentWrapper>

          <Dialog
            open={deleteModalIsOpen}
            onClose={closeDeleteModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex: 99 }}
          >

            <StyledDialogTitle id="alert-dialog-title">{'Êtes-vous sûr de vouloir supprimer cet aidant ?'}</StyledDialogTitle>

            <IconButton
              aria-label="close"
              onClick={() => closeDeleteModal()}
              sx={{
                position: 'absolute',
                right: 20,
                top: 20,
                color: colors.dark_blue
              }}
            >
              <CloseIcon />
            </IconButton>

            <StyledDialogContent>
              {!isLoading && !isSuccess && !isError &&
                <>
                  <StyledDialogContentText id="alert-dialog-description">La suppression est définitive</StyledDialogContentText>
                </>}
              {isLoading && <Box sx={{ padding: "50px 0px" }}>

                <SpinnerComponent />
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
                </Box>
              </Box>
              }
              {
                isError && <Box sx={{ padding: "50px 0px" }}>

                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                    <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer.</StyledDialogMessage>
                  </Box>
                </Box>
              }
              {
                isSuccess && <Box sx={{ padding: "50px 0px" }}>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                    <StyledDialogMessage>Aidant supprimé avec succès.</StyledDialogMessage>
                  </Box>
                </Box>
              }
            </StyledDialogContent>
            {
              <DialogActions>
                <ActionsWrapper>
                  <CancelBtn onClick={() => closeDeleteModal()}>{
                    !isLoading && !isError && !isSuccess ?
                      "Annuler" : "Fermer"
                  }</CancelBtn>
                  {!isLoading && !isError && !isSuccess && <ConfirmBtn onClick={() => removeRelation(id!, managerId)} autoFocus>
                    Supprimer
                  </ConfirmBtn>}
                </ActionsWrapper>
              </DialogActions>}

          </Dialog>
        </PageWrapper>
      </PageWithHeader>
      <Dialog open={openAdd} onClose={() => closeAddManager()} style={{ height: "100%", zIndex: 99 }} >

        <StyledDialogTitle>Ajouter un professionnel </StyledDialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => closeAddManager()}
          sx={{
            position: 'absolute',
            right: 20,
            top: 20,
            color: colors.dark_blue
          }}
        >
          <CloseIcon />
        </IconButton>

        <StyledDialogContent >
          {!isLoading && !isSuccess && !isError &&
            <>
              <StyledDialogContentText>
                Veuillez sélectionner le type de professionnel  à assigner. Vous pouvez ensuite le chercher par son nom ou son prénom.
              </StyledDialogContentText>

              <div style={{ margin: "20px 20px 70px 0px", zIndex: 2 }}>

                <Autocomplete
                  ListboxProps={{ style: { maxHeight: 100, overflow: 'auto' } }}
                  onChange={handleUserTypeChange}
                  value={capitalizeFirstLetter(managerType) || null}
                  disablePortal
                  id="combo-box-demo"
                  options={userTypeOptions}
                  sx={{ width: '100%', marginBottom: '10px' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Type de professionnel "}
                      onChange={(event) => handleUserTypeChange(event, event.target.value)}
                    />
                  )}
                />

                {managerType === "aidant" && (
                  <Autocomplete
                    ListboxProps={{ style: { maxHeight: 100, overflow: 'auto' } }}
                    onChange={handleChange}
                    value={value || null}
                    disablePortal
                    id="combo-box-demo"
                    options={list}
                    sx={{ width: '100%' }}
                    renderInput={(params) => <TextField {...params} label={capitalizeFirstLetter(managerType)} />}
                  />
                )}

                {managerType === "professionnel de santé" && (
                  <Autocomplete
                    ListboxProps={{ style: { maxHeight: 100, overflow: 'auto' } }}
                    onChange={handleChange}
                    value={value || null}
                    disablePortal
                    id="combo-box-demo"
                    options={formattedhealthProList}
                    sx={{ width: '100%' }}
                    renderInput={(params) => <TextField {...params} label={capitalizeFirstLetter(managerType)} />}
                  />
                )}

              </div>
            </>
          }
          {isLoading &&
            <Box sx={{ padding: "50px 0px" }}>
              <SpinnerComponent />
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
              </Box>
            </Box>
          }
          {
            isSuccess &&
            <Box sx={{ padding: "50px 0px" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                <StyledDialogMessage>Professionnel ajouté avec succès.</StyledDialogMessage>
              </Box>
            </Box>
          }
          {
            isError && <Box sx={{ padding: "50px 0px" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer.</StyledDialogMessage>
              </Box>
            </Box>
          }
        </StyledDialogContent>
        {
          <DialogActions>
            <ActionsWrapper>
              <CancelBtn onClick={() => closeAddManager()}>Retour</CancelBtn>
              {!isLoading && !isSuccess && !isError &&
                <ConfirmBtn
                  onClick={() => AssignManagerToPatient(selectedManager)}>Ajouter</ConfirmBtn>}
            </ActionsWrapper>
          </DialogActions>
        }
      </Dialog>
    </>
  );
}

export default PatientManagers;
