import axios from 'axios';
import { requestChecker } from 'utils/RequestCheck';

const { REACT_APP_BASE_URL } = process.env;

async function getTransmissions(acc_token: string, patientId: string) {
  let url = REACT_APP_BASE_URL + `/transmissions?patientId=` + patientId;

  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function createTransmission(acc_token: string, data: any) {
  let url = REACT_APP_BASE_URL + `/transmissions/create-transmission`;
  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

export const transmissionService = {
  getTransmissions,
  createTransmission
};
