import React from 'react'
import { Oval } from 'react-loader-spinner'
import {
    Box,
} from '@mui/material';
function SpinnerComponent() {
    return (
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>

            <Oval
                height={80}
                width={80}
                color="#18C5BF"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#C2D4D8"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />
        </Box>
    )
}

export default SpinnerComponent