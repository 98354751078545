import React, { useEffect, useState } from 'react'
import styled from "styled-components"
import GroupsIcon from '@mui/icons-material/Groups';
import CircleNotificationsSharpIcon from '@mui/icons-material/CircleNotificationsSharp';
import { useSelector } from 'react-redux';
import { notifService } from 'services/notifService';
import { usersService } from 'services/usersService';
import { Box, Typography } from '@mui/material';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
interface patientsProps {
  title: string;
  type: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  width?: number;
  description?: string
}

interface PatientsButtonCoverProps {
  width?: number;
  disabled?: boolean;
}
const PatientsButtonCover = styled.button<PatientsButtonCoverProps>`
  display: flex;
  flex-direction: row;
  // justify-content: center;
  // align-items: center;
  width: ${(props) => (props.width ? `${props.width}%` : '45%')};
  padding: 15px 10px;
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  color: #035282;
  background-color: ${(props) => (props.disabled ? '#035282' : '#FFFF')};
  border: 0;
  border-radius: 4px;
  font-family: open sans;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  padding-left: 7px;
  padding-right: 7px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2); /* Add this line for the shadow */
  &:hover {
    background-color: inherit;
    color: #fff;
    border: 1px solid #fff;
  }

  &:active {
    background-color: inherit;
    color: #fff;
    border: 1px solid #fff;
  }
`;

function PatientsButton({ title, onClick, type, disabled, width, description }: patientsProps) {
  const [unreadNotif, setUnreadNotif] = useState();
  const [membersCount, setMembersCount] = useState();


  const acc_token = useSelector((state: any) => state.userDomisante.userToken);

  const { userInfo }: any = useSelector((state: any) => state.userDomisante);
  async function getUnreadNotifs() {
    let notifs = await notifService.countUnreadNotifications(acc_token)
    setUnreadNotif((await notifs).notifications)
  }

  async function getMemberCount() {
    let members_count = await usersService.countAssociatedPatientManager(acc_token)
    setMembersCount((await members_count).data)
  }

  useEffect(() => {
    if (type == "alerts") {
      getUnreadNotifs()
    }
    if (type == "patients") {
      getMemberCount()
    }
  }, [unreadNotif]);
  return (
    <PatientsButtonCover
      type="button"
      onClick={onClick}
      disabled={disabled}
      width={width}
    >
      <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Box style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <Box style={{ marginRight: "10px", marginLeft: "20px", }}>
            {type === 'patients' && <GroupsIcon style={{ fontSize: 60, color: "#18C5BF" }} />}
            {type === 'alerts' && <CircleNotificationsSharpIcon style={{ fontSize: 60, color: "#18C5BF" }} />}
            {type === 'limits' && <MonitorHeartIcon style={{ fontSize: 60, color: "#18C5BF" }} />}
          </Box>
          <Box>
            {type === 'patients' && <div style={{ fontSize: 30 }}> {title} ({membersCount}) </div>}
            {type === 'alerts' && <div style={{ fontSize: 30 }}> {title} ({unreadNotif}) </div>}
            {type === 'limits' && <div style={{ fontSize: 30 }}> {title} </div>}
          </Box>
        </Box>
        <Box style={{ width: "90%", textAlign: "center" }}>{description && <Typography style={{ fontSize: 18, textAlign: "center", }}> {description}  </Typography>}</Box>

      </Box>

    </PatientsButtonCover>
  );
}

export default PatientsButton