import React, { useEffect, useState } from 'react'
import { Bar, } from 'react-chartjs-2'
import { colors } from 'utils/Constants'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import dayjs from 'dayjs';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
type BarChartProps = {
    options: any,
    data: any,
    type: string,
    small: boolean,
    double?: boolean,
    data2?: any,
    twoConstants?: boolean,
    type2?: string,
    length?: number
}
export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Chart.js Line Chart',
        },
    },
};
function BarChart({ options, data, type, type2, small, double, data2, twoConstants, length }: BarChartProps) {
    const [labels, setlabels] = useState<any[]>([])
    const [values1Array, setvalues1Array] = useState<any[]>([])
    const [values2Array, setvalues2Array] = useState<any[]>([])
    function matchMeasureName(type: string) {
        switch (type) {
            case "DIASTOLIC_BLOOD_PRESSURE":
                return "Tension diastolique "
            case "BLOOD_PRESSURE":
                return "Tension systolique "
            case "HEART_RATE_MESSAGE":
                return "Fréquence cardiaque "
            case "WEIGHT":
                return "Poids "
            case "TEMP_BODY":
                return "Température "
            case "OXYGEN_SATURATION":
                return "Saturation en oxygène "
            case "PAIN":
                return "Douleur "
            case "DOLOPLUS":
                return "Doloplus "
            case "DOLOPLUS":
                return "Doloplus "
            case "BLOOD_SUGAR":
                return "Taux de glycémie "
            default:
                break;
        }
    }
    function matchMeasureBorderColor(type: string) {
        switch (type) {
            case "BLOOD_PRESSURE":
                return colors.orange
            case "HEART_RATE_MESSAGE":
            case "URINE":

                return colors.sky_blue
            case "WEIGHT":
            case "DOLOPLUS":

                return colors.turquoise
            case "TEMP_BODY":
            case "PAIN":
                return colors.turquoise
            case "OXYGEN_SATURATION":
            case "BLOOD_SUGAR":
            case "STOOL":

                return colors.orange
            default:
                break;
        }
    }
    function matchMeasureColor(type: string) {
        switch (type) {
            case "BLOOD_PRESSURE":
                return colors.light_orange
            case "HEART_RATE_MESSAGE":
            case "URINE":
                return colors.light_sky_blue
            case "WEIGHT":
            case "DOLOPLUS":
                return colors.light_turquoise
            case "TEMP_BODY":
            case "PAIN":
                return colors.light_green
            case "OXYGEN_SATURATION":
            case "STOOL":
            case "BLOOD_SUGAR":


                return colors.light_orange
            default:
                break;
        }
    }
    if (type == "BLOOD_PRESSURE") {
        console.log("les deux types reçus : ", type, type2)
    }


    function getArrayLabels(array: any[]) {
        let labels = []
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            labels.push(element.eventTime)
        }
        return labels
    }
    function getValuesLabels(array: any[]) {
        let labels = []
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            labels.push(element.value)
        }
        return labels
    }

    function removeDuplicates(arr: Iterable<unknown> | null | undefined) {
        return [...new Set(arr)];
    }
    function getAllDataArray(data1: any[], data2: any[]) {

        let result = data1.concat(data2)
        let sortedArray = result.sort((a: { eventTime: any; }, b: { eventTime: any; }) => {
            return new Date(a.eventTime).getTime() - new Date(b.eventTime).getTime();
        })
        return sortedArray
    }

    function getChartParams(data: any) {
        let labelsArray: any[] = []
        let values1Array: any[] = []
        let values2Array: any[] = []
        if (type == "BLOOD_PRESSURE") {
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                if (small) {
                    labelsArray.push(dayjs(element.eventTime).format('DD/MM/YYYY'))
                } else {
                    labelsArray.push(dayjs(element.eventTime).format('DD/MM/YYYY HH:mm'))
                }
                if (double) {
                    let values = element.value.split("/")
                    if (values.length > 1) {
                        let firstValue = values[0]
                        let secondValue = values[1]
                        values1Array.push(firstValue)
                        values2Array.push(secondValue)
                    }
                } else {
                    values1Array.push(element.value)
                }
            }
            setvalues1Array(values1Array)
            setvalues2Array(values2Array)
            setlabels(labelsArray)

        }

        if (data2 && type == "PAIN") {
            let allData = getAllDataArray(data, data2)
            let prevEventTime = null
            for (let index = 0; index < allData.length; index++) {
                const dataElement = allData[index];
                if (dataElement["eventTime"] != prevEventTime) {

                    if (small) {
                        labelsArray.push(dayjs(dataElement["eventTime"]).format('DD/MM/YYYY'))
                    } else {
                        labelsArray.push(dayjs(dataElement["eventTime"]).format('DD/MM/YYYY HH:mm'))
                    }
                    if (dataElement["type"] == "PAIN") {
                        values1Array.push(dataElement["value"])
                        values2Array.push(0)
                    } else {
                        values2Array.push(dataElement["value"])
                        values1Array.push(0)
                    }
                }
                else {
                    if (dataElement["type"] == "PAIN") {
                        values1Array[values1Array.length - 1] = dataElement["value"]
                    } else {
                        values2Array[values2Array.length - 1] = dataElement["value"]

                    }
                }
                prevEventTime = dataElement["eventTime"]
            }
            setvalues1Array(values1Array)
            setvalues2Array(values2Array)
            setlabels(labelsArray)
        }
    }

    function getDataset() {
        if (double && type == "BLOOD_PRESSURE") {
            return [
                {
                    fill: true,
                    label: matchMeasureName(type),
                    data: values1Array,
                    borderColor: matchMeasureBorderColor(type),
                    backgroundColor: matchMeasureColor(type),
                    borderWidth: 1,
                },
                {
                    fill: true,
                    label: matchMeasureName(type2!),
                    data: values2Array,
                    borderColor: colors.turquoise,
                    backgroundColor: colors.light_turquoise,
                    borderWidth: 1,
                },

            ]
        } else if (twoConstants) {
            return [
                {
                    fill: true,
                    label: matchMeasureName(type),
                    data: values1Array,
                    borderColor: matchMeasureBorderColor(type),
                    backgroundColor: matchMeasureColor(type),
                    borderWidth: 1,
                },
                {
                    fill: true,
                    label: matchMeasureName(type2 ? type2 : type),
                    data: values2Array,
                    borderColor: matchMeasureBorderColor(type2 ? type2 : type),
                    backgroundColor: matchMeasureColor(type2 ? type2 : type),
                    borderWidth: 1,
                },

            ]
        } else {
            return [
                {
                    fill: true,
                    label: matchMeasureName(type),
                    data: values1Array,
                    borderColor: matchMeasureBorderColor(type),
                    backgroundColor: matchMeasureColor(type),
                    borderWidth: 1,
                },
            ]
        }
    }
    const chartdata = {
        labels: length && labels.length > length ? labels.slice(- length) : labels,
        datasets: getDataset()
    };
    useEffect(() => {
        getChartParams(data)
    }, [data])

    return (

        <Bar options={options} data={chartdata} />
    )
}

export default BarChart