import Card from 'components/Cards/Card';
import { ForgotWrapper, Img, ImgContainer, LogoWrapper, Wrapper } from 'components/StyledWrappers/StyledWrappers';
import { InputBlueBottom } from 'components/InputControls.tsx/Inputs';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { memberService } from 'services/memberService';
import AuthTemplatePage from 'components/ReusableDivs/AuthTemplatePage';

interface ChangePwdProps { }

const Label = styled.p`
  font-size: 14px;
  color: red;
`;
const ChangePwd: React.FC<ChangePwdProps> = () => {
  const [errors, setErrors] = useState({
    password: '',
    confirmPassword: ''
  });
  const [passwords, setPasswords] = useState({
    password: '',
    confirmPassword: ''
  });
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  let data = {
    email: searchParams.get('email'),
    token: searchParams.get('token'),
    newPassword: passwords.password
  };
  const handleSubmit = () => {
    if (validatePassword(passwords)) {
      memberService.changePwd(data);
      navigate('/changed-pwd')
    }

  };
  console.log("passwords", passwords)

  const validatePassword = (values: any) => {
    const errors = {
      password: '',
      confirmPassword: ''
    };
    var passwordRegex = /(?=^.{8,20}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

    console.log("password", values.password)
    console.log("confirmPassword", values.confirmPassword)

    if (!values.password) {
      errors.password = 'Veuillez entrer un mot de passe';
    } else if (!passwordRegex.test(values.password)) {
      errors.password = 'Mot de passe invalide.';
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = 'Veuillez confirmer votre mot de passe';
    } else if (!passwordRegex.test(values.confirmPassword) && values.confirmPassword !== values.password) {
      errors.confirmPassword = 'Veuillez entrer le même mot de passe';
    }
    setErrors(errors);
    return Object.values(errors).every((x) => x === '');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });
  };

  return (

    <AuthTemplatePage >
      <Card
        title="Vous voulez changer de mot de passe ?"
        description=""
        buttonTitle="Confirmer"
        onClick={() => handleSubmit()}
      >
        <InputBlueBottom
          value={passwords.password}
          onChange={handleChange}
          placeholder="Tapez votre nouveau password"
          required
          type="password"
          name="password"
        />
        <Label>{errors.password}</Label>

        <InputBlueBottom
          value={passwords.confirmPassword}
          onChange={handleChange}
          placeholder="Tapez votre nouveau password à nouveau"
          type="password"
          name="confirmPassword"
          required
        />
        <Label>{errors.confirmPassword}</Label>
      </Card>
    </AuthTemplatePage>
  );
};

export default ChangePwd;
