import axios from 'axios';
import { requestChecker } from 'utils/RequestCheck';
const { REACT_APP_BASE_URL } = process.env


async function getPreventions(setPreventions: React.Dispatch<React.SetStateAction<any>>, acc_token: string) {
    let url = REACT_APP_BASE_URL + '/preventions/'
    try {
        let response = await axios.request({
            url: url,
            method: 'get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                access_token: `${acc_token}`
            },
            timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
        });
        if (requestChecker.in200s(response.status)) {
            setPreventions(response.data);
            return response.data;
        } else {
            return null;
        }
    } catch (error: any) {
        requestChecker.HandleErrors(error, url);
        return null;
    }
}

async function deletePrevention(id: string, acc_token: string) {
    let url = REACT_APP_BASE_URL + `/preventions/del-prevention/${id}`

    try {
        let response = await axios.request({
            url: url,
            method: 'delete',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                access_token: `${acc_token}`
            },
            timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
        });
        if (requestChecker.in200s(response.status)) {
            return response.data;
        } else {
            return null;
        }
    } catch (error: any) {
        requestChecker.HandleErrors(error, url);
        return null;
    }
}
async function updatePrevention(id: string, data: any, acc_token: string) {
    let url = REACT_APP_BASE_URL + `/preventions/update-prevention/${id}`
    try {
        let response = await axios.request({
            url: url,
            method: 'post',
            data: data,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                access_token: `${acc_token}`
            },
            timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
        });
        if (requestChecker.in200s(response.status)) {
            return response.data;
        } else {
            return null;
        }
    } catch (error: any) {
        requestChecker.HandleErrors(error, url);
        return null;
    }
}

async function addNewPrevention(data: any, acc_token: string) {

    let url = REACT_APP_BASE_URL + `/preventions`

    try {
        let response = await axios.request({
            url: url,
            method: 'post',
            data: data,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                access_token: `${acc_token}`
            },
            timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
        });
        if (requestChecker.in200s(response.status)) {
            return response.data;
        } else {
            return null;
        }
    } catch (error: any) {
        requestChecker.HandleErrors(error, url);
        return null;
    }
}
export const preventionsService = {
    getPreventions,
    addNewPrevention,
    updatePrevention,
    deletePrevention
};