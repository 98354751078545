import Card from 'components/Cards/Card';
import { ForgotWrapper, Img, ImgContainer, LogoWrapper, Wrapper } from 'components/StyledWrappers/StyledWrappers';
import { InputBlueBottom } from 'components/InputControls.tsx/Inputs';
import React, { useEffect, useState } from 'react';
import { memberService } from 'services/memberService';
import styled from 'styled-components';
import { Navigate, useNavigate } from 'react-router-dom';
import SpinnerComponent from 'components/spinners/SpinnerComponent';
import { Box, Typography } from '@mui/material';
import { in200s } from 'utils/RequestCheckFns';
import AuthTemplatePage from 'components/ReusableDivs/AuthTemplatePage';
import { StyledDialogMessage } from 'styles/dialogs/DialogStyles';

const Label = styled.p`
font-size:14px ;
color:red ;
`
interface ResetPwdProps { }

const ResetPassword: React.FC<ResetPwdProps> = () => {

  const [email, setEmail] = useState({
    email: "",
  })
  const [sentPwd, setSentPwd] = useState(false)

  const [errors, setErrors] = useState({ email: "" })
  const [isLoading, setisLoading] = useState(false)
  const navigate = useNavigate()

  const validateEmail = (values: any) => {
    const errors = {
      email: ""
    }
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (values.email !== "s") {
      errors.email = emailRegex.test(values.email) ? '' : 'Email non valide.';
      setErrors(errors);
      return Object.values(errors).every((x) => x === '');

    }

  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEmail({ ...email, [name]: value })
  }

  const handleSubmit = async () => {
    setisLoading(true)
    if (validateEmail(email)) {
      let response = await memberService.resetPwd(email, setSentPwd)
      if (in200s(response)) {
        setisLoading(false)
      }

    }
  }



  return (

    <AuthTemplatePage
    >
      {!sentPwd ?
        <Card
          title="Mot de passe oublié ? "
          description=" Entrez l'adresse e-mail que vous utilisez pour votre compte ci-dessous et nous vous enverrons un lien de
        réinitialisation de mot de passe"
          buttonTitle="Confirmer"
          onClick={() => handleSubmit()}
        >
          {!isLoading ? <>
            <InputBlueBottom
              value={email.email}
              onChange={(e) => handleChange(e)}
              placeholder="E-mail"
              type="email"
              required
              name="email"
            />
            <Label>{errors.email}</Label>
          </> :
            <Box sx={{ padding: "50px 0px" }}>
              <SpinnerComponent />
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
              </Box>
            </Box>
          }
        </Card>
        :
        <Card
          title="Votre lien de réintialisation a été envoyé !"
          description=" Veuillez vérifier votre boite mail et cliquer sur le lien"
          buttonTitle="Retour à l'accueil"
          onClick={() => navigate('/')}
        >
          <></>
        </Card>

      }
    </AuthTemplatePage>
  );
}

export default ResetPassword;
