import React from 'react'
import { TailSpin } from 'react-loader-spinner'
import styled from "styled-components"

const SpinnerWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
margin-top:150px;
`
function NoData() {
  return (
    <SpinnerWrapper>

      <TailSpin
        height="80"
        width="80"
        color="#18C5BF"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </SpinnerWrapper>
  )
}

export default NoData