import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import { colors } from 'utils/Constants';

const useStyles = makeStyles({
    root: {
        width: "100%",
        "& .MuiInputLabel-root.Mui-focused": {
            color: "#035282"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#035282"
        },
        '& .MuiSvgIcon-root': {
            color: colors.turquoise,
        },
        '& .MuiFormLabel-root': {
            color: colors.dark_blue,

        },
    }
});

type CustomSelectProps = {
    value: string | undefined;
    label: string;
    setValue?: React.Dispatch<React.SetStateAction<string>>;
    onChange: (event: any) => void;
    items: any[];
}

function CustomSelect({ value, onChange, label, items }: CustomSelectProps) {
    const classes = useStyles();

    return (
        <Box sx={{ minWidth: 120, }} style={{ marginTop: "10px", marginBottom: "150x", }}>

            <TextField
                className={classes.root}
                value={value}
                onChange={onChange}
                variant="outlined"
                label={label}
                select
            >

                {
                    items.map((item, index) => (
                        <MenuItem value={item.value} key={index}>{item.label}</MenuItem>
                    ))
                }
            </TextField>
        </Box>
    )
}

export default CustomSelect