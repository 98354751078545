import styled from 'styled-components';

const CardButton = styled.button`
  display: block;
  width: 100%;
  padding: 12px 0;
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background-color: #035282;
  font-family: open sans;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);

  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);

  &:hover {
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.16);
    transform: translate(0, -5px);
  }
`;

interface ButtonProps {
  title: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const Button: React.FC<ButtonProps> = ({ title, onClick }) => {
  return (
    <CardButton type="button" onClick={onClick}>
      {title}
    </CardButton>
  );
};
export default Button;
