import axios from 'axios';
import { curryN, gte, is } from 'ramda';
import { requestChecker } from 'utils/RequestCheck';

// Vérification pour voir si une erreur a une valeur entre 200 et 299
const isWithin = curryN(3, (min, max, value) => {
  const isNumber = is(Number);
  return isNumber(min) && isNumber(max) && isNumber(value) && gte(value, min) && gte(max, value);
});

const { REACT_APP_BASE_URL } = process.env;
const in200s = isWithin(200, 299);
// Appelle l'API post /appoinments
async function postAppointment(data: {
  title: string;
  description: string;
  type: string;
  place: string;
  startDateTime: string;
  endDateTime: string;
  userId: string;
}, acc_token: string) {

  let url = REACT_APP_BASE_URL + `/appointments/`;

  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      //i cahnged appt to data because the function didn't accept it
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout, // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });

    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return response.status;//is it null or this one 
    }

  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }

  // return axios
  //   .post(REACT_APP_BASE_URL + `/appointments/`, appt, {
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',
  //       'Access-Control-Allow-Origin': '*',
  //       access_token: `${acc_token}`
  //     }
  //   })
  //   .then((response) => {
  //     if (in200s(response.status)) {
  //       return response.status;
  //     }
  //     return response.status;
  //   })
  //   .catch(function (error) {
  //     return error;
  //   });
}



// Appelle l'API put /appoinments
async function putAppointment(
  appt: {
    title: string;
    description: string;
    type: string;
    place: string;
    startDateTime: string;
    endDateTime: string;
    userId: string;
  },
  id: string, acc_token: string
) {

  let url = REACT_APP_BASE_URL + `/appointments/${id}`
  try {
    let response = await axios.request({
      url: url,
      method: 'put',
      data: appt,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`,
      },
      timeout: requestChecker.requestTimeout, // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return response.status;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;

  }

  // return axios
  //   .put(REACT_APP_BASE_URL + `/appointments/${id}`, appt, {
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',
  //       'Access-Control-Allow-Origin': '*',
  //       access_token: `${acc_token}`
  //     }
  //   })
  //   .then((response) => {
  //     if (in200s(response.status)) {
  //       return response.data;
  //     }
  //     return response.status;
  //   })
  //   .catch(function (error) {
  //     return error;
  //   });
}

// Appelle l'API delete /appointments
async function deleteAppointment(id: string, acc_token: string) {
  let url = REACT_APP_BASE_URL + `/appointments/${id}`

  try {
    let response = await axios.request({
      url: url,
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`,
      },
      timeout: requestChecker.requestTimeout, // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return response.status;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;

  }


}

// Appel de l'API get /appointments
// Démarre le formatage des données reçues
async function getRDV(startVisibleDate: string, endVisibleDate: string, id: string, acc_token: string) {


  let url = REACT_APP_BASE_URL + `/appointments/?start=` + startVisibleDate + `&end=` + endVisibleDate + `&userId=` + id

  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`,
      },
      timeout: requestChecker.requestTimeout,
    });

    if (requestChecker.in200s(response.status)) {
      return response.data.appointments;
    } else {
      return []; //it was a null but i chnaged to empty array
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;

  }




  // return axios
  //   .get(REACT_APP_BASE_URL + `/appointments/?start=` + startVisibleDate + `&end=` + endVisibleDate + `&userId=` + id, {
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',
  //       'Access-Control-Allow-Origin': '*',
  //       access_token: `${acc_token}`
  //     }
  //   })
  //   .then((response) => {
  //     if (in200s(response.status)) {
  //       return response.data.appointments;
  //     }
  //     return null;
  //   })
  //   .catch(function (error) {
  //     return error;
  //   });
}


async function fetchUserManagers(patientId: string, setdata: any, acc_token: string) {
  let url = REACT_APP_BASE_URL + `/user/fetch-associated-patient-manager?patientId=${patientId}`

  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`,
      },
      timeout: requestChecker.requestTimeout,
    });

    if (requestChecker.in200s(response.status)) {
      if (response.data.data.length > 0) {

        setdata(response.data.data[0]['patientManagers'])
        return response.data.data[0]['patientManagers'];
      }
    } else {
      return []; //it was a null but i chnaged to empty array
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;

  }






  // return axios
  //   .get(REACT_APP_BASE_URL + `/user/fetch-associated-patient-manager?patientId=${patientId}`, {
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',
  //       'Access-Control-Allow-Origin': '*',
  //       access_token: `${acc_token}`
  //     }
  //   })
  //   .then((response) => {
  //     if (in200s(response.status)) {
  //       if (response.data.data.length > 0) {

  //         setdata(response.data.data[0]['patientManagers'])
  //         return response.data.data[0]['patientManagers'];
  //       }
  //     }
  //     return null;
  //   })
  //   .catch(function (error) {
  //     return error;
  //   });
}
export const calendarService = {
  getRDV,
  deleteAppointment,
  postAppointment,
  putAppointment,
  fetchUserManagers
};
