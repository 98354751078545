import React from 'react'
import { Box, Typography, } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
type ProfileBlockProps = {
  children: any,
  icon: React.ReactNode,
  edit?: boolean,
  enableUpdate?: React.Dispatch<React.SetStateAction<boolean>>,
  editable?: boolean,
  updateFn?: () => {}
}

function ProfileBlock({ children, icon, edit, updateFn, editable, enableUpdate }: ProfileBlockProps) {
  return (
    <Box sx={{
      display: "flex", flexDirection: "row", height: "100%", padding: "10px", position: 'absolute',
      top: 5,
      bottom: 20,
      left: 10,
      right: 10
    }}>
      <Box sx={{ flex: 11,display:"flex" }}>
        {children}
      </Box>
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-between", height: "80%" ,}}>

        <Box sx={{}}>
          {icon}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>

        {editable && <IconButton onClick={edit ? () => updateFn!() : () => enableUpdate!(!edit)}>
          {
            edit ? <SaveOutlinedIcon /> : <EditOutlinedIcon />
          }

        </IconButton>}
      </Box>
    </Box>
    </Box >
  )
}

export default ProfileBlock