import PageWithHeader from 'components/ReusableDivs/PageWithHeader';
import { useCallback, useEffect, useState, CSSProperties, } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Box, Chip, DialogActions, IconButton, TextField, Divider } from '@mui/material';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import PeopleIcon from '@mui/icons-material/People';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import ScaleIcon from '@mui/icons-material/Scale';
import { calendarService } from '../../services/calendarService';
import useWindowDimensions from '../../utils/useWindowDimensions';
import './CalendarPage.css';
import { CancelBtn, ConfirmBtn, StyledDialogActions, StyledDialogContent, StyledDialogContentField, StyledDialogContentText, StyledDialogMessage, StyledDialogTitle, StyledFieldContainer, StyledFieldName, TertiaryBtn } from 'styles/dialogs/DialogStyles';
import { InputBlueBottom } from 'components/InputControls.tsx/Inputs';
import "../../styles/inputs/htmlInput.css";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { SelectChangeEvent } from '@mui/material/Select/Select';
import { makeStyles } from '@mui/styles';
import CustomSelect from 'components/InputControls.tsx/CustomSelect';
import { useParams } from 'react-router-dom';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { in200s } from 'utils/RequestCheckFns';
import { useSelector } from 'react-redux';
import PageWrapper from 'components/ReusableDivs/PageWrapper';
import SpinnerComponent from 'components/spinners/SpinnerComponent';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloseIcon from '@mui/icons-material/Close';
import { Role, colors } from 'utils/Constants';
import { ActionsWrapper } from 'screens/Menu/UsersList';
import { usersService } from 'services/usersService';
// import { DatePicker as CustomDatePicker } from "react-datepicker";
import DatePicker from "react-datepicker";
import "./date-picker.css";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from '@mui/icons-material/CalendarToday'; // Assuming you have a calendar icon component
import { registerLocale } from "react-datepicker";

import fr from "date-fns/locale/fr";
registerLocale('fr', fr)




export const eventTypes = [
  { label: "Ménage", value: 'rdv_menage' },
  { label: "Soins", value: 'nursing' },
  { label: "Prise de mesures", value: 'measures' },
  { label: "Consultation", value: 'consultation' },
  { label: "Autre", value: 'other' },
];


const useStyles = makeStyles({
  root: {
    width: "100%",
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#035282"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#035282"
    }
  }
});

type formatedManager = { label: string; value: any; }



export interface StylesDictionary {
  [Key: string]: CSSProperties;
}



export const customStyles: StylesDictionary = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99
  },
  input: {
    width: '100%',
    fontSize: 15,
    fontFamily: 'open sans',
    right: 0,
  },
  labels: {
    maxWidth: 400,
    width: '30%',
    fontSize: 18,
    flexGrow: 1,
    marginTop: '3%',
    fontWeight: '400'
  },
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
  },
  alert: {
    color: 'red',
    textAlign: 'left',
    width: '70%',
    marginLeft: '30%'
  },
  wrapper: { paddingTop: "10px", paddingBottom: "10px", fontFamily: "open sans", display: "flex", alignItems: "center", backgroundColor: "red" },
  datePicker: {
    width: "100%",
    backgroundColor: "red"
  }
};

type EventInterface = {
  title: string;
  description: string;
  start: Date;
  end: Date;
  createdBy?: string;
  editedBy?: string;
  id?: string;
  place: string;
  type: string;
  userId: string;
  managerId?: string;
  startHour: string;
  endHour: string;
  participants?: any[]
};

const customSx = {
  '& .MuiFormLabel-root': {
    color: colors.dark_blue,
  },


  '& label.Mui-focused': {
    color: '#035282',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#035282',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#035282',
    },
  },
  '& .MuiInputBase-input': {

    fontFamily: [
      'open sans',

    ].join(','),

  },

  '& .MuiIconButton-root.MuiInputAdornment-root.MuiInputAdornment-positionEnd:not(.Mui-disabled)::before': {
    display: 'none',
  },
  '& .MuiSvgIcon-root': {
    color: colors.turquoise,
  },

}
const localizer = momentLocalizer(moment);

function CalendarPage() {
  const userInfo = useSelector((state: any) => state.userDomisante.userInfo);
  let fixedOptions: string | any[] = [];
  let participantsInitialValue = [...fixedOptions]
  const [participants, setParticipants] = useState(participantsInitialValue);
  const { firstname, lastname, id } = useParams()
  var startOfWeek = moment().startOf('isoWeek').toISOString();
  var endOfWeek = moment().endOf('isoWeek').toISOString();
  let dateInit = new Date()

  const [calendarDate, setcalendarDate] = useState(dateInit)
  const [calendarHeight, setcalendarHeight] = useState<number>(500)
  const { height, width } = useWindowDimensions();
  const [value, setValue] = useState<Dayjs | null>(null);
  const [startTimeValue, setstartTimeValue] = useState<Dayjs | null>(dayjs('2022-04-07'));
  const [endTimeValue, setEndTimeValue] = useState<Dayjs | null>(dayjs('2022-04-07'));
  const [startVisibleDate, setstartVisibleDate] = useState<string>(startOfWeek);
  const [endVisibleDate, setendVisibleDate] = useState<string>(endOfWeek);
  const [refresh, setrefresh] = useState(false)
  const [myEventsList, setmyEventsList] = useState<EventInterface[]>([]);
  const [currentAppointment, setCurrentAppointment] = useState<EventInterface>(
    {
      title: "",
      description: "",
      start: new Date(),
      end: new Date(),
      createdBy: "",
      editedBy: "",
      id: "",
      place: "",
      type: "",
      userId: "",
      managerId: "",
      startHour: "",
      endHour: "",
    }
  );
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [newModalIsOpen, setnewModalIsOpen] = useState(false);
  const [validateModalIsOpen, setvalidateModalIsOpen] = useState(false);
  const classes = useStyles();
  const [startNewEvent, setstartNewEvent] = useState();
  const [endNewEvent, setendNewEvent] = useState();
  const [inThePast, setinThePast] = useState(false);
  const [formattedManagersList, setformattedManagersList] = useState<formatedManager[]>([])
  const [isEdit, setisEdit] = useState(false)
  const [errorData, seterrorData] = useState({ title: '', description: '', type: '', place: '', start: '', end: '' });
  const [isError, setisError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [helpers, setHelpers] = useState([])
  const acc_token: string = useSelector((state: any) => state.userDomisante.userToken);
  const currentUserRole = useSelector((state: any) => state.userDomisante.userRole);
  const currentUserRoles = useSelector((state: any) => state.userDomisante.userInfo.roles);

  const currentUserId = useSelector((state: any) => state.userDomisante.userInfo.id);
  const orgaIdfromRedux = useSelector((state: any) => state.userDomisante.managedOrganizationId);

  const [TimeDuration, setTimeDuration] = useState<number>()
  const [participantsOptions, setparticipantsOptions] = useState([])

  async function getHelpersList() {
    let response
    if (currentUserRoles.includes(Role.ORGANIZATION_ADMIN) && orgaIdfromRedux) {
      response = await usersService.getPros(setHelpers, acc_token, orgaIdfromRedux)
    } else {
      response = await usersService.getPros(setHelpers, acc_token)
    }
    if (response) {
      setHelpers(response)
      let tempArr = []
      for (let index = 0; index < response.length; index++) {
        const element = response[index];
        tempArr.push({
          name: element.firstname + " " + element.lastname,
          id: element.id
        });
      }
      // Assuming `arrayOfObjects` is your array of objects
      const currentParticipant: { id: string, name: string } | undefined = tempArr.find((obj: { id: string, name: string }) => obj.id === currentUserId);

      if (currentParticipant) {
        fixedOptions = [currentParticipant]
      }
      //@ts-ignore
      //@ts-ignore
      setparticipantsOptions(tempArr)
      setParticipants([...fixedOptions])
    }

  }

  useEffect(() => {
    getHelpersList()
  }, [])

  //Fermeture des modales
  function closeValidateModal() {
    setvalidateModalIsOpen(false);
    setisError(false);
    setIsSuccess(false)
    closeModal()
  }

  function closeModal() {
    setisEdit(false)
    setEditModalIsOpen(false);
    emptyAppointmentFields()
    setrefresh(!refresh)
  }

  function closeNewModal() {
    setisEdit(false)
    setnewModalIsOpen(false);
    seterrorData({ title: '', description: '', type: '', place: '', start: '', end: '' });
    emptyAppointmentFields()
    setIsSuccess(false)
    setisError(false)
  }

  // Creation d'un nouveau RDV
  const handleSelectSlot = useCallback(
    ({ start, end }: any) => {
      setnewModalIsOpen(true);
      setstartNewEvent(start);
      const diffInMinutes = dayjs(end).diff(dayjs(start), "minutes");

      setTimeDuration(diffInMinutes)
      setstartTimeValue(dayjs(start))
      setEndTimeValue(dayjs(end))
      setendNewEvent(end);
      setCurrentAppointment({
        ...currentAppointment, start: start, end: end, startHour: `${dayjs(start).hour()}: ${dayjs(start).minute()}`, endHour: `${dayjs(end).hour()}: ${dayjs(end).minute()}`
      })

    },
    []
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCurrentAppointment({
      ...currentAppointment,
      [name]: value
    });
  };
  function changeDate(value: Dayjs, type: string) {
    if (type == "start") {
      let endDate = value
      setCurrentAppointment({
        ...currentAppointment,
        start: new Date(value.toDate()),
      })
    }
    if (type == "end") {
      setCurrentAppointment({
        ...currentAppointment,
        end: new Date(value.toDate())
      })
    }
  }
  function changeHour(value: Dayjs, type: string) {

    console.log("value", value, typeof value,)
    if (type == "start") {
      let end = value.add(TimeDuration ? TimeDuration : 0, "minutes")
      setEndTimeValue(end)
      setCurrentAppointment({
        ...currentAppointment,
        startHour: `${value.hour()}: ${value.minute()}`,
        endHour: `${end.hour()}: ${end.minute()}`
      })

    }
    if (type == "end") {
      setCurrentAppointment({
        ...currentAppointment,
        endHour: `${value.hour()}: ${value.minute()}`
      })
      const diffInMinutes = value.diff(dayjs(startTimeValue), "minutes");

      setTimeDuration(diffInMinutes)
    }
  }


  //Modification d'un rdv
  function editAppointment() {
    setEditModalIsOpen(false);
    setnewModalIsOpen(true);
    setisEdit(true)
  }

  // Ouvre la modale de validation pour la suppression d'un RDV
  function validationDelete() {
    setvalidateModalIsOpen(true);
  }

  // Appelle les fonctions pour supprimer le RDV et recharger la page
  async function callDelete() {
    if (currentAppointment?.id) {
      setisLoading(false)
      let response = await calendarService.deleteAppointment(currentAppointment.id, acc_token);
      if (response && in200s(await response)) {
        setIsSuccess(true)
        setisLoading(false)
      } else {
        setisError(true)
        setisLoading(false)
      }
    }
    emptyAppointmentFields()
    setrefresh(!refresh);

  }

  function formatParticipants(participants: any[]) {
    let participantsIds = []
    for (let index = 0; index < participants.length; index++) {
      const element = participants[index];
      participantsIds.push(element["id"])
    }
    return participantsIds
  }

  // Gestion des données lors de l'ajout ou de la modification d'un RDV
  // Vérification des champs obligatoires
  // Creation d'un objet qui récupère tous les paramètres
  // Envoyé au put si c'est une modification et au post si c'est une creation
  async function handleDataNewAppointment() {

    let appt = { title: '', description: '', type: '', place: '', startDateTime: '', endDateTime: '', userId: '' };
    const errors = { title: '', description: '', type: '', place: '', start: '', end: '' };
    let isErrorTest = false;
    console.log("currentAppointment", currentAppointment)
    if ('title' in currentAppointment) {
      errors.title = currentAppointment.title.length > 0 ? '' : 'Veuillez saisir un titre';
    }
    if ('type' in currentAppointment) {
      errors.type = currentAppointment.type != "" ? '' : 'Veuillez saisir un type';
    }
    if ('start' in currentAppointment) {
      errors.start = currentAppointment.start != null ? '' : 'Veuillez choisir une date de début';
    }
    if ('end' in currentAppointment) {
      errors.end = currentAppointment.end != null ? '' : 'Veuillez choisir une date de fin';
    }
    if ('startHour' in currentAppointment) {
      errors.start = currentAppointment.start != null ? '' : 'Veuillez choisir une heure de début';
    }
    if ('endHour' in currentAppointment) {
      errors.end = currentAppointment.end != null ? '' : 'Veuillez choisir une heure de fin';
    }

    let startHourAndMinutes = currentAppointment.startHour.split(":")
    let endHourAndMinutes = currentAppointment.endHour.split(":")

    let app_date_start = new Date(currentAppointment.start)
    let app_date_end = new Date(currentAppointment.start)

    let finalStartDate = moment(app_date_start).set('hour', Number(startHourAndMinutes[0])).set('minute', Number(startHourAndMinutes[1])).format()
    let finalEndDate = moment(app_date_end).set('hour', Number(endHourAndMinutes[0])).set('minute', Number(endHourAndMinutes[1])).format()


    if (finalStartDate > finalEndDate) {
      errors.end = 'Veuillez choisir une date de fin ultérieure à celle de début';
      isErrorTest = true;
    }
    console.log("errors", errors)
    let now = new Date()
    console.log("isError", isError)

    if (
      moment(now).format() > moment(app_date_end).format()
    ) {
      errors.end = "Veuillez choisir une date qui n'est pas déjà passée";
      isErrorTest = true;
    }

    if (Object.values(errors).every((x) => x === '')) {

      appt = {
        ...currentAppointment,
        startDateTime: finalStartDate,
        endDateTime: finalEndDate,
        userId: id!
      }
      let participantsIds = []
      for (let index = 0; index < participants.length; index++) {
        const element = participants[index];
        participantsIds.push(element["id"])
      }
      console.log("participantsIds", participantsIds)

      let finalDataToSend = {
        title: currentAppointment.title,
        description: currentAppointment.description,
        startDateTime: finalStartDate,
        endDateTime: finalEndDate,
        place: currentAppointment.place,
        type: currentAppointment.type,
        userId: id!,
        managerId: currentAppointment.managerId,
      }

      if (!isEdit) {
        setisLoading(true)
        //@ts-ignore
        // finalDataToSend = { ...finalDataToSend, participants: formatParticipants(participants) }
        finalDataToSend = { ...finalDataToSend, participants: [] }

        console.log("finalDataToSend", finalDataToSend)
        let response = await calendarService.postAppointment(finalDataToSend, acc_token);
        if (in200s(response)) {
          setIsSuccess(true)
          setisLoading(false)
          setrefresh(!refresh)
        } else {
          setisError(true)
          setisLoading(false)
        }
      }
      else {
        setisLoading(true)
        //@ts-ignore
        // finalDataToSend = { ...finalDataToSend, participants: formatParticipants(currentAppointment.participants) }
        finalDataToSend = { ...finalDataToSend, participants: [] }
        console.log("finalDataToSend", finalDataToSend)

        if (currentAppointment?.id) {
          let response = await calendarService.putAppointment(finalDataToSend, currentAppointment.id, acc_token);
          if (in200s(response)) {
            setIsSuccess(true)
            setisLoading(false)
            setrefresh(!refresh)
          } else {
            setisError(true)
            setisLoading(false)
          }
        }

      }
    }
    // setisError(isErrorTest);
    seterrorData(errors);
  }

  console.log("currentAppointment", currentAppointment)
  function emptyAppointmentFields() {
    setCurrentAppointment({
      title: "",
      description: "",
      start: new Date(),
      end: new Date(),
      createdBy: "",
      editedBy: "",
      id: "",
      place: "",
      type: "",
      userId: "",
      managerId: "",
      startHour: "",
      endHour: ""
    });
    setParticipants(participantsInitialValue)
  }

  // Formatage des données des RDV récupérées
  function formatRDV(rdv: []) {
    let title = '';
    let start;
    let end;
    let description = '';
    let createdBy = '';
    let editedBy = '';
    let id = '';
    let place = '';
    let type = '';
    let userId = '';
    let managerId = '';
    let participants: any[] = []

    let fetchedData = [] as Array<EventInterface>;
    for (let index = 0; index < rdv.length; index++) {
      title = rdv[index]['title'];
      start = new Date(rdv[index]['startDateTime']);
      end = new Date(rdv[index]['endDateTime']);
      description = rdv[index]['description'];
      createdBy = rdv[index]['createdBy'];
      editedBy = rdv[index]['editedBy'];
      id = rdv[index]['id'];
      place = rdv[index]['place'];
      type = rdv[index]['type'];
      userId = rdv[index]['userId'];
      // participants = rdv[index]['participants'];
      // let formattedParticipants: { name: string, id: any }[] = []
      // if (participants && participants.length > 0) {
      //   for (let index = 0; index < participants.length; index++) {
      //     const element = participants[index];
      //     formattedParticipants.push({ name: `${element.firstname} ${element.lastname}`, id: element.id })
      //   }
      // }

      // console.log("formattedParticipants", formattedParticipants)
      if (rdv[index]['Manager']) {
        managerId = rdv[index]['Manager']['id'];
      }

      fetchedData.push({
        title: title,
        start: start,
        end: end,
        description: description,
        createdBy: createdBy,
        editedBy: editedBy,
        id: id,
        place: place,
        type: type,
        userId: userId,
        managerId: managerId,
        startHour: "",
        endHour: "",
        // participants: formattedParticipants
      });
    }
    setmyEventsList(fetchedData);
  }
  // Ouverture des détails d'un RDV
  // Vérification si l'évent est dans le passé ou non pour gérer l'affichage des boutons Modifier et Supprimer
  const handleSelectEvent = useCallback((event: any) => {
    setEditModalIsOpen(true);
    console.log("event", event)
    let startHour = `${dayjs(event.start).hour()
      }:${dayjs(event.start).minute()} `
    let endHour = `${dayjs(event.end).hour()}:${dayjs(event.end).minute()} `

    setCurrentAppointment({ ...event, startHour: startHour, endHour: endHour });

    setstartTimeValue(event.start)
    setEndTimeValue(event.end)

    //Dispaly start hour and end Hour 

    const currentDate = new Date();

    if (event.end && currentDate > event.end) {
      setinThePast(true);
    } else {
      setinThePast(false);
    }
  }, []);

  // Formatage de la date pour l'affichage des détails d'un RDV
  function formatDate(date: Date, type?: string) {
    let newDate;
    newDate = moment(date).format('DD/MM/yyyy');
    let hour = moment(date).format('HH:mm');
    if (type && type == "hour") {
      return hour.toString();
    } else {
      return newDate.toString();
    }

  }

  //Appelle la fonction getRDV dès que les bornes (dates) changent
  // I.E : Quand on change de d'affichage (Mois/semaine/jour) ou que l'on change de date (avance d'une semaine, d'un mois etc.)
  useEffect(() => {
    getFormatedRDV()
  }, [startVisibleDate, endVisibleDate, refresh]);

  useEffect(() => {
    if (height) {
      setcalendarHeight(height * 0.7)
    }
  }, [height, width,])



  const handleChangeType = (event: SelectChangeEvent) => {
    setCurrentAppointment({
      ...currentAppointment
      , type: event.target.value
    });

  };

  const handleChangeManager = (event: SelectChangeEvent) => {
    setCurrentAppointment({
      ...currentAppointment
      , managerId: event.target.value
    });

  };
  async function getFormatedRDV() {

    const response = await calendarService.getRDV(startVisibleDate, endVisibleDate, id!, acc_token);
    formatRDV(response)
  }
  // Gestion des bornes d'affichage pour le get
  // Changement des valeurs dès que la view ou la date change
  function updateTimes(date: moment.Moment, view: string) {
    let start, end;
    if (view === 'day') {
      start = moment(date).startOf('day');
      end = moment(date).endOf('day');
    } else if (view === 'week') {
      start = moment(date).startOf('isoWeek');
      end = moment(date).endOf('isoWeek');
    } else if (view === 'month') {
      start = moment(date).startOf('month').subtract(7, 'days');
      end = moment(date).endOf('month').add(7, 'days');
    } else if (view === 'agenda') {
      start = moment(date).startOf('day');
      end = moment(date).endOf('day').add(1, 'month');
    }
    const finalStart = start?.toISOString();
    setstartVisibleDate(finalStart!);
    const finalEnd = end?.toISOString();
    setendVisibleDate(finalEnd!);
  }

  console.log("isEdit", isEdit)


  return (
    <PageWithHeader>
      <PageWrapper title={`Agenda de ${firstname} ${lastname} `} backBtnContainer={true}>
        <div style={{ width: '90%', marginLeft: '5%', marginTop: '2%', fontFamily: 'open sans' }}>
          <div
            style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold', marginBottom: '2%', fontFamily: 'open sans', color: "#035282" }}
          >

          </div>
          <Calendar
            localizer={localizer}
            events={myEventsList}
            startAccessor="start"
            endAccessor="end"
            defaultView="week"
            onNavigate={(date, view) => {
              const new_Date = moment(date);
              setcalendarDate(date)
              updateTimes(new_Date, view);
            }}
            onView={(view) => {
              const new_Date = moment(calendarDate);
              updateTimes(new_Date, view);
            }}
            scrollToTime={moment()
              .set({ h: 8, m: 0 })
              .toDate()}
            style={{ height: calendarHeight, zIndex: 0, fontFamily: 'open sans' }}
            messages={{
              next: 'Suivant',
              previous: 'Précédent',
              today: "Aujourd'hui",
              month: 'Mois',
              week: 'Semaine',
              day: 'Jour'
            }}
            onSelectSlot={handleSelectSlot}
            selectable
            onSelectEvent={handleSelectEvent}
            eventPropGetter={(event) => {
              let newStyle = {
                backgroundColor: 'lightgrey',
                color: 'black',
                border: 'none',
                fontFamily: 'open sans'
              };


              if (event.type == 'consultation') {
                newStyle.backgroundColor = '#18C5BF';
              }
              if (event.type == 'rdv_menage') {
                newStyle.backgroundColor = '#F4C274';
              }
              if (event.type == 'nursing') {
                newStyle.backgroundColor = '#18C5BF';
              }
              if (event.type == 'rdv_aidant') {
                newStyle.backgroundColor = '#F4C274';
              }
              if (event.type == 'measures') {
                newStyle.backgroundColor = '#F4C274';
              }
              if (event.type == 'other') {
                newStyle.backgroundColor = '#F4C274';
              }

              return {
                className: '',
                style: newStyle
              };
            }}
          />

          {/* MODALE D'AFFICHAGE DES RDV */}
          <Dialog
            open={editModalIsOpen}
            onClose={() => { closeModal(); console.log("Closing modal d'ajout") }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ fontFamily: 'open sans', zIndex: 99 }}
            fullWidth
            maxWidth="sm"
          >
            <StyledDialogTitle sx={{}} id="customized-dialog-title">
              {currentAppointment && currentAppointment.title}
            </StyledDialogTitle>
            <Divider />
            <IconButton
              aria-label="close"
              onClick={() => closeModal()}
              sx={{
                position: 'absolute',
                right: 20,
                top: 20,
                color: colors.dark_blue
              }}
            >
              <CloseIcon />
            </IconButton>
            <StyledDialogContent>
              {/* <StyledDialogContentText>
                Date :  {currentAppointment && formatDate(currentAppointment.start)} jusqu'au{' '}
                {currentAppointment && formatDate(currentAppointment.end)}
              </StyledDialogContentText> */}
              <StyledFieldContainer>
                <StyledFieldName> Date :</StyledFieldName>
                <StyledDialogContentField>
                  {currentAppointment && `${formatDate(currentAppointment.start)} de ${formatDate(currentAppointment.start, "hour")} à ${formatDate(currentAppointment.end, "hour")} `}
                </StyledDialogContentField>
              </StyledFieldContainer>
              <StyledFieldContainer>
                <StyledFieldName> Description :</StyledFieldName>
                <StyledDialogContentField>
                  {currentAppointment && currentAppointment.description}
                </StyledDialogContentField>
              </StyledFieldContainer>
              <StyledFieldContainer>
                <StyledFieldName>Lieu :</StyledFieldName>
                <StyledDialogContentField>{currentAppointment && currentAppointment.place}</StyledDialogContentField>
              </StyledFieldContainer>
              <StyledFieldContainer>
                <StyledFieldName>Type :</StyledFieldName>

                {currentAppointment && currentAppointment.type === 'consultation' && (
                  <Chip
                    label="consultation"
                    size="medium"
                    style={{ width: 'auto', marginLeft: '2%', padding: '2%', backgroundColor: '#C2D4D8' }}
                    icon={<MeetingRoomIcon />}
                  />
                )}
                {currentAppointment && currentAppointment.type === 'nursing' && (
                  <Chip
                    label="Soin"
                    size="medium"
                    style={{ width: 'auto', marginLeft: '2%', padding: '2%', backgroundColor: '#A0FFE9' }}
                    icon={<HealthAndSafetyIcon />}
                  />
                )}
                {currentAppointment && currentAppointment.type === 'rdv_aidant' && (
                  <Chip
                    label="RDV Aidant"
                    size="medium"
                    style={{ width: 'auto', marginLeft: '2%', padding: '2%', backgroundColor: '#8CC8DB' }}
                    icon={<PeopleIcon />}
                  />
                )}
                {currentAppointment && currentAppointment.type === 'rdv_menage' && (
                  <Chip
                    label="RDV Menage"
                    size="medium"
                    style={{ width: 'auto', marginLeft: '2%', padding: '2%', backgroundColor: '#F4C274' }}
                    icon={<CleaningServicesIcon />}
                  />
                )}
                {currentAppointment && currentAppointment.type === 'measures' && (
                  <Chip
                    label="Mesures"
                    size="medium"
                    style={{ width: 'auto', marginLeft: '2%', padding: '2%', backgroundColor: '#FFF1BF' }}
                    icon={<ScaleIcon />}
                  />
                )}
                {currentAppointment &&
                  currentAppointment.type !== 'consultation' &&
                  currentAppointment.type !== 'nursing' &&
                  currentAppointment.type !== 'rdv_aidant' &&
                  currentAppointment.type !== 'rdv_menage' &&
                  currentAppointment.type !== 'measures' && (
                    <Chip
                      label="Autre"
                      size="medium"
                      style={{ width: 'auto', marginLeft: '2%', padding: '2%', backgroundColor: '#C1F5FF' }}
                    />
                  )}
              </StyledFieldContainer>
              {/* Hiding participants */}
              {/* {
                currentAppointment.participants && currentAppointment.participants.length > 0 &&
                <StyledFieldContainer>
                  <StyledFieldName>Participants :</StyledFieldName>
                  <StyledDialogContentField>{ }</StyledDialogContentField>
                  <Box style={{ width: "60%" }}>
                    {currentAppointment.participants.map((chip, index) => (
                      <Chip style={{ marginTop: "5px" }} key={index} label={`${chip['name']} `} />
                    ))}
                  </Box>
                </StyledFieldContainer>
              } */}

            </StyledDialogContent>
            <DialogActions>
              <ActionsWrapper>

                <Box style={{
                  display: "flex", flexDirection: "row"
                }}>
                  <CancelBtn onClick={closeModal} >
                    Fermer
                  </CancelBtn>

                  {/* {(inThePast || (currentUserRole != 'aidant' && currentUserRole != 'professionnel de santé')) && (
                    <CancelBtn onClick={validationDelete} autoFocus disabled>
                      Supprimer
                    </CancelBtn>
                  )} */}

                </Box>
                <Box style={{ display: "flex", flexDirection: "row", }}>
                  {!inThePast && (currentUserRole == 'aidant' || currentUserRole == 'professionnel de santé') && (
                    <TertiaryBtn onClick={validationDelete} autoFocus >
                      Supprimer
                    </TertiaryBtn>
                  )}
                  {!inThePast && (currentUserRole == 'aidant' || currentUserRole == 'professionnel de santé') && (
                    <ConfirmBtn onClick={editAppointment} autoFocus>
                      Modifier
                    </ConfirmBtn>
                  )}
                  {inThePast || (currentUserRole != 'aidant' && currentUserRole != 'professionnel de santé') && (
                    <ConfirmBtn onClick={editAppointment} autoFocus disabled>
                      Modifier
                    </ConfirmBtn>
                  )}
                </Box>


              </ActionsWrapper>
            </DialogActions>
          </Dialog>



          {/* MODALE DE SAISIE POUR LA CREATION/MODIFICATIONS DES RDV */}
          <Dialog
            open={newModalIsOpen}
            onClose={() => { closeNewModal(); console.log("Closing edit modal") }}
            style={{ fontFamily: 'open sans', zIndex: 99 }}
            fullWidth
            maxWidth="md"
          >

            <StyledDialogTitle>
              Saisir les détails
            </StyledDialogTitle>
            <Divider />
            <IconButton
              aria-label="close"
              onClick={() => closeNewModal()}
              sx={{
                position: 'absolute',
                right: 20,
                top: 20,
                color: colors.dark_blue
              }}
            >
              <CloseIcon />
            </IconButton>
            {!isError && !isSuccess && !isLoading &&
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StyledDialogContent>
                  {/* <StyledDialogContentText>
                    Veuillez ajouter les spécifications de votre rendez-vous. Vous devez renseigner le titre, les dates de début et de fin et le type.
                  </StyledDialogContentText> */}
                  {/* Handling start & end dates */}
                  <Box style={{
                    display: "flex",
                    flexDirection: "row", justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "15px",
                    marginBottom: "15px"
                  }}>
                    <Box style={{ width: "50%", display: "flex", flexDirection: "row", zIndex: 100 }} >
                      {currentAppointment && (

                        <DatePicker
                          className="custom-datepicker"
                          locale="fr"
                          selected={currentAppointment['start']}
                          onChange={(newValue: string | number | Date | dayjs.Dayjs | null | undefined) => {
                            changeDate(newValue ? dayjs(newValue) : dayjs(new Date()), "start")
                          }}
                          dateFormat="dd/MM/yyyy"
                          customInput={
                            <TextField className={classes.root}
                              sx={{
                                width: "100%",
                                ...customSx
                              }}
                              label="Date"
                              fullWidth
                              InputProps={{ // Add InputProps prop to customize input properties
                                endAdornment: (
                                  <IconButton>
                                    <CalendarIcon />
                                  </IconButton>
                                ),
                              }}

                            />
                          }
                        />
                      )}

                      {!currentAppointment && (

                        <DatePicker
                          className="custom-datepicker"
                          locale="fr"
                          dateFormat="dd/MM/yyyy"
                          selected={currentAppointment['start']}
                          onChange={(newValue) => {
                            changeDate(newValue ? dayjs(newValue!) : dayjs(new Date()), "start")
                          }}

                          customInput={
                            <TextField className={classes.root}
                              sx={{
                                width: "100%",
                                ...customSx

                              }}
                              label="Date"
                              fullWidth
                              InputProps={{ // Add InputProps prop to customize input properties
                                endAdornment: (
                                  <IconButton>
                                    <CalendarIcon />
                                  </IconButton>
                                ),
                              }}

                            />
                          }
                        />
                      )}

                    </Box>

                    <Box style={{ width: "50%", display: "flex", flexDirection: "row", justifyContent: "space-around" }} >
                      <Box style={{ width: "20%", justifyContent: "center", flexDirection: "row", display: "inline-flex" }}>
                        <Divider orientation="vertical" variant="inset" flexItem style={{ margin: '0 5px', height: '50px', alignItems: "center" }} />

                      </Box>

                      <Box style={{ width: "80%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <Box style={{ width: "45%" }}>
                          {currentAppointment && (
                            <>
                              <TimePicker
                                ampm={false}
                                label="Heure de début"
                                openTo="hours"
                                views={['hours', 'minutes',]}
                                format="HH:mm"
                                value={dayjs(startTimeValue)}
                                onChange={(newValue) => {
                                  setstartTimeValue(newValue);
                                  console.log("newValue", newValue)
                                  changeHour(newValue ? newValue : dayjs(), "start")
                                }}
                                sx={customSx}
                              />
                            </>
                          )}
                          {!currentAppointment && (
                            <TimePicker
                              ampm={false}
                              openTo="hours"
                              views={['hours', 'minutes',]}
                              format="HH:mm"
                              // mask="__:__:__"
                              label="Heure de début"
                              value={startTimeValue}
                              onChange={(newValue) => {
                                setstartTimeValue(newValue);
                                changeHour(newValue ? newValue : dayjs(), "start")
                              }}
                              sx={customSx}
                            />
                          )}
                        </Box>
                        <Box style={{ width: "45%" }}>
                          <TimePicker
                            ampm={false}
                            openTo="hours"
                            views={['hours', 'minutes',]}
                            format="HH:mm"
                            // mask="__:__:__"
                            label="Heure de fin"
                            value={dayjs(endTimeValue)}
                            onChange={(newValue) => {
                              setEndTimeValue(newValue);
                              changeHour(newValue ? newValue : dayjs(), "end")
                            }}
                            sx={customSx}
                          />
                        </Box>
                      </Box>
                    </Box>

                  </Box>
                  <div style={customStyles.alert}>

                    {errorData.end}
                  </div>
                  <InputBlueBottom
                    type="text"
                    name="title"
                    value={currentAppointment.title}
                    onChange={(e) => handleChange(e)}
                    placeholder="Titre"
                  />
                  <div style={customStyles.alert}>{errorData.title}</div>
                  {
                    isEdit && currentAppointment.type == "rdv_aidant" ?
                      <TextField
                        className={classes.root}
                        id="outlined-read-only-input"
                        label="Type"
                        defaultValue="Aidant"
                        InputProps={{
                          readOnly: true,
                        }}
                      /> :
                      <CustomSelect
                        value={currentAppointment.type}
                        onChange={handleChangeType}
                        label="Type"
                        items={eventTypes}
                      />

                  }
                  <div style={customStyles.alert}>{errorData.type}</div>

                  {/* hiding appointment participants  */}
                  {/* <Autocomplete
                    multiple
                    id="fixed-tags-demo"
                    value={isEdit ? currentAppointment.participants : participants}
                    onChange={(event, newValue) => {

                      if (isEdit) {
                        // currentAppointment.participants
                        setCurrentAppointment({
                          ...currentAppointment, participants: [
                            ...fixedOptions,
                            ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
                          ]
                        })
                      }
                      else {
                        setParticipants([
                          ...fixedOptions,
                          ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
                        ]);
                      }
                      console.log("participants", participants)

                    }}
                    options={participantsOptions.length > 0 ? participantsOptions : []}
                    getOptionLabel={(option) => option.name}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option.name}
                          {...getTagProps({ index })}
                          disabled={fixedOptions.indexOf(option) !== -1}
                        />
                      ))
                    }
                    style={{ width: "100%", marginTop: "15px", marginBottom: "10px" }}
                    renderInput={(params) => (
                      <TextField className={classes.root}
                        sx={{
                          width: "100%",
                          ...customSx
                        }}
                        {...params} label="Participants" placeholder="Participants" fullWidth />

                    )}
                  /> */}
                  <InputBlueBottom
                    type="text"
                    name="description"
                    value={currentAppointment.description}
                    onChange={(e) => handleChange(e)}
                    placeholder="Description"
                  />

                  <InputBlueBottom
                    type="text"
                    name="place"
                    value={currentAppointment.place}
                    onChange={(e) => handleChange(e)}
                    placeholder="Lieu"
                  />

                  <div style={customStyles.alert}>{errorData.place}</div>


                  {
                    currentAppointment.type === "rdv_aidant" &&
                    <CustomSelect
                      value={currentAppointment.managerId}
                      onChange={handleChangeManager}
                      label="Aidant"
                      items={formattedManagersList}
                    />
                  }

                  {/* <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
                    
                  </Box> */}


                  <div style={customStyles.alert}>{isError && errorData.end}</div>
                </StyledDialogContent>
              </LocalizationProvider>
            }
            {
              isLoading && <Box sx={{ padding: "50px 0px" }}>
                <SpinnerComponent />
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
                </Box>
              </Box>
            }
            {
              isSuccess && <Box sx={{ padding: "50px 0px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>{isEdit ? "Rendez-vous modifié avec succès." : "Rendez-vous ajouté avec succès."}</StyledDialogMessage>
                </Box>
              </Box>
            }
            {
              isError && <Box sx={{ padding: "50px 0px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer.</StyledDialogMessage>
                </Box>
              </Box>
            }
            <DialogActions>
              <StyledDialogActions>
                <ActionsWrapper>
                  <CancelBtn onClick={closeNewModal} autoFocus
                  >
                    {!isError && !isSuccess && !isLoading ? "Annuler" : "Fermer"}
                  </CancelBtn>

                  {!isError && !isSuccess && !isLoading && <>
                    {/* edit */}
                    {currentAppointment && (
                      <ConfirmBtn onClick={() => handleDataNewAppointment()} autoFocus
                      >
                        Valider
                      </ConfirmBtn>
                    )}

                    {/* !edit */}
                    {!currentAppointment && (currentUserRole == 'aidant' || currentUserRole == 'professionnel de santé') && (
                      <ConfirmBtn onClick={() => handleDataNewAppointment()} autoFocus
                      >
                        Valider
                      </ConfirmBtn>
                    )}

                    {!currentAppointment && (currentUserRole != 'aidant' && currentUserRole != 'professionnel de santé') && (
                      <ConfirmBtn onClick={() => handleDataNewAppointment()} autoFocus
                        disabled>
                        Valider
                      </ConfirmBtn>
                    )}
                  </>}
                </ActionsWrapper>
              </StyledDialogActions>
            </DialogActions>
          </Dialog>


          {/* MODALE DE VALIDATION DE LA SUPPRESSION DU RDV */}
          <Dialog
            open={validateModalIsOpen}
            onClose={() => { closeValidateModal(); console.log("Closing validation delete modal") }}
            maxWidth="md"
            style={{ zIndex: 99 }}>

            <StyledDialogTitle  >
              Suppression RDV
            </StyledDialogTitle>
            <Divider />
            <IconButton
              aria-label="close"
              onClick={() => closeValidateModal()}
              sx={{
                position: 'absolute',
                right: 20,
                top: 20,
                color: colors.dark_blue
              }}
            >
              <CloseIcon />
            </IconButton>

            <StyledDialogContent>
              {!isSuccess && !isError && !isLoading && <StyledDialogContentText>
                Êtes-vous sûr de vouloir supprimer cet événement ?
              </StyledDialogContentText>}
              {
                isLoading && <Box sx={{ padding: "50px 0px" }}>
                  <SpinnerComponent />
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                    <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
                  </Box>
                </Box>
              }
              {
                isSuccess && <Box sx={{ padding: "50px 0px" }}>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                    <StyledDialogMessage>Rendez-vous supprimé avec succès.</StyledDialogMessage>
                  </Box>
                </Box>
              }
              {
                isError && <Box sx={{ padding: "50px 0px" }}>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                    <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer.</StyledDialogMessage>
                  </Box>
                </Box>
              }
            </StyledDialogContent>
            <DialogActions>
              <ActionsWrapper>

                <CancelBtn onClick={closeValidateModal} autoFocus>
                  {!isSuccess && !isError && !isLoading ?
                    "Annuler" : "Fermer"}
                </CancelBtn>

                {!isSuccess && !isError && !isLoading && <ConfirmBtn onClick={() => callDelete()} autoFocus>
                  Valider
                </ConfirmBtn>}
              </ActionsWrapper>
            </DialogActions>
          </Dialog>
        </div>
      </PageWrapper>
    </PageWithHeader>
  );
}

export default CalendarPage;
const top100Films = [
  { name: 'The Shawshank Redemption', id: 1994 },
  { name: 'The Godfather', id: 1972 },
  { name: 'The Godfather: Part II', id: 1974 },
  { name: 'The Dark Knight', id: 2008 },
  { name: '12 Angry Men', id: 1957 },
  { name: "Schindler's List", id: 1993 },
  { name: 'Pulp Fiction', id: 1994 },
  {
    name: 'The Lord of the Rings: The Return of the King',
    id: 2003,
  },
  { name: 'The Good, the Bad and the Ugly', id: 1966 },
  { name: 'Fight Club', id: 1999 },
  {
    name: 'The Lord of the Rings: The Fellowship of the Ring',
    id: 2001,
  },
  {
    name: 'Star Wars: Episode V - The Empire Strikes Back',
    id: 1980,
  },
  { name: 'Forrest Gump', id: 1994 },
  { name: 'Inception', id: 2010 },
  {
    name: 'The Lord of the Rings: The Two Towers',
    id: 2002,
  },
  { name: "One Flew Over the Cuckoo's Nest", id: 1975 },
  { name: 'Goodfellas', id: 1990 },
  { name: 'The Matrix', id: 1999 },
  { name: 'Seven Samurai', id: 1954 },
  {
    name: 'Star Wars: Episode IV - A New Hope',
    id: 1977,
  },
  { name: 'City of God', id: 2002 },
  { name: 'Se7en', id: 1995 },
  { name: 'The Silence of the Lambs', id: 1991 },
  { name: "It's a Wonderful Life", id: 1946 },
  { name: 'Life Is Beautiful', id: 1997 },
  { name: 'The Usual Suspects', id: 1995 },
  { name: 'Léon: The Professional', id: 1994 },
  { name: 'Spirited Away', id: 2001 },
  { name: 'Saving Private Ryan', id: 1998 },
  { name: 'Once Upon a Time in the West', id: 1968 },
  { name: 'American History X', id: 1998 },
  { name: 'Interstellar', id: 2014 },
  { name: 'Casablanca', id: 1942 },
  { name: 'City Lights', id: 1931 },
  { name: 'Psycho', id: 1960 },
  { name: 'The Green Mile', id: 1999 },
  { name: 'The Intouchables', id: 2011 },
  { name: 'Modern Times', id: 1936 },
  { name: 'Raiders of the Lost Ark', id: 1981 },
  { name: 'Rear Window', id: 1954 },
  { name: 'The Pianist', id: 2002 },
  { name: 'The Departed', id: 2006 },
  { name: 'Terminator 2: Judgment Day', id: 1991 },
  { name: 'Back to the Future', id: 1985 },
  { name: 'Whiplash', id: 2014 },
  { name: 'Gladiator', id: 2000 },
  { name: 'Memento', id: 2000 },
  { name: 'The Prestige', id: 2006 },
  { name: 'The Lion King', id: 1994 },
  { name: 'Apocalypse Now', id: 1979 },
  { name: 'Alien', id: 1979 },
  { name: 'Sunset Boulevard', id: 1950 },
  {
    name: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
    id: 1964,
  },
  { name: 'The Great Dictator', id: 1940 },
  { name: 'Cinema Paradiso', id: 1988 },
  { name: 'The Lives of Others', id: 2006 },
  { name: 'Grave of the Fireflies', id: 1988 },
  { name: 'Paths of Glory', id: 1957 },
  { name: 'Django Unchained', id: 2012 },
  { name: 'The Shining', id: 1980 },
  { name: 'WALL·E', id: 2008 },
  { name: 'American Beauty', id: 1999 },
  { name: 'The Dark Knight Rises', id: 2012 },
  { name: 'Princess Mononoke', id: 1997 },
  { name: 'Aliens', id: 1986 },
  { name: 'Oldboy', id: 2003 },
  { name: 'Once Upon a Time in America', id: 1984 },
  { name: 'Witness for the Prosecution', id: 1957 },
  { name: 'Das Boot', id: 1981 },
  { name: 'Citizen Kane', id: 1941 },
  { name: 'North by Northwest', id: 1959 },
  { name: 'Vertigo', id: 1958 },
  {
    name: 'Star Wars: Episode VI - Return of the Jedi',
    id: 1983,
  },
  { name: 'Reservoir Dogs', id: 1992 },
  { name: 'Braveheart', id: 1995 },
  { name: 'M', id: 1931 },
  { name: 'Requiem for a Dream', id: 2000 },
  { name: 'Amélie', id: 2001 },
  { name: 'A Clockwork Orange', id: 1971 },
  { name: 'Like Stars on Earth', id: 2007 },
  { name: 'Taxi Driver', id: 1976 },
  { name: 'Lawrence of Arabia', id: 1962 },
  { name: 'Double Indemnity', id: 1944 },
  {
    name: 'Eternal Sunshine of the Spotless Mind',
    id: 2004,
  },
  { name: 'Amadeus', id: 1984 },
  { name: 'To Kill a Mockingbird', id: 1962 },
  { name: 'Toy Story 3', id: 2010 },
  { name: 'Logan', id: 2017 },
  { name: 'Full Metal Jacket', id: 1987 },
  { name: 'Dangal', id: 2016 },
  { name: 'The Sting', id: 1973 },
  { name: '2001: A Space Odyssey', id: 1968 },
  { name: "Singin' in the Rain", id: 1952 },
  { name: 'Toy Story', id: 1995 },
  { name: 'Bicycle Thieves', id: 1948 },
  { name: 'The Kid', id: 1921 },
  { name: 'Inglourious Basterds', id: 2009 },
  { name: 'Snatch', id: 2000 },
  { name: '3 Idiots', id: 2009 },
  { name: 'Monty Python and the Holy Grail', id: 1975 },
];
