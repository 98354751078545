import styled from '@emotion/styled';
import { Table, TableCell, tableCellClasses, TableRow } from '@mui/material';

export const StyledTableCell = styled(TableCell)(({}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#8CC8DB',
    color: '#FFFFFF',
    fontWeight: 'bold',
    fontSize: 18
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    borderBottom: '1px solid #F4C274',
    color: '#035282',
    fontFamily: 'open sans',
    maxWidth: '150px',
    wordWrap: 'break-word'
    // overflowWrap: 'break-word'
  }
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: '1px solid #C2D4D8',
  '&:hover': {
    backgroundColor: '#f5f5f5' // Change the background color on hover
  }
}));

export const StyledTable = styled(Table)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    minWidth: 70,
    border: '1px solid #8CC8DB'
  }
}));
