import PageWithHeader from 'components/ReusableDivs/PageWithHeader';
import PageWrapper from 'components/ReusableDivs/PageWrapper';
import React, { useEffect, useState } from 'react';
import { preventionsService } from 'services/preventionsService';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import NoData from 'components/ReusableDivs/NoData';
import Empty from 'components/ReusableDivs/Empty';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import dayjs, { Dayjs } from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import styled from 'styled-components';
import { StylesDictionary } from 'screens/calendar/CalendarPage';
import DeleteIcon from '@mui/icons-material/Delete';
import { DialogContent } from '@mui/material';
import { StyledTable, StyledTableCell } from 'styles/tables/TableStyles';
import { ContentWrapper } from 'styles/pages/pageStyles';
import "../../styles/tables/tableStyles.css"
import "../../styles/dialogs/dialogs.css"
import { CancelBtn, ConfirmBtn, StyledDialogActions, StyledDialogContent, StyledDialogContentText, StyledDialogTitle, } from 'styles/dialogs/DialogStyles';
import { useSelector } from 'react-redux';

const DatePickerWrapper = styled.div`
  padding: 3px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding-right: 50px;
  padding-left: 20px;
`;
const FieldName = styled.h1`
  font-size: 15px;
  padding-right: 15px;
  min-width: 150px;
`;
export const customStyles: StylesDictionary = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99
  },
  input: {
    width: '70%',
    fontSize: 15,
    fontFamily: 'open sans',
    right: 0
  },
  labels: {
    maxWidth: 400,
    width: '30%',
    fontSize: 18,
    flexGrow: 1,
    fontWeight: '400'
  },
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10
  },
  alert: {
    color: 'red',
    textAlign: 'left',
    width: '70%',
    marginLeft: '30%'
  },
  DisplayDates: {
    width: '60%',
    fontSize: 15,
    fontFamily: 'open sans',
    right: 0
  },
};

function Preventions() {
  const [preventions, setPreventions] = useState([]);
  const handleOpen = () => setOpen(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [type, setType] = useState('');
  const [seletedPrevention, setseletedPrevention] = useState("")
  const [refresh, setrefresh] = useState(false);
  const [pushDate, setPushDate] = React.useState<Dayjs | null>(dayjs(new Date()));;
  const [expirationDate, setExpirationDate] = React.useState<Dayjs | null>(dayjs(new Date("2023-02-20T08:05:33.705Z")));
  const [Update, setUpdate] = useState(false)
  const [patientGenderFemme, setPatientGenderFemme] = useState(false);
  const [patientGenderHomme, setPatientGenderHomme] = useState(false);
  const [URL, setURL] = useState('');
  const [preventionToDelete, setpreventionToDelete] = useState("")
  const [openDelete, setOpenDelete] = useState(false)
  const [preventionData, setPreventionData] = useState({
    title: '',
    description: '',
    type: '',
    pushDate: '',
    expirationDate: '',
    contentURL: '',
    patientAgeMin: '',
    patientAgeMax: '',
    patientGender: ''
  });

  const acc_token = useSelector((state: any) => state.userDomisante.userToken);


  const selectPrevention = (id: string) => {
    setUpdate(true)
    const preventionToUpdate = preventions.find(element => element['uuid'] == id);
    if (preventionToUpdate) {
      let intermediatePreventionObject = {
        title: preventionToUpdate['title'],
        description: preventionToUpdate['description'],
        pushDate: preventionToUpdate['pushDate'],
        expirationDate: preventionToUpdate['expirationDate'],
        contentURL: preventionToUpdate['contentURL'],
        patientAgeMin: preventionToUpdate['patientAgeMin'] != null ? preventionToUpdate['patientAgeMin'] : "",
        patientAgeMax: preventionToUpdate['patientAgeMax'] != null ? preventionToUpdate['patientAgeMax'] : "",
        patientGender: preventionToUpdate['patientGender'],
        type: type
      }
      setPreventionData(intermediatePreventionObject)
      setPushDate(dayjs(new Date(preventionToUpdate['pushDate'])))
      setExpirationDate(dayjs(new Date(preventionToUpdate['expirationDate'])))

      if (preventionToUpdate['patientGender'] === "Femme") {
        setPatientGenderFemme(true)
      }
      if (preventionToUpdate['patientGender'] === "Homme") {
        setPatientGenderHomme(true)
      }
      if (preventionToUpdate['patientGender'] == null) {
        setPatientGenderHomme(true)
        setPatientGenderFemme(true)
      }
      preventionToUpdate['type'] == "formulaire" ? setType("formulaire") : setType("information")

    }
  }

  const handlePreventionData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPreventionData({
      ...preventionData,
      [name]: value
    });
  };

  const handleExpChange = (newValue: Dayjs | null) => {
    setExpirationDate(newValue);
    setPreventionData({
      ...preventionData,
      ['expirationDate']: newValue!.format()
    });
  };
  const handlePushChange = (newValue: Dayjs | null) => {
    setPushDate(newValue);
    setPreventionData({
      ...preventionData,
      ['pushDate']: newValue!.format()
    });
  };
  const handleClickOpen = () => {
    setOpen(true);
    setrefresh(!refresh);
  };

  const handleAddPrevention = () => {
    preventionsService.addNewPrevention(preventionData, acc_token);
    handleClose();
    setrefresh(!refresh);
    setPreventionData({
      title: '',
      description: '',
      type: '',
      pushDate: '',
      expirationDate: '',
      contentURL: '',
      patientAgeMin: '',
      patientAgeMax: '',
      patientGender: ''
    });
    window.location.reload();
  };

  const handleClose = () => setOpen(false);
  const handleCloseDelete = () => setOpenDelete(false);


  type conditions = { [key: string]: string };
  const updatePreventionData = () => {
    const updateDetails: conditions = {};
    if (preventionData.patientAgeMin !== "") {
      updateDetails.patientAgeMin = preventionData.patientAgeMin
    }
    if (preventionData.patientAgeMax !== "") {
      updateDetails.patientAgeMax = preventionData.patientAgeMin
    }

    preventionsService.updatePrevention(seletedPrevention, preventionData, acc_token);

    handleClose();
    setrefresh(!refresh);
    setPreventionData({
      title: '',
      description: '',
      type: '',
      pushDate: '',
      expirationDate: '',
      contentURL: '',
      patientAgeMin: '',
      patientAgeMax: '',
      patientGender: ''
    });
    window.location.reload();
  }


  const deletePreventionData = () => {
    setOpenDelete(false)
    setpreventionToDelete('')
    preventionsService.deletePrevention(preventionToDelete, acc_token);
    window.location.reload();
  }


  const StyledWrapper = styled.div`
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    `
  useEffect(() => {
    preventionsService.getPreventions(setPreventions, acc_token);
    if (preventions.length === 0) {
      setIsEmpty(true);
    }

    if ((patientGenderFemme && patientGenderHomme) || (!patientGenderFemme && !patientGenderHomme)) {
      setPreventionData({
        ...preventionData,
        patientGender: ''
      });
    }
    if (patientGenderFemme && !patientGenderHomme) {
      setPreventionData({
        ...preventionData,
        patientGender: 'Femme'
      });
    }
    if (!patientGenderFemme && patientGenderHomme) {
      setPreventionData({
        ...preventionData,
        patientGender: 'Homme'
      });
    }
    if (type !== '') {
      setPreventionData({
        ...preventionData,
        type: type
      });
    }
    if (URL !== '') {
      setPreventionData({
        ...preventionData,
        contentURL: URL
      });
    }
  }, [pushDate, expirationDate, patientGenderHomme, patientGenderFemme, type, URL, preventions.length, refresh, Update]);

  return (
    <PageWithHeader>
      <PageWrapper title="Liste des préventions" btnTitle="Ajouter une prévention" btnClick={() => handleClickOpen()}>
        <ContentWrapper>
          <StyledWrapper >
            {preventions.length > 0 && (
              <TableContainer>
                <StyledTable size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">
                        Titre
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Description
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Age Min.
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Age Max.
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Genre
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Type
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        URL du contenu
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Date de notification
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Date d'expiration
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        N. bénéficiaires touchés
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Actions
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {preventions.map((row) => (
                      <TableRow key={row['id']}>
                        <StyledTableCell align="left">
                          {row['title']}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="left">
                          {row['description']}
                        </StyledTableCell>

                        <StyledTableCell component="th" scope="row" align="center">
                          {row['patientAgeMin'] !== null ? (
                            row['patientAgeMin']
                          ) : (
                            <div className='not-provided-data'> non spécifié</div>
                          )}
                        </StyledTableCell>

                        <StyledTableCell component="th" scope="row" align="center">
                          {row['patientAgeMax'] !== null ? (
                            row['patientAgeMax']
                          ) : (
                            <div className='not-provided-data'> non spécifié</div>
                          )}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="center">
                          {row['patientGender'] !== null ? (
                            row['patientGender']
                          ) : (
                            <div className='not-provided-data'> non spécifié</div>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">{row['type']}</StyledTableCell>
                        <StyledTableCell align="center">{row['contentURL']}</StyledTableCell>
                        <StyledTableCell align="center">{dayjs(row['pushDate']).format("DD-MM-YYYY HH:mm")}</StyledTableCell>
                        <StyledTableCell align="center">{dayjs(row['expirationDate']).format("DD-MM-YYYY HH:mm")}</StyledTableCell>
                        <StyledTableCell align="center">{row['patients']}</StyledTableCell>

                        <StyledTableCell component="th" scope="row" align="center">
                          <IconButton
                            onClick={() => {
                              handleOpen();
                              selectPrevention(row['uuid']);
                              setseletedPrevention(row['uuid'])
                            }}
                          >
                            <ModeEditIcon style={{ color: '#18C5BF' }} />
                          </IconButton>

                          <IconButton
                            onClick={() => {
                              setOpenDelete(true)
                              setpreventionToDelete(row['uuid'])
                            }}
                          >
                            <DeleteIcon style={{ color: '#035282' }} />
                          </IconButton>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </TableContainer>
            )}
          </StyledWrapper>

          {preventions.length === 0 && !isEmpty && <NoData />}
          {preventions.length === 0 && isEmpty && <Empty />}
        </ContentWrapper>
        <div>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
            <Dialog
              style={{ zIndex: 99 }}
              open={open} onClose={handleClose} fullWidth maxWidth="lg" >
              {Update ?
                <StyledDialogTitle className='dialog-style'  >
                  Modifier une prévention
                </StyledDialogTitle> :

                <StyledDialogTitle className='dialog-style'>
                  Ajouter une prévention
                </StyledDialogTitle>}

              <div>
                <div style={{ paddingRight: "15px" }}>
                  {Update ?
                    <StyledDialogContentText className='dialog-content-text'>
                      Veuillez modifier les spécifications de votre prévention

                    </StyledDialogContentText> :
                    <StyledDialogContentText className='dialog-content-text'>
                      Veuillez ajouter les spécifications de votre prévention.
                    </StyledDialogContentText>}
                </div>
                <div style={{ paddingLeft: '0px' }}>
                  <DatePickerWrapper>
                    <FieldName style={customStyles.labels}>Date de notification:</FieldName>
                    <DateTimePicker
                      value={pushDate ? dayjs(pushDate.toDate()) : null}
                      onChange={handlePushChange}
                      sx={{
                        '& label.Mui-focused': {
                          color: '#035282',
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: '#035282',
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: '#035282',
                          },
                        },
                        '& .MuiInputBase-input': {

                          fontFamily: [
                            'open sans',

                          ].join(','),

                        },

                        '& .MuiIconButton-root.MuiInputAdornment-root.MuiInputAdornment-positionEnd:not(.Mui-disabled)::before': {
                          display: 'none',
                        },
                      }}
                      minDate={pushDate ? pushDate : undefined}
                      showDaysOutsideCurrentMonth
                    />

                  </DatePickerWrapper>
                  <DatePickerWrapper>
                    <FieldName style={customStyles.labels}>Date d'expiration :</FieldName>
                    <DateTimePicker
                      value={dayjs(expirationDate?.toDate())}
                      onChange={handleExpChange}
                      sx={{
                        '& label.Mui-focused': {
                          color: '#035282',
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: '#035282',
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: '#035282',
                          },
                        },
                        '& .MuiInputBase-input': {

                          fontFamily: [
                            'open sans',

                          ].join(','),

                        },

                        '& .MuiIconButton-root.MuiInputAdornment-root.MuiInputAdornment-positionEnd:not(.Mui-disabled)::before': {
                          display: 'none',
                        },
                      }}
                      minDate={pushDate ? pushDate : undefined}
                      showDaysOutsideCurrentMonth
                    />
                  </DatePickerWrapper>
                  <DatePickerWrapper>
                    <FieldName style={customStyles.labels}>Titre : </FieldName>
                    <TextField
                      autoFocus
                      margin="dense"
                      type="text"
                      style={customStyles.input}
                      variant="outlined"
                      value={preventionData.title}
                      name="title"
                      onChange={handlePreventionData}
                      InputProps={{
                        style: { height: '40px', color: '#035282' }
                      }}
                    />
                  </DatePickerWrapper>
                  <DatePickerWrapper>
                    <FieldName style={customStyles.labels}>Description : </FieldName>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      type="text"
                      value={preventionData.description}
                      onChange={handlePreventionData}
                      style={customStyles.input}
                      variant="outlined"
                      multiline
                      name="description"
                      InputProps={{
                        style: { minHeight: '40px', color: '#035282' }
                      }}
                    />

                  </DatePickerWrapper>
                  <DialogContentText className='dialog-content-text'>
                    Bénéficiaires cibles :
                  </DialogContentText>

                  <DatePickerWrapper>
                    <FieldName style={customStyles.labels}>Genre : </FieldName>
                    <div style={{ width: '70%' }}>
                      <FormControlLabel
                        value="Femme"
                        style={{ width: '15%', marginLeft: 0 }}
                        control={
                          <Checkbox
                            sx={{
                              color: '#035282',
                              '&.Mui-checked': {
                                color: '#18C5BF'
                              }
                            }}
                            checked={patientGenderFemme}
                            onChange={(e) => {
                              setPatientGenderFemme(e.target.checked);
                            }}
                          />
                        }
                        label="Femme"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="Homme"
                        style={{ width: '15%', marginLeft: 0 }}
                        control={
                          <Checkbox
                            sx={{
                              color: '#035282',
                              '&.Mui-checked': {
                                color: '#18C5BF'
                              }
                            }}
                            checked={patientGenderHomme}
                            onChange={(e) => { setPatientGenderHomme(e.target.checked) }}
                          />
                        }
                        label="Homme"
                        labelPlacement="end"
                      />
                    </div>
                  </DatePickerWrapper>

                  <DatePickerWrapper>
                    <FieldName style={customStyles.labels}>Age min : </FieldName>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      type="email"
                      style={customStyles.input}
                      variant="outlined"
                      value={preventionData.patientAgeMin}
                      onChange={handlePreventionData}
                      name="patientAgeMin"
                      InputProps={{
                        style: { height: '40px', color: '#035282' }
                      }}
                    />
                  </DatePickerWrapper>
                  <DatePickerWrapper>
                    <FieldName style={customStyles.labels}>Age max : </FieldName>
                    <TextField
                      autoFocus
                      margin="dense"
                      type="email"
                      style={customStyles.input}
                      variant="outlined"
                      value={preventionData.patientAgeMax}
                      onChange={handlePreventionData}
                      name="patientAgeMax"
                      InputProps={{
                        style: { height: '40px', color: '#035282' }
                      }}
                    />
                  </DatePickerWrapper>

                  <DatePickerWrapper>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      <FieldName style={customStyles.labels}>Type : </FieldName>
                      <FormControl style={{ width: '70%' }}>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={type}
                        >
                          <FormControlLabel
                            value="formulaire"
                            control={<Radio onChange={(e: any) => {
                              setType(e.target.value);
                            }} />}
                            label="Questionnaire"

                          />
                          <FormControlLabel
                            value="information"
                            control={<Radio onChange={(e: any) => setType(e.target.value)} />}
                            label="Information (URL)"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </DatePickerWrapper>

                  <DatePickerWrapper>
                    <FieldName style={customStyles.labels}>URL : </FieldName>
                    <div style={{ width: '70%' }}>
                      {type === 'formulaire' ? (
                        <FormControl variant="standard">
                          <Select
                            value={URL}
                            onChange={(e) => setURL(e.target.value)}
                            fullWidth
                            variant="outlined"
                            name="contentURl"
                            style={{ minWidth: '320px', height: '40px', color: '#035282' }}
                          >
                            <MenuItem value="url1">URl 1</MenuItem>
                            <MenuItem value="url2">URl 2</MenuItem>
                            <MenuItem value="url3">URl 3</MenuItem>
                          </Select>
                        </FormControl>
                      ) : (
                        ''
                      )}
                      {type === 'information' ? (
                        <FormControl variant="standard" >
                          <TextField
                            autoFocus
                            margin="dense"
                            type="email"
                            style={{ minWidth: '320px', maxWidth: '300px', height: '40px' }}
                            variant="outlined"
                            value={preventionData.contentURL}
                            onChange={handlePreventionData}
                            name="contentURL"
                            fullWidth
                            InputProps={{
                              style: { height: '40px', color: '#035282' }
                            }}
                          />
                        </FormControl>
                      ) : (
                        ''
                      )}
                    </div>
                  </DatePickerWrapper>
                </div>
              </div>
              <StyledDialogActions >
                <CancelBtn onClick={handleClose} >
                  Annuler
                </CancelBtn>

                {Update ?
                  <ConfirmBtn onClick={updatePreventionData} >
                    Modifier
                  </ConfirmBtn> :
                  <ConfirmBtn onClick={handleAddPrevention} >
                    Ajouter
                  </ConfirmBtn>
                }

              </StyledDialogActions>
            </Dialog>


            <Dialog
              open={openDelete}
              onClose={handleCloseDelete}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              style={{ zIndex: 99 }}
            >
              <DialogTitle id="alert-dialog-title">
                {"Êtes-vous sûr de bien vouloir supprimer cette prévention ?"}
              </DialogTitle>
              <StyledDialogContent>
                <DialogContentText id="alert-dialog-description">
                  La suppression d'une prévention entraine la suppression automatique de toutes les notifications associées.
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  Voulez vous confirmer cette action ?
                </DialogContentText>
              </StyledDialogContent>
              <DialogActions style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 15px 10px' }}>
                <Button onClick={handleCloseDelete}> Annuler </Button>
                <Button onClick={deletePreventionData} autoFocus>
                  Supprimer
                </Button>
              </DialogActions>
            </Dialog>
          </LocalizationProvider>
        </div>
      </PageWrapper>
    </PageWithHeader>
  );
}

export default Preventions;


