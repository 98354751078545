import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OrganizationService } from 'services/organizationsService';
import useWindowDimensions from 'utils/useWindowDimensions';
import OrgaCard from './OrgaCard';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Box, } from '@mui/material';
import { updateManageOrganizationIdState, updateManageOrgnaizationNameState, updateUserRole } from 'store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Content, Wrapper } from 'screens/Menu/Dashboard';
import { Img, ImgContainer } from 'components/StyledWrappers/StyledWrappers';
import { Role } from 'utils/Constants';
import DropDownMenu from 'screens/login/DropDownMenu';



export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1500 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1500, min: 900 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 900, min: 0 },
    items: 1
  }
}
function OrgaChoice() {
  const [localOrgas, setlocalOrgas] = useState<any[]>([]);
  const { height, width } = useWindowDimensions();
  const acc_token = useSelector((state: any) => state.userDomisante.userToken);
  async function getOrgas() {

    let response = await OrganizationService.getUserOrganizations(acc_token)
    setlocalOrgas(response);
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    getOrgas();
  }, []);

  useEffect(() => {
    console.log("localOrgas", localOrgas)
  }, [localOrgas]);

  const [openError, setopenError] = useState(false)
  const initialUserRoles = useSelector((state: any) =>
    state.userDomisante.userInfo ? state.userDomisante.userInfo.roles : null
  );
  const userRole = useSelector((state: any) => state.userDomisante.userRole);

  function userHasMultipleRoles(roles: string) {
    let numRoles = roles.split('|').length;

    return numRoles > 1
  }

  function validateOrga(orgaId: string, orgaName: string) {
    if (orgaId) {

      dispatch(updateManageOrganizationIdState(orgaId))
      dispatch(updateManageOrgnaizationNameState(orgaName));

      /**
       * *Checking if user has multiple roles 
       */

      if (userHasMultipleRoles(initialUserRoles)) {
        navigate('/role-choice')
      } else {
        dispatch(updateUserRole(Role.ORGANIZATION_ADMIN));

        redirectToRoleHomePage(userRole)
      }
    } else {
      setopenError(true)
    }
  }

  const [selectedOrgaId, setselectedOrgaId] = useState("")
  const [selectedOrgaName, setselectedOrgaName] = useState("")

  useEffect(() => {
  }, [selectedOrgaId])

  function setParamsOrga(id: string, name: string) {
    setselectedOrgaId(id)
    setselectedOrgaName(name)
  }

  function redirectToRoleHomePage(userRole: string) {
    switch (userRole) {
      case 'organization admin':
        navigate('/users');
        break;
      case 'aidant':
      case 'professionnel de santé':
        navigate('/dashboard');
        break;
      case 'professional':
        navigate('/get-homes');
        break;
      case 'admin':
        navigate('/get-homes');
        break;
      case 'super admin':
        navigate('/get-homes');
        break;

      default:
        break;
    }
  }


  return (
    <Wrapper>
      <Content style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <ImgContainer  >
            <Img src={process.env.PUBLIC_URL + '/Logo_final_fond_bleu.svg'} alt="DomiSanté"
            />
          </ImgContainer>
        </Box>
        <div
          style={{
            backgroundColor: 'transparent',
            height: '60vh',
            width: '50%',
            marginTop: '5vh',
            display: "flex",
            flexDirection: "column"
          }}
        >
          <div style={{
            textAlign: 'center',
            padding: '2vw',
            fontSize: '24px', fontWeight: 500, color: 'white',
          }}>
            Choisir une de vos organisations
          </div>
          <div
            style={{
              height: '35vh',
              width: '70vw',
              margin: 'auto',

            }}
          >
            <Carousel responsive={responsive}>
              {localOrgas ? localOrgas.map((obj, index) => {
                return <OrgaCard title={obj['name']} address={obj['address']} key={index} onClick={() => { validateOrga(obj['id'], obj['name']) }} isSelected={obj['id'] == selectedOrgaId} />;
              }) : <div>Aucune organisation trouvée</div>}
            </Carousel>
          </div>

          {/* <div style={{ width: '20vh', margin: '1.5vh auto' }}>
          <Button title={'Valider'} onClick={() => validateOrga(selectedOrgaId, selectedOrgaName)} />
        </div> */}
          {
            openError && <div style={{ textAlign: 'center', color: 'red' }}>Veuillez sélectionner une organisation</div>
          }
        </div>

      </Content>
      <DropDownMenu />
    </Wrapper>
  );
}

export default OrgaChoice;
