const initialHomeFields = {
  adress1: '',
  adress2: '',
  country: 'France',
  city: '',
  zipCode: '',
  subscriberName: '',
  subscriberLastname: '',
  subscriberEmail: ''
};
export const validateHome = (values: typeof initialHomeFields, setHomeErrors: any) => {
  const errors = {
    adress1: '',
    country: '',
    city: '',
    zipCode: ''
  };
  const emailRegex = /$^|.+@.+..+/;
  const nameAndLastNameRegex = /(^[a-zA-Z][a-zA-Z\s]{0,20}[a-zA-Z]$)/;
  let zipCodeRegex = /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/;

  if (!values.adress1) {
    errors.adress1 = 'Veuillez entrer une adresse 1';
  }

  if (!values.country) {
    errors.country = 'Veuillez entrer un pays ';
  }
  if (!values.city) {
    errors.city = 'Veuillez entrer une ville';
  }
  if (!values.zipCode) {
    errors.zipCode = 'Veuillez entrer un code postal';
  } else if (!zipCodeRegex.test(values.zipCode)) {
    errors.zipCode = 'Veuillez entrer un code postal valide';
  }

  setHomeErrors(errors);
  return Object.values(errors).every((x) => x === '');
};
