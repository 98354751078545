import { Select } from 'components/InputControls.tsx/Select';
import { LargeCardHeader, LargeCardHeading, LargeCardWrapper } from './CardItems';
import { CardBody, CardFieldset } from './ColumnCard';
import { IconButton, Tooltip } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
interface PageCardProps {
  children: any;
  title: string;
  select?: Boolean;
  handleChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: any;
  backBtnContainer?: boolean;
}

export function PageCard({ children, title, select, handleChange, value, backBtnContainer, }: PageCardProps) {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <LargeCardWrapper style={{ position: "relative" }} >
      <LargeCardHeader
        style={{
          display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center",
          width: "100%",
        }}>
        <div style={{ width: "100%" }}>
          {
            backBtnContainer &&
            <Tooltip title="Retour" placement="top" sx={{
              zIndex: 10,
              position: "absolute",
              transform: "translate(-50%, -50%)",

            }}>
              <IconButton
                style={{ marginTop: "10px", marginLeft: "30px" }}
                onClick={handleGoBack}
              >
                <ArrowBackIosNewIcon style={{ color: '#18C5BF', fontSize: "35px" }} />
              </IconButton>
            </Tooltip>
          }
          <LargeCardHeading style={{
            alignSelf: "center", textAlign: "center", marginRight: "auto",
            marginLeft: "auto", zIndex: 5
          }}>{title}</LargeCardHeading>
        </div>

        {select && handleChange ? (
          <Select
            value={value}
            handleChange={handleChange}
            option1="Actif"
            option2="Inactif"
            selectName="Statut"
            value1="active"
            value2="inactive"
          />
        ) : (
          <></>
        )}
      </LargeCardHeader>
      <CardBody>
        <CardFieldset>{children}</CardFieldset>
      </CardBody>
    </LargeCardWrapper >
  );
}
