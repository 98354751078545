import axios from 'axios';
import { is, curryN, gte } from 'ramda';
import { Role } from 'utils/Constants';
import { requestChecker } from 'utils/RequestCheck';

const isWithin = curryN(3, (min, max, value) => {
  const isNumber = is(Number);
  return isNumber(min) && isNumber(max) && isNumber(value) && gte(value, min) && gte(max, value);
});
const { REACT_APP_BASE_URL } = process.env

const in200s = isWithin(200, 299);


async function getUsers(setUsersData: React.Dispatch<React.SetStateAction<any>>, acc_token: string, orgaId?: string | null) {
  let url = REACT_APP_BASE_URL + "/user/users-list"
  if (orgaId) {
    url += "?organizationId=" + orgaId
  }
  try {
    let response: any = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      setUsersData(response.data);
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }

}
async function getPros(setUsersData: React.Dispatch<React.SetStateAction<any>>, acc_token: string, orgaId?: string | null) {
  let url = REACT_APP_BASE_URL + "/user/pros-list"
  if (orgaId) {
    url += "?organizationId=" + orgaId
  }
  try {
    let response: any = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      setUsersData(response.data);
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }

}


async function getProfessionals(setUsersData: React.Dispatch<React.SetStateAction<any>>, acc_token: string, orgaId?: string | null) {
  let url = REACT_APP_BASE_URL + "/user/users-list"
  if (orgaId) {
    url += "?organizationId=" + orgaId + "&role=" + Role.HEALTH_PROFESSIONAL
  }

  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      setUsersData(response.data);
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }

}
async function getPatients(setUsersData: React.Dispatch<React.SetStateAction<any>>, acc_token: string, orgaId?: string | null) {

  let url = REACT_APP_BASE_URL + "/user/patients-list"
  if (orgaId) {
    url += "?organizationId=" + orgaId
  }
  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      setUsersData(response.data);
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }

}

async function findPatients(lastname: string, firstname: string, date: string, setUsersData: React.Dispatch<React.SetStateAction<any>>, acc_token: string, orgaId?: string | null) {

  let url = REACT_APP_BASE_URL + "/user/patients-list/?lastname=" + lastname + "&firstname=" + firstname + "&birthdate=" + date
  if (orgaId) {
    url += "&organizationId=" + orgaId
  }

  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      setUsersData(response.data);
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}


async function addNewUSer(data: any, acc_token: string) {

  let url = REACT_APP_BASE_URL + `/admin/create-account`
  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function addNewPatient(data: any, hellocare: boolean, anamnese: boolean, acc_token: string) {
  let url = REACT_APP_BASE_URL + `/user/create-member?tlkAccount=true`

  if (anamnese) {
    url += `&anamneseAccount=true`
  }

  if (hellocare) {
    url += `&hellocareAccount=true`
  }

  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: 10000 // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function search(setUsersData: React.Dispatch<React.SetStateAction<any>>, acc_token: string, name?: string, role?: string) {
  let url = REACT_APP_BASE_URL + `/user/search?`
  if (name) {
    url += `&name=${name}`
  }
  if (role) {
    url += `&role=${role}`
  }

  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      setUsersData(response.data);
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}
/////////////////////////////////////////////////////////////////////////////////////:::
async function fetchPatientInfo(acc_token: string, patientId: string) {
  let url = REACT_APP_BASE_URL + `/user/patient-details?patientId=${patientId}`

  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}
////////////////////////////////////////////////////////////////////////////////////
async function fetchManagers(setUsersData: React.Dispatch<React.SetStateAction<any>>, organizationId: string, role: string, acc_token: string) {
  let url = REACT_APP_BASE_URL + `/user/users-list?organizationId=${organizationId}&role=${role}`
  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      setUsersData(response.data);
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function fetchPatients(setUsersData: React.Dispatch<React.SetStateAction<any>>, acc_token: string, organizationId?: string, homeId?: string) {
  let url = REACT_APP_BASE_URL + `/user/patients-list?`
  if (organizationId) {
    url += `&organizationId=${organizationId}`
  }
  if (homeId) {
    url += `&homeId=${homeId}`
  }
  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      setUsersData(response.data);
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function fetchAssociatedPatientManager(setUsersData: React.Dispatch<React.SetStateAction<any>>, id: string, manager: boolean, acc_token: string) {
  let url = REACT_APP_BASE_URL + `/user/fetch-associated-patient-manager`
  if (manager) {
    url += `?managerId=` + id
  }
  if (manager) {

    try {
      let response = await axios.request({
        url: url,
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          access_token: `${acc_token}`
        },
        timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
      });
      if (requestChecker.in200s(response.status)) {
        if (response.data && response.data.data.length > 0) {
          setUsersData(
            response.data.data[0].managerPatients);
          return response.data.data[0].managerPatients;
        } else {
          setUsersData([])
          return []
        }
      } else {
        return null;
      }
    } catch (error: any) {
      requestChecker.HandleErrors(error, url);
      return null;
    }
  }
  else {
    url += `?patientId=` + id
  }
  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      if (response.data.data && response.data.data.length > 0) {
        setUsersData(response.data.data[0].patientManagers);
        return response.data.data[0].patientManagers
      }
      else {
        setUsersData([])
        return []
      }
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function removeAssociation(id_manager: string, id_patient: string, acc_token: string) {

  let url = REACT_APP_BASE_URL + `/user/del-associate-patient-manager?managerId=` + id_manager + '&patientId=' + id_patient
  try {
    let response = await axios.request({
      url: url,
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}
async function deletePatient(idPatient: string, acc_token: string) {

  let url = REACT_APP_BASE_URL + `/user/delete-user/` + idPatient

  try {
    let response = await axios.request({
      url: url,
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function deleteUser(idUser: string, acc_token: string) {

  let url = REACT_APP_BASE_URL + `/user/delete-user/` + idUser
  try {
    let response = await axios.request({
      url: url,
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function associateManagerPatient(data: any, acc_token: string) {

  let url = REACT_APP_BASE_URL + `/user/associate-patient-manager`
  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function associateNewPatientHome(data: any, acc_token: string) {

  let url = REACT_APP_BASE_URL + `/user/associate-patient-manager`
  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function associatePatientHome(homeId: string, userId: string, acc_token: string, organizationId?: string,) {

  let url = REACT_APP_BASE_URL + `/user/associate-patient-home?homeId=` + homeId + '&userId=' + userId
  if (organizationId) {
    url += '&organizationId=' + organizationId
  }

  try {
    let response = await axios.request({
      url: url,
      method: 'put',
      data: {},
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function DiassociatePatientHome(homeId: string, userId: string, acc_token: string, organizationId?: string,) {

  let url = REACT_APP_BASE_URL + `/user/diassociate-patient-home?homeId=` + homeId + '&userId=' + userId
  if (organizationId) {
    url += '&organizationId=' + organizationId
  }

  console.log("Diassociate url", url)

  try {
    let response = await axios.request({
      url: url,
      method: 'put',
      data: {},
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function fetchAssociatedPatientRelatives(setUsersData: React.Dispatch<React.SetStateAction<any>>, id: string, acc_token: string) {
  let url = REACT_APP_BASE_URL + `/user/patient-family-members`
  if (id) {
    url += `?patientId=` + id
  }

  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {

      console.log("patientFamilyMembers", response.data.data.patientFamilyMembers)
      if (response.data.data && response.data.data.patientFamilyMembers) {
        console.log("response data fetchAssociatedPatientRelatives", response.data);

        setUsersData(response.data.data.patientFamilyMembers);
        // setUsersData(response.data.data);
        return response.data.data.patientFamilyMembers;
      }
      else {
        setUsersData([]);
        return []
      }
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

// try {
//   let response = await axios.request({
//     url: url,
//     method: 'get',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//       'Access-Control-Allow-Origin': '*',
//       access_token: `${acc_token}`
//     },
//     timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
//   });
//   if (requestChecker.in200s(response.status)) {
//     if (response.data.data.patientFamilyMembers) {
//       setUsersData(response.data.data.patientFamilyMembers);
//       return response.data.data.patientFamilyMembers
//     }
//     else {
//       setUsersData([]);
//       return []
//     }
//   } else {
//     return null;
//   }
// } catch (error: any) {
//   requestChecker.HandleErrors(error, url);
//   return null;
// }
// }

// }


async function addRelative(patientId: string, data: any, acc_token: string) {
  let url = REACT_APP_BASE_URL + `/user/associate-patient-family?patientId=${patientId}`
  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}


async function removeRelative(id_manager: string, id_patient: string, acc_token: string) {
  let url = REACT_APP_BASE_URL + `/user/del-patient-family-association?familyMemberId=` + id_manager + '&patientId=' + id_patient
  try {
    let response = await axios.request({
      url: url,
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}
async function updateRelative(id_manager: string, id_patient: string, constants: string, agenda: string, transmissions: string, teleassistance: string, acc_token: string) {

  let url = REACT_APP_BASE_URL + `/user/update-patient-family-association?familyMemberId=`
    + id_manager +
    '&patientId=' + id_patient +
    '&constants=' + constants +
    '&agenda=' + agenda +
    '&transmissions=' + transmissions +
    '&teleassistance=' + teleassistance

  try {
    let response = await axios.request({
      url: url,
      method: 'put',
      data: {},
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function updateProfile(data: any, acc_token: string) {
  let url = REACT_APP_BASE_URL + `/user/profile`

  try {
    let response = await axios.request({
      url: url,
      method: 'put',
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}
async function updateUserData(data: any, acc_token: string, userId: string) {
  let url = REACT_APP_BASE_URL + `/user/update-user`

  if (userId) {
    url += `/${userId}`
  }
  try {
    let response = await axios.request({
      url: url,
      method: 'put',
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function countAssociatedPatientManager(acc_token: string) {
  let url = REACT_APP_BASE_URL + `/user/count-associated-patient-manager`

  return axios.get(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      access_token: `${acc_token}`
    }
  })
    .then((response) => {
      if (in200s(response.status)) {
        return response.data;
      }
      return null;
    })
    .catch(function (error) {
      return error;
    });
}

export const usersService = {
  getUsers,
  getPros,
  getProfessionals,
  addNewUSer,
  getPatients,
  findPatients,
  search,
  fetchAssociatedPatientManager,
  removeAssociation,
  fetchManagers,
  fetchPatientInfo,
  associateManagerPatient,
  fetchPatients,
  fetchAssociatedPatientRelatives,
  addRelative,
  removeRelative,
  updateRelative,
  addNewPatient,
  countAssociatedPatientManager,
  associatePatientHome,
  DiassociatePatientHome,
  deletePatient,
  deleteUser,
  updateProfile,
  updateUserData,

};