import Card from 'components/Cards/Card';
import { InputBlueBottom } from 'components/InputControls.tsx/Inputs';
import React, { useState } from 'react';
import { useNavigate, } from 'react-router-dom';
import styled from 'styled-components';
import { memberService } from 'services/memberService';
import AuthTemplatePage from 'components/ReusableDivs/AuthTemplatePage';
import { useDispatch, useSelector } from 'react-redux';
import { logout, updateMustChangePwdState } from 'store/userSlice';
import { in200s } from 'utils/RequestCheckFns';

interface ChangePwdProps { }

const Label = styled.p`
  font-size: 14px;
  color: red;
`;
const UpdatePassword: React.FC<ChangePwdProps> = () => {
    const [errors, setErrors] = useState({
        newPassword: '',
        confirmNewPassword: '',
    });
    const [passwords, setPasswords] = useState({
        newPassword: '',
        confirmNewPassword: '',
        currentPassword: "",

    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const acc_token = useSelector((state: any) => state.userDomisante.userToken);
    const [isError, setisError] = useState(false)
    const handleSubmit = async () => {
        console.log("passwords", passwords)
        if (validatePassword(passwords)) {
            let data = {
                oldPassword: passwords.currentPassword,
                newPassword: passwords.newPassword
            };
            let updateResponse = await memberService.updatePwd(data, acc_token);
            if (updateResponse && in200s(updateResponse)) {
                dispatch(updateMustChangePwdState(false));
                navigate('/changed-pwd')
            } else {
                setisError(true)
            }
        }
    };

    const validatePassword = (values: any) => {
        const errors = {
            newPassword: '',
            confirmNewPassword: ''
        };
        var passwordRegex = /(?=^.{8,20}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

        if (!values.newPassword) {
            errors.newPassword = 'Veuillez entrer un mot de passe';
        } else if (!passwordRegex.test(values.newPassword)) {
            errors.newPassword = 'Mot de passe invalide.';
        }
        if (!values.confirmNewPassword) {
            errors.confirmNewPassword = 'Veuillez confirmer votre mot de passe';
        } else if (!passwordRegex.test(values.confirmNewPassword) && values.newPassword !== values.confirmNewPassword) {
            errors.confirmNewPassword = 'Veuillez entrer le même mot de passe';
        }
        setErrors(errors);
        return Object.values(errors).every((x) => x === '');
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setPasswords({ ...passwords, [name]: value });
    };

    return (

        <AuthTemplatePage >
            <Card
                title="Veuillez changer votre mot de passe"
                description=""
                buttonTitle="Confirmer"
                onClick={() => handleSubmit()}
                withCancelBtn={true}
                cancelFn={() => {
                    dispatch(logout());
                    navigate("/")
                }}
            >
                <InputBlueBottom
                    value={passwords.currentPassword}
                    onChange={handleChange}
                    placeholder="Mot de passe actuel"
                    required
                    type="password"
                    name="currentPassword"
                />
                <InputBlueBottom
                    value={passwords.newPassword}
                    onChange={handleChange}
                    placeholder="Nouveau mot de passe"
                    required
                    type="password"
                    name="newPassword"
                />
                <Label>{errors.newPassword}</Label>

                <InputBlueBottom
                    value={passwords.confirmNewPassword}
                    onChange={handleChange}
                    placeholder="Confirmation du nouveau mot de passe"
                    type="password"
                    name="confirmNewPassword"
                    required
                />
                <Label>{errors.confirmNewPassword}</Label>
                {
                    isError && <Label>Mot de passe invalide ! Veuillez réessayer.</Label>
                }
            </Card>
        </AuthTemplatePage>
    );
};

export default UpdatePassword;
