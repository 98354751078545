import React from 'react'
import { Box, Typography } from '@mui/material';
import { Wrapper } from 'components/StyledWrappers/SubscriptionWrappers';
import { ForgotWrapper, Img, ImgContainer, LogoWrapper } from 'components/StyledWrappers/StyledWrappers';

function Redirect404Page() {
  return (
    <Box style={{ height: "100vh", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column", }}>
      <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        {/* <LogoWrapper>
          <ImgContainer>
            <Img src={process.env.PUBLIC_URL + '/DomiSante_Logo.png'} alt="DomiSanté" />
          </ImgContainer>
        </LogoWrapper> */}
        <LogoWrapper>
          <ImgContainer>
            <Img src={process.env.PUBLIC_URL + '/404-error-ghost.png'} alt="DomiSanté" />
          </ImgContainer>
        </LogoWrapper>
        <Typography style={{ fontSize: "20px", color: "#035282" , fontWeight:"600"}}>Une erreur est survenue. Merci de réessayer plus tard.</Typography>
      </Box>
    </Box>
  )
}

export default Redirect404Page