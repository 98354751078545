import React, { useEffect, useState } from 'react';
import '../../styles/patient_info.css';
import BlueSkyButton from 'components/InputControls.tsx/BlueSkyButton';
import { Dialog, DialogTitle, DialogActions, IconButton } from '@mui/material';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSelector } from 'react-redux';
import { Box, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { CancelBtn, ConfirmBtn, StyledDialogTitle, ToggleBtn, StyledDialogContent, StyledDialogMessage, TertiaryBtn, } from 'styles/dialogs/DialogStyles';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, } from '@mui/x-date-pickers/internals/demo';
import { measuresService } from 'services/measuresService';
import { InputBlueBottom } from 'components/InputControls.tsx/Inputs';
import { StyledTable, StyledTableCell } from 'styles/tables/TableStyles';
import { format } from 'date-fns'
import SpinnerComponent from 'components/spinners/SpinnerComponent';
import { Oval } from 'react-loader-spinner'
import CheckBox from '@mui/material/Checkbox';
import { useParams } from 'react-router-dom';
import { ActionsWrapper } from 'screens/Menu/UsersList';
import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,

} from 'chart.js';
import { isAfter, } from 'date-fns';
import { colors } from 'utils/Constants';
import LineChart from './LineChart';
import BarChart from './BarChart';

const initialState = {
  DIASTOLIC_BLOOD_PRESSURE: {
    lowest: 30,
    highest: 140
  },
  SYSTOLIC_BLOOD_PRESSURE: {
    lowest: 60,
    highest: 220
  },
  BLOOD_PRESSURE: {
    lowest: 30,
    highest: 140
  },
  HEART_RATE_MESSAGE: {
    lowest: 30,
    highest: 220
  },
  OXYGEN_SATURATION: {
    lowest: 70,
    highest: 100
  },
  WEIGHT: {
    lowest: 0,
    highest: 300
  },
  TEMP_BODY: {
    lowest: 35,
    highest: 42
  },
  steps: {
    lowest: 0,
    highest: 100000000
  },
  BLOOD_SUGAR: {
    lowest: 0,
    highest: 100
  },
  PAIN: {
    lowest: 0,
    highest: 10
  },
  URINE: {
    lowest: 0,
    highest: 1000
  },
  STOOL: {
    lowest: 0,
    highest: 1000
  },
};
type ValidStateType = keyof typeof initialState;

interface ConstantsProps {
  title: string;
  logo: string;
  type: ValidStateType;
  measurelimits: any;
  setmeasurelimits?: any;

  measureArray: { type: string, value: string, eventTime: string }[],
  measureArray2?: { type: string, value: string, eventTime: string }[],
  measureLoading: boolean,
  refresh: boolean,
  setrefresh: any,
}




export const BarChartOptions = {
  responsive: true,

  plugins: {
    legend: {
      position: 'top' as const,
    },

  },
  label: false
};

const Constants: React.FC<ConstantsProps> = ({ title, logo, type, measurelimits, setmeasurelimits, measureArray, measureArray2, measureLoading, refresh, setrefresh }) => {

  ChartJS.register(
    CategoryScale,
    TimeScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    PointElement,
    LineElement,
  );

  const [openMeasureHistory, setOpenMeasureHistory] = useState(false);
  const [OpenMeasureLimit, setOpenMeasureLimit] = useState(false);
  const [limitArray, setlimitArray] = useState([]);
  const [isWithinRange, setIsWithinRange] = useState(true);
  const [endDate, setendDate] = useState(new Date())
  const [startDate, setstartDate] = useState(dayjs(endDate).subtract(1, 'month').toDate())
  /**
   * Common chart data is shared between all of the components
   */
  const [lastMeasure, setLastMeasure] = useState({ eventTime: '', value: '' })
  const [secondLastMeasure, setSecondLastMeasure] = useState({ eventTime: '', value: '' })

  const [combinedArray, setCombinedArray] = useState<any[]>([]);
  const [limitId, setLimitId] = useState('');
  const [minValueDia, setMinValueDia] = useState('');
  const [minValueSys, setMinValueSys] = useState('');
  const [maxValueDia, setMaxValueDia] = useState('');
  const [maxValueSys, setMaxValueSys] = useState('');
  const [minValue, setMinValue] = useState('');
  const [maxValue, setMaxValue] = useState('');
  const [minToShow, setMinToShow] = useState('')
  const [maxToShow, setMaxToShow] = useState('')
  const [lastEdit, setLastEdit] = useState('');
  const [loading, setLoading] = useState(true);
  const [systolicValue, setsystolicValue] = useState("")
  const [diastolicValue, setdiastolicValue] = useState("")
  const [sysOutOfRange, setsysOutOfRange] = useState(false)
  const [diaOutOfRange, setDiaOutOfRange] = useState(false)
  const [deleteModalOpen, setdeleteModalOpen] = useState(false)
  const [isError, setisError] = useState(false)
  const [isSuccess, setisSuccess] = useState(false)
  const [isLoading, setisLoading] = useState(false)


  const smallChartOptions = {
    responsive: true,
    borderRadius: 5,
    // maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },


    // Modify the axis by adding scales
    scales: {
      // to remove the labels
      x: {

        // to remove the x-axis grid
        grid: {
          drawBorder: false,
          display: false,
        },

      },
      y: {
        suggestedMin: matchMeasureChartMinValues(type),
      }

    },
  };
  // const [measureLoading, setmeasureLoading] = useState(true);
  const [lastEditor, setLastEditor] = useState({
    firstname: '',
    lastname: ''
  });
  const [NewLimit, setNewLimit] = useState({
    patientId: '',
    measureType: '',
    minValue: '',
    maxValue: '',
    visibleForPatient: false
  });
  const [secondaryNewLimit, setSecondaryNewLimit] = useState({//secondary new limit is for diastolic limits
    patientId: '',
    measureType: '',
    minValue: '',
    maxValue: '',
    visibleForPatient: false
  });
  const [showChart, setShowChart] = useState(true);
  const [showUpdateFields, setShowUpdateFields] = useState(false);
  const [openHistoriqueModal, setOpenHistoriqueModal] = useState(false);
  const [visibleForPatient, setVisibleForPatient] = useState(false);
  const [visibleForPatientToShow, setVisibleForPatientToShow] = useState(false);
  const [historyMeasures, sethistoryMeasures] = useState<any[]>([])
  const [historyMeasures2, sethistoryMeasures2] = useState<any[]>([])
  const [doubleHistoryArray, setDoubleHistoryArray] = useState<any[]>([])
  const [painHistory, setPainHistory] = useState<any[]>([])
  const [bloodPressureHistory, setbloodPressureHistory] = useState<any[]>([])
  const acc_token = useSelector((state: any) => state.userDomisante.userToken);
  const userRole: string = useSelector((state: any) => state.userDomisante.userRole);
  const { patientId } = useParams()! ?? 'undefined';
  const [measureWithinLimits, setmeasureWithinLimits] = useState(true)

  function matchMeasureChartMinValues(type: string) {
    switch (type) {

      case "HEART_RATE_MESSAGE":
        return 50
      case "WEIGHT":
        return 40
      case "TEMP_BODY":
        return 34
      case "OXYGEN_SATURATION":
        return 80
      case "BLOOD_SUGAR":
        return 50
      default:
        break;
    }
  }
  const setLimits = async () => {
    if (userRole == "professionnel de santé") {
      if (measurelimits) {
        setMinValue(measurelimits.minValue);
        setMaxValue(measurelimits.maxValue);
        setMaxToShow(measurelimits.maxValue);
        setMinToShow(measurelimits.minValue);
        setVisibleForPatientToShow(measurelimits.visibleForPatient);
        setLimitId(measurelimits.id);
        const formattedDate = format(new Date(measurelimits.createdAt), 'dd/MM/yyyy');
        setLastEdit(formattedDate);
        setLastEditor({
          firstname: measurelimits.professionalId.firstname,
          lastname: measurelimits.professionalId.lastname
        })
        setNewLimit({
          patientId: patientId!,
          measureType: type,
          minValue: measurelimits.maxValue,
          maxValue: measurelimits.maxValue,
          visibleForPatient: visibleForPatient
        })
      }
    }
  }
  function sortByCreatedAt(arr: any[]): any[] {
    return arr.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  }
  const fetchLimitsHistory = async (patientId: string) => {
    if (type === 'BLOOD_PRESSURE') {
      let limit_data: any = await measuresService.getMeasureLimitsHistory(acc_token, patientId, 'SYSTOLIC_BLOOD_PRESSURE');
      setlimitArray(limit_data.data);

      let secondary_limit_data = await measuresService.getMeasureLimitsHistory(acc_token, patientId, 'DIASTOLIC_BLOOD_PRESSURE');
      let merge = [...limit_data.data, ...secondary_limit_data.data];
      merge = sortByCreatedAt(merge);
      setCombinedArray(merge);
    } else {
      let limit_data = await measuresService.getMeasureLimitsHistory(acc_token, patientId, type);
      setlimitArray(limit_data.data);
    }
  };


  useEffect(() => {
    if (openHistoriqueModal) {
      fetchLimitsHistory(patientId!);
    }
  }, [openHistoriqueModal]);

  const fetchMeasureHistory = async (patientId: string, startDate?: Date, endDate?: Date) => {

    setLoading(true)
    let formatedStartDate = dayjs(startDate).format('YYYY-MM-DD')
    let formatedEndDate = dayjs(endDate).format('YYYY-MM-DD')
    let hist_response

    /***
     * * Prefilling chart measures for history mode : getting only last month measures for each type
     */
    if (type == 'HEART_RATE_MESSAGE') {
      hist_response = await measuresService.getMeasures(acc_token, patientId, 'BPM', formatedStartDate, formatedEndDate)
    } else if (type == 'TEMP_BODY') {

      hist_response = await measuresService.getMeasures(acc_token, patientId, 'TEMPERATURE', formatedStartDate, formatedEndDate)

    }
    else if (type == "BLOOD_PRESSURE") {

      let hist_response = await measuresService.getMeasures(acc_token, patientId, 'BLOOD_PRESSURE', formatedStartDate, formatedEndDate)
      setbloodPressureHistory(hist_response.data ? hist_response.data : [])
      let seperatedValues: any[] = []
      for (let index = 0; index < hist_response.data.length; index++) {
        const element = hist_response.data[index];
        let values = element["value"].split("/")
        seperatedValues.push({ eventTime: element["eventTime"], value: values[0], value2: values[1] })
      }
      console.log("seperatedValues ----------", seperatedValues)
      sethistoryMeasures(seperatedValues)
      console.log("historyMeasures ----------", historyMeasures)

    }
    else if (type == 'PAIN') {
      let hist_pain: any = await measuresService.getMeasures(acc_token, patientId, 'PAIN', formatedStartDate, formatedEndDate)

      let hist_doloplus: any = await measuresService.getMeasures(acc_token, patientId, 'DOLOPLUS', formatedStartDate, formatedEndDate)
      console.log("hist_pain", hist_pain)
      console.log("hist_doloplus", hist_doloplus)


      let tempPain = [...hist_pain.data]
      let tempDoloplus = [...hist_doloplus.data]

      if (await hist_pain && (await hist_pain).data) {
        sethistoryMeasures(tempPain)
        console.log("fetching measure history from PAIN", tempPain)
      }
      if (await hist_doloplus && (await hist_doloplus).data) {
        console.log("fetching measure history from DOLOPLUS", tempDoloplus)

        sethistoryMeasures2(tempDoloplus)
      }
      let fusedArrays = tempPain.concat(tempDoloplus)

      fusedArrays.sort((a: { eventTime: any; }, b: { eventTime: any; }) => {
        return new Date(a.eventTime).getTime() - new Date(b.eventTime).getTime();
      })
      // fusedArrays.forEach((element: { eventTime: any; }) => {
      //   element.eventTime = dayjs(element.eventTime).format('DD/MM/YYYY')
      // });
      setPainHistory(fusedArrays)
    }
    else if (type == 'URINE') {
      let hist_urine: any = await measuresService.getMeasures(acc_token, patientId, 'URINE', formatedStartDate, formatedEndDate)
      if (await hist_urine && (await hist_urine).data) {
        sethistoryMeasures((hist_urine).data)
      }
      let hist_urine_probe: any = await measuresService.getMeasures(acc_token, patientId, 'URINE_PROBE', formatedStartDate, formatedEndDate)
      let tempUrine = [...hist_urine.data]
      let tempUrineProbe = [...hist_urine_probe.data]
      let fusedArrays = tempUrine.concat(tempUrineProbe)
      fusedArrays.sort((a: { eventTime: number; }, b: { eventTime: number; }) => {
        return a.eventTime - b.eventTime;
      })
      fusedArrays.forEach((element: { eventTime: any; }) => {
        element.eventTime = dayjs(element.eventTime).format('DD/MM/YYYY HH:mm')
      });
      setDoubleHistoryArray(fusedArrays)
      if (await hist_urine_probe && (await hist_urine_probe).data) {
        sethistoryMeasures2((hist_urine_probe).data)
      }
    } else {
      hist_response = await measuresService.getMeasures(acc_token, patientId, type, formatedStartDate, formatedEndDate)
    }
    if (await hist_response && (await hist_response).data) {
      sethistoryMeasures((hist_response).data)
    }

    setLoading(false)
    return hist_response
  }


  /**
   *
   * Renaming chart variables
   */

  // const fetchMeasurePreview = async (patientId: string,) => {
  //   setLoading(true)
  //   let formatedStartDate = dayjs("1970").format('YYYY-MM-DD')
  //   let formatedEndDate = dayjs("2025").format('YYYY-MM-DD')
  //   /**
  //    * *Prefilling data charts
  //    * *
  //    * */
  //   /***
  //    * * Prefilling chart measures for preview mode : getting only last 4 measures for each type
  //    */

  //   if (type == 'HEART_RATE_MESSAGE') {
  //     hist_response = await measuresService.getMeasures(acc_token, patientId, 'BPM', formatedStartDate, formatedEndDate, 4)
  //   } else if (type == 'TEMP_BODY') {
  //     hist_response = await measuresService.getMeasures(acc_token, patientId, 'TEMPERATURE', formatedStartDate, formatedEndDate, 4)
  //   } else {
  //     hist_response = await measuresService.getMeasures(acc_token, patientId, type, formatedStartDate, formatedEndDate, 4)
  //   }

  //   // if (await hist_response && (await hist_response).data.length > 0) {
  //   //   let tempData = formatDetailedData((await hist_response).data)
  //   //   // setCommonChartData(tempData)
  //   //   // /**
  //   //   //  * In this line, we're calling formatDataSet which formats the data types and , if it has blood pressure type, it fills the socond chart data
  //   //   //  */
  //   //   // const formatedList = formatDataSet(tempData, type, setSmallformattedSecondChartData)
  //   //   // console.log("formatedList", formatedList)
  //   //   // /**
  //   //   //  * This variable is used by all line charts
  //   //   //  */
  //   //   // setSmallformattedChartData(formatedList)

  //   // }
  //   setLoading(false)

  // }

  function formatDataSet(tempData: any[], type: string, secondDataSetter: any, preview?: boolean) {
    let formatedList = []
    let formatedSecondList = []

    for (let index = 0; index < tempData.length; index++) {
      const element = tempData[index];
      if (preview) {
        const eventTime = new Date(element['eventTime']);
        const formattedDate = eventTime.toISOString().split('T')[0];
        formatedList.push({
          x: formattedDate, y: element['value'],
        })
        if (type == "BLOOD_PRESSURE") {
          formatedSecondList.push({
            x: formattedDate, y: element['value2'],
          })

        }
      } else {
        formatedList.push({
          x: element['eventTime'], y: element['value'],
        })
        if (type == "BLOOD_PRESSURE") {
          formatedSecondList.push({
            x: element['eventTime'], y: element['value2'],
          })

        }
      }
    }


    secondDataSetter(formatedSecondList)

    return formatedList
  }
  const seperateLastMeasure = async () => {
    if (measureArray && measureArray.length > 0) {

      setLastMeasure({
        eventTime: new Date(measureArray[measureArray.length - 1].eventTime).toLocaleDateString('fr-GB', {
          month: 'numeric',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }),
        value: measureArray[measureArray.length - 1].value,
      });

    }
    if (measureArray2 && measureArray2.length > 0) {
      setSecondLastMeasure({
        eventTime: new Date(measureArray2[measureArray2.length - 1].eventTime).toLocaleDateString('fr-GB', {
          month: 'numeric',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }),
        value: measureArray2[measureArray2.length - 1].value,
      });
    }
  };



  // useEffect(() => {

  //   /**Filling peview data charts */
  //   fetchMeasurePreview(patientId!)
  // }, []);


  async function fetchHistory(patientId: string, startDate: Date, endDate: Date) {

    let response = await fetchMeasureHistory(patientId!, startDate, endDate);

  }
  useEffect(() => {
    if (openMeasureHistory == true) {
      fetchHistory(patientId!, startDate, endDate)
    }
  }, [startDate, endDate, openMeasureHistory]);

  useEffect(() => {
    setLimits();
  }, [measurelimits, refresh]);




  useEffect(() => {
    if (OpenMeasureLimit === true) {
      setNewLimit({
        patientId: patientId!,
        measureType: type,
        minValue: minValue,
        maxValue: maxValue,
        visibleForPatient: visibleForPatient
      });
    }

  }, [minValue, maxValue, visibleForPatient, OpenMeasureLimit, refresh]);

  useEffect(() => {
    if (OpenMeasureLimit === true && type == 'BLOOD_PRESSURE') {
      setNewLimit({
        patientId: patientId!,
        measureType: 'SYSTOLIC_BLOOD_PRESSURE',
        minValue: minValueSys,
        maxValue: maxValueSys,
        visibleForPatient: visibleForPatient
      });
    }

  }, [minValueSys, maxValueSys, visibleForPatient, OpenMeasureLimit, refresh]);

  useEffect(() => {
    if (OpenMeasureLimit === true && type == 'BLOOD_PRESSURE') {
      setSecondaryNewLimit({
        patientId: patientId!,
        measureType: 'DIASTOLIC_BLOOD_PRESSURE',
        minValue: minValueDia,
        maxValue: maxValueDia,
        visibleForPatient: visibleForPatient
      });
    }
  }, [minValueDia, maxValueDia, OpenMeasureLimit, visibleForPatient]);



  useEffect(() => {
    seperateLastMeasure()
  }, [measureArray.length, refresh]);



  function isValueWithinRange(value: number, type: keyof typeof initialState) {
    const range = initialState[type];
    if (range) {
      const { lowest, highest } = range;
      return value >= lowest && value <= highest;
    }
  }
  const formatEventTime = (eventTime: string) => {
    const [day, month] = eventTime.split('/');
    return `${day}/${month}`;
  };

  function formatDetailedData(dataArray: any[]) {
    if (type == 'BLOOD_PRESSURE') {
      let intermediateData: any = []
      dataArray.forEach(data => {
        const { eventTime, value } = data;
        const bpValues = value.split(' / ');
        const transformedData = {
          eventTime: new Date(eventTime).toLocaleDateString('fr-GB', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          }),
          value: bpValues[0],
          value2: bpValues[1]
        };
        intermediateData.push(transformedData);
      });
      return intermediateData

    } else {
      let intermediateData: any = []
      dataArray.forEach(data => {
        const { eventTime, value } = data;
        const transformedData = {
          eventTime: new Date(eventTime).toLocaleDateString('fr-GB', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          }),
          value,
        };
        intermediateData.push(transformedData);
      });
      return intermediateData

    }
  }

  const handleCloseDialog = () => {
    setShowUpdateFields(false)
    console.log("Passing ShowUpdateFields to false")
    setOpenMeasureHistory(false);
    setShowChart(true);
  };
  const handleOpenDialog = () => {
    setOpenMeasureHistory(true);
  };
  const handleCloseMeasureLimit = () => {
    setOpenMeasureLimit(false);
    setShowUpdateFields(false)
  };
  const handleOpenMeasureLimit = async () => {
    setOpenMeasureLimit(true);
  };
  const handleCancelUpdate = () => {
    setShowUpdateFields(true);
  };

  const handleUpdate = () => {
    setShowUpdateFields(true);
  };


  const handleReturn = () => {
    setNewLimit({
      patientId: patientId!,
      measureType: type,
      minValue: minToShow,
      maxValue: maxToShow,
      visibleForPatient: false
    })
    setVisibleForPatient(false)
    setShowUpdateFields(false)
    setMaxValueDia('')
    setMaxValueSys('')
    setMinValueDia('')
    setMinValueSys('')
  };

  const handleDelete = async () => {
    const currentTime = new Date().getTime();
    const minutes = new Date(currentTime).getMinutes();
    const seconds = new Date(currentTime).getSeconds();


    let response = await measuresService.deleteMeasureLimit(acc_token, limitId);

    if (response == true) {
      setmeasurelimits()
      setMaxValue('')
      setMinValue('')
      setLimitId('')
      setLastEdit('')
      setLastEditor({ firstname: '', lastname: '' })
      setMaxValueDia('')
      setMaxValueSys('')
      setMinValueDia('')
      setMinValueSys('')
      setMaxToShow('')
      setMinToShow('')
      setrefresh(!refresh)
      setOpenMeasureLimit(false);
      handleCloseMeasureLimit()
      setdeleteModalOpen(false);

    }

  };

  const handleCloseHistorique = () => {
    setOpenHistoriqueModal(false);
  }

  const handleCheckboxChange = (event: any) => {
    const isChecked = event.target.checked;
    setVisibleForPatient(isChecked);
  };

  const dynamicStyle = (type: string) => {
    return {
      ...styles.units,

      color: changeValueColor(type)
    }
  };
  const dynamicMeasureStyle = (type: string) => {
    return {
      marginRight: '5px',
      fontWeight: '700',
      marginTop: '20px',
      color: changeValueColor(type)
    };
  };


  function changeValueColor(type: string) {
    if (type == "diastolic") {
      if (diaOutOfRange) {
        return 'red'
      }
      else return "#035282"
    }
    if (type == "systolic") {
      if (sysOutOfRange) {
        return 'red'
      }
      else return "#035282"
    }
    if (type == "BLOOD_PRESSURE") {
      if (diaOutOfRange || sysOutOfRange) {
        return 'red'
      }
      else return "#035282"
    }

    if (type == "none") {
      return "#035282"
    }
    else {
      if (!measureWithinLimits) {
        return "red"
      }
      else {
        return "#035282"
      }
    }
  }



  useEffect(() => {
    if (lastMeasure.value != '') {
      checkMeasureWithinLimits(Number(lastMeasure.value), minToShow, maxToShow)
    }
    if (type == "BLOOD_PRESSURE" && lastMeasure.value) {
      let values = lastMeasure.value.split("/")
      if (values.length > 0) {
        setsystolicValue(values[0].trim())
        setdiastolicValue(values[1].trim())
        checkBPisWithinLimits(Number(values[0].trim()), Number(values[1].trim()), minToShow, maxToShow)
      }
    }
  }, [lastMeasure, minToShow, maxToShow, type, refresh])

  function checkBPisWithinLimits(sys: number, dia: number, minValues: any, maxValues: any) {

    let minSys = null
    let maxSys = null
    let minDia = null
    let maxDia = null


    if (minValues != null) {
      let minValuesArray = minValues.split("/")

      if (minValuesArray[0] && minValuesArray[0] != "--") {
        minSys = minValuesArray[0].trimEnd()
        setMinValueSys(minSys)
      }
      if (minValuesArray[1] && minValuesArray[1] != "--") {
        minDia = minValuesArray[1]
        setMinValueDia(minDia)
      }
    }
    if (maxValues != null) {
      let maxValuesArray = maxValues.split("/")

      if (maxValuesArray[0] && maxValuesArray[0] != "--") {
        maxSys = maxValuesArray[0].trimEnd()
        setMaxValueSys(maxSys)
      }
      if (maxValuesArray[1] && maxValuesArray[1] != "--") {
        maxDia = maxValuesArray[1].trimEnd()
        setMaxValueDia(maxDia)
      }
    }

    let sysIsWithinLimits = checkMeasureWithinLimits(sys, minSys, maxSys)
    setsysOutOfRange(!sysIsWithinLimits)
    let diaIsWithinLimits = checkMeasureWithinLimits(dia, minDia, maxDia)
    setDiaOutOfRange(!diaIsWithinLimits)

    setmeasureWithinLimits(!(sysIsWithinLimits && diaIsWithinLimits))
  }

  function recentValue(firstValue: { eventTime: string, value: string }, secondValue: { eventTime: string, value: string }) {
    let result
    if (firstValue.eventTime != "" && secondValue.eventTime != "") {
      let comaprison = isAfter(new Date(firstValue.eventTime), new Date(secondValue.eventTime));
      if (comaprison) {
        result = firstValue.value;

      } else {
        result = secondValue.value
      }
    } else if (firstValue.eventTime != "" && secondValue.eventTime == "") {

      result = firstValue.value
    } else {
      result = secondValue.value;
    }
    return matchQuantity(result)

  }
  function recentValueDate(firstValue: { eventTime: string, value: string }, secondValue: { eventTime: string, value: string }) {
    if (firstValue.eventTime != "" && secondValue.eventTime != "") {
      let comaprison = isAfter(new Date(firstValue.eventTime), new Date(secondValue.eventTime));

      if (comaprison) {
        return firstValue.eventTime

      } else {
        return (secondValue.eventTime);
      }
    } else if (firstValue.eventTime != "" && secondValue.eventTime == "") {
      return firstValue.eventTime

    } else {
      return secondValue.eventTime;
    }

  }


  function checkMeasureWithinLimits(value: number, minToShow: string | null, maxToShow: string | null) {
    let minValue = 0;
    let maxValue = Number.MAX_VALUE;
    if (minToShow !== null && minToShow !== "" && minToShow !== "--") {
      minValue = Number(minToShow)
    }

    if (maxToShow !== null && maxToShow !== "" && maxToShow !== "--") {
      maxValue = Number(maxToShow)
    }


    setmeasureWithinLimits(value >= minValue && value <= maxValue);
    return value >= minValue && value <= maxValue
  }

  const handleSave = async () => {

    let response
    let response2
    setisLoading(true)

    if (type === 'BLOOD_PRESSURE') {
      response = await measuresService.addMeasureLimits(acc_token, NewLimit);
      response2 = await measuresService.addMeasureLimits(acc_token, secondaryNewLimit);
    } else {
      response = await measuresService.addMeasureLimits(acc_token, NewLimit);
    }

    if (response == true || (type === 'BLOOD_PRESSURE' && response2 == true)) {
      setShowUpdateFields(false);
      setrefresh(!refresh)
      handleReturn()
      setisSuccess(true)
    }
    else {
      setisError(true)
    }


  }

  function emptyFields() {
    setMinValueDia("")
    setMinValueSys("")
    setMaxValueSys("")
    setMaxValueDia("")
  }
  function closeDeleteModal() {
    setdeleteModalOpen(false)
  }
  function matchQuantity(quantity: string) {
    let formattedQuantity = Number(quantity)
    switch (formattedQuantity) {
      case 1:
        return "Peu d'urine"
      case 2:
        return "Quantité moyenne"
      case 2:
        return "Quantité importante"
      default:
        return quantity + " ml"
    }
  }
  function matchUrineName(name: string) {
    switch (name) {
      case "URINE":
        return "Urine sans sonde"
      case "URINE_PROBE":
        return "Urine avec sonde"
      default:
        break;
    }
  }
  function matchPainName(name: string) {
    switch (name) {
      case "PAIN":
        return "Douleur"
      case "DOLOPLUS":
        return "Doloplus"
      default:
        break;
    }
  }
  function matchStoolValue(value: string) {
    let formattedValue = Number(value)
    switch (formattedValue) {
      case 0:
        return "Non"
      case 1:
        return "Oui"
      default:
        return "--"
    }
  }
  function checkDecimalValue(value: string) {
    const parts = value.toString().split(".");
    if (parts.length === 2 && parts[1] === "0") {
      return parseInt(parts[0]);
    } else {
      return value;
    }
  }

  useEffect(() => {
    console.log("show update fields ___________", showUpdateFields)
  }, [showUpdateFields])


  function BorderColor(type: string) {
    if (type == "BLOOD_PRESSURE") {
      if (sysOutOfRange || diaOutOfRange) {
        return "2px solid red"
      }
      else return "none"
    }
    else {
      if (!measureWithinLimits) {
        return "2px solid red"
      }
      else return "none"
    }
  }
  return (
    <div style={{
      marginTop: '20px', position: 'relative', backgroundColor: '#fff', padding: '20px', width: '85%', height: '450px',
      borderRadius: '10px', border: BorderColor(type)
    }}>
      <div style={styles.line}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt="Title Logo" style={{ width: '35px', height: 'auto', marginBottom: '10px' }} />
          <h3 style={{ color: '#035282', marginLeft: '8px', fontSize: "17px", fontFamily: 'open sans' }}>{title}</h3>
        </div>


      </div>
      {measureLoading ? (
        <div style={{ position: "absolute", left: "40%", bottom: "37%" }}>
          <Oval
            height={50}
            width={50}
            color="#18C5BF"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#C2D4D8"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', }}>

          {
            type == 'BLOOD_PRESSURE' ?
              <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', }}>
                <p style={dynamicMeasureStyle("systolic")}>
                  {checkDecimalValue(systolicValue)}
                </p>
                <p style={dynamicMeasureStyle("none")} >
                  /
                </p>
                <p style={dynamicMeasureStyle("diastolic")}>
                  {checkDecimalValue(diastolicValue)}
                </p>
              </div> :

              <div>
                {
                  (type == "PAIN" || type == "URINE" || type == "STOOL") &&
                  <div style={{ display: "flex", flexDirection: "column", }}>
                    {type == "PAIN" && <div>
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
                        <p style={{ fontSize: "20px", marginRight: "5px", color: colors.dark_blue }}>
                          Douleur :
                        </p>
                        <p style={dynamicMeasureStyle(type)}>
                          {checkDecimalValue(lastMeasure.value)}
                        </p>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
                        <p style={{ fontSize: "20px", marginRight: "5px", color: colors.dark_blue }}>
                          Doloplus :
                        </p>
                        <p style={dynamicMeasureStyle(type)}>
                          {checkDecimalValue(secondLastMeasure.value)}
                        </p>
                      </div>
                    </div>}
                    {
                      type == "URINE" &&
                      <div>
                        <p style={{ fontSize: "20px", marginRight: "5px", color: colors.dark_blue }}>
                          {/* {checkDecimalValue(lastMeasure.value)} */}
                          {
                            recentValue(lastMeasure, secondLastMeasure)
                          }
                        </p>
                      </div>
                    }
                    {
                      type == "STOOL" &&
                      <div>
                        <p style={{ fontSize: "20px", marginRight: "5px", color: colors.dark_blue }}>
                          {
                            matchStoolValue(lastMeasure.value)
                          }

                        </p>
                        {lastMeasure.eventTime &&
                          <div style={{ float: 'left', marginTop: '-6px', fontWeight: '100', color: '#0ED2EB', fontSize: '15px', marginBottom: "15px" }}>
                            <div style={{}}>

                              {
                                type == "STOOL" && lastMeasure.eventTime
                              }
                            </div>
                          </div>

                        }
                      </div>
                    }
                  </div>
                }
                {

                  !(type == "PAIN" || type == "URINE" || type == "STOOL") &&
                  <p style={dynamicMeasureStyle(type)}>
                    {checkDecimalValue(lastMeasure.value)}
                  </p>
                }
              </div>

          }

          {title === 'Tension' && (<p style={dynamicStyle(type)}>mmHg</p>)}
          {title === 'Fréquence Cardiaque' && (<p style={dynamicStyle(type)}>Bpm</p>)}
          {title === 'Saturation en oxygène' && (<p style={dynamicStyle(type)}>SpO2</p>)}
          {title === 'Poids' && (<p style={dynamicStyle(type)}> Kg </p>)}
          {title === 'Température Corporelle' && (<p style={dynamicStyle(type)}> °C </p>)}
          {title === 'tension' && <p style={dynamicStyle(type)}></p>}
          {title === 'Taux de glycémie' && <p style={dynamicStyle(type)}>mg/dL</p>}


        </div>)
      }

      {
        (lastMeasure.value || secondLastMeasure.value) &&
        <div style={{ float: 'left', marginTop: '-6px', fontWeight: '100', color: '#0ED2EB', fontSize: '15px', marginBottom: "15px" }}>
          <div style={{}}>
            {
              type == "URINE" ?
                recentValueDate(lastMeasure, secondLastMeasure)
                : lastMeasure.eventTime
            }
            {
              type == "STOOL" && lastMeasure.eventTime
            }
          </div>
        </div>

      }
      <div style={{ textAlign: 'left', }}>
        {(measureArray && measureArray.length > 1) && (
          <div style={{ height: "175px", width: "100%", margin: "15px 0px" }}>
            {(title == 'Tension' || title == 'Douleur') ?
              <>
                {
                  title == 'Douleur' &&
                  <BarChart
                    options={smallChartOptions}
                    data={measureArray}
                    type={type}
                    small={true}
                    data2={measureArray2}
                    type2={"DOLOPLUS"}
                    twoConstants={true}
                    length={4}

                  />

                }
                {
                  title == 'Tension' &&
                  <BarChart options={smallChartOptions} data={measureArray} type={type} small={true} double={true} type2={"DIASTOLIC_BLOOD_PRESSURE"} />

                }

              </>
              :
              <>
                {
                  (type == "STOOL" || type == "URINE") ?
                    <></> :
                    < LineChart options={smallChartOptions} type={type} data={measureArray} small={true} />

                }
              </>

            }
          </div>
        )}
        {/* professionnel de santé */}
        {userRole == 'professionnel de santé' && (
          <Box style={{ color: '#035282', marginTop: '25px', position: 'absolute', bottom: '55px', width: '90%', left: "5%" }}>
            <div>
              {(minToShow !== '') && (
                <p style={{ ...styles.values, marginBottom: '-10px' }}>Seuil minimal : {minToShow}</p>
              )}
              {(maxToShow !== '') && (
                <p style={styles.values}>Seuil maximal : {maxToShow}</p>
              )}
            </div>
          </Box>
        )}


        <div style={{
          position: 'absolute', bottom: '10px',
          width: '90%',
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row"
        }}>

          {userRole == 'professionnel de santé' && (
            <BlueSkyButton
              title={'Gérer les seuils'}
              onClick={() => {
                handleOpenMeasureLimit();
              }}
            />)}
          <BlueSkyButton
            title={'Historique'}
            onClick={() => {
              handleOpenDialog();
            }}
          />

        </div>
      </div>

      <Dialog
        open={openMeasureHistory}
        style={{ zIndex: 99 }}
        onClose={handleCloseDialog}
        maxWidth="md" fullWidth>
        <StyledDialogTitle sx={{}} id="customized-dialog-title">
          Historique de {title}
        </StyledDialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => handleCloseDialog()}
          sx={{
            position: 'absolute',
            right: 20,
            top: 20,
            color: colors.dark_blue
          }}
        >
          <CloseIcon />
        </IconButton>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
          <Box style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <div style={styles.datePicker_layout}>
              <div style={{ paddingRight: "10px" }}>
                <DemoContainer
                  components={['DatePicker']}
                >
                  <DatePicker
                    //@ts-ignore
                    label="Date de naissance"
                    format="DD/MM/YYYY"
                    value={dayjs(startDate)}
                    onChange={async (newValue: any) => {
                      setstartDate(newValue!);
                    }}
                    sx={{
                      '& label.Mui-focused': {
                        color: '#035282',
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#035282',
                      },
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: '#035282',
                        },
                      },
                      '& .MuiInputBase-input': {
                        fontFamily: [
                          'open sans',
                        ].join(','),
                      },
                      '& .MuiIconButton-root.MuiInputAdornment-root.MuiInputAdornment-positionEnd:not(.Mui-disabled)::before': {
                        display: 'none',
                      },
                    }}
                  />
                </DemoContainer>
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <DemoContainer
                  components={['DatePicker']}
                >
                  <DatePicker
                    //@ts-ignore
                    format="DD/MM/YYYY"
                    label="Date de fin"
                    value={dayjs(endDate)}
                    onChange={(newValue: any) => {
                      setendDate(newValue!);
                    }}
                    sx={{
                      '& label.Mui-focused': {
                        color: '#035282',
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#035282',
                      },
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: '#035282',
                        },
                      },
                      '& .MuiInputBase-input': {
                        fontFamily: [
                          'open sans',
                        ].join(','),
                      },
                      '& .MuiIconButton-root.MuiInputAdornment-root.MuiInputAdornment-positionEnd:not(.Mui-disabled)::before': {
                        display: 'none',
                      },
                    }}
                  ></DatePicker>
                </DemoContainer>
              </div>
              {
                !(type == "STOOL" || type == "URINE") &&
                <div style={{ display: 'flex', marginLeft: "10%" }}>
                  <ToggleBtn onClick={() => setShowChart(true)} style={{ backgroundColor: showChart ? '#035282' : 'white', color: showChart ? 'white' : '#035282' }}>
                    Graphe
                  </ToggleBtn>
                  <ToggleBtn onClick={() => setShowChart(false)} style={{ backgroundColor: showChart ? 'white' : '#035282', color: showChart ? '#035282' : 'white' }}>
                    Liste
                  </ToggleBtn>
                </div>
              }
            </div>
          </Box>
        </LocalizationProvider>
        < StyledDialogContent>
          {loading ? (
            <SpinnerComponent />
          ) : (
            showChart && !(type == "STOOL" || type == "URINE") ? (
              <>
                {type === 'BLOOD_PRESSURE' &&
                  <BarChart options={BarChartOptions} data={bloodPressureHistory} type={type} type2={"DIASTOLIC_BLOOD_PRESSURE"} small={false} double={true} />
                }
                {type === 'PAIN' &&
                  <BarChart
                    options={BarChartOptions}
                    data={historyMeasures}
                    type={type}
                    type2={"DOLOPLUS"}
                    small={false}
                    twoConstants={true}
                    data2={historyMeasures2}

                  />
                }
                {(type !== 'BLOOD_PRESSURE' && type !== 'PAIN') &&
                  < LineChart options={smallChartOptions} type={type} data={historyMeasures} small={false} />
                }

              </>
            ) : (
              <TableContainer style={{ maxWidth: "100%" }}>
                <StyledTable size="medium" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      {type == "PAIN" &&

                        (
                          <>
                            <StyledTableCell align="center">
                              Type
                            </StyledTableCell>
                          </>)

                      }
                      {type === 'URINE' && (
                        <>
                          <StyledTableCell align="center">
                            Type
                          </StyledTableCell>
                        </>)}
                      <StyledTableCell align="center">
                        Date
                      </StyledTableCell>
                      {type === 'BLOOD_PRESSURE' && (
                        <>
                          <StyledTableCell align="center">
                            Tension systolique
                          </StyledTableCell>
                        </>)}


                      {type === 'BLOOD_PRESSURE' ? (
                        <>
                          <StyledTableCell align="center">
                            Tension diastolique
                          </StyledTableCell>
                        </>) : (<StyledTableCell align="center">
                          Seuil
                        </StyledTableCell>)}


                    </TableRow>
                  </TableHead>
                  <TableBody>


                    {(type !== 'BLOOD_PRESSURE' && type !== 'URINE' && type !== 'PAIN') && historyMeasures.map((row) => (
                      <TableRow key={row["id"]} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                        <StyledTableCell component="th" scope="row" align="center">
                          {dayjs(row['eventTime']).format('DD/MM/YYYY HH:mm')}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="center">
                          {
                            type == "STOOL" ?
                              matchStoolValue(row['value'])
                              : row['value']
                          }
                        </StyledTableCell>
                      </TableRow>
                    ))}

                    {type == 'BLOOD_PRESSURE' && historyMeasures.map((row) => (
                      <TableRow key={row["id"]} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <StyledTableCell component="th" scope="row" align="center">
                          {dayjs(row['eventTime']).format('DD/MM/YYYY HH:mm')}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="center">
                          {row['value']}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="center">
                          {row['value2']}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                    {type == 'URINE' && doubleHistoryArray.map((row) => (
                      <TableRow key={row["id"]} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <StyledTableCell component="th" scope="row" align="center">
                          {matchUrineName(row['type'])}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="center">
                          {row['eventTime']}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="center">
                          {matchQuantity(row['value'])}
                        </StyledTableCell>

                      </TableRow>
                    ))}
                    {type == "PAIN" && painHistory.map((row) => (
                      <TableRow key={row["id"]} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <StyledTableCell component="th" scope="row" align="center">
                          {matchPainName(row['type'])}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="center">
                          {dayjs(row['eventTime']).format('DD/MM/YYYY HH:mm')}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row" align="center">
                          {row['value']}
                        </StyledTableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </TableContainer>
            )
          )}
        </ StyledDialogContent>
        <DialogActions>
          <ActionsWrapper>
            <CancelBtn onClick={() => handleCloseDialog()} autoFocus> Fermer </CancelBtn>
          </ActionsWrapper>
        </DialogActions>
      </Dialog>

      {// this is limit history modal
      }
      <Dialog open={openHistoriqueModal} onClose={handleCloseHistorique}
        style={{ zIndex: 99 }}
        maxWidth="md" fullWidth>
        <DialogTitle style={{ color: '#035282' }}>Historique des seuils de {title}</DialogTitle>
        < StyledDialogContent>
          <TableContainer style={{ maxWidth: "100%" }} >
            <StyledTable size="medium" aria-label="a dense table" >
              <TableHead>
                <TableRow >
                  {type === 'BLOOD_PRESSURE' && (
                    <>
                      <StyledTableCell align="center">
                        Type de tension
                      </StyledTableCell>
                    </>
                  )}
                  <StyledTableCell align="center">
                    Seuil minimal
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Seuil maximal
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Ajouté le :
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    par :
                  </StyledTableCell>

                </TableRow>
              </TableHead>

              <TableBody>

                {type !== 'BLOOD_PRESSURE' && limitArray.map((row: any) => (
                  <TableRow key={row["id"]} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {row['minValue']}
                    </StyledTableCell>

                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {row['maxValue']}
                    </StyledTableCell>

                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {new Date(row['createdAt']).toLocaleDateString('fr-GB', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                      })}
                    </StyledTableCell>

                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="center">
                      {row['professionalId'].firstname + " " + row['professionalId'].lastname}
                    </StyledTableCell>

                  </TableRow>
                ))}


                {type === 'BLOOD_PRESSURE' && combinedArray.map((row) => (
                  <TableRow key={row["id"]} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {row['measureType'] === 'SYSTOLIC_BLOOD_PRESSURE' ? 'Tension systolique' : 'Tension diastolique'}
                    </StyledTableCell>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {row['minValue']}
                    </StyledTableCell>

                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {row['maxValue']}
                    </StyledTableCell>

                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {new Date(row['createdAt']).toLocaleDateString('fr-GB', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                      })}
                    </StyledTableCell>

                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="center">
                      {row['professionalId'].firstname + " " + row['professionalId'].lastname}
                    </StyledTableCell>

                  </TableRow>
                )
                )}
              </TableBody>


            </StyledTable>
          </TableContainer>
        </ StyledDialogContent>
        <DialogActions>
          <ActionsWrapper>
            <>
            </>
            <CancelBtn onClick={handleCloseHistorique} autoFocus >
              Retour
            </CancelBtn>
          </ActionsWrapper>
        </DialogActions>
      </Dialog>
      <Dialog
        open={OpenMeasureLimit}
        onClose={handleCloseMeasureLimit}
        maxWidth="md" fullWidth
        style={{ zIndex: 99 }}>

        <StyledDialogTitle sx={{}} id="customized-dialog-title">
          Seuils de {title}
        </StyledDialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => handleCloseMeasureLimit()}
          sx={{
            position: 'absolute',
            right: 20,
            top: 20,
            color: colors.dark_blue
          }}
        >
          <CloseIcon />
        </IconButton>
        <StyledDialogContent>
          {!showUpdateFields ? (
            <div style={{ fontSize: "25px", fontWeight: '200', fontFamily: 'open sans' }}>
              <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <Box style={{ padding: "20px" }}>
                  {
                    (maxValue === '' && minValue === '') ? (
                      <Typography style={{ padding: "5px" }}>
                        Aucun seuil n'est défini
                      </Typography>
                    ) : (
                      <>
                        <Typography style={{ padding: "5px" }}>Seuil minimal : {minToShow}</Typography>
                        <Typography style={{ padding: "5px" }}>Seuil maximal : {maxToShow}</Typography>
                        <Typography style={{ padding: "5px" }}>Mise en place le : {lastEdit} par : {lastEditor.lastname} {lastEditor.firstname}</Typography>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: 6, }}>
                          <CheckBox
                            checked={visibleForPatientToShow}
                            style={{
                              color: visibleForPatientToShow ? '#18C5BF' : 'grey',
                            }}
                            disabled
                          />
                          <Typography style={{ color: visibleForPatientToShow ? 'inherit' : 'grey', }} >
                            Afficher au patient
                          </Typography>
                        </div>
                      </>
                    )
                  }
                </Box>
                <Box style={{ backgroundColor: "transparent" }}>
                  {/* <Tooltip2 title="Afficher l'historique des seuils" placement="top">
                    <IconButton onClick={() => {
                      setOpenHistoriqueModal(true)
                    }}>
                      <HistoryIcon style={{ color: '#18C5BF', margin: 'auto', }} />
                    </IconButton>
                  </Tooltip2> */}

                </Box>

              </Box>
              {/* <div style={styles.buttons_layout}>
                <BlueSkyButton title={(minValue === '' || maxValue === '') ? 'Ajouter' : 'Modifier'} onClick={handleUpdate}></BlueSkyButton>
                {!(minValue === '' && maxValue === '') && <BlueSkyButton title={'Supprimer'} onClick={() => setdeleteModalOpen(true)} disabled={(minValue === '' || maxValue === '')}></BlueSkyButton>}
                <BlueSkyButton title={'Historique'} onClick={() => setOpenHistoriqueModal(true)}></BlueSkyButton>
              </div> */}
            </div>
          ) : (
            <Box style={{ padding: "20px" }}>
              {!isLoading && !isError && !isSuccess &&
                <Box>
                  {type === 'BLOOD_PRESSURE' ? (
                    <div>
                      <InputBlueBottom
                        type="text"
                        placeholder="Minimum systolique"
                        name="minValue"
                        value={minValueSys}
                        onChange={(e) => {
                          const newValue = parseInt(e.target.value);
                          setMinValueSys(e.target.value);
                          if (isValueWithinRange(newValue, 'SYSTOLIC_BLOOD_PRESSURE')) {
                            setMinValueSys(e.target.value);
                            setIsWithinRange(true);
                          } else {
                            setIsWithinRange(false);
                          }
                        }} />
                      <InputBlueBottom
                        type="text"
                        placeholder="Maximum systolique"
                        name="maxValue"
                        value={maxValueSys}
                        onChange={(e) => {
                          const newValue = parseInt(e.target.value);
                          setMaxValueSys(e.target.value);
                          if (isValueWithinRange(newValue, 'SYSTOLIC_BLOOD_PRESSURE')) {
                            setMaxValueSys(e.target.value);
                            setIsWithinRange(true);
                          } else {
                            setIsWithinRange(false);
                          }
                        }}
                      />
                      <InputBlueBottom
                        type="text"
                        placeholder="Minimum diastolique"
                        name="minValue"
                        value={minValueDia}
                        onChange={(e) => {
                          const newValue = parseInt(e.target.value);
                          setMinValueDia(e.target.value);
                          if (isValueWithinRange(newValue, 'DIASTOLIC_BLOOD_PRESSURE')) {
                            setMinValueDia(e.target.value);
                            setIsWithinRange(true);
                          } else {
                            setIsWithinRange(false);
                          }
                        }} />
                      <InputBlueBottom
                        type="text"
                        placeholder="Maximum diastolique"
                        name="maxValue"
                        value={maxValueDia}
                        onChange={(e) => {
                          const newValue = parseInt(e.target.value);
                          setMaxValueDia(e.target.value);
                          if (isValueWithinRange(newValue, 'DIASTOLIC_BLOOD_PRESSURE')) {
                            setMaxValueDia(e.target.value);
                            setIsWithinRange(true);
                          } else {
                            setIsWithinRange(false);
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <InputBlueBottom
                        type="text"
                        placeholder="min"
                        name="minValue"
                        value={minValue}
                        onChange={(e) => {
                          const newValue = parseInt(e.target.value);
                          setMinValue(e.target.value);
                          if (isValueWithinRange(newValue, type)) {
                            setMinValue(e.target.value);
                            setIsWithinRange(true);
                          } else {
                            setIsWithinRange(false);
                          }
                        }} />
                      <InputBlueBottom
                        type="text"
                        placeholder="max"
                        name="maxValue"
                        value={maxValue}
                        onChange={(e) => {
                          const newValue = parseInt(e.target.value);
                          setMaxValue(e.target.value);
                          if (isValueWithinRange(newValue, type)) {
                            setMaxValue(e.target.value);
                            setIsWithinRange(true);
                          } else {
                            setIsWithinRange(false);
                          }
                        }}
                      />
                    </div>
                  )}
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: 6, marginTop: "20px" }}>
                    <CheckBox
                      checked={visibleForPatient}
                      onChange={handleCheckboxChange}
                      inputProps={{ 'aria-label': 'Afficher au patient' }}
                      style={{ color: visibleForPatient ? '#18C5BF' : '#035282', borderColor: "inherit" }}
                    />
                    <Typography style={{ color: '#035282' }}> Afficher au patient</Typography>
                  </div>
                </Box>
              }
              {isLoading &&
                <Box sx={{ padding: "50px 0px" }}>
                  <SpinnerComponent />
                </Box>
              }
              {
                isError && <Box sx={{ padding: "50px 0px" }}>

                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                    <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer.</StyledDialogMessage>
                  </Box>
                </Box>
              }
              {
                isSuccess && <Box sx={{ padding: "50px 0px" }}>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                    <StyledDialogMessage>Seuil ajoutée avec succès.</StyledDialogMessage>
                  </Box>
                </Box>
              }
            </Box>

          )}

        </ StyledDialogContent>
        <DialogActions>
          <ActionsWrapper>
            {
              !(minValue === '' && maxValue === '') ?
                <CancelBtn onClick={() => { handleCloseMeasureLimit() }}>
                  Fermer
                </CancelBtn> :
                <CancelBtn onClick={() => handleCloseMeasureLimit()}>
                  {
                    !isLoading && !isError && !isSuccess ?
                      "Fermer" : "Annuler"
                  }
                </CancelBtn>
            }
            <Box>
              {!(minValue === '' && maxValue === '') && !showUpdateFields && (
                <TertiaryBtn autoFocus onClick={() => setdeleteModalOpen(true)} >
                  Supprimer
                </TertiaryBtn>
              )}
              {
                !isLoading && !isError && !isSuccess &&
                <ConfirmBtn onClick={() => handleUpdate()}>{(minValue === '' || maxValue === '') ? 'Ajouter' : 'Modifier'}</ConfirmBtn>
              }
            </Box>

          </ActionsWrapper>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteModalOpen} onClose={() => setdeleteModalOpen(false)} fullWidth={true}
        maxWidth='md'
        style={{ zIndex: 9999 }}
      >
        <StyledDialogTitle id="customized-dialog-title">
          Êtes-vous sûr de bien vouloir supprimer ce seuil de {title} ?
        </StyledDialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => closeDeleteModal()}
          sx={{
            position: 'absolute',
            right: 20,
            top: 20,
            color: colors.dark_blue
          }}
        >
          <CloseIcon />
        </IconButton>
        <StyledDialogContent>

          <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
            <Typography style={{ padding: "5px" }}>Seuil minimal : {minToShow}</Typography>
            <Typography style={{ padding: "5px" }}>Seuil maximal : {maxToShow}</Typography>
            <Typography style={{ padding: "5px" }}>Ajouté le : {lastEdit} par : {lastEditor.lastname} {lastEditor.firstname}</Typography>

          </Box>

        </ StyledDialogContent>
        <DialogActions>
          <ActionsWrapper>
            <CancelBtn onClick={() => closeDeleteModal()}>Annuler</CancelBtn>
            <ConfirmBtn onClick={() => handleDelete()}>Supprimer</ConfirmBtn>
          </ActionsWrapper>

        </DialogActions>
      </Dialog>
    </div >
  );
};
const styles = {
  units: {
    marginBottom: '-10px',
    marginLeft: '5px',
    fontSize: '17px',
    fontWeight: '10',
    color: '#035282', fontFamily: "open sans"
  },
  values: {
    fontWeight: '100', fontSize: '15px', fontFamily: "open sans"
  },
  container: {
    marginTop: '20px',
    position: 'relative',
    backgroundColor: '#fff',
    padding: '20px',
    width: '85%',
    height: '250px',
    borderRadius: '10px'
  },
  line: {
    color: '#035282',
    borderBottom: '2px solid #F4C274',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: "open sans"
  },
  limit: {
    display: "flex", alignItems: "center", justifyContent: "space-between"
  },
  title: {
    marginTop: "10px", color: "#035282"
  },
  buttons_layout: {
    display: 'flex', padding: '20px', gap: '20px'
  },
  datePicker_layout: {
    display: "flex", alignItems: "left", justifyContent: "left", marginLeft: "25px",
    width: "80%"
  },
  datepicker_text: {
    paddingTop: "5px", paddingBottom: "5px", fontFamily: "open sans",
  }


};
export default Constants;
