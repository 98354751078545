import axios from 'axios';
import { is, curryN, gte } from 'ramda';
import 'react-toastify/dist/ReactToastify.css';
import { requestChecker } from 'utils/RequestCheck';
const isWithin = curryN(3, (min, max, value) => {
  const isNumber = is(Number);
  return isNumber(min) && isNumber(max) && isNumber(value) && gte(value, min) && gte(max, value);
});
const { REACT_APP_BASE_URL } = process.env;
const in200s = isWithin(200, 299);
async function getHomes(
  setHomes: React.Dispatch<React.SetStateAction<any>>,
  acc_token: string,
  orgaId?: string,
  withTablet?: boolean
) {
  let url = REACT_APP_BASE_URL + '/home/get-homes?';
  if (orgaId) {
    url += 'organizationId=' + orgaId;
  }

  if (withTablet != undefined) {
    url += orgaId ? `&with_tablet=${withTablet}` : `with_tablet=${withTablet}`;
  }
  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      setHomes(response.data);
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function getUsersByHome(
  id: string,
  setHome: React.Dispatch<React.SetStateAction<any>>,
  setUsersData: React.Dispatch<React.SetStateAction<any>>,
  acc_token: string
) {
  let url = `${REACT_APP_BASE_URL}/home/get-home-details/${id}`;

  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout
    });

    if (requestChecker.in200s(response.status)) {
      setHome(response.data.data);
      setUsersData(response.data.data.residents);
      return response.data.data.residents;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function getHome(id: string, setHome: React.Dispatch<React.SetStateAction<any>>, acc_token: string) {
  let url = `${REACT_APP_BASE_URL}/home/get-home-details/${id}`;

  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout
    });

    if (requestChecker.in200s(response.status)) {
      setHome(response.data.data);
      console.log('getting home data', response.data.data);
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}
async function getDevicesByUser(id: string, acc_token: string) {
  let url = REACT_APP_BASE_URL + '/devices/devices/' + `${id}`;

  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout
    });

    if (requestChecker.in200s(response.status)) {
      return response.data.devices;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function assignTablet(data: any, acc_token: string) {
  let url = REACT_APP_BASE_URL + `/tablet/assign-tablet`;

  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout
    });

    console.log('requestChecker.in200s(response.status)', requestChecker.in200s(response.status));
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return response.status;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function findPatients(
  name: any,
  lastname: string,
  birthdate: string,
  setPatients: React.Dispatch<React.SetStateAction<any>>,
  acc_token: string
) {
  let url =
    REACT_APP_BASE_URL + `/admin/find-patients/lastname=` + lastname + `&firstname=` + name + `&birthdate=` + birthdate;

  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout
    });

    if (requestChecker.in200s(response.status)) {
      setPatients(response.data);
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function assignObject(data: any, acc_token: string) {
  let url = REACT_APP_BASE_URL + `/devices/assign-device`;

  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout
    });

    if (requestChecker.in200s(response.status)) {
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function postHome(data: any) {
  let url = REACT_APP_BASE_URL + `/member/register`;

  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      timeout: requestChecker.requestTimeout
    });

    if (requestChecker.in200s(response.status)) {
      console.log('Adding home');
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

export const homeService = {
  getHomes,
  assignTablet,
  getUsersByHome,
  getDevicesByUser,
  assignObject,
  getHome,
  findPatients,
  postHome
};
