import axios from 'axios';
import { requestChecker } from 'utils/RequestCheck';

const { REACT_APP_BASE_URL } = process.env;

async function getNotifications(acc_token: string) {
  let url = REACT_APP_BASE_URL + `/notifications`;
  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function updateNotification(acc_token: string, notif_id: string, isRead?: boolean, isArchived?: boolean) {
  let url = REACT_APP_BASE_URL + `/notifications?id=` + notif_id;
  if (isRead) {
    url += `&isRead=${isRead}`;
  }
  if (isArchived) {
    url += `&isArchived=${isArchived}`;
  }

  try {
    let response = await axios.request({
      url: url,
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function countUnreadNotifications(acc_token: string, patientId?: string) {
  let url = REACT_APP_BASE_URL + `/notifications/current-notifications`;

  if (patientId) {
    url += `&patientId=${patientId}`;
  }

  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

export const notifService = {
  getNotifications,
  updateNotification,
  countUnreadNotifications
};
