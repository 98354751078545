import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import PageWithHeader from 'components/ReusableDivs/PageWithHeader';
import PageWrapper from 'components/ReusableDivs/PageWrapper';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { OrganizationService } from 'services/organizationsService';
import { formatPhoneNumber } from 'utils/formatters/phoneNumberFormatter';

function MyOrganizations() {

    const [organizations, setOrganizations] = useState([])
    const acc_token = useSelector((state: any) => state.userDomisante.userToken);

    useEffect(() => {
        OrganizationService.getUserOrganizations(acc_token, setOrganizations)
    }, [])
    return (
        <>
            <PageWithHeader>
                <PageWrapper title={'Mes Organisations'} >
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} size="medium" aria-label="a dense table">
                            <TableHead
                                sx={{
                                    color: 'red',
                                    borderBottom: '2px solid black'
                                }}
                            >
                                <TableRow
                                    sx={{
                                        borderBottom: '2px solid #C2D4D8'
                                    }}
                                >
                                    <TableCell sx={{ color: '#545454', fontWeight: 'bold' }} align="left">
                                        Nom
                                    </TableCell>
                                    <TableCell sx={{ color: '#545454', fontWeight: 'bold' }} align="left">
                                        Adresse
                                    </TableCell>
                                    <TableCell sx={{ color: '#545454', fontWeight: 'bold' }} align="left">
                                        Email
                                    </TableCell>
                                    <TableCell sx={{ color: '#545454', fontWeight: 'bold' }} align="left">
                                        Numéro de téléphone{' '}
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {organizations.length > 0 &&
                                    organizations.map((row) => (
                                        <TableRow key={row['id']} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row" align="left">
                                                {row['name']}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="left">
                                                {row['address']}
                                            </TableCell>
                                            <TableCell align="left" style={{
                                                maxWidth: "150px",
                                                wordWrap: "break-word",
                                                overflowWrap: "break-word",
                                            }}>{row['email']}</TableCell>
                                            <TableCell align="left">{formatPhoneNumber(row['phoneNumber'])}</TableCell>

                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </PageWrapper>
            </PageWithHeader>


        </>
    )
}

export default MyOrganizations