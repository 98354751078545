import PageWithHeader from 'components/ReusableDivs/PageWithHeader';
import PageWrapper from 'components/ReusableDivs/PageWrapper';
import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Box,
  Checkbox,
  Typography,
} from '@mui/material';
import { usersService } from '../../services/usersService';
import styled from 'styled-components';
import { useNavigate, useParams, } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import NoData from 'components/ReusableDivs/NoData';
import Empty from 'components/ReusableDivs/Empty';
import "../../styles/tables/tableStyles.css"
import { StyledTable, StyledTableCell } from 'styles/tables/TableStyles';
import { CancelBtn, ConfirmBtn, StyledDialogContent, StyledDialogContentText, StyledDialogMessage, StyledDialogTitle } from 'styles/dialogs/DialogStyles';
import { InputBlueBottom } from 'components/InputControls.tsx/Inputs';
import { BsCheckSquareFill } from "react-icons/bs";
import { CgCloseR } from "react-icons/cg";
import { FaCircle } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import SpinnerComponent from 'components/spinners/SpinnerComponent';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { Role, colors } from 'utils/Constants';

export const ContentWrapper = styled.div`
  display: 'flex';
  flex-direction: 'row';
  align-items: 'center';
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0px 10px;
`;

type userProps = {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  active: boolean;
  PatientFamily: {
    constants: boolean,
    agenda: boolean,
    transmissions: boolean,
    teleassistance: boolean
  }
};

function PatientRelatives() {
  const [users, setUsers] = useState<userProps[] | []>([]);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [managerId, setmanagerId] = useState('');
  const [isEmpty, setIsEmpty] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [edit, setedit] = useState(false)
  const [refresh, setrefresh] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [isError, setisError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [RelativeData, setRelativeData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    constants: false,
    transmissions: false,
    agenda: false,
    teleassistance: false,
    id: ""
  })
  const [NameChanged, setNameChanged] = useState(false)

  const acc_token = useSelector((state: any) => state.userDomisante.userToken);
  const connectedRole = useSelector((state: any) => state.userDomisante.userInfo.roles);


  // useEffect(() => {
  //   if (openAdd && edit && managerId != "") {
  //     const found = users.find(element => element.id == managerId);
  //     if (found) {
  //       setRelativeData({
  //         firstname: found.firstname,
  //         lastname: found.lastname,
  //         email: found.email,
  //         constants: found.PatientFamily.constants,
  //         transmissions: found.PatientFamily.transmissions,
  //         agenda: found.PatientFamily.agenda,
  //         teleassistance: found.PatientFamily.teleassistance
  //       })

  //     }

  //   }
  // }, [managerId, edit])


  let title = 'Liste des proches';
  const { firstname, lastname, id } = useParams()

  if (firstname && lastname) {
    title = 'Liste des proches de ' + firstname + ' ' + lastname;
  }


  async function fetchRelatives() {
    let response = await usersService.fetchAssociatedPatientRelatives(setUsers, id!, acc_token);
    if (response) {
      if (users.length === 0) {
        setIsEmpty(true);
      }
    }


  }
  useEffect(() => {

    fetchRelatives()
    console.log("fetched patient relatives", users)

  }, [refresh,]);


  async function removeRelative(id: string, id_manager: string) {
    setisLoading(true)

    let response = await usersService.removeRelative(id_manager, id!, acc_token);
    if (response == 200) {
      setIsSuccess(true)
      setisLoading(false)

      setrefresh(!refresh)

    } else {
      setisError(true)
      setisLoading(false)
    }

  }

  function closeDeleteModal() {
    setDeleteModalIsOpen(false);
    setIsSuccess(false)
    setisError(false)
  }

  function emptyRelativeData() {
    setRelativeData({
      firstname: "",
      lastname: "",
      email: "",
      constants: false,
      transmissions: false,
      agenda: false,
      teleassistance: false,
      id: ""
    })
  }

  const handleChangeRelative = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setRelativeData({
      ...RelativeData, [name]: value
    })
  }
  const closeAddManager = async () => {
    setOpenAdd(false)
    emptyRelativeData()
    setisError(false)
    setIsSuccess(false)
    setedit(false)
  }

  async function AddRelative() {
    setisLoading(true)
    let response = await usersService.addRelative(id!, RelativeData, acc_token)
    if (response == 200) {
      setisLoading(false)
      setIsSuccess(true)
      setrefresh(!refresh)
    } else {
      setisError(true)
      setisLoading(false)
    }
  }

  async function UpdateRelative() {
    setisLoading(true)
    /**
     * Call updateRelative name and lastname
     */
    if (NameChanged == true) {

      let data = {
        lastname: RelativeData.lastname,
        firstname: RelativeData.firstname
      }
      let updateUser = await usersService.updateUserData(data, acc_token, RelativeData.id)
      if (updateUser) {
        console.log("updateUser", updateUser)
      }
    }

    let response = await usersService.updateRelative(managerId, id!, RelativeData.constants.toString(),
      RelativeData.agenda.toString(), RelativeData.transmissions.toString(), RelativeData.teleassistance.toString(), acc_token)
    if (response == 200) {
      setisLoading(false)
      setIsSuccess(true)
      setrefresh(!refresh)
    }
    else {
      setisError(true)
      setisLoading(false)
    }
  }

  return (
    <>
      <PageWithHeader>
        <PageWrapper title={title} btnTitle={connectedRole != null && connectedRole.includes(Role.ORGANIZATION_ADMIN) ? 'Ajouter' : undefined}
          btnClick={() => setOpenAdd(true)}
          backBtnContainer={true}
        >
          <ContentWrapper>
            {users.length > 0 && (
              <TableContainer>
                <StyledTable size="medium">
                  <TableHead>
                    <TableRow
                      sx={{
                        borderBottom: '2px solid #C2D4D8'
                      }}
                    >
                      <StyledTableCell align="left">
                        Nom
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Email
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Constantes
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Agenda
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Téléassistance
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Transmissions
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Actions{' '}
                      </StyledTableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((row) => (
                      <TableRow key={row['id']} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                        <StyledTableCell component="th" scope="row" align="left" >
                          <div style={{ display: "inline", flexDirection: "row", alignItems: "center" }}>

                            <div style={{ display: "inline", justifyContent: "center", }}>
                              {row.active ?
                                <FaCircle color="#18C5BF" size="15" /> :
                                <FaCircle color='#C2D4D8' size="15" />
                              }
                            </div>
                            <div style={{ display: "inline", flexDirection: "row", paddingLeft: 10 }}>
                              <div style={{ display: "inline", paddingRight: 5 }}>{row['firstname']}
                              </div>
                              <div style={{ display: "inline" }}>
                                {row['lastname']}
                              </div>

                            </div>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="left"
                          style={{
                            maxWidth: "150px",
                            wordWrap: "break-word",
                            overflowWrap: "break-word",
                          }}
                        >{row['email']}</StyledTableCell>
                        <StyledTableCell align="center">
                          <div style={{ display: "flex", justifyContent: "center", }}>
                            {row.PatientFamily.constants ?
                              <BsCheckSquareFill color="#F4C274" size="19" /> :
                              <CgCloseR color='#C2D4D8' size="20" />
                            }
                          </div>


                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div style={{ display: "flex", justifyContent: "center", }}>
                            {row.PatientFamily.agenda ?
                              <BsCheckSquareFill color="#F4C274" size="19" /> :
                              <CgCloseR color='#C2D4D8' size="20" />
                            }
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div style={{ display: "flex", justifyContent: "center", }}>
                            {row.PatientFamily.teleassistance ?
                              <BsCheckSquareFill color="#F4C274" size="19" /> :
                              <CgCloseR color='#C2D4D8' size="20" />
                            }
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div style={{ display: "flex", justifyContent: "center", }}>
                            {row.PatientFamily.transmissions ?
                              <BsCheckSquareFill color="#F4C274" size="19" /> :
                              <CgCloseR color='#C2D4D8' size="20" />
                            }
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Tooltip title="Modifier" placement="top">
                            <IconButton
                              onClick={() => {
                                setOpenAdd(true);
                                setedit(true);
                                setmanagerId(row['id']);
                                console.log("row ___________", row)
                                setRelativeData({
                                  firstname: row['firstname'],
                                  lastname: row['lastname'],
                                  email: row["email"],
                                  constants: row["PatientFamily"]["constants"],
                                  transmissions: row["PatientFamily"]["transmissions"],
                                  agenda: row["PatientFamily"]["agenda"],
                                  teleassistance: row["PatientFamily"]["teleassistance"],
                                  id: row["id"]
                                })
                              }}
                            >
                              <MdEdit style={{ color: '#F4C274' }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Supprimer" placement="top">
                            <IconButton
                              onClick={() => {
                                setDeleteModalIsOpen(true);
                                setmanagerId(row['id']);
                              }}
                            >
                              <DeleteIcon style={{ color: '#035282' }} />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </TableContainer>
            )}
            {users.length === 0 && !isEmpty && <NoData />}
            {users.length === 0 && isEmpty && <Empty />}
          </ContentWrapper>

          <Dialog
            maxWidth="md"
            fullWidth={true}
            open={deleteModalIsOpen}
            onClose={closeDeleteModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex: 99 }}
          >
            <StyledDialogTitle id="alert-dialog-title">{'Êtes-vous sûr de vouloir supprimer ce proche ?'}</StyledDialogTitle>

            <IconButton
              aria-label="close"
              onClick={() => closeDeleteModal()}
              sx={{
                position: 'absolute',
                right: 20,
                top: 20,
                color: colors.dark_blue
              }}
            >
              <CloseIcon />
            </IconButton>

            {!isLoading && !isError && !isSuccess &&
              <>
                <StyledDialogContent>
                  <StyledDialogContentText id="alert-dialog-description">La suppression est définitive</StyledDialogContentText>
                </StyledDialogContent>

              </>}
            {
              isError && <Box sx={{ padding: "50px 0px" }}>

                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer .</StyledDialogMessage>
                </Box>
              </Box>
            }
            {
              isSuccess && <Box sx={{ padding: "50px 0px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Proche supprimé avec succès .</StyledDialogMessage>
                </Box>
              </Box>
            }
            {isLoading && <Box sx={{ padding: "50px 0px" }}>

              <SpinnerComponent />
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
              </Box>
            </Box>
            }
            <DialogActions>
              <ActionsWrapper>
                <CancelBtn onClick={() => closeDeleteModal()}>{
                  !isLoading && !isError && !isSuccess ?
                    "Annuler" : "Fermer"
                }</CancelBtn>
                {!isLoading && !isError && !isSuccess && <ConfirmBtn onClick={() => removeRelative(id!, managerId)} autoFocus>
                  Supprimer
                </ConfirmBtn>}
              </ActionsWrapper>
            </DialogActions>
          </Dialog>
        </PageWrapper>
      </PageWithHeader >
      <Dialog
        style={{ zIndex: 99 }}
        maxWidth="md"
        fullWidth={true}
        open={openAdd} onClose={() => closeAddManager()} >
        <StyledDialogTitle>Ajouter un proche</StyledDialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => closeAddManager()}
          sx={{
            position: 'absolute',
            right: 20,
            top: 20,
            color: colors.dark_blue
          }}
        >
          <CloseIcon />
        </IconButton>

        <StyledDialogContent style={{ paddingRight: "5%" }} >

          {!isLoading && !isSuccess && !isError &&
            <>

              {
                edit ? <StyledDialogContentText>
                  Veuillez sélectionner le nom, prénom et email du proche à modifier.
                </StyledDialogContentText> :
                  <StyledDialogContentText>
                    Veuillez sélectionner le nom, prénom et email du proche à assigner.
                  </StyledDialogContentText>
              }

              <InputBlueBottom
                type="text"
                name="lastname"
                value={RelativeData.lastname}
                onChange={(e) => { handleChangeRelative(e); setNameChanged(true) }}
                placeholder="Nom"
                disabled={edit ? true : false}
              />
              <InputBlueBottom
                type="text"
                name="firstname"
                value={RelativeData.firstname}
                onChange={(e) => { handleChangeRelative(e); setNameChanged(true) }}
                placeholder="Prénom"
                disabled={edit ? true : false}
              />
              <InputBlueBottom
                type="email"
                name="email"
                value={RelativeData.email}
                onChange={(e) => handleChangeRelative(e)}
                placeholder="Email"
                disabled={edit ? true : false}
              />
              <div style={{ marginTop: 30 }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: 6, }}>
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: '#18C5BF'
                        }
                      }}
                      checked={RelativeData.constants}
                      onChange={(event: any) => {
                        setRelativeData({
                          ...RelativeData,
                          constants: event.target.checked
                        });
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}

                    />
                    <Typography>
                      A accès aux constantes
                    </Typography>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: 6, }}>

                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: '#18C5BF'
                        }
                      }}
                      checked={RelativeData.agenda}
                      onChange={(event: any) => {
                        setRelativeData({
                          ...RelativeData,
                          agenda: event.target.checked
                        });
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}

                    />
                    <Typography>
                      A accès à l'agenda
                    </Typography>
                  </div>
                </div>

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: 6, }}>

                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: '#18C5BF'
                        }
                      }}
                      checked={RelativeData.transmissions}
                      onChange={(event: any) => {
                        setRelativeData({
                          ...RelativeData,
                          transmissions: event.target.checked
                        });
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}

                    />
                    <Typography>
                      A accès aux transmissions
                    </Typography>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: 6, }}>

                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: '#18C5BF'
                        }
                      }}
                      checked={RelativeData.teleassistance}
                      onChange={(event: any) => {
                        setRelativeData({
                          ...RelativeData,
                          teleassistance: event.target.checked
                        });
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}

                    />
                    <Typography>
                      A accès à la téléassistance
                    </Typography>
                  </div>
                </div>
              </div>
            </>
          }
          {isLoading && <Box sx={{ padding: "50px 0px" }}>

            <SpinnerComponent />

            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
              <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
            </Box>
          </Box>}
          {
            isSuccess &&
            <Box sx={{ padding: "50px 0px" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                <StyledDialogMessage >
                  {
                    edit ? "Proche mis à jour avec succès." : " Proche ajouté avec succès."
                  }
                </StyledDialogMessage>
              </Box>
            </Box>
          }
          {
            isError && <Box sx={{ padding: "50px 0px" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer .</StyledDialogMessage>
              </Box>
            </Box>
          }
        </StyledDialogContent>

        {
          <DialogActions>
            <ActionsWrapper>
              <CancelBtn onClick={() => closeAddManager()}>{
                !isLoading && !isError && !isSuccess ?
                  "Annuler" : "Fermer"
              }</CancelBtn>

              {!isLoading && !isError && !isSuccess && <>  {
                edit ? <ConfirmBtn
                  onClick={() => UpdateRelative()}
                >Modifier</ConfirmBtn> :
                  <ConfirmBtn
                    onClick={() => AddRelative()}
                  >Ajouter</ConfirmBtn>
              }</>}
            </ActionsWrapper>
          </DialogActions>
        }
      </Dialog>
    </>
  );
}

export default PatientRelatives;
