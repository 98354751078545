import './App.css';
import 'material-icons/iconfont/material-icons.css';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';

import Register from './Inscription/Register';
import Login from './screens/login';
import { ChangePassword, ResetPassword } from './screens/password';
import { Homes } from 'screens/homes/Homes';
import HomeDetails from 'screens/home/HomeDetails';
import UsersList from 'screens/Menu/UsersList';
import TabletsList from 'screens/Menu/TabletsList';
import Devices from 'screens/Menu/Devices';
import Preventions from 'screens/Menu/Preventions';
import Profile from 'screens/Menu/Profile';
import Patients from 'screens/Menu/Patients';
import PrivateRoutes from 'utils/PrivateRoutes';
import CalendarPage from './screens/calendar/CalendarPage';
import MyCalendarPage from './screens/calendar/MyCalendarPage';
import PasswordChanged from 'screens/password/PasswordChanged';
import Organizations from 'screens/Menu/Organizations';
import OrganisationDetails from 'screens/organisations/OrganizationDetails';
import MyOrganizations from 'screens/organisations/MyOrganizations';
import OrgaChoice from 'screens/organisations/OrgaChoice';
import ManagerPatients from 'screens/patients/ManagerPatients';
import PatientManagers from 'screens/patients/PatientManagers';
import PatientRelatives from 'screens/patients/PatientRelatives';
import Redirect404Page from 'screens/errors/Redirect404Page';
import PatientDetails from 'screens/patients/PatientDetails';
import Alerts from 'screens/Menu/Alerts';
import HomeScreen from 'screens/Menu/Dashboard';
import Dashboard from 'screens/Menu/Dashboard';
import 'react-toastify/dist/ReactToastify.css';
import MeasureLimits from 'screens/Menu/MeasureLimits';
import RoleChoice from './screens/login/RoleChoice';
import UpdatePassword from 'screens/password/UpdatePassword';
import VerifyOTP from './screens/login/VerifyOTP';
// Lazy load the components
// const Login = lazy(() => import('./screens/login'));

// const Register = lazy(() => import('./Inscription/Register'));
// const ChangePassword = lazy(() => import('./screens/password/ChangePassword'));
// const ResetPassword = lazy(() => import('./screens/password/ResetPassword'));
// const Homes = lazy(() => import('screens/homes/Homes'));
// const HomeDetails = lazy(() => import('screens/home/HomeDetails'));
// const UsersList = lazy(() => import('screens/Menu/UsersList'));
// const TabletsList = lazy(() => import('screens/Menu/TabletsList'));
// const Devices = lazy(() => import('screens/Menu/Devices'));
// const Preventions = lazy(() => import('screens/Menu/Preventions'));
// const Profile = lazy(() => import('screens/Menu/Profile'));
// const Patients = lazy(() => import('screens/Menu/Patients'));
// const PrivateRoutes = lazy(() => import('utils/PrivateRoutes'));
// const CalendarPage = lazy(() => import('./screens/calendar/CalendarPage'));
// const MyCalendarPage = lazy(() => import('./screens/calendar/MyCalendarPage'));
// const PasswordChanged = lazy(() => import('screens/password/PasswordChanged'));
// const Organizations = lazy(() => import('screens/Menu/Organizations'));
// const OrganisationDetails = lazy(() => import('screens/organisations/OrganizationDetails'));
// const MyOrganizations = lazy(() => import('screens/organisations/MyOrganizations'));
// const OrgaChoice = lazy(() => import('screens/organisations/OrgaChoice'));
// const ManagerPatients = lazy(() => import('screens/patients/ManagerPatients'));
// const PatientManagers = lazy(() => import('screens/patients/PatientManagers'));
// const PatientRelatives = lazy(() => import('screens/patients/PatientRelatives'));
// const Redirect404Page = lazy(() => import('screens/errors/Redirect404Page'));
// const PatientDetails = lazy(() => import('screens/patients/PatientDetails'));
// const Alerts = lazy(() => import('screens/Menu/Alerts'));
// const HomeScreen = lazy(() => import('screens/Menu/Dashboard'));
// const Dashboard = lazy(() => import('screens/Menu/Dashboard'));
// const MeasureLimits = lazy(() => import('screens/Menu/MeasureLimits'));
// const RoleChoice = lazy(() => import('./screens/login/RoleChoice'));
const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

function App() {
  const theme = useTheme({
    palette: {
      primary: {
        main: 'red'
      }
    },
    typography: {
      fontFamily: ['open sans'].join(',')
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route element={<PrivateRoutes />}>
              //list of protected Routes
              <Route exact path="/get-homes" element={<Homes />} />
              <Route exact path="/home-details/:id" element={<HomeDetails />} />
              <Route exact path="/users" element={<UsersList />} />
              <Route exact path="/tablets" element={<TabletsList />} />
              <Route exact path="/devices" element={<Devices />} />
              <Route exact path="/preventions" element={<Preventions />} />
              <Route exact path="/profile" element={<Profile />} />
              <Route exact path="/patients" element={<Patients />} />
              <Route exact path="/calendar/:firstname/:lastname/:id" element={<CalendarPage />} />
              <Route exact path="/mycalendar" element={<MyCalendarPage />} />
              <Route exact path="/organizations" element={<Organizations />} />
              <Route exact path="/organization-details/:id" element={<OrganisationDetails />} />
              <Route exact path="/my-organizations" element={<MyOrganizations />} />
              <Route exact path="/orga-choice" element={<OrgaChoice />} />
              <Route exact path="/role-choice" element={<RoleChoice />} />
              <Route exact path="/manager-patients/:firstname/:lastname/:id" element={<ManagerPatients />} />
              <Route exact path="/patient-managers/:firstname/:lastname/:id" element={<PatientManagers />} />
              <Route exact path="/patient-relatives/:firstname/:lastname/:id" element={<PatientRelatives />} />
              <Route exact path="/patient-details/:patientId" element={<PatientDetails />} />
              <Route exact path="/alerts" element={<Alerts />} />
              <Route exact path="/measure-limits/:patientId" element={<MeasureLimits />} />
              <Route exact path="/measure-limits/" element={<MeasureLimits />} />
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/update-pwd" element={<UpdatePassword />} />
            </Route>

            <Route
              exact
              path="/"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <Login />{' '}
                </Suspense>
              }
            />

            <Route exact path="/register" element={<Register />} />
            <Route exact path="/reset-pwd" element={<ResetPassword />} />
            <Route exact path="/verify-otp/:temp_token/:channel" element={<VerifyOTP />} />
            <Route exact path="/change-pwd" element={<ChangePassword />} />
            <Route exact path="/changed-pwd" element={<PasswordChanged />} />
            <Route path="*" element={<Redirect404Page />} />
          </Routes>
        </BrowserRouter>
      </div>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default connect(mapStateToProps)(App);
