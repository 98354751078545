import PageWithHeader from 'components/ReusableDivs/PageWithHeader'
import PageWrapper from 'components/ReusableDivs/PageWrapper'
import React, { useState, useEffect } from 'react'
import { ActionsWrapper } from './UsersList'
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { tabletsService } from 'services/tabletsService';
import NoData from 'components/ReusableDivs/NoData';
import IconButton from '@mui/material/IconButton';
import { CssTextField, InputBlueBottom } from 'components/InputControls.tsx/Inputs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import 'react-toastify/dist/ReactToastify.css';
import Empty from 'components/ReusableDivs/Empty';
import { homeService } from 'services/homeService';
import { StyledTable, StyledTableCell, StyledTableRow } from 'styles/tables/TableStyles';
import "../../styles/tables/tableStyles.css"
import { ContentWrapper } from 'styles/pages/pageStyles';
import { CancelBtn, ConfirmBtn, StyledDialogContent, StyledDialogContentText, StyledDialogMessage, StyledDialogTitle } from 'styles/dialogs/DialogStyles';
import { in200s } from 'utils/RequestCheckFns';
import SpinnerComponent from 'components/spinners/SpinnerComponent';
import { Autocomplete, Box, FormControl, InputLabel, OutlinedInput, Popper, TextField, TextFieldProps, Tooltip, Typography, } from '@mui/material';
import { useSelector } from 'react-redux';
import { Role, colors, } from 'utils/Constants';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckBox from '@mui/material/Checkbox';
import ActionButton from 'components/InputControls.tsx/ActionButton';
import { StyledHead } from './Patients';
import OrangeButton from 'components/InputControls.tsx/OrangeButton';
import { devicesService } from 'services/devicesService';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';

interface Home {
  value: string;
  label: string;
  // Add any other properties of the Patient object
}

const useStyles = makeStyles({
  errorMessage: {
    color: "#C2D4D8", fontSize: "20px"
  },
  errorBox: {
    display: "flex", alignItems: "center", justifyContent: "center", margin: "10px"
  }
});

let editModes = {
  edit: "Edit",
  add: "Add"
}
function TabletsList() {
  let emptyHome = { value: "", label: "" }
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [tablets, setTablets] = useState([])
  const [modalIsOpen, setIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [elementToDelete, setelementToDelete] = useState("")
  const [refresh, setRefresh] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [HomeData, setHomeData] = useState([])
  const [newTablet, setNewTablet] = useState({
    imei: "",
    deviceID: ""
  })
  const [isError, setisError] = useState(false)
  const [isSuccess, setisSuccess] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [homeToDiassociate, sethomeToDiassociate] = useState("")
  const [tabletToDiassociate, settabletToDiassociate] = useState("")
  const acc_token = useSelector((state: any) => state.userDomisante.userToken);
  const managedOrgaId = useSelector((state: any) => state.userDomisante.managedOrganizationId);
  const userRole = useSelector((state: any) => state.userDomisante.userRole);
  const orgaIdfromRedux = useSelector((state: any) => state.userDomisante.managedOrganizationId);
  const [tabletCheck, setTabletCheck] = useState(true)
  const [noTabletCheck, setNoTabletCheck] = useState(true)
  const [tabletListToShow, setTabletsListToshow] = useState<any[]>([])
  const [searchKey, setsearchKey] = useState("")
  const [selectedHome, setSelectedHome] = useState<Home>({ value: "", label: "" });
  const [homes, setHomes] = useState<Home[] | any[]>([])
  const [homesToDisplay, setHomesToDisplay] = useState<Home[] | any[]>([])

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedTablet, setselectedTablet] = useState<any>(null)
  const [doc2uSerialumber, setdoc2uSerialumber] = useState<any>(null)
  const [sim, setSim] = useState<any>(null)

  const [refreshHomesList, setRefreshHomesList] = useState(false)
  const [tabletSerialNumber, setTabletSerialNumber] = useState("")
  const [editMode, seteditMode] = useState(editModes.add)

  /**
   * Change handlers
   */


  const handleChangeNewTablet = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setNewTablet({
      ...newTablet, [name]: value
    })
  }
  const handleDoc2uChangeSerial = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setdoc2uSerialumber(value)
  }
  const handleTabletCheck = (event: any) => {
    const isChecked = event.target.checked;
    setTabletCheck(isChecked)
  };
  const handleNoTabletCheck = (event: any) => {
    const isChecked = event.target.checked;
    setNoTabletCheck(isChecked)
  };

  const handleChangeHome = (e: any, v: any) => {
    setSelectedHome(v);
    if (v == null) {
      setSelectedHome(emptyHome);
    }
  }
  /**
   * Fetching and formatting data
   */
  async function fetchHomes() {
    let response
    if (userRole === Role.ORGANIZATION_ADMIN && orgaIdfromRedux !== null) {
      response = await homeService.getHomes(setHomes, acc_token, orgaIdfromRedux, false)
    }
    else {
      response = await homeService.getHomes(setHomes, acc_token, undefined, false)
    }
    if (response) {
      formatHomesList(response)
    }
  }
  function formatHomesList(list: any[]) {
    let finalList = [];
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      finalList.push({
        label: `${element.adress1} ${element.adress2} ${element.zipCode} ${element.city}`, value: element.id,
        key: element.id
      })
    }
    setHomes(finalList)
    setHomesToDisplay(finalList)
  }
  const handleSearch = async () => {
    /**
     * *Rajouter des conditions pour le lancement de la recherche
     */
    if (searchKey.length > 0) {
      await tabletsService.getAllTablets(setTablets, acc_token, managedOrgaId, searchKey)
    }
  };

  function emptyUpdateTabFields() {
    setdoc2uSerialumber("");
    setSelectedHome(emptyHome)
    setTabletSerialNumber("")
    setSim("")
    setselectedTablet(null)
  }

  function prefillUpdateTabFields(tablet: any) {
    setHomesToDisplay(homes)

    if (tablet["deviceID"]) {
      setTabletSerialNumber(tablet["deviceID"])
    }
    if (tablet["doc2u"] != null) {
      setdoc2uSerialumber(tablet["doc2u"]["serialNumber"])
    }
    if (tablet["imei"] != null) {
      setSim(tablet["imei"])
    }
    if (tablet['Home'] != null) {
      let home = tablet['Home']
      let homeId = tablet['Home']['id']
      let homeLabel = `${home['adress1']} ${home['adress2']} ${home['zipCode']} ${home['city']}`

      setHomesToDisplay([...homes, {
        label: homeLabel, value: homeId
      }])

      setSelectedHome({
        label: homeLabel, value: homeId
      })
    }
  }


  /**
   * UseEffects
   */
  useEffect(() => {
    fetchHomes()
  }, [refreshHomesList])


  useEffect(() => {

    if (tablets.length === 0) {
      setIsEmpty(true)
    }
    if (userRole == Role.ORGANIZATION_ADMIN) {
      tabletsService.getAllTablets(setTablets, acc_token, managedOrgaId)
    }
    else {
      tabletsService.getAllTablets(setTablets, acc_token)
    }
  }, [refresh,])
  useEffect(() => {
    if (tabletCheck == false && noTabletCheck == false) {
      setTabletsListToshow([])
    }

    if (tabletCheck == false && noTabletCheck == true) {
      const filteredHomes = tablets.filter(tablet => tablet["Home"] == null);
      setTabletsListToshow(filteredHomes)
    }
    if (tabletCheck == true && noTabletCheck == false) {
      const filteredHomes = tablets.filter(tablet => tablet["Home"] != null);
      setTabletsListToshow(filteredHomes)

    }
    if (tabletCheck == true && noTabletCheck == true) {
      setTabletsListToshow(tablets)
    }
  }, [tabletCheck, noTabletCheck, tablets]);


  /**
   * Modal handlers
   */
  function closeModal() {
    setIsOpen(false);
    setNewTablet({
      imei: "",
      deviceID: ""
    })

  }
  function closeDeleteModal() {
    setDeleteModalIsOpen(false);
    setisError(false)
    setisSuccess(false)
    setisLoading(false)
  }
  const handleCloseUpdateModal = () => {

    setOpen(false)
    setisError(false)
    setisLoading(false)
    setisSuccess(false)
  }

  function openDeleteModal(id: string) {
    setDeleteModalIsOpen(true);
    setelementToDelete(id)
  }

  /**
   * Tablet crud api calls
   */
 

  async function createNewTablet() {
    let newTablet = {
      deviceID: tabletSerialNumber,
      imei: sim,
      organizationId: ""
    }
    if (userRole === Role.ORGANIZATION_ADMIN && orgaIdfromRedux !== null) {
      newTablet.organizationId = orgaIdfromRedux
    }

    let tabletCreation = await tabletsService.addNewTablet(newTablet, acc_token)

    if (tabletCreation) {
      let newTablet = {
        id: tabletCreation['tabletId'],
        deviceID: tabletCreation["deviceID"],
        imei: tabletCreation["imei"],
        organizationId: tabletCreation["organizationId"],
        doc2u: null,
        Home: null
      }
      setselectedTablet(newTablet)
      return newTablet
    } else {
      return null
    }
  }

  async function associateTabletDoc2u(tablet: any) {
    let data = {
      deviceSerial: doc2uSerialumber,
      tabletId: tablet['id'], imei: ""
    }
    if (sim && sim.length > 0) {
      data = {
        deviceSerial: doc2uSerialumber,
        tabletId: tablet['id'],
        imei: sim
      }
    }
    let response = await devicesService.associateTabletDoc2u(data, acc_token)
    if (response) {
      return true

    } else {
      return false
    }
  }
  async function updateTabletDetails(tabletId: string) {
    let updateTabletFields

    if (sim && sim.length > 0) {
      updateTabletFields = {
        imei: sim
      }
    } else {
      updateTabletFields = {
        imei: null
      }
    }

    console.log("updateTablet fields *******************", updateTabletFields)
    let tabletUpdate = await tabletsService.updateTablet(updateTabletFields, acc_token, tabletId)
    if (tabletUpdate) {
      return true
    } else {
      return false
    }
  }


  async function diassociateHome(initialHomeId: string, tabletId: string) {
    let diassociateResponse = await tabletsService.diassociateTablet(initialHomeId, tabletId, acc_token, managedOrgaId)

    if (diassociateResponse) {
      return true
    } else {
      return false
    }
  }


  async function associateHome(newHomeId: string, newDeviceId: string) {

    /**
      * Calling assign tablet api to assign selected home to tablet if :
      * * homeid has changed
      */
    let data = {
      deviceID: newDeviceId,
      home_id: newHomeId
    }

    let associateResponse = await homeService.assignTablet(data, acc_token);

    if (associateResponse) {
      return true
    } else {
      return false
    }
    // setRefreshHomesList(!refreshHomesList)
  }

  async function createOrUpdateTablet2() {
    /**
     * In case of tablet creation 
     */
    let closeModal = false
    let errorOccured = false

    let tablet = selectedTablet

    /**
    * Here we are creating a new tablet
    */
    if (tablet == null && tabletSerialNumber.length > 0) {
      /**
       *  first case : Adding a new tablet with a new  serialNumber 
       */
      tablet = await createNewTablet()
      if (tablet == null) {
        errorOccured = true
      }

    }

    /**
     * In this level, we have a tablet object that contains Sim , doc2U, 
     */

    if (tablet != null) {
      let simChanged = sim != tablet["imei"]
      let doc2uChanged = (doc2uSerialumber && tablet["doc2u"] == null) || (tablet["doc2u"] != null && doc2uSerialumber != tablet["doc2u"]["serialNumber"])
      let homeChanged = ((selectedHome["value"].length > 0 && tablet["Home"] == null) || (tablet["Home"] != null && tablet["Home"]["id"] != selectedHome["value"]))

      console.log("simChanged", simChanged, "sim : ", sim, "intialSim :", tablet["imei"])
      console.log("homeChanged", homeChanged)
      if (simChanged) {
        /**
         * Sim changed so we should call update tablet api
         */
        let updateTablet = await updateTabletDetails(tablet['id'])
        if (!updateTablet) {
          errorOccured = true
        }
        if (!doc2uChanged && !homeChanged) {
          closeModal = true
        }
      }
      if (doc2uChanged) {
        let associatedDoc2u = await associateTabletDoc2u(tablet)
        if (!associatedDoc2u) {
          errorOccured = true
        }
        if (!homeChanged) {
          closeModal = true
        }

      }
      let newHomeId = selectedHome['value']
      if (homeChanged) {
        console.log("selectedHome *********", selectedHome)
        console.log("emptyHome != selectedHome", selectedHome != emptyHome, emptyHome)
        //Disassocier un foyer 
        if (newHomeId == "" && tablet["Home"] != null) {
          let diassociatedHome = await diassociateHome(tablet["Home"]["id"], tablet["id"])
          if (!diassociatedHome) {
            errorOccured = true
          }
        }
        else if (newHomeId.length > 0) {
          let associatedHome = await associateHome(newHomeId, tablet["deviceID"])
          if (!associatedHome) {
            errorOccured = true
          }
        }
        closeModal = true
        setRefreshHomesList(!refreshHomesList)
      }
      else {
        closeModal = true
      }
    }
    else {
      errorOccured = true
    }
    if (closeModal) {
      setisLoading(false)
      setRefresh(!refresh)
      emptyUpdateTabFields()
      setisSuccess(true)
    }
    if (errorOccured) {
      setisError(true)
    }

  }
  const AddTablet = async () => {
    setisLoading(true)
    let response = await tabletsService.addNewTablet(newTablet, acc_token)

    if (in200s(response)) {
      setisLoading(false)

    }
    else {
      setisError(true)
    }
    setNewTablet({
      imei: "",
      deviceID: ""
    })
    closeModal()
    setRefresh(!refresh)
  }
  const deleteTablet = async () => {
    setisLoading(true)
    let response = await tabletsService.deleteTablet(elementToDelete, acc_token)
    if (in200s(response)) {
      setisSuccess(true)
      setisLoading(false)
      setRefresh(!refresh)
    } else {
      setisError(true)
      setisLoading(false)

    }

  }

  return (
    <>
      <PageWithHeader>
        <PageWrapper title="Liste des tablettes"
        >
          <StyledHead >

            <div style={{ width: "400px" }}>
              <InputBlueBottom
                type="text"
                placeholder="Nom du bénéficiaire"
                name="searchKey"
                value={searchKey}
                onChange={(e) => setsearchKey(e.target.value)}
              />
            </div>
            <div style={{ width: '200px', display: "flex", flexDirection: "row" }}>
              <ActionButton title="Chercher" onClick={handleSearch} />
            </div>
            <div style={{ width: '200px', display: "flex", flexDirection: "row", marginLeft: "30px" }}>
              <OrangeButton title="Ajouter" onClick={() => { setOpen(true); seteditMode(editModes.add) }} />
            </div>
          </StyledHead>


          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: 6, }}>
              <CheckBox
                checked={tabletCheck}
                onChange={handleTabletCheck}
                inputProps={{ 'aria-label': 'Afficher au patient' }}
                style={{ color: tabletCheck ? '#18C5BF' : '#035282', borderColor: "inherit" }}
              />

              <Typography style={{ color: '#035282' }}>Assignées </Typography>
            </div>

            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: 6, }}>
              <CheckBox
                checked={noTabletCheck}
                onChange={handleNoTabletCheck}
                inputProps={{ 'aria-label': 'Afficher au patient' }}
                style={{ color: noTabletCheck ? '#18C5BF' : '#035282', borderColor: "inherit" }}
              />

              <Typography style={{ color: '#035282' }}>Non assignées </Typography>
            </div>
          </div>
          <ContentWrapper>

            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%" }}  >

              {tabletListToShow.length > 0 &&
                <TableContainer >
                  <StyledTable size="medium" aria-label="a dense table">
                    <TableHead>
                      <StyledTableRow >
                        <StyledTableCell align="left">Numéro de série</StyledTableCell>
                        <StyledTableCell align="left">Sim</StyledTableCell>
                        <StyledTableCell align="left">Adresse du foyer</StyledTableCell>
                        <StyledTableCell align="left">Doc2U</StyledTableCell>
                        <StyledTableCell align="left">Foyer</StyledTableCell>
                        <StyledTableCell align="left">Actions</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>

                      {tabletListToShow.map((row) => (
                        <TableRow
                          key={row["id"]}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >

                          <StyledTableCell
                            style={{
                              maxWidth: "150px",
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                            }}
                            component="th" scope="row" align="left">
                            {row["deviceID"] ? row["deviceID"] : <div className='not-provided-data'> Données absentes</div>}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              maxWidth: "150px",
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                            }}
                            component="th" scope="row" align="left" >
                            {row["imei"] ? row["imei"] :

                              <div className='not-provided-data'> Données absentes</div>}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="left">
                            {row["Home"] ? `${row["Home"]['adress1']}, ${row["Home"]['adress2']}, ${row["Home"]['city']} ${row["Home"]['zipCode']} ` : <div className='not-provided-data'> Données absentes</div>}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="left">
                            {row["doc2u"] ? row["doc2u"]["serialNumber"] : <div className='not-provided-data'> Données absentes</div>}
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row" align="left">
                            {row["Home"] &&
                              <>
                                <Tooltip title="Consulter les détails du foyer" placement="top">
                                  <IconButton
                                    style={{}}
                                    to={`/home-details/${row["Home"]['id']}`}
                                    component={Link}>
                                    <HomeIcon style={{ color: "#035282", fontSize: 30 }} />
                                  </IconButton>
                                </Tooltip>
                              </>
                            }
                          </StyledTableCell>


                          <StyledTableCell component="th" scope="row" align="left" >
                            <p style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                              <Tooltip title="Modifier la tablette" placement="top">
                                <IconButton onClick={() => {
                                  setselectedTablet(row)
                                  handleOpen();
                                  sethomeToDiassociate(row["Home"] != null ? row["Home"]["id"] : "");
                                  settabletToDiassociate(row["id"]);
                                  prefillUpdateTabFields(row)
                                  seteditMode(editModes.edit)
                                }} >
                                  <ModeEditIcon style={{ color: "#18C5BF" }} />
                                </IconButton>
                              </Tooltip>
                              {
                                row["Home"] == null &&
                                row["doc2u"] == null &&

                                <Tooltip title="Supprimer la tablette" placement="top">
                                  <IconButton onClick={() => openDeleteModal(row["id"])} style={{ display: "inline" }}>
                                    <DeleteIcon style={{ color: "#ed4157" }} />
                                  </IconButton>
                                </Tooltip>}
                            </p>

                          </StyledTableCell>

                        </TableRow>
                      ))}
                    </TableBody>
                  </StyledTable>
                </TableContainer>
              }

            </div>

            {tablets.length === 0 && !isEmpty && <NoData />}
            {tablets.length === 0 && isEmpty && <Empty />}
          </ContentWrapper>

        </PageWrapper>
      </PageWithHeader >


      <div>
        <Dialog
          style={{ zIndex: 99 }}
          open={deleteModalIsOpen}
          onClose={closeDeleteModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <StyledDialogTitle id="alert-dialog-title">
            {"Êtes-vous sûr de bien vouloir supprimer cette tablette ?"}
          </StyledDialogTitle>

          <IconButton
            aria-label="close"
            onClick={() => closeDeleteModal()}
            sx={{
              position: 'absolute',
              right: 20,
              top: 20,
              color: colors.dark_blue
            }}
          >
            <CloseIcon />
          </IconButton>


          <StyledDialogContent>
            {!isLoading && !isSuccess && !isError && <StyledDialogContentText id="alert-dialog-description">
              La suppression de la tablette est définitive. Voulez-vous supprimer quand même ?

            </StyledDialogContentText>}
            {
              isLoading && <Box sx={{ padding: "50px 0px" }}>
                <SpinnerComponent />
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
                </Box>
              </Box>
            }
            {
              isError && <Box sx={{ padding: "50px 0px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Une erreur s'est produite, veuillez réessayer.</StyledDialogMessage>
                </Box>
              </Box>
            }
            {
              isSuccess && <Box sx={{ padding: "50px 0px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Tablette supprimée avec succès.</StyledDialogMessage>
                </Box>
              </Box>
            }
          </ StyledDialogContent>
          <DialogActions>
            <ActionsWrapper>
              <CancelBtn onClick={() => closeDeleteModal()}>{isSuccess ? "Fermer" : "Annuler"}</CancelBtn>
              {!isLoading && !isSuccess && !isError && <ConfirmBtn onClick={() => deleteTablet()} autoFocus>
                Supprimer
              </ConfirmBtn>}
            </ActionsWrapper>

          </DialogActions>
        </Dialog>
        <>
          {/* Modal d'ajout et d'edit d'une tablette */}
          <Dialog
            open={open}
            onClose={() => setOpen(!open)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex: 99, padding: "30px 80px" }}
            maxWidth="lg"
          >
            <StyledDialogTitle id="alert-dialog-title">
              {
                editMode == editModes.add ? "Ajouter les données de la tablette" : "Modifier les données de la tablette"
              }
            </StyledDialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => { handleCloseUpdateModal(); emptyUpdateTabFields() }}
              sx={{
                position: 'absolute',
                right: 20,
                top: 20,
                color: colors.dark_blue
              }}
            >
              <CloseIcon />
            </IconButton>


            <StyledDialogContent>
              {
                !isLoading && !isError && !isSuccess ?
                  <>
                    <StyledDialogContentText id="alert-dialog-description">
                      Veuillez renseigner les données à modifier dans les champs suivants
                    </StyledDialogContentText>

                    <div style={{ marginTop: "10px", }}>

                      <CssTextField
                        style={{ margin: '5px 0px', width: "94%" }}
                        // defaultValue={selectedTablet ? selectedTablet['deviceID'] : ""}
                        label="Numéro de série de la tablette"
                        InputProps={{
                          readOnly: editMode == editModes.add ? false : true,
                          style: {
                            fontStyle: 'italic',
                            color: '#035282',
                          },
                        }}
                        value={tabletSerialNumber}
                        onChange={(e) => { setTabletSerialNumber(e.target.value) }}
                        InputLabelProps={{
                          style: { color: '#035282', backgroundColor: "transparent" }
                        }}
                        fullWidth
                        disabled={editMode == editModes.add ? false : true}
                      />
                      <div style={{ margin: "20px 0px", }}>
                        <div style={{ margin: "5px 0px", display: "flex", flexDirection: "row", alignItems: "center", }}>
                          <FormControl sx={{ margin: "5px 0px", width: "100%" }} variant="outlined" >
                            <InputLabel htmlFor="outlined-adornment-password">Sim</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={'text'}
                              fullWidth
                              value={sim}
                              onChange={(e) => setSim(e.target.value)}
                            />
                          </FormControl>
                          <Tooltip title="Supprimer" placement="top">
                            <IconButton onClick={() => setSim("")} >
                              <HighlightOffIcon style={{ color: "#035282", fontSize: 30 }} />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <Typography style={{ fontStyle: "italic", fontSize: "15px", maxWidth: "95%", }}>Renseigner le numéro de la carte Sim assignée à la tablette.
                        </Typography>
                      </div>
                      <div style={{ margin: "20px 0px", }}>
                        <div style={{ margin: "5px 0px", display: "flex", flexDirection: "row", alignItems: "center", }}>
                          <FormControl sx={{ margin: "5px 0px", width: "100%" }} variant="outlined" >
                            <InputLabel htmlFor="outlined-adornment-password">Doc2u</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={'text'}
                              fullWidth
                              value={doc2uSerialumber}
                              onChange={handleDoc2uChangeSerial}
                            />
                          </FormControl>
                          <Tooltip title="Supprimer" placement="top">
                            <IconButton
                              onClick={() => setdoc2uSerialumber("")}
                            >
                              <HighlightOffIcon style={{ color: "#035282", fontSize: 30 }} />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <Typography style={{ fontStyle: "italic", fontSize: "15px", maxWidth: "95%", }}>Renseigner le numéro de série du matériel Doc2u.
                          Laisser vide pour dissocier le matériel de la tablette.</Typography>
                      </div>

                      <div style={{ margin: "20px 0px", marginBottom: "50px", }}>
                        <div style={{ margin: "5px 0px", display: "flex", flexDirection: "row", alignItems: "center" }}>
                          <Autocomplete
                            disableClearable={true}
                            onChange={handleChangeHome}
                            value={selectedHome || undefined}
                            options={homesToDisplay}
                            sx={{ width: '100%', }}
                            renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                              <TextField
                                {...params}
                                label="Foyer"
                                fullWidth
                                inputProps={{
                                  ...params.inputProps,
                                  style: { zIndex: 9999, width: "100%" }, // Set a higher z-index value to ensure visibility
                                }}
                              />
                            )}
                          />
                          <Popper
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            placement="auto-end"
                            style={{ zIndex: 9999 }} // Set a higher z-index value
                          />
                          <Tooltip title="Supprimer" placement="top">
                            <IconButton
                              onClick={() => setSelectedHome(emptyHome)}
                            >
                              <HighlightOffIcon style={{ color: "#035282", fontSize: 30 }} />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <Typography style={{ fontStyle: "italic", fontSize: "15px", maxWidth: "95%", }}>Sélectionner le foyer à assigner à la tablette. Laisser vide pour le dissocier.</Typography>
                      </div>


                    </div>
                  </>
                  :
                  <Box sx={{ padding: "50px 0px" }}>
                    {isLoading && !isError && <SpinnerComponent />}
                    {isLoading && !isError && !isSuccess && <Box className={classes.errorBox}>
                      <Typography className={classes.errorMessage}>Traitement de la requête en cours ...</Typography>
                    </Box>}
                    {
                      !isSuccess && isError && !isLoading && <Box sx={{ padding: "50px 0px" }}>

                        <Box className={classes.errorBox}>
                          <Typography className={classes.errorMessage}>Une erreur s'est produite, veuillez vérifier vos paramètres et réessayer plus tard.</Typography>
                        </Box>
                      </Box>
                    }
                    {
                      isSuccess && !isLoading && !isError
                      && <Box sx={{ padding: "50px 0px" }}>

                        <Box className={classes.errorBox}>
                          <Typography className={classes.errorMessage}>{editMode == editModes.add ? "Élément ajouté avec succès !" : "Élément mis à jour avec succès !"}</Typography>
                        </Box>
                      </Box>
                    }
                  </Box>

              }
            </StyledDialogContent>
            <DialogActions>
              <ActionsWrapper>
                <CancelBtn onClick={() => { handleCloseUpdateModal(); emptyUpdateTabFields() }}>{
                  !isLoading && !isError && !isSuccess ?
                    "Annuler" : "Fermer"
                }
                </CancelBtn>
                {
                  !isLoading && !isError && !isSuccess &&
                  <ConfirmBtn onClick={() => createOrUpdateTablet2()} autoFocus>
                    Confirmer
                  </ConfirmBtn>
                }

              </ActionsWrapper>

            </DialogActions>
          </Dialog>

        </>
      </div>

      {/* Add tablet modal */}

      <Dialog
        open={modalIsOpen}
        onClose={closeModal}
        fullWidth={true}
        maxWidth='md'
        style={{ zIndex: 99 }}
      >
        <StyledDialogTitle >Ajouter une tablette</StyledDialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => closeModal()}
          sx={{
            position: 'absolute',
            right: 20,
            top: 20,
            color: colors.dark_blue
          }}
        >
          <CloseIcon />
        </IconButton>

        <StyledDialogContent>
          {
            !isLoading ?
              <>
                <StyledDialogContentText style={{ padding: 5 }}>
                  Veuillez entrer les spécifications de la nouvelle tablette. Vous devez remplir au moins un champs!
                </StyledDialogContentText>
                <div style={{
                  padding: "20px",
                  display: "flex", flexDirection: "column", gap: 5
                }}>
                  <InputBlueBottom
                    value={newTablet.imei}
                    onChange={handleChangeNewTablet}
                    placeholder="imei"
                    type="text"
                    name="imei"
                    required
                  />
                  <div style={{
                    display: 'flex', flexDirection: "column", width: "100%"
                  }}>
                    <InputBlueBottom
                      value={newTablet.deviceID}
                      onChange={handleChangeNewTablet}
                      placeholder="deviceID"
                      type="text"
                      name="deviceID"
                      required={false}
                    />

                  </div>
                </div>

              </> :
              <Box sx={{ padding: "50px 0px" }}>

                <SpinnerComponent />
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                  <StyledDialogMessage>Traitement de la requête en cours ...</StyledDialogMessage>
                </Box>
              </Box>
          }

        </ StyledDialogContent>
        {
          !isLoading && <DialogActions>
            <ActionsWrapper>
              <CancelBtn onClick={closeModal}>{isSuccess ? "Fermer" : "Annuler"}</CancelBtn>

              <ConfirmBtn onClick={() => AddTablet()} > Ajouter</ConfirmBtn>
            </ActionsWrapper>

          </DialogActions>
        }

      </Dialog>
    </>
  )
}

export default TabletsList