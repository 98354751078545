const organization_admin="organization admin"

export const Constants={
    organization_admin
}

export enum Role {
    SUPER_ADMIN = 'super admin',
    ADMIN = 'admin',
    MEMBER = 'adhérent',
    HELPER = 'aidant',
    PROFESSIONAL = 'professional',
    ORGANIZATION_ADMIN = 'organization admin',
    FAMILY = 'famille',
    HEALTH_PROFESSIONAL = 'professionnel de santé',
  }
  export const colors = {
    dark_blue: '#035282',
    turquoise: '#18C5BF',
    orange: '#F4C274',
    dark_gray: '#C2D4D8',
    light_blue: '#8CC8DB',
    light_gray: '#EEF1F2',
    light_orange: '#FFF1BF',
    sky_blue: '#0ED2EB',
    light_sky_blue: '#C1F5FF',
    light_turquoise: '#A0FFE9',
    light_green: '#D4FFDF',
  };