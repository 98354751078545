import styled from 'styled-components';

export const SectionTitle = styled.h1`
font-size:20px;
font-weight: 700;
color: #035282;
margin-top:20px ;
`

export const Wrapper = styled.div`
display: 'flex';
flex-direction: "column";
padding: 10px 200px;
padding-bottom: 100px;
margin-top: 50px;
`

export const UsersInfo = styled.div`
padding-top: "30px";
align-items: "center";
`
export const Users = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
gap: 50px;
display:block ;
`
export const CardWrapperSubscription = styled.div`
  overflow: hidden;
  padding: 20px 50px ;
  margin: 30px 20px;
  width: 35vw;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05), 0 0px 40px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background-color: white;
  
`;
