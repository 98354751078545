import React from 'react';
import Card from 'components/Cards/Card';
import { useNavigate } from 'react-router-dom';
import AuthTemplatePage from 'components/ReusableDivs/AuthTemplatePage';

function PasswordChanged() {
  const navigate = useNavigate();
  return (

    <AuthTemplatePage>
      <Card title="Réinitialisation terminée !"
        description="Votre mot de passe a bien été modifié !"
        buttonTitle="Retour à l'accueil"
        onClick={() => navigate('/')} children={undefined} />
    </AuthTemplatePage>
  );
}

export default PasswordChanged;
