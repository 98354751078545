import axios from 'axios';
import { requestChecker } from 'utils/RequestCheck';
const { REACT_APP_BASE_URL } = process.env

async function getAllTablets(setTablets: React.Dispatch<React.SetStateAction<any>>, acc_token: string, organizationId?: string, name?: string) {

  let url = REACT_APP_BASE_URL + "/tablet/get-tablet?"
  if (organizationId) {
    url += '&organizationId=' + organizationId
  }
  if (name) {
    url += '&name=' + name
  }

  try {
    let response = await axios.request({
      url: url,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      setTablets(response.data.data);
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function addNewTablet(data: any, acc_token: string) {

  let url = REACT_APP_BASE_URL + `/tablet/add-tablet`
  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      console.log("response.data.data", response.data.data)
      return response.data.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}


async function updateTablet(data: any, acc_token: string, tabletId: string) {

  let url = REACT_APP_BASE_URL + `/tablet/${tabletId}`
  try {
    let response = await axios.request({
      url: url,
      method: 'patch',
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}
async function deleteTablet(id: string, acc_token: string) {

  let url = REACT_APP_BASE_URL + `/tablet/delete-tablet/` + id

  try {
    let response = await axios.request({
      url: url,
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function diassociateTablet(homeId: string, tabletId: string, acc_token: string, organizationId?: string) {
  let url = REACT_APP_BASE_URL + `/tablet/diassociate-tablet/?homeId=` + homeId +
    '&tabletId=' + tabletId

  if (organizationId) {
    url += '&organizationId=' + organizationId
  }
  try {
    let response = await axios.request({
      url: url,
      method: 'put',
      data: {},
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout // Timeout after 5 seconds 5000, // Timeout after 5 seconds
    });
    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

export const tabletsService = {
  getAllTablets,
  addNewTablet,
  deleteTablet,
  diassociateTablet,
  updateTablet
};