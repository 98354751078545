import PageWithHeader from 'components/ReusableDivs/PageWithHeader';
import { useCallback, useEffect, useState, CSSProperties } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Button, Chip, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import PeopleIcon from '@mui/icons-material/People';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import ScaleIcon from '@mui/icons-material/Scale';
import { calendarService } from '../../services/calendarService'
import useWindowDimensions from '../../utils/useWindowDimensions';
import './CalendarPage.css'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StyledDialogContent } from 'styles/dialogs/DialogStyles';

export interface StylesDictionary {
  [Key: string]: CSSProperties;
}

const customStyles: StylesDictionary = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99
  },
  input: {
    width: '70%',
    fontSize: 15,
    fontFamily: 'open sans',
    right: 0
  },
  labels: {
    maxWidth: 400,
    width: '30%',
    fontSize: 18,
    flexGrow: 1,
    marginTop: '3%',
    fontWeight: '400'
  },
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10
  },
  alert: {
    color: 'red',
    textAlign: 'left',
    width: '70%',
    marginLeft: '30%'
  }
};

type EventInterface = {
  title: string;
  description: string;
  start: Date;
  end: Date;
  createdBy?: string;
  editedBy?: string;
  id?: string;
  place: string;
  type: string;
  userId: string;
};

const localizer = momentLocalizer(moment);

function MyCalendarPage() {
  // récupération de l'id et du prénom à partir de l'url
  const urlPath = decodeURIComponent(window.location.toString());

  const id = 'cd50b81d-fab5-4afd-a042-c313295e8998';

  var startOfWeek = moment().startOf('isoWeek').toISOString();
  var endOfWeek = moment().endOf('isoWeek').toISOString();
  const [startVisibleDate, setstartVisibleDate] = useState<string>(startOfWeek);
  const [endVisibleDate, setendVisibleDate] = useState<string>(endOfWeek);

  const [myEventsList, setmyEventsList] = useState<EventInterface[]>([]);
  //const AppointmentTemp = {title: "", description:"",start: new Date, end: new Date, createdBy:"", editedBy:"", id:"", place:"", type:"", userId:""}
  const [currentAppointment, setCurrentAppointment] = useState<EventInterface>();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [newModalIsOpen, setnewModalIsOpen] = useState(false);
  const [validateModalIsOpen, setvalidateModalIsOpen] = useState(false);

  const [startNewEvent, setstartNewEvent] = useState();
  const [endNewEvent, setendNewEvent] = useState();

  const [inThePast, setinThePast] = useState(false);

  const [errorData, seterrorData] = useState({ title: '', description: '', type: '', place: '', start: '', end: '' });
  const [isError, setisError] = useState(false);
  const acc_token = useSelector((state: any) => state.userDomisante.userToken);
  //Fermeture des modales
  function closeValidateModal() {
    setvalidateModalIsOpen(false);
  }

  function closeModal() {
    setIsOpen(false);
    setCurrentAppointment(undefined);
  }

  function closeNewModal() {
    setnewModalIsOpen(false);
    seterrorData({ title: '', description: '', type: '', place: '', start: '', end: '' });
  }

  // Creation d'un nouveau RDV
  const handleSelectSlot = useCallback(
    ({ start, end }: any) => {
      setnewModalIsOpen(true);
      setstartNewEvent(start);
      setendNewEvent(end);
    },
    []
  );

  //Modification d'un rdv
  function edit() {
    setIsOpen(false);
    setnewModalIsOpen(true);
  }

  // Ouvre la modale de validation pour la suppression d'un RDV
  function validationDelete() {
    setvalidateModalIsOpen(true);
  }

  // Appelle les fonctions pour supprimer le RDV et recharger la page
  function callDelete() {
    if (currentAppointment?.id) {
      calendarService.deleteAppointment(currentAppointment.id, acc_token);
    }
    setCurrentAppointment(undefined);
    window.location.reload();
  }

  // Gestion des données lors de l'ajout ou de la modification d'un RDV
  // Vérification des champs obligatoires
  // Creation d'un objet qui récupère tous les paramètres
  // Envoyé au put si c'est une modification et au post si c'est une creation
  async function handleDataNewAppointment(edit: boolean) {
    const appt = { title: '', description: '', type: '', place: '', startDateTime: '', endDateTime: '', userId: '' };
    const error = { title: '', description: '', type: '', place: '', start: '', end: '' };
    let isErrorTest = false;

    if (!(document.getElementById('title') as HTMLInputElement).value) {
      error.title = 'Veuillez saisir un titre';
      isErrorTest = true;
    }

    if ((document.getElementById('type') as HTMLInputElement).value === 'choix') {
      error.type = 'Veuillez choisir un type';
      isErrorTest = true;
    }

    if (!(document.getElementById('start') as HTMLInputElement).value) {
      error.start = 'Veuillez choisir une date de début';
      isErrorTest = true;
    }

    if (!(document.getElementById('end') as HTMLInputElement).value) {
      error.end = 'Veuillez choisir une date de fin';
      isErrorTest = true;
    }

    if (
      moment((document.getElementById('start') as HTMLInputElement).value).format() >
      moment((document.getElementById('end') as HTMLInputElement).value).format()
    ) {
      error.end = 'Veuillez choisir une date de fin ultérieure à celle de début';
      isErrorTest = true;
    }

    let now = new Date()
    if (
      moment(now).format() > moment((document.getElementById('end') as HTMLInputElement).value).format()
    ) {
      error.end = "Veuillez choisir une date qui n'est pas déjà passée";
      isErrorTest = true;
    }

    if (!isErrorTest) {
      appt.title = (document.getElementById('title') as HTMLInputElement).value;
      appt.description = (document.getElementById('description') as HTMLInputElement).value;
      appt.type = (document.getElementById('type') as HTMLInputElement).value;
      appt.place = (document.getElementById('place') as HTMLInputElement).value;
      appt.startDateTime = moment((document.getElementById('start') as HTMLInputElement).value).format();
      appt.endDateTime = moment((document.getElementById('end') as HTMLInputElement).value).format();
      appt.userId = id;
      closeNewModal();

      if (!edit) {
        calendarService.postAppointment(appt, acc_token);

      } else {
        if (currentAppointment?.id) {
          calendarService.putAppointment(appt, currentAppointment.id, acc_token);
        }
        setCurrentAppointment(undefined);
      }
      window.location.reload();
    }
    setisError(isErrorTest);
    seterrorData(error);
  }


  // Formatage des données des RDV récupérées
  function formatRDV(rdv: []) {
    let title = '';
    let start;
    let end;
    let description = '';
    let createdBy = '';
    let editedBy = '';
    let id = '';
    let place = '';
    let type = '';
    let userId = '';

    let fetchedData = [] as Array<EventInterface>;
    for (let index = 0; index < rdv.length; index++) {
      title = rdv[index]['title'];
      start = new Date(rdv[index]['startDateTime']);
      end = new Date(rdv[index]['endDateTime']);
      description = rdv[index]['description'];
      createdBy = rdv[index]['createdBy'];
      editedBy = rdv[index]['editedBy'];
      id = rdv[index]['id'];
      place = rdv[index]['place'];
      type = rdv[index]['type'];
      userId = rdv[index]['userId'];

      fetchedData.push({
        title: title,
        start: start,
        end: end,
        description: description,
        createdBy: createdBy,
        editedBy: editedBy,
        id: id,
        place: place,
        type: type,
        userId: userId
      });
    }
    setmyEventsList(fetchedData);
  }

  // Ouverture des détails d'un RDV
  // Vérification si l'évent est dans le passé ou non pour gérer l'affichage des boutons Modifier et Supprimer
  const handleSelectEvent = useCallback((event: any) => {
    setIsOpen(true);
    setCurrentAppointment(event);
    const currentDate = new Date();

    if (event.end && currentDate > event.end) {
      setinThePast(true);
    } else {
      setinThePast(false);
    }
  }, []);

  // Formatage de la date pour l'affichage des détails d'un RDV
  function formatDate(date: Date) {
    let newDate;
    newDate = moment(date).format('DD/MM à hh:mm');
    return newDate.toString();
  }

  //Appelle la fonction getRDV dès que les bornes (dates) changent
  // I.E : Quand on change de d'affichage (Mois/semaine/jour) ou que l'on change de date (avance d'une semaine, d'un mois etc.)
  useEffect(() => {
    getFormatedRDV()
  }, [startVisibleDate, endVisibleDate]);

  async function getFormatedRDV() {

    const response = await calendarService.getRDV(startVisibleDate, endVisibleDate, id, acc_token);
    formatRDV(response)
  }
  // Gestion des bornes d'affichage pour le get
  // Changement des valeurs dès que la view ou la date change
  function updateTimes(date: moment.Moment, view: string) {
    let start, end;
    if (view === 'day') {
      start = moment(date).startOf('day');
      end = moment(date).endOf('day');
    } else if (view === 'week') {
      start = moment(date).startOf('isoWeek');
      end = moment(date).endOf('isoWeek');
    } else if (view === 'month') {
      start = moment(date).startOf('month').subtract(7, 'days');
      end = moment(date).endOf('month').add(7, 'days');
    } else if (view === 'agenda') {
      start = moment(date).startOf('day');
      end = moment(date).endOf('day').add(1, 'month');
    }
    const finalStart = start?.toISOString();
    setstartVisibleDate(finalStart!);
    const finalEnd = end?.toISOString();
    setendVisibleDate(finalEnd!);
  }

  let dateInit = new Date()
  const [calendarDate, setcalendarDate] = useState(dateInit)

  const [calendarHeight, setcalendarHeight] = useState<number>(500)
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (height) {
      setcalendarHeight(height * 0.7)
    }
  }, [height, width])

  const currentUserRole = useSelector((state: any) => state.userDomisante.userRole)

  return (
    <PageWithHeader>
      <div style={{ width: '90%', marginLeft: '5%', marginTop: '2%', fontFamily: 'open sans' }}>
        <div
          style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold', marginBottom: '2%', fontFamily: 'open sans' }}
        >
          Mon Agenda
        </div>
        <Calendar
          localizer={localizer}
          events={myEventsList}
          startAccessor="start"
          endAccessor="end"
          defaultView="week"
          onNavigate={(date, view) => {
            const new_Date = moment(date);
            setcalendarDate(date)
            updateTimes(new_Date, view);
          }}
          onView={(view) => {
            const new_Date = moment(calendarDate);
            updateTimes(new_Date, view);
          }}
          scrollToTime={moment()
            .set({ h: 8, m: 0 })
            .toDate()}
          style={{ height: calendarHeight, zIndex: 0, fontFamily: 'open sans' }}
          messages={{
            next: 'Suivant',
            previous: 'Précédent',
            today: "Aujourd'hui",
            month: 'Mois',
            week: 'Semaine',
            day: 'Jour'
          }}
          onSelectSlot={handleSelectSlot}
          selectable
          onSelectEvent={handleSelectEvent}
          eventPropGetter={(event) => {
            let newStyle = {
              backgroundColor: 'lightgrey',
              color: 'black',
              border: 'none',
              fontFamily: 'open sans'
            };

            if (event.type === 'consultation') {
              newStyle.backgroundColor = '#C2D4D8';
            } else if (event.type === 'nursing') {
              newStyle.backgroundColor = '#A0FFE9';
            } else if (event.type === 'rdv_aidant') {
              newStyle.backgroundColor = '#8CC8DB';
            } else if (event.type === 'rdv_menage') {
              newStyle.backgroundColor = '#F4C274';
            } else if (event.type === 'measures') {
              newStyle.backgroundColor = '#FFF1BF';
            } else {
              newStyle.backgroundColor = '#C1F5FF';
            }

            return {
              className: '',
              style: newStyle
            };
          }}
        />

        {/* MODALE D'AFFICHAGE DES RDV */}
        <Dialog
          open={modalIsOpen}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ fontFamily: 'open sans', zIndex: 99 }}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ marginTop: '10px', fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}
          >
            {currentAppointment && currentAppointment.title}
          </DialogTitle>
          <StyledDialogContent>
            <div style={{ padding: '0px 20px 10px 20px' }}>
              <Typography
                id="modal-modal-text"
                variant="h6"
                component="h6"
                style={{ fontSize: 15, flexGrow: 1, textAlign: 'center' }}
              >
                Du {currentAppointment && formatDate(currentAppointment.start)} jusqu'au{' '}
                {currentAppointment && formatDate(currentAppointment.end)}
              </Typography>
              <div style={{ width: 'inherit', paddingTop: '25px', display: 'flex', flexDirection: 'column' }}>
                <Typography id="modal-modal-text" variant="h6" component="h6" style={{ fontSize: 15, flexGrow: 1 }}>
                  Description : {currentAppointment && currentAppointment.description}
                </Typography>
                <Typography
                  id="modal-modal-text"
                  variant="h6"
                  component="h6"
                  style={{ fontSize: 15, flexGrow: 1, marginTop: '3%' }}
                >
                  Lieu : {currentAppointment && currentAppointment.place}
                </Typography>
                <Typography
                  id="modal-modal-text"
                  variant="h6"
                  component="h6"
                  style={{ fontSize: 15, flexGrow: 1, marginTop: '3%' }}
                >
                  Type :
                  {currentAppointment && currentAppointment.type === 'consultation' && (
                    <Chip
                      label="consultation"
                      size="medium"
                      style={{ width: 'auto', marginLeft: '2%', padding: '2%', backgroundColor: '#C2D4D8' }}
                      icon={<MeetingRoomIcon />}
                    />
                  )}
                  {currentAppointment && currentAppointment.type === 'nursing' && (
                    <Chip
                      label="Soin"
                      size="medium"
                      style={{ width: 'auto', marginLeft: '2%', padding: '2%', backgroundColor: '#A0FFE9' }}
                      icon={<HealthAndSafetyIcon />}
                    />
                  )}
                  {currentAppointment && currentAppointment.type === 'rdv_aidant' && (
                    <Chip
                      label="RDV Aidant"
                      size="medium"
                      style={{ width: 'auto', marginLeft: '2%', padding: '2%', backgroundColor: '#8CC8DB' }}
                      icon={<PeopleIcon />}
                    />
                  )}
                  {currentAppointment && currentAppointment.type === 'rdv_menage' && (
                    <Chip
                      label="RDV Menage"
                      size="medium"
                      style={{ width: 'auto', marginLeft: '2%', padding: '2%', backgroundColor: '#F4C274' }}
                      icon={<CleaningServicesIcon />}
                    />
                  )}
                  {currentAppointment && currentAppointment.type === 'measures' && (
                    <Chip
                      label="Mesures"
                      size="medium"
                      style={{ width: 'auto', marginLeft: '2%', padding: '2%', backgroundColor: '#FFF1BF' }}
                      icon={<ScaleIcon />}
                    />
                  )}
                  {currentAppointment &&
                    currentAppointment.type !== 'consultation' &&
                    currentAppointment.type !== 'nursing' &&
                    currentAppointment.type !== 'rdv_aidant' &&
                    currentAppointment.type !== 'rdv_menage' &&
                    currentAppointment.type !== 'measures' && (
                      <Chip
                        label="Autre"
                        size="medium"
                        style={{ width: 'auto', marginLeft: '2%', padding: '2%', backgroundColor: '#C1F5FF' }}
                      />
                    )}
                </Typography>
              </div>
            </div>
          </StyledDialogContent>
          <DialogActions style={{ display: 'flex', flexDirection: 'row' }}>

            <Button onClick={closeModal} autoFocus style={{ left: 10, backgroundColor: 'rgb(238, 241, 242)', position: 'absolute', color: 'rgb(3, 82, 130)', fontSize: '12px' }}>
              Fermer
            </Button>

            {!inThePast && (currentUserRole == 'aidant' || currentUserRole == 'professionnel de santé') && (
              <Button onClick={validationDelete} autoFocus style={{ backgroundColor: '#F4C274', color: 'white', fontSize: '12px' }}>
                Supprimer
              </Button>
            )}

            {(inThePast || (currentUserRole != 'aidant' && currentUserRole != 'professionnel de santé')) && (
              <Button onClick={validationDelete} autoFocus disabled style={{ fontSize: '12px' }}>
                Supprimer
              </Button>
            )}

            {!inThePast && (currentUserRole == 'aidant' || currentUserRole == 'professionnel de santé') && (
              <Button onClick={edit} autoFocus style={{ backgroundColor: 'rgb(3, 82, 130)', color: 'white', fontSize: '12px' }}>
                Modifier
              </Button>
            )}
            {inThePast || (currentUserRole != 'aidant' && currentUserRole != 'professionnel de santé') && (
              <Button onClick={edit} autoFocus disabled style={{ fontSize: '12px' }}>
                Modifier
              </Button>
            )}




          </DialogActions>
        </Dialog>



        {/* MODALE DE SAISIE POUR LA CREATION/MODIFICATIONS DES RDV */}
        <Dialog
          open={newModalIsOpen}
          onClose={closeNewModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ fontFamily: 'open sans', zIndex: 99 }}
          fullWidth

          maxWidth="md"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ marginTop: '10px', fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}
          >
            Saisir les détails
          </DialogTitle>
          <StyledDialogContent>
            <div style={customStyles.containerRow}>
              <Typography id="modal-modal-text" variant="h6" component="h6" style={customStyles.labels}>
                Titre *
              </Typography>
              <input
                type="text"
                name="title"
                id="title"
                style={customStyles.input}
                defaultValue={currentAppointment?.title}
              />
            </div>
            <div style={customStyles.alert}>{isError && errorData.title}</div>

            <div style={customStyles.containerRow}>
              <Typography id="modal-modal-text" variant="h6" component="h6" style={customStyles.labels}>
                Description
              </Typography>
              <input
                type="text"
                name="description"
                id="description"
                style={customStyles.input}
                defaultValue={currentAppointment?.description}
              />
            </div>
            <div style={customStyles.alert}>{isError && errorData.description}</div>
            <div style={customStyles.containerRow}>
              <Typography id="modal-modal-text" variant="h6" component="h6" style={customStyles.labels}>
                Type *
              </Typography>
              <select style={customStyles.input} id="type" defaultValue={currentAppointment?.type}>
                <option value="choix">-- Choisir ici --</option>
                <option value="consultation">Consultation</option>
                <option value="nursing">Soin</option>
                <option value="rdv_aidant">RDV Aidant</option>
                <option value="rdv_menage">Ménage</option>
                <option value="measures">Mesure</option>
                <option value="other">Autre</option>
              </select>
            </div>
            <div style={customStyles.alert}>{isError && errorData.type}</div>
            <div style={customStyles.containerRow}>
              <Typography id="modal-modal-text" variant="h6" component="h6" style={customStyles.labels}>
                Lieu
              </Typography>
              <input
                type="text"
                name="place"
                id="place"
                style={customStyles.input}
                defaultValue={currentAppointment?.place}
              />
            </div>
            <div style={customStyles.alert}>{isError && errorData.place}</div>
            <div style={customStyles.containerRow}>
              <Typography id="modal-modal-text" variant="h6" component="h6" style={customStyles.labels}>
                Début *
              </Typography>
              {currentAppointment && (
                <input
                  type="datetime-local"
                  name="start"
                  id="start"
                  style={customStyles.input}
                  defaultValue={moment(currentAppointment.start).format('YYYY-MM-DDTkk:mm')}
                />
              )}
              {!currentAppointment && (
                <input
                  type="datetime-local"
                  name="start"
                  id="start"
                  style={customStyles.input}
                  defaultValue={moment(startNewEvent).format('YYYY-MM-DDTkk:mm')}
                />
              )}
            </div>
            <div style={customStyles.alert}>{isError && errorData.start}</div>
            <div style={customStyles.containerRow}>
              <Typography id="modal-modal-text" variant="h6" component="h6" style={customStyles.labels}>
                Fin *
              </Typography>
              {currentAppointment && (
                <input
                  type="datetime-local"
                  name="end"
                  id="end"
                  style={customStyles.input}
                  defaultValue={moment(currentAppointment.end).format('YYYY-MM-DDTkk:mm')}
                />
              )}
              {!currentAppointment && (
                <input
                  type="datetime-local"
                  name="end"
                  id="end"
                  style={customStyles.input}
                  defaultValue={moment(endNewEvent).format('YYYY-MM-DDTkk:mm')}
                />
              )}
            </div>
            <div style={customStyles.alert}>{isError && errorData.end}</div>
          </StyledDialogContent>
          <DialogActions>
            {/* edit */}
            {currentAppointment && (
              <Button onClick={() => handleDataNewAppointment(true)} autoFocus style={{ backgroundColor: 'rgb(3, 82, 130)', color: 'white', fontSize: '12px' }}>
                Valider
              </Button>
            )}

            {/* !edit */}
            {!currentAppointment && (currentUserRole == 'aidant' || currentUserRole == 'professionnel de santé') && (
              <Button onClick={() => handleDataNewAppointment(false)} autoFocus style={{ backgroundColor: 'rgb(3, 82, 130)', color: 'white', fontSize: '12px' }}>
                Valider
              </Button>
            )}

            {!currentAppointment && (currentUserRole != 'aidant' && currentUserRole != 'professionnel de santé') && (
              <Button onClick={() => handleDataNewAppointment(false)} autoFocus style={{ fontSize: '12px' }} disabled>
                Valider
              </Button>
            )}

            <Button onClick={closeNewModal} autoFocus style={{ left: 10, backgroundColor: 'rgb(238, 241, 242)', position: 'absolute', color: 'rgb(3, 82, 130)', fontSize: '12px' }}>
              Fermer
            </Button>
          </DialogActions>
        </Dialog>


        {/* MODALE DE VALIDATION DE LA SUPPRESSION DU RDV */}
        <Dialog
          open={validateModalIsOpen}
          onClose={closeValidateModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ fontFamily: 'open sans', zIndex: 99 }}
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ marginTop: '10px', fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}
          >
            Suppression RDV
          </DialogTitle>
          <StyledDialogContent>
            <Typography id="modal-modal-text" variant="h6" component="h6">
              Êtes-vous sûr de vouloir supprimer cet événement ?
            </Typography>
          </StyledDialogContent>
          <DialogActions>
            <Button onClick={callDelete} autoFocus>
              Valider
            </Button>

            <Button onClick={closeValidateModal} autoFocus>
              Annuler
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </PageWithHeader>
  );
}

export default MyCalendarPage;
