import React from 'react'

function Empty() {
    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "200px" }}>

            <h1 style={{ fontSize: "18px", color: "#9b9b9b", fontWeight: "400" }}>Pas de données pour l'instant. </h1>
        </div>
    )
}

export default Empty