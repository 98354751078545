
import styled from 'styled-components';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PatientsButton from 'components/InputControls.tsx/PatientsButton';
import { Img, ImgContainer } from 'components/StyledWrappers/StyledWrappers';
import { Box } from '@mui/material';
import DropDownMenu from 'screens/login/DropDownMenu';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
`;

export const Content = styled.div`
  flex: 1;
  padding-top: 10px;
  background: linear-gradient(to bottom, #035282, #109BA9);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  font-size: 20px;
`;


const Dashboard = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);


  const firstname = useSelector((state: any) => state.userDomisante.userInfo.firstname);
  const lastname = useSelector((state: any) => state.userDomisante.userInfo.lastname);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirect = (page: string) => {
    navigate(`/${page}`);
  };
  const handleButtonClick = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <Wrapper>
      <Content>

        <div style={{ marginBottom: "10px", marginTop: "40px" }}>
          <Box style={{ width: "100%" }}>
            <ImgContainer  >
              <Img src={process.env.PUBLIC_URL + '/Logo_final_fond_bleu.svg'} alt="DomiSanté"
              />
            </ImgContainer>
          </Box>
        </div>
        <div style={{ maxWidth: "1100px", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "15vh" }}>
          <div style={{ fontWeight: "700", marginBottom: "10px" }}>Bonjour {firstname} {lastname}</div>
        </div>
        <Box style={{ display: "flex", flexDirection: "row", width: "70%", justifyContent: "space-between", marginTop: "10px" }}>
          <PatientsButton title='Mes bénéficiaires' onClick={() => navigate('/patients')} type='patients'
            description="Retrouver toutes les informations relatives à vos bénéficiaires (identité, coordonnées, constantes, transmissions, etc.) "
          />
          <PatientsButton title='Alertes' onClick={() => navigate('/alerts')} type='alerts'
            description="Accéder à l'ensemble des alertes liées au dépassement des seuils de mesures définies"
          />
        </Box>
        <Box style={{ display: "flex", flexDirection: "row", width: "70%", marginTop: "25px" }}>
          <PatientsButton width={100} title='Gérer les seuils de mesure' onClick={() => navigate('/measure-limits')} type='limits'
            description="Définir pour chacun de vos bénéficiaires et pour chaque type de constante les seuils de mesures qui permettent de déclencher des alertes"
          />
        </Box>
      </Content>


      <DropDownMenu />
    </Wrapper>
  );
}







export default Dashboard;