import React, { useState, useEffect } from 'react'
import { Box, TextField, Typography, } from '@mui/material'
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ProfileBlock from './ProfileBlock';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import { InputBlueBottom } from 'components/InputControls.tsx/Inputs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { customStyles } from '../../calendar/CalendarPage';
import { usersService } from 'services/usersService';
import { in200s } from 'utils/RequestCheckFns';
import moment from 'moment';
import { updateFirstname, updateLastname, updateBirthDateState, updatePhoneNumberState } from 'store/userSlice';
import dayjs from 'dayjs';

export const StyledBox = styled(Box)({
    width: '45%',
    height: "fit-content",
    minHeight: 80,
    backgroundColor: '#EEF1F2',
    margin: "20px 0px",
    padding: "20px",
    borderRadius: "8px",
    position: 'relative'
})

const StyledField = styled(Typography)({
    color: "#035282",
    padding: "0px 5px",
})


type currentUserType = {
    email: string,
    firstname: string,
    id: string,
    lastname: string,
    roles: string,
    birthDate: string,
    phoneNumber: string
}
type nameDataType = {

    lastname?: string,
    firstname?: string,
}
type birthDateDataType = {
    birthDate?: string,
}
type phoneNumberDataType = {
    phoneNumber?: string
}
function ProfileInfos() {
    const { userInfo } = useSelector((state: any) => state.userDomisante);

    const [currentUser, setcurrentUser] = useState<currentUserType>({
        email: "",
        firstname: "",
        id: "",
        lastname: "",
        roles: "",
        birthDate: "",
        phoneNumber: ""
    })

    const [updateName, setupdateName] = useState(false)
    const [updateBirthDate, setupdateBirthDate] = useState(false)
    const [phoneNumber, setphoneNumber] = useState("")
    const [updatePhoneNumber, setupdatePhoneNumber] = useState(false)
    const acc_token = useSelector((state: any) => state.userDomisante.userToken);

    const dispatch = useDispatch();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        setcurrentUser({
            ...currentUser, [name]: value
        })
    };


    async function UpdateName() {
        let nameData: nameDataType = {}
        if (currentUser.firstname != "") {
            dispatch(updateFirstname(currentUser.firstname));
            nameData.firstname = currentUser.firstname

        }
        if (currentUser.lastname != "") {
            dispatch(updateLastname(currentUser.lastname));
            nameData.lastname = currentUser.lastname

        }
        let response = await usersService.updateProfile(nameData, acc_token)

        if (in200s(response)) {
            setupdateName(!updateName)

        }
    }
    async function UpdateBirthDate() {
        let birthDateData: birthDateDataType = {}
        if (currentUser.birthDate != "") {
            dispatch(updateBirthDateState(currentUser.birthDate));
            birthDateData.birthDate = currentUser.birthDate

        }

        let response = await usersService.updateProfile(birthDateData, acc_token)

        if (in200s(response)) {
            setupdateBirthDate(!updateBirthDate)
        }
    }
    async function UpdatePhoneNumber() {
        let phoneNumberData: phoneNumberDataType = {}
        if (currentUser.phoneNumber != "") {
            dispatch(updatePhoneNumberState(currentUser.phoneNumber));
            phoneNumberData.phoneNumber = currentUser.phoneNumber

            let response = await usersService.updateProfile(phoneNumberData, acc_token)

            if (in200s(response)) {
                setupdatePhoneNumber(!updatePhoneNumber)
            }
        }
    }

    const handleEscapeKeyPress = (e: any) => {
        const isInput = e.target.tagName.toLowerCase() === 'input' || e.target.closest('input');
        if (isInput) {
            if (e.keyCode === 27) {
                setupdateName(false);
                setupdateBirthDate(false);
                setupdatePhoneNumber(false);
            }
        }
    };
    useEffect(() => {
        document.addEventListener('keyup', handleEscapeKeyPress);
        return () => {
            document.removeEventListener('keyup', handleEscapeKeyPress);
        };
    }, []);

    useEffect(() => {
        if (userInfo) {
            setcurrentUser(userInfo)
        }
    }, [])

    return (
        <Box style={{ display: "flex", flex: 1, }}>
            <Box style={{ flex: 1, width: "100%" }}>

                <Box display="flex" flexWrap="wrap" style={{ justifyContent: "space-between", }}  >
                    <StyledBox style={{ display: "flex", flexDirection: "column", }}>

                        <ProfileBlock
                            icon={<AccountCircleOutlinedIcon style={{ color: "#F4C274" }} />}
                            edit={updateName}
                            updateFn={UpdateName}
                            editable
                            enableUpdate={setupdateName}
                        >
                            <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Box style={{ display: "flex", flexDirection: "row", margin: "0px 5px" }}>
                                    {
                                        updateName ? (
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <InputBlueBottom
                                                    type="text"
                                                    name="firstname"
                                                    value={currentUser.firstname}
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder="Prénom"
                                                />
                                                <CloseOutlinedIcon onClick={() => setupdateName(false)} style={{ marginLeft: "5px", cursor: "pointer" }} />
                                            </div>
                                        ) : (
                                            <>
                                                <StyledField>
                                                    Prénom:
                                                </StyledField>
                                                <Typography>
                                                    {currentUser?.firstname}
                                                </Typography>
                                            </>
                                        )
                                    }
                                </Box>
                                <Box style={{ display: "flex", flexDirection: "row", margin: "0px 5px" }}>
                                    {
                                        updateName ? (
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <InputBlueBottom
                                                    type="text"
                                                    name="lastname"
                                                    value={currentUser.lastname}
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder="Nom"
                                                />
                                                <CloseOutlinedIcon onClick={() => setupdateName(false)} style={{ marginLeft: "5px", cursor: "pointer" }} />
                                            </div>
                                        ) : (
                                            <>
                                                <StyledField>
                                                    Nom de famille:
                                                </StyledField>
                                                <Typography>
                                                    {currentUser?.lastname}
                                                </Typography>
                                            </>
                                        )
                                    }
                                </Box>
                            </Box>
                        </ProfileBlock>


                    </StyledBox>










                    <StyledBox style={{ display: "flex", flexDirection: "column" }}>
                        <ProfileBlock
                            icon={<CalendarMonthOutlinedIcon style={{ color: "#F4C274" }} />}
                            edit={updateBirthDate}
                            updateFn={UpdateBirthDate}
                            editable
                            enableUpdate={setupdateBirthDate}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                {
                                    updateBirthDate ? (
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <DatePicker
                                                //@ts-ignore
                                                format="DD/MM/YYYY"
                                                label="Date de naissance"
                                                value={currentUser.birthDate}
                                                onChange={(newValue: any) => {
                                                    const iso_to_date = new Date(newValue!)
                                                    //const date_string=iso_to_date.toString()
                                                    const day = iso_to_date.getDate().toString().padStart(2, '0');
                                                    const month = (iso_to_date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
                                                    const year = iso_to_date.getFullYear().toString();

                                                    //qta3
                                                    setcurrentUser({
                                                        ...currentUser,
                                                        birthDate: `${day}/${month}/${year}`
                                                        //birthDate: date_string
                                                    })
                                                }}
                                                sx={{
                                                    '& label.Mui-focused': {
                                                        color: '#035282',
                                                    },
                                                    '& .MuiInput-underline:after': {
                                                        borderBottomColor: '#035282',
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: '#035282',
                                                        },
                                                    },
                                                    '& .MuiInputBase-input': {

                                                        fontFamily: [
                                                            'open sans',

                                                        ].join(','),

                                                    },

                                                    '& .MuiIconButton-root.MuiInputAdornment-root.MuiInputAdornment-positionEnd:not(.Mui-disabled)::before': {
                                                        display: 'none',
                                                    },
                                                }}
                                            />
                                            <CloseOutlinedIcon onClick={() => setupdateBirthDate(false)} style={{ marginLeft: "5px", cursor: "pointer" }} />
                                        </div>
                                    ) : (
                                        <Box style={{ display: "flex", flexDirection: "row" }}>
                                            <StyledField>
                                                Date de naissance:
                                            </StyledField>
                                            <Typography>
                                                {dayjs(currentUser?.birthDate).format('DD/MM/YYYY')}
                                            </Typography>
                                        </Box>
                                    )
                                }
                            </LocalizationProvider>
                        </ProfileBlock>















                    </StyledBox>
                    <StyledBox style={{ display: "flex", flexDirection: "column" }}>
                        <ProfileBlock icon={<AlternateEmailOutlinedIcon style={{ color: "#F4C274" }} />}  >
                            <Box style={{ display: "flex", flexDirection: "row" }}>
                                <StyledField>
                                    Email :
                                </StyledField>
                                <Typography>
                                    {currentUser?.email}
                                </Typography>
                            </Box>

                        </ProfileBlock>

                    </StyledBox>
                    <StyledBox style={{ display: "flex", flexDirection: "column" }}>
                        <ProfileBlock
                            icon={<PhoneAndroidOutlinedIcon style={{ color: "#F4C274" }} />}
                            edit={updatePhoneNumber}
                            updateFn={UpdatePhoneNumber}
                            editable
                            enableUpdate={setupdatePhoneNumber}
                        >
                            <Box style={{ display: "flex", flexDirection: "row" }}>
                                {
                                    updatePhoneNumber ? (
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <InputBlueBottom
                                                type="text"
                                                name="phoneNumber"
                                                value={currentUser.phoneNumber}
                                                onChange={(e) => handleChange(e)}
                                                placeholder="Numéro de téléphone"
                                            />
                                            <CloseOutlinedIcon onClick={() => setupdatePhoneNumber(false)} style={{ marginLeft: "5px", cursor: "pointer" }} />
                                        </div>
                                    ) : (
                                        <>
                                            <StyledField>
                                                Numéro de téléphone:
                                            </StyledField>
                                            <Typography>
                                                {currentUser?.phoneNumber}
                                            </Typography>
                                        </>
                                    )
                                }
                            </Box>
                        </ProfileBlock>

                    </StyledBox>

                </Box>

            </Box>
        </Box>
    )
}

export default ProfileInfos
