import axios from 'axios';
import { is, curryN, gte } from 'ramda';
import { toast } from 'react-toastify';

const requestTimeout = 15000;

const isWithin = curryN(3, (min, max, value) => {
  const isNumber = is(Number);
  return isNumber(min) && isNumber(max) && isNumber(value) && gte(value, min) && gte(max, value);
});

const in200s = isWithin(200, 299);
const notifyError = (message: string) => {}; //toast.error(message);

function HandleErrors(error: any, url: string) {
  if (axios.isAxiosError(error)) {
    // Handle Axios-specific errors
    if (error.response) {
      // The request was made and the server responded with a status code outside of the range of 2xx
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      // console.log('Error coming from this url', url);

      if (error.response.status === 403) {
        notifyError("Vous n'avez pas les autorisations nécessaires pour accéder à cette ressource !");
      }
      if (error.response.status === 400) {
        notifyError('Merci de vérifier vos paramètres !');
      } else {
        notifyError(`Une erreur est survenue ${error.response.status}, merci de contacter votre administrateur !`);
      }
    } else if (error.request) {
      // The request was made but no response was received or something went wrong while setting up the request
      console.log(error.request, "from this url", url );
      notifyError(`Une erreur est survenue. Merci de réessayer plus tard !`);
    }
    console.error(`Axios Error: ${error.message}`);
  } else {
    // Handle other types of errors
    console.error(`Error: ${error.message}`);
    notifyError(`Error: ${error.message}`);
  }
}

const requestHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*'
};

export const requestChecker = {
  in200s,
  requestHeaders,
  HandleErrors,
  requestTimeout
};
