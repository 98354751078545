import Card from 'components/Cards/Card';
import { InputBlueBottom } from 'components/InputControls.tsx/Inputs';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { userLogin } from 'store/userSlice';
import { Typography, Checkbox } from '@mui/material';
import AuthTemplatePage from 'components/ReusableDivs/AuthTemplatePage';
import LoginCheck from './LoginCheck';
const Label = styled.p`
  font-size: 14px;
  color: red;
`;
const ForgotPassword = () => {
  return (
    <div style={{ marginTop: 30 }}>
      <Link to="/reset-pwd" style={{ color: '#035282' }}>
        Mots de passe oublié ?{' '}
      </Link>
    </div>
  );
};

function Login(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checkSession, setcheckSession] = useState(false);
  const [credentials, setCredentials] = useState({
    login: '',
    password: ''
  });
  const [remainLoggedIn, setRemainLoggedIn] = useState(false);
  const [errors, setErrors] = useState({
    login: '',
    password: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value
    });
  };

  const validateLoginFields = (values) => {
    const errors = {
      login: '',
      password: ''
    };
    const LoginRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!values.login) {
      errors.login = 'Veuillez renseigner votre email ou login';
    } else if (values.login == 'ssiad-gouzon@domisante.com') {
      errors.login = 'Login invalide.';
    } else if (values.login.indexOf('@') >= 0) {
      errors.login = LoginRegex.test(values.login) ? '' : 'Adresse email invalide';
    }
    if (!values.password) {
      errors.password = values.password ? '' : 'Veuillez entrer votre mot de passe';
    }
    setErrors(errors);
    console.log('errors', errors);
    return Object.values(errors).every((x) => x === '');
  };

  const handleSubmit = async (e) => {
    const errors = {
      login: '',
      password: ''
    };
    e.preventDefault();
    if (validateLoginFields(credentials)) {
      let data = credentials;
      if (remainLoggedIn) {
        data = {
          ...credentials,
          longSession: true
        };
      }
      let test = await dispatch(userLogin(data));
      setcheckSession(true);
      console.log('test payload ', test);

      console.log(
        'test.payload.tempToken !== null && test.payload.enable2FA == true && test.payload.channel',
        test.payload.tempToken !== null && test.payload.enable2FA == true && test.payload.channel
      );
      if (test.payload === 401) {
        errors.password = 'Vos identifiants (login et/ou mot de passe) sont incorrects ';
      } else if (test.payload.tempToken !== null && test.payload.enable2FA == true && test.payload.channel) {
        navigate('/verify-otp/' + test.payload.tempToken + '/' + test.payload.channel);
      }
      setErrors(errors);
    }
  };

  return (
    <AuthTemplatePage>
      <Card
        title="Authentification"
        description=" Veuillez saisir votre adresse email ou votre identifiant ainsi que votre mot de passe"
        buttonTitle="Se connecter"
        onClick={handleSubmit}
      >
        <InputBlueBottom
          value={credentials.login}
          onChange={handleChange}
          placeholder="Email / Identifiant"
          required
          type="email"
          name="login"
        />
        <Label>{errors.login}</Label>
        <InputBlueBottom
          value={credentials.password}
          onChange={handleChange}
          placeholder="Mot de passe"
          type={'password'}
          name="password"
          style={{ flex: 1 }}
        />
        <div style={{ alignItems: 'center', position: 'absolute', bottom: '33%', left: '90%' }}></div>
        <Label>{errors.password}</Label>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 6 }}>
          <Checkbox
            sx={{
              '&.Mui-checked': {
                color: '#18C5BF'
              }
            }}
            checked={remainLoggedIn}
            onChange={(e) => {
              setRemainLoggedIn(e.target.checked);
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography>Rester connecté</Typography>
        </div>
        <ToastContainer />
        <ForgotPassword />
        {checkSession == true && <LoginCheck />}
      </Card>
    </AuthTemplatePage>
  );
}

export default Login;
