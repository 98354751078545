import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { colors } from 'utils/Constants'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import dayjs from 'dayjs';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
type LineChartProps = {
    options: any,
    data: any,
    type: string,
    small: boolean,
    double?: boolean,
    data2?: any
}
export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Chart.js Line Chart',
        },
    },
};
function LineChart({ options, data, type, small, double, data2 }: LineChartProps) {
    const [labels, setlabels] = useState<any[]>([])
    const [valuesArray, setvaluesArray] = useState<any[]>([])
    function matchMeasureName(type: string) {
        switch (type) {
            case "BLOOD_PRESSURE":
                return "Tension "
            case "HEART_RATE_MESSAGE":
                return "Fréquence cardiaque "
            case "WEIGHT":
                return "Poids "
            case "TEMP_BODY":
                return "Température "
            case "OXYGEN_SATURATION":
                return "Saturation en oxygène "
            case "OXYGEN_SATURATION":
                return "Saturation en oxygène "
            case "PAIN":
                return "Douleur "
            case "DOLOPLUS":
                return "Doloplus "
            case "DOLOPLUS":
                return "Doloplus "
            case "BLOOD_SUGAR":
                return "Taux de glycémie "
            default:
                break;
        }
    }
    function matchMeasureBorderColor(type: string) {
        switch (type) {
            case "BLOOD_PRESSURE":
                return colors.orange
            case "HEART_RATE_MESSAGE":
            case "URINE":

                return colors.sky_blue
            case "WEIGHT":
                return colors.turquoise
            case "TEMP_BODY":
            case "PAIN":
                return colors.turquoise
            case "OXYGEN_SATURATION":
            case "BLOOD_SUGAR":
            case "STOOL":

                return colors.orange
            default:
                break;
        }
    }
    function matchMeasureColor(type: string) {
        switch (type) {
            case "BLOOD_PRESSURE":
                return colors.light_orange
            case "HEART_RATE_MESSAGE":
            case "URINE":
                return colors.light_sky_blue
            case "WEIGHT":
                return colors.light_turquoise
            case "TEMP_BODY":
            case "PAIN":
                return colors.light_green
            case "OXYGEN_SATURATION":
            case "STOOL":
            case "BLOOD_SUGAR":


                return colors.light_orange
            default:
                break;
        }
    }
    const smallLineChartData = {
        datasets: [{
            fill: true,
            data: data,
            label: matchMeasureName(type),
            borderColor: matchMeasureBorderColor(type),
            backgroundColor: matchMeasureColor(type),
            borderWidth: 1,
        }],
    };

    function getChartParams(data: any) {
        let labelsArray = []
        let valuesArray = []
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (small) {
                labelsArray.push(dayjs(element.eventTime).format('DD/MM/YYYY '))

            } else {
                labelsArray.push(dayjs(element.eventTime).format('DD/MM/YYYY HH:mm'))

            }
            valuesArray.push(element.value)
        }
        setlabels(labelsArray)
        setvaluesArray(valuesArray)
    }
    const chartdata = {
        labels,
        datasets: double ?
            [
                {
                    fill: true,
                    label: matchMeasureName(type),
                    data: valuesArray,
                    borderColor: matchMeasureBorderColor(type),
                    backgroundColor: matchMeasureColor(type),
                    borderWidth: 1,
                },
                double && {
                    fill: true,
                    label: matchMeasureName(type),
                    data: valuesArray,
                    borderColor: matchMeasureBorderColor(type),
                    backgroundColor: matchMeasureColor(type),
                    borderWidth: 1,
                },
            ] : [
                {
                    fill: true,
                    label: matchMeasureName(type),
                    data: valuesArray,
                    borderColor: matchMeasureBorderColor(type),
                    backgroundColor: matchMeasureColor(type),
                    borderWidth: 1,
                },
            ]
    };
    useEffect(() => {
        getChartParams(data)
    }, [data])

    return (
        <Line options={options} data={chartdata} />
    )
}

export default LineChart