import { useState } from "react";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "store/userSlice";

function DropDownMenu() {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const firstname = useSelector((state: any) => state.userDomisante.userInfo.firstname);
    const lastname = useSelector((state: any) => state.userDomisante.userInfo.lastname);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleMouseEnter = () => {
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setIsHover(false);
    };
    const handleButtonClick = () => {
        setDropdownOpen((prevState) => !prevState);
    };


    return (
        <>
            <button
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{
                    display: 'flex',
                    padding: '1.45%',
                    fontSize: '16px',
                    position: 'absolute',
                    right: 0,
                    fontFamily: 'open sans',
                    backgroundColor: (isDropdownOpen || isHover) ? 'inherit' : 'inherit',
                    border: 'none',
                    color: 'white',
                    cursor: 'pointer',
                    textDecoration: (isDropdownOpen || isHover) ? "underline" : "none",
                }}
                onClick={handleButtonClick}>
                <ExitToAppIcon style={{ marginRight: 10 }} />
                <div>
                    {firstname} {lastname}
                </div>
            </button>
            {
                isDropdownOpen && (
                    <div style={{
                        position: 'absolute',
                        top: '8.2%',
                        right: "10px",
                        width: "15%",
                        backgroundColor: 'white',
                        border: '1px solid #ccc',
                        fontWeight: "500",
                        borderRadius: "10px",
                    }}>
                        <div
                            style={{ cursor: 'pointer', color: '#035282', borderBottom: '1px solid #18c5bf', paddingTop: "10px", marginLeft: "30px", marginRight: "30px", paddingBottom: "10px" }}
                            onClick={() => navigate('/profile')}
                        >
                            Mon profil
                        </div>
                        <div
                            style={{ cursor: 'pointer', color: '#035282', paddingTop: "10px", paddingLeft: "30px", paddingRight: "30px", paddingBottom: "10px" }}
                            onClick={() => dispatch(logout())}
                        >
                            Déconnexion
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default DropDownMenu