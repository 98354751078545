import axios from 'axios';

import { is, curryN, gte } from 'ramda';
import { toast } from 'react-toastify';
import { requestChecker } from 'utils/RequestCheck';

const isWithin = curryN(3, (min, max, value) => {
  const isNumber = is(Number);
  return isNumber(min) && isNumber(max) && isNumber(value) && gte(value, min) && gte(max, value);
});

const { REACT_APP_BASE_URL } = process.env;

const in200s = isWithin(200, 299);

const memberApiClient = axios.create({
  baseURL: REACT_APP_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },
  timeout: 50000
});

async function register(data: any) {
  let url = REACT_APP_BASE_URL + '/member/register';
  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      timeout: requestChecker.requestTimeout
    });

    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function loginUser(data: any) {
  let url = REACT_APP_BASE_URL + '/auth/login';
  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      timeout: requestChecker.requestTimeout
    });

    if (requestChecker.in200s(response.status)) {
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function resetPwd(data: any, setter: React.Dispatch<React.SetStateAction<any>>) {
  let url = REACT_APP_BASE_URL + '/auth/reset-password';

  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      timeout: requestChecker.requestTimeout
    });

    if (requestChecker.in200s(response.status)) {
      setter(true);
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function changePwd(data: any) {
  let url = REACT_APP_BASE_URL + '/auth/set-password';
  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      timeout: requestChecker.requestTimeout
    });

    if (requestChecker.in200s(response.status)) {
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}

async function updatePwd(data: any, acc_token: string) {
  let url = REACT_APP_BASE_URL + '/auth/change-password';
  try {
    let response = await axios.request({
      url: url,
      method: 'post',
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        access_token: `${acc_token}`
      },
      timeout: requestChecker.requestTimeout
    });

    if (requestChecker.in200s(response.status)) {
      return response.status;
    } else {
      return null;
    }
  } catch (error: any) {
    requestChecker.HandleErrors(error, url);
    return null;
  }
}
export const memberService = {
  register,
  loginUser,
  resetPwd,
  updatePwd,
  changePwd
};
