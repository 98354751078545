import styled from 'styled-components';

export const Wrapper = styled.div`
  /* width: 100vw; */
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:column ;
 background: linear-gradient(to bottom  , #035282, #109BA9);
`;
export const ForgotWrapper = styled.div`
  min-height: 200px;
  min-width: 400px;
  margin:50px ;
`;
export const LogoWrapper = styled.div`
  // width: 98vw;
  // height: auto ;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ImgContainer = styled.div`
max-width:300px ;
height:auto ;
`
export const Img = styled.img`
width:200px;
min-width:100px ;
/* height: 100%;
 width: 100%; */
 /* object-fit: contain; */
flex-grow: 1;
max-width:100%;
height:auto;
`
