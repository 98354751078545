import React, { useEffect, useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { InputBlueBottom } from 'components/InputControls.tsx/Inputs';
import { Label } from 'screens/homes/Homes';
import CustomSelect from 'components/InputControls.tsx/CustomSelect';
import dayjs, { Dayjs } from 'dayjs';
import { Checkbox, Typography, } from '@mui/material';
import { DemoContainer, } from '@mui/x-date-pickers/internals/demo';


type NewPatientProps = {
    userDetails: any, setuserDetails: any,
    sethelloCareAccount: any,
    setanamneseAccount: any,
    anamneseAccount: boolean,
    helloCareAccount: boolean,
    userErrors: any,
}
function NewPatient({ userDetails, setuserDetails, sethelloCareAccount, setanamneseAccount, helloCareAccount, anamneseAccount,
    userErrors, }: NewPatientProps) {
    const [value, setValue] = useState(userDetails.gender);

    const handleChangePatientDetails = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        setuserDetails({
            ...userDetails, [name]: value
        })
    }

    const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
        setuserDetails({
            ...userDetails, ["gender"]: (event.target as HTMLInputElement).value
        })
    };

    const [dateValue, setDateValue] = useState<Dayjs | null>(userDetails.birthDate ? dayjs(userDetails.birthDate) : dayjs(new Date()))

    const handleDateChange = (newValue: Dayjs | null) => {
        const formattedDate = newValue ? dayjs(newValue).format('YYYY-MM-DD') : '';
        setuserDetails((prevState: any) => ({
            ...prevState,
            birthDate: formattedDate
        }));
        setDateValue(newValue);
    };




    useEffect(() => {

    }, [userErrors])


    return (
        <div>
            <InputBlueBottom
                type="text"
                name="lastname"
                value={userDetails.lastname}
                onChange={(e) => handleChangePatientDetails(e)}
                placeholder="Nom"
                required={true}

            />
            <Label>{userErrors.lastname}</Label>
            <InputBlueBottom
                type="text"
                name="firstname"
                value={userDetails.firstname}
                onChange={(e) => handleChangePatientDetails(e)}
                placeholder="Prénom"
                required={true}

            />
            <Label>{userErrors.firstname}</Label>

            {/* <DatePickerWrapper>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
                    <DesktopDatePicker
                        label="Date de naissance"
                        inputFormat="DD/MM/yyyy"
                        value={dateValue}
                        onChange={handleDateChange}
                        // defaultValue={dayjs('2022-04-17')}
                        maxDate={dayjs(new Date())}
                        renderInput={
                            (params) => {
                                return <CssTextField style={{ width: "100%" }} label="Standard" variant="outlined"
                                    inputProps={{ style: { color: "#035282" } }} {...params}
                                    required={true} />;
                            }

                        }
                    />
                    <DatePicker
                      
                    />
                </LocalizationProvider>
            </DatePickerWrapper> */}
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                <DemoContainer
                    components={[
                        'DatePicker',
                        'MobileDatePicker',
                        'DesktopDatePicker',
                        'StaticDatePicker',
                    ]}
                >

                    <DatePicker
                        //@ts-ignore
                        label="Date de naissance"
                        format="DD/MM/YYYY"
                        value={dateValue}
                        onChange={handleDateChange}
                        defaultValue={dayjs(userDetails.birthDate)}
                        sx={{
                            '& label.Mui-focused': {
                                color: '#035282',
                            },
                            '& .MuiInput-underline:after': {
                                borderBottomColor: '#035282',
                            },
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    borderColor: '#035282',
                                },
                            },
                            '& .MuiInputBase-input': {

                                fontFamily: [
                                    'open sans',

                                ].join(','),

                            },

                            '& .MuiIconButton-root.MuiInputAdornment-root.MuiInputAdornment-positionEnd:not(.Mui-disabled)::before': {
                                display: 'none',
                            },
                        }}
                    />

                </DemoContainer>
            </LocalizationProvider>
            <Label>{userErrors.birthDate}</Label>
            <CustomSelect
                value={value}
                onChange={handleGenderChange}
                label='Genre'
                items={[{ value: "Homme", label: "Homme" }, { value: "Femme", label: "Femme" }]}
            />
            <Label>{userErrors.gender}</Label>
            <InputBlueBottom
                type="email"
                value={userDetails.email}
                name="email"
                onChange={(e) => handleChangePatientDetails(e)}
                placeholder="Email"
                required={true}
            />
            <Label>{userErrors.email}</Label>



            <InputBlueBottom
                type="text"
                name="phoneNumber"
                value={userDetails.phoneNumber}
                onChange={(e) => handleChangePatientDetails(e)}
                placeholder="Numéro de téléphone"
                required={true}

            />
            <Label>{userErrors.phoneNumber}</Label>


            <InputBlueBottom
                type="text"
                name="ssn"
                value={userDetails.ssn}
                onChange={(e) => handleChangePatientDetails(e)}
                placeholder="Numéro de sécurité sociale"
                required={true}

            />
            <Label>{userErrors.ssn}</Label>

            <InputBlueBottom
                type="text"
                name="external_ref"
                value={userDetails.external_ref ? userDetails.external_ref : ""}
                onChange={(e) => handleChangePatientDetails(e)}
                placeholder="Identifiant externe"
            />
            <Label>{userErrors.external_ref}</Label>


            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: 6, }}>
                <Checkbox
                    sx={{
                        "&.Mui-checked": {
                            color: '#18C5BF'
                        }
                    }}
                    checked={anamneseAccount}
                    onChange={(e) => { setanamneseAccount(e.target.checked); }}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography>
                    Activer profil patient Anamnèse
                </Typography>
            </div>

            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: 6, }}>
                <Checkbox
                    sx={{
                        "&.Mui-checked": {
                            color: '#18C5BF'
                        }
                    }}
                    checked={helloCareAccount}
                    onChange={(e) => {
                        sethelloCareAccount(e.target.checked);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography>
                    Activer la téléconsultation
                </Typography>
            </div>
        </div>
    )
}

export default NewPatient