import { TextField } from '@mui/material';
import styled from 'styled-components';
import { useState } from 'react';

export const Input = styled.input`
  padding: 10px 15px;
  margin:20px 0px;
  border: 2px solid #E6E6E9;
  border-radius: 5px;
  min-width:380px ;
  min-height:20px;
  display:block ;
  &:focus {
    outline: none !important;
    border:2px solid #035282;
 }
`;
export const InputBlue = styled.input`
  padding: 7px 0;
  width: 100%;
  font-family: inherit;
  font-size: 14px;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #035282;
  border-left: 0;
  transition: border-bottom-color 0.25s ease-in;
  margin: 20px 0px 0px;

  &:focus {
    border-bottom-color: #035282;
    outline: 0;
  }
`;


const Wrapper = styled.div`
padding-top:5px;
padding-bottom:5px;
font-family: open sans;
`
export const Container = styled.div`
  .input-outline {
    width: 100%;
    position: relative;
    display: flex;
    input {
      margin-top:5px;
      font-size: 14px;
      padding: 12px 10px 7px;
      display: block;
      width: 100%;
      border: none;
      border-radius: 0px;
      color: rgb(116, 132, 143);
      &:focus {
        outline: none;
      }
      &:focus ~ label,
      &:not([value=""]) ~ label {
        top: -7px;
        font-size: 14px;
        color: #035282;
        background: #fff;
        padding: 0px 5px;
      }
     
      &:focus ~ .border {
        border-bottom: 2px solid #035282 !important;
        position: absolute;
        top: 0px;
        pointer-events: none;
        transition: all 0.1s ease;
      }
    }

    label {
      color: rgba(0, 0, 0, 0.54);
      font-size: 14px;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 0px;
      top: 9px;
      transition: 0.2s ease;
    }

    .border {
      border-top: 0;
      border-right: 0;
      border-bottom: 1px solid #035282;
      border-left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      pointer-events: none;
      transition: all 0.3s ease;
    }
  }
`;

interface InputBlueBottomProps {
  type: string;
  placeholder?: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  value: any,
  label?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultvalue?: any;
}

export const CssTextField = styled(TextField)({
  marginTop: "15px", marginBottom: "15px",
  '& label.Mui-focused': {
    color: '#035282',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#035282',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#035282',
    },
  },
  '& .MuiInputBase-input': {

    fontFamily: [
      'open sans',

    ].join(','),

  },

  '& .MuiIconButton-root.MuiInputAdornment-root.MuiInputAdornment-positionEnd:not(.Mui-disabled)::before': {
    display: 'none',
  },

});

export function InputBlueBottom({ type, placeholder, name, required, value, onChange, defaultvalue, disabled, label }: InputBlueBottomProps) {
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Wrapper>

      {defaultvalue != undefined ?
        <>

          <CssTextField
            InputLabelProps={{
              style: { color: '#035282', backgroundColor: "transparent", paddingTop: "15px", paddingBottom: "15px", }
            }}
            label={label ? label : placeholder}
            color="secondary"
            value={value}
            onChange={onChange}
            required={required}
            type={showPassword ? 'text' : type}
            name={name}
            fullWidth
            defaultValue={defaultvalue}
            placeholder={placeholder}
            disabled={disabled ? true : false}
            variant="outlined"
          />
        </>

        : <CssTextField
          InputLabelProps={{
            style: { color: '#035282', backgroundColor: "transparent" }
          }}
          label={placeholder}
          color="secondary"
          value={value}
          onChange={onChange}
          required={required}
          type={type == 'password' ? (showPassword ? 'text' : type) : (type)}
          name={name}
          fullWidth
          placeholder={placeholder}
          disabled={disabled ? true : false}
        />

      }
    </Wrapper>

  )

}

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;